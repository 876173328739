import React, { useEffect, useState } from 'react'
import { AiFillEye } from 'react-icons/ai';
import mycollegepedialogo from "../../assets/images/Logo.png";
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FaEyeSlash } from 'react-icons/fa';
import { LinearProgress } from '@mui/material';
const Newpassword = () => {
    const details = useParams();
    const [warning, setWarning] = useState('');
    const [Loading, setLoading] = useState(true);
    const [viewPassword, setViewPassword] = useState(false);
    const [formData, setFormData] = useState({
        email: details.email,
        token: details.token,
        password: '',
        confirmPassword: ''
    });
    const navigate = useNavigate();

    const passwordViewHandler = () => {
        setViewPassword(!viewPassword);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    useEffect(() => {
        const verifyemail = async () => {
            try {
                const email = details.email;
                const token = details.token;
                const res = await axios.get(`https://portal.mycollegepedia.com/account/password_reset_form/${email}/${token}/`)
                console.log(res);
                if (res.status === 200) {
                    setLoading(false)
                }
                if (res.status === 400) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Invalid Link ',
                        text: 'Please try again.',
                    });
                }
            } catch (error) {
                if (error.response.status === 400) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Invalid Link ',
                        text: 'Please try again.',
                    });
                    navigate('/loginpage')
                }
                console.log(error)
            }
        }
        verifyemail()
    }, [details,navigate])
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);

        if (formData.password !== formData.confirmPassword) {
            setWarning('Password and confirm password do not match.');
            return;
        }
        try {
            const response = await axios.post('https://portal.mycollegepedia.com/account/password_reset_confirm/', formData);

            console.log(response)
            if (response.status === 200) {
                setWarning('');
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    },
                });
                Toast.fire({
                    icon: 'success',
                    // text: response.data.message,
                    title: 'Password updated successfully',
                });
                if (response.status === 400) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Invalid Link ',
                        text: 'Please try again.',
                    });
                }
                navigate(`/loginpage`);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Please try again.',
                });
                console.log("error",response)
                // setWarning(response.data.message || 'Server error. Please try again.');
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'User not found.',
            });
            // setWarning(error.message || 'An error occurred.');
            console.error('Axios Error:', error);
        }
    };
    if (Loading) {
        return <div>
            <div className="my-10 px-3">
                <LinearProgress />
            </div>
        </div>;
    }
    return (
        <div className="main-wrapper">
            <div className="login-page">
                <div className="container login-body">
                    <div className="loginbox">
                        <div className="login-right-wrap">
                            <div className="account-header">
                                <div className="flex justify-center mb-4 text-center account-logo">
                                    <Link to="/">
                                        <img src={mycollegepedialogo} alt="clgLogo" className="w-full brightness-110" />
                                    </Link>
                                </div>
                            </div>
                            <div className="login-header">
                                <h3>
                                    New-password <span>MyCollegePedia</span>
                                </h3>
                            </div>
                            <form onSubmit={handleSubmit} className="max-w-md mx-auto">

                                <div className="relative mb-4 form-group">
                                    <label htmlFor="password" className="control-label">
                                        Password
                                    </label>
                                    <input
                                        type={viewPassword ? 'text' : 'password'}
                                        name="password"
                                        placeholder="Password"
                                        id="password"
                                        required
                                        autoComplete="new-password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        className="h-12 form-control rounded-[4px] border border-[#222222] pl-2 text-[#222222] text-[1em]"
                                    />
                                    <div className="absolute transform -translate-y-1/2 cursor-pointer right-4 top-[72%]">
                                        {viewPassword ? (
                                            <AiFillEye
                                                onClick={passwordViewHandler}
                                                size="20px"
                                                className="text-[#707070]"
                                            />
                                        ) : (
                                            <FaEyeSlash
                                                onClick={passwordViewHandler}
                                                size="20px"
                                                color="#909090"
                                            />
                                        )}
                                    </div>
                                </div>

                                <div className="mb-4 form-group">
                                    <label className="control-label">Confirm Password</label>
                                    <input
                                        type="password"
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                        id="confirmPassword"
                                        required
                                        autoComplete="new-password"
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                        className="h-12 form-control rounded-[4px] border border-[#222222] pr-2 text-[#222222] text-[1em]"
                                    />
                                </div>

                                {warning && (
                                    <div className="mb-4 text-red-500">
                                        {warning}
                                    </div>
                                )}

                                <div className="text-center">
                                    <button
                                        className="!text-[#052963] btn hover:!text-white btn-primary btn-block account-btn"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>


                        </div>
                        <div className="text-center dont-have">
                                    Already have an account? <Link to="/loginpage">Login</Link>
                                </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Newpassword