import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Apicontext from '../../Page/Contextapi';
import { BsFacebook, BsInstagram, BsLinkedin, BsTelephoneForward } from 'react-icons/bs';
import { AiOutlineMail } from 'react-icons/ai';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { motion } from 'framer-motion';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Notificationmsg from '../Notification/Notification';
import { FaXTwitter } from 'react-icons/fa6';
import {FaTelegram, FaWhatsapp, FaYoutube } from 'react-icons/fa';

const TopNav = () => {
  const [detail, setDetails] = useState(null);
  const [open, setOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const { examCount, applicationCount, APIdata } = useContext(Apicontext);

  useEffect(() => {
    const details = APIdata;
    setDetails(details.detail);
    const notifications = examCount + applicationCount;
    setNotificationCount(notifications);
  }, [APIdata]);

  const handleNotification = () => {
    setOpen(!open);
  };

  const handleCloseNotification = () => {
    setOpen(false);
  };
  const handleClearNotifications = () => {
    setNotificationCount(0);
  };
  const handlePhoneClick = () => {
    const phoneNumber = detail && detail.primary_mobile ? detail.primary_mobile : '+917880110983';
    window.location.href = 'tel:' + phoneNumber;
};

  return (
    <>
      <ToastContainer />
    
      <div className="grid items-center justify-between grid-cols-1 py-2 bg-white md:px-20 top-nav-section md:grid-cols-2">
        <div className="d-flex align-items-center top-left-section">
          <div className="transition-transform  items-center ease-in-out hover:text-black duration-500 ">
            <a href={'tel:' + (detail && detail.primary_mobile ? detail.primary_mobile : '+917880110983')} title={`Mycollegepedia | Get in touch with Mycollegepedia. Call us at ${detail && detail.primary_mobile } or email us at mycollegepedia@gmail.com for any queries or support.`} className="flex items-center gap-2" onClick={handlePhoneClick}>
              <BsTelephoneForward className="sm:text-xl text-[#052963] text-lg " />
              <span className="flex  reveal text-[#052963]">{detail && detail ? detail.primary_mobile : '+91 7880110983'}</span>
            </a>
          </div>
          <div className="ransition-transform items-center ease-in-out hover:text-black duration-500 ">
            <a href={'mailto:' + (detail && detail.email ? detail.email : 'mycollegepedia@gmail.com')} title='Mycollegepedia | Email - Get in touch with Mycollegepedia '  className="flex items-center text-[#052963] gap-2  transition-transform ease-in-out ">
              <AiOutlineMail className="sm:text-xl text-[#052963] text-lg " />
              <span className="overflow-x-hidden reveal">{detail && detail ? detail.email : 'mycollegepedia@gmail.com'}</span>
            </a>
          </div>
        </div>
        <div className="justify-center mr-1 top-right-section md:justify-end">
          <div className="hidden md:space-x-3 lg:flex ">
          <Link target='_blank' to='https://www.whatsapp.com/mycollegepediaofficial' aria-label="Visit MyCollegePediaFacebook Page" title="Visit MyCollegePediaFacebook Page"> <FaWhatsapp size={22} color="	#25D366" className="" /></Link>
            <Link target='_blank' to='https://www.facebook.com/mycollegepediaofficial' aria-label="Visit MyCollegePediaFacebook Page" title="Visit MyCollegePediaFacebook Page"> <BsFacebook size={22} color="#1877F2" className="" /></Link>
            <Link target='_blank' to='https://www.instagram.com/my_college_pedia/' aria-label="Visit MyCollegePedia instagram Page" title="Visit MyCollegePedia instagram Page"><BsInstagram size={22} color="#E4405F" className="" /></Link>
            <Link target='_blank' to='https://twitter.com/mycollegepedia'><FaXTwitter size={22} color='#000' className="" aria-label="Visit MyCollegePedia twitter Page" title="Visit MyCollegePedia twitter Page" /></Link>
            <Link target='_blank' to='https://www.telegram.com/in/my-college-pedia/'><FaTelegram size={22} color='#0A66C2' className="" aria-label="Visit MyCollegePedia Linkdin Page" title="Visit MyCollegePedia Linkdin Page" /></Link>
            <Link target='_blank' to='https://www.youtube.com/in/my-college-pedia/'><FaYoutube size={22} color='#ff0000' className="" aria-label="Visit MyCollegePedia Linkdin Page" title="Visit MyCollegePedia Linkdin Page" /></Link>
            <Link target='_blank' to='https://www.linkedin.com/in/my-college-pedia/'><BsLinkedin size={22} color='#0A66C2' className="" aria-label="Visit MyCollegePedia Linkdin Page" title="Visit MyCollegePedia Linkdin Page" /></Link>
            <button onClick={handleNotification}><IoMdNotificationsOutline color='black' size={26} />{notificationCount > 0 && <span className="notification-count">({notificationCount || ''})</span>}</button>
           
          </div>
        </div>
      </div>
      {open && (
        <motion.div
          className="notification-panel"
          initial={{ x: '100%' }}
          animate={{ x: 0 }}
          transition={{ type: 'spring', stiffness: 200, damping: 12 }}
          onClick={handleCloseNotification}
        >
          <div className="notification-content">
            <div className=''>
            {/* <p className='text-danger float-right'><FaTimes /></p> */}
            <h2 className='notification_heading'>Notifications</h2>
            </div>
            
            <Notificationmsg />
            
          </div>
        </motion.div>
      )}
    </>
  );
};

export default TopNav;