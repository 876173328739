import React, { useEffect, useMemo, useState } from "react";
import { FaPlus, FaEdit, FaWhatsapp, FaPhoneVolume, FaFacebookF, FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

import { MdDelete, MdAlternateEmail } from "react-icons/md";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import dimage from '../../assets/images/log-reg-img.avif';
import DireactorUpdate from "./DireactorUpdate";
import { TbWorldWww } from "react-icons/tb";
const Directors = () => {
  const token = useMemo(() => localStorage.getItem("token") || "", []);
  const [directors, setDirectors] = useState([]);
  const [selectRecord, setSelectrecord] = useState(null);
  const [show, setShow] = useState(false);
 
  const handleFunction = (data) => {
    setSelectrecord(data);
    setShow(!show);
  };
  const headers = useMemo(() => ({
    Authorization: `JWT ${token}`,
  }), [token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://portal.mycollegepedia.com/counsellor/director-detail/",
          { headers }
        );
        setDirectors(response.data);
      } catch (error) { }
    };
    fetchData();
  }, [headers, show]);

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `https://portal.mycollegepedia.com/counsellor/director-detail/${id}`,
        { headers }
      );
      setDirectors(directors.filter(director => director.id !== id));
      Swal.fire({
        position:"top-end",
        icon: "success",
        timer:2500,
        text: "deleted successfully!",
    });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <section className="director common_dash my-3">
        <div className="container-fluid">
          <div className="row align-items-center top">
            <div className="col-lg-6">
              <h3>Directors</h3>
            </div>
            <div className="col-lg-6">
              <Breadcrumb className="bread">
                <Breadcrumb.Item><NavLink to="/counsellor-dashboard">Home</NavLink></Breadcrumb.Item>
                <Breadcrumb.Item active>Directors</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          {!show ? (
            <div className="row">
              <div className="col-lg-12">
                <Link
                  to="/counsellor-dashboard/add-director"
                  className="btn-sm flex my-3 text-white float-right items-center bg-[#052963]"
                  title="Add-Director"
                >
                  <FaPlus />
                  Add
                </Link>
              </div>
              {directors.map((elem, i) => (
                <div className="col-lg-6 col-md-12" key={i}>
                  <div className="director_card mt-3">
                    <div className="card">
                      <div className="row">
                        <div className="col-lg-5 col-md-6 pe-0">
                          <div className="left_side">
                            <div className="pic">
                              <img src={dimage} alt="profile" />
                            </div>
                            <h3 className="text-center">{elem.name}</h3>
                          </div>
                        </div>
                        <div className="col-lg-7 col-md-6 ps-1">
                          <div className="right_side">
                            <div className="buttons">
                              <button
                                className="edit"
                                title="Update"
                                onClick={() => handleFunction(elem)}
                              >
                                <FaEdit />
                              </button>
                              <button
                                className="delete"
                                onClick={() => handleDelete(elem.id)}
                              >
                                <MdDelete />
                              </button>
                            </div>
                            <div className="organization_type">
                              <h3>Partnership</h3>
                            </div>
                            <div className="emaill">
                              <h3 className="d-flex"><MdAlternateEmail />Email</h3>
                              <p>{elem.email}</p>
                            </div>
                            <div className="phone">
                              <h3 className="d-flex"><FaPhoneVolume />Contact No.</h3>
                              <p>{elem.primary_mobile}</p>
                              <p>{elem.secondary_mobile}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                        <div className="social_links">
                            <div id="website">
                              <Link to={elem.website ? elem.website : ""}>
                                {" "}
                                <TbWorldWww />
                              </Link>
                            </div>
                            <div id="whatsapp">
                              <Link
                                to={elem.whatsapp ? elem.whatsapp : ""}
                              >
                                <FaWhatsapp />
                              </Link>
                            </div>
                            <div id="facebook">
                              <FaFacebookF />
                            </div>
                            <div id="instagram">
                              <Link
                                to={elem.instagram ? elem.instagram : ""}
                              >
                                <FaInstagram />
                              </Link>
                            </div>

                            <div id="linkedin">
                              <Link
                                to={elem.linkedin ? elem.linkedin : ""}
                              >
                                <FaLinkedin />
                              </Link>
                            </div>
                            <div id="twitter">
                              <FaTwitter />
                            </div>
                            <div id="youtube">
                              <FaYoutube />
                            </div>
                            <div id="telegram">
                              <FaTelegramPlane />
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <DireactorUpdate handleFunction={handleFunction}  selectRecord={selectRecord} />
          )}
        </div>
      </section>
    </>
  );
};

export default Directors;