import React, { useState, useEffect, useContext } from 'react'
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import { MdBackHand } from "react-icons/md";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Apicontext from '../../Page/Contextapi';
import DirectorInfo from './DirectorInfo';
import CompanyInfo from './Company_Info';
import Address from './CounslrAddress';
import CompanyOverview from './CompanayOVerview';

const Registration_Counslr = () => {
    const user = JSON.parse(localStorage.getItem('userprofile')) || {};
    const contextdata = useContext(Apicontext);
    const [activeStep, setActiveStep] = useState(1);
    const [data, setData] = useState([]);
    const [Loading, setLoading] = useState(true);

    const info = useLocation();
    console.log("information ",info)
    const [formData, setFormData] = useState({
        company: {
            counsellor_user:user.id ,
            city: '',
            current_address: '',
            permanent_address: '',
            zipcode: '',
            primary_mobile: '',
            secondary_mobile: '',
            email: '',
            website: '',
            company_name: '',
            logo: '',
            image: '',
            company_certificate: '',
            gst: '',
            pan_no: '',
            pan_card: '',
            overview: '',
            registration_date: '',
            country: '',
            state: '',
            area_of_operation: [],
        },
        director: {
            primary_mobile: '',
            secondary_mobile: '',
            email: '',
            whatsapp: '',
            facebook: '',
            instagram: '',
            linkedin: '',
            twitter: '',
            youtube: '',
            telegram: '',
            website: '',
            name: '',
            profile: '',
            identity: '',
            id_proof: '',
            counsellor: 0,
            organization_type: 0,
        },
    });

    const [statesList, setStatesList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const APIdata = contextdata.APIdata;
        setData(APIdata);
        setLoading(false);
    }, [contextdata]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://portal.mycollegepedia.com/main/state/?country_id=${formData.company.country}`);
                const states = response.data;
                setStatesList(states);
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        };
        if (formData.company.country) {
            fetchData();
        }
    }, [formData.company.country]);

   
    const handleStepChange = (step) => {
        setActiveStep(step);
    };
    const handleSaveAndContinue = () => {
        const nextStep = activeStep + 1;
        handleStepChange(nextStep);
    };

    const handleBack = () => {
        const prevStep = activeStep - 1;
        handleStepChange(prevStep);
    };
    const handleRichTextChange = (name, value) => {
        setFormData((prevState) => ({
            ...prevState,
            company: {
                ...prevState.company,
                [name]: value,
            },
        }));
    };
    const handleInputChange = (step, event) => {
        const { name, value, type, checked, files } = event.target;
        setFormData((prevState) => {
            const newValue =
                type === 'file' ? files[0] :
                type === 'checkbox' ? (checked ? [...prevState[step][name], value] : prevState[step][name].filter(item => item !== value)) :
                value;

            return {
                ...prevState,
                [step]: {
                    ...prevState[step],
                    [name]: newValue,
                },
            };
        });
    };
    
    
    const handleFormSubmit = async (event) => {
        event.preventDefault();

        console.log("formdata compnay ",formData.company)
        console.log("formdata director ",formData.director)
        try {
            const companyResponse = await axios.post('https://portal.mycollegepedia.com/counsellor/register/', formData.company);
            if (companyResponse.status === 201) {
                const directorResponse = await axios.post('https://portal.mycollegepedia.com/counsellor/director-register/', formData.director);
                if (directorResponse.status === 201) {
                    Swal.fire({
                        icon: 'success',
                        position:'top-end',
                        timerProgressBar:true,
                        title: 'Registration Successful',
                        text: 'Your registration was successful.',
                    });
                    navigate('/');
                } else {
                    Swal.fire({
                        icon: 'error',
                        position:'top-end',
                        title: 'Director Registration Failed',
                        text: directorResponse.data.message,
                    });
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    position:'top-end',
                    title: 'Company Registration Failed',
                    text: companyResponse.data.message,
                });
            }
        } catch (error) {
            console.error('Form Submission Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Submission Failed',
                text: 'Please try again later.',
            });
        }
    };

    if (Loading) {
        return (
            <div>
                <div className="my-10 px-3">
                    <LinearProgress />
                </div>
            </div>
        );
    }

    const renderTabs = () => {
        const stepLabels = [
            'Company Info',
            'Overview',
            'Director Info',
            'Address',
            
        ];

        return stepLabels.map((label, index) => (
            <li key={index + 1} role="presentation" className={activeStep === index + 1 ? 'active' : 'disabled'}>
                <a
                    href={`#step${index + 1}`}
                    data-toggle="tab"
                    aria-controls={`step${index + 1}`}
                    role="tab"
                    aria-expanded={activeStep === index + 1 ? 'true' : 'false'}
                    onClick={() => handleStepChange(index + 1)}
                >
                    <span className={`round-tab ${activeStep === index + 1 ? 'active' : ''}`}>{index + 1}</span> <i>{label}</i>
                </a>
            </li>
        ));
    };

    return (
        <div>
            <section className="signup-step-container common_dash">
                <div className="container ">
                    <div className="row align-items-center top ">
                        <div className="col-lg-6">
                            <h3>Welcome {user.name || ''} <MdBackHand className="text-warning anim" /> !</h3>
                        </div>
                        <div className="col-lg-6">
                            <Breadcrumb className="bread">
                                <Breadcrumb.Item ><NavLink to="/">Home</NavLink></Breadcrumb.Item>
                                <Breadcrumb.Item active>counsellor-register</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                    <div className="items-center row d-flex justify-content-center mb-5">
                        <div className="col-md-12">
                            <div className="wizard">
                                <div className="pt-3 wizard-inner">
                                    <div className="connecting-line" />
                                    <ul className="nav nav-tabs" role="tablist">
                                        {renderTabs()}
                                    </ul>
                                </div>
                                <form encType="multipart/form-data" className="login-box" onSubmit={handleFormSubmit}>
                                    <div className="tab-content" id="main_form">
                                        <div className={`tab-pane ${activeStep === 1 ? 'active' : ''}`} role="tabpanel" id="step1">
                                            <CompanyInfo formData={formData.company}  handleInputChange={(e) => handleInputChange('company', e)} />
                                            <ul className="list-inline pull-right">
                                                <li>
                                                    <button
                                                        type="button"
                                                        onClick={handleSaveAndContinue}
                                                        className="text-white default-btn next-step"
                                                    >
                                                        Save & Next Continue
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className={`tab-pane ${activeStep === 2 ? 'active' : ''}`} role="tabpanel" id="step2">
                                            <CompanyOverview data={data} formData={formData.company} handleRichTextChange={handleRichTextChange} handleInputChange={(e) => handleInputChange('company', e)} />
                                            <ul className="list-inline pull-right">
                                                <li>
                                                    <button
                                                        type="button"
                                                        onClick={handleSaveAndContinue}
                                                        className="text-white default-btn next-step"
                                                    >
                                                        Save & Next Continue
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className={`tab-pane ${activeStep === 3 ? 'active' : ''}`} role="tabpanel" id="step3">
                                            <DirectorInfo formData={formData.director} handleInputChange={(e) => handleInputChange('director', e)} />
                                            <ul className="list-inline pull-right">
                                                {activeStep !== 1 && (
                                                    <li>
                                                        <button
                                                            type="button"
                                                            className="default-btn prev-step"
                                                            onClick={handleBack}
                                                        >
                                                            Back
                                                        </button>
                                                    </li>
                                                )}
                                                <li>
                                                    <button
                                                        type="button"
                                                        className="text-white default-btn next-step"
                                                        onClick={handleSaveAndContinue}
                                                    >
                                                        Save & Next Continue
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className={`tab-pane ${activeStep === 4 ? 'active' : ''}`} role="tabpanel" id="step4">
                                            <Address
                                                formData={formData.company}
                                                statesList={statesList}
                                                countryList={data.Country}
                                                handleInputChange={(e) => handleInputChange('company', e)}
                                            />
                                            <ul className="list-inline pull-right">
                                                {activeStep !== 1 && (
                                                    <li>
                                                        <button
                                                            type="button"
                                                            className="default-btn prev-step"
                                                            onClick={handleBack}
                                                        >
                                                            Back
                                                        </button>
                                                    </li>
                                                )}
                                                {activeStep !== 4 ? (
                                                    <li>
                                                        <button
                                                           type="submit"
                                                            className="text-white default-btn next-step"
                                                            onClick={handleSaveAndContinue}
                                                        >
                                                            Save & Next Continue
                                                        </button>
                                                    </li>
                                                ) : (
                                                    <li>
                                                        <button
                                                            type="submit"
                                                            className="text-white default-btn next-step"
                                                        >
                                                            Submit & Return
                                                        </button>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                        <div className={`tab-pane ${activeStep === 5 ? 'active' : ''}`} id="step5">
                                            <p className='px-8 py-2 text-justify'>
                                                Thank you for registering with MyCollegePedia.Our team will review your documents and complete the verification process within a few days. You will be notified via email once the verification is complete.
                                                <i>The MyCollegePedia Team</i>
                                            </p>
                                            <ul className="list-inline pull-right">
                                                {activeStep !== 1 && (
                                                    <li>
                                                        <button
                                                            type="button"
                                                            className="default-btn prev-step"
                                                            onClick={handleBack}
                                                        >
                                                            Back
                                                        </button>
                                                    </li>
                                                )}
                                                <li>
                                                    <button
                                                        type="button"
                                                        className="text-white default-btn next-step"
                                                        onClick={() => navigate('/')}
                                                    >
                                                        Finish & Return
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="clearfix" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default Registration_Counslr;