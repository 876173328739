

const Address = ({ formData, statesList, countryList, handleInputChange }) => {
    return (
        <div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>City / Town *</label>
                        <input
                            className="form-control"
                            type="text"
                            name="city"
                            value={formData.city}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Country *</label>
                        <select
                            className="form-control form-select"
                            name="country"
                            value={formData.country}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Select Country</option>
                            {countryList && countryList.map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>State *</label>
                        <select
                            className="form-control form-select"
                            name="state"
                            value={formData.state}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Select State</option>
                            {statesList && statesList.map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Zip code *</label>
                        <input
                            className="form-control cm-number"
                            type="number"
                            name="zipcode"
                            value={formData.zipcode}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Current Address *</label>
                        <textarea
                            className="form-control"
                            name="current_address"
                            value={formData.current_address}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Permanent Address *</label>
                        <textarea
                            className="form-control"
                            name="permanent_address"
                            value={formData.permanent_address}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Address