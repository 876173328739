import {
    FaCheck, FaFacebook, FaInstagram,
    FaLinkedinIn,
    FaRegClipboard,
    FaTimes,
} from "react-icons/fa";

import { Link, useNavigate, NavLink } from "react-router-dom";
import { MdOutlinePending } from "react-icons/md";
import { useEffect, useState, useMemo, useContext } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";

import Button from "react-bootstrap/Button";
import { FaEye } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import Apicontext from "../../Page/Contextapi";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { MdBackHand } from "react-icons/md";
import Collegeprofileupdate from "./Collegeprofileupdate";

const CollegeProfile = () => {
    const token = useMemo(() => localStorage.getItem("token") || "", []);
    const [collegeData, setCollegeData] = useState(null);
    const [LeadsData, setLeadsData] = useState({});
    const [Loading, setLoading] = useState(true);
    // const [category, setCategory] = useState([]);
    // const [subcategory, setSubCategory] = useState([]);
    const [Editpage, setEditPage] = useState(false);
    const updateopen = () => {
        setEditPage(!Editpage);
    };


    const contextData = useContext(Apicontext);

    const navigate = useNavigate();
    const headers = useMemo(
        () => ({
            Authorization: `JWT ${token}`,
        }),
        [token]
    );

    useEffect(() => {
        const data = contextData.APIdata;
        // setCategory(data.category)
        // setSubCategory(data.subcategory)
        const fetchData = async () => {
            try {
                const collegeResponse = await axios.get(
                    "https://portal.mycollegepedia.com/college/college-detail/",
                    { headers, }
                );
                const leadsResponse = await axios.get("https://portal.mycollegepedia.com/college/college-leads/", {
                    headers,
                }
                );
                setLoading(false);
                setCollegeData(collegeResponse.data[0]);
                // const collegeData = collegeResponse.data[0];
                setLeadsData(leadsResponse.data);

            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem("token");
                    Swal.fire({
                        icon: "error",
                        title: "Please try again Login",
                        text: "There was  an error unauthorized.",
                    });
                    navigate("/login");
                }
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [headers, navigate, contextData]);

    const getLeadCount = (status) => {
        if (LeadsData && LeadsData.length) {
            const filteredLeads = LeadsData.filter((lead) => lead.status === status);
            return filteredLeads.length;
        } else {
            return 0;
        }
    };
    const total = LeadsData.length;
    const data = [
        { value: total, label: "Total" },
        { value: getLeadCount("Pending"), label: "Pending" },
        { value: getLeadCount("Accepted"), label: "Accepted" },
        { value: getLeadCount("Rejected"), label: "Rejected" },
    ];

    const size = {
        width: 450,
        height: 240,
    };
    if (Loading) {
        return (
            <div className="min-h-screen p-3 flex items-center justify-center">
                <div className="w-16 h-16 border-4 border-dashed rounded-full animate-spin border-[#052963] dark:border-[whitesmoke]"></div>
            </div>
        );
    }

    return (

        <div className="mt-3 mb-3 college_profile common_dash">
            {Editpage ? (
                <Collegeprofileupdate updateopen={updateopen} />
            ) : (
                <div className="container">
                    <div className="row align-items-center top ">
                        <div className="col-lg-6">
                            <h3>Welcome <MdBackHand className="text-warning anim" /> !</h3>
                        </div>
                        <div className="col-lg-6">
                            <Breadcrumb className="bread">
                                <Breadcrumb.Item ><NavLink to="/college-dashboard">Home</NavLink></Breadcrumb.Item>

                                <Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                    <div className="mb-4 row">
                        <div className="col-lg-3 view">
                            <div className="card hover:bg-gray-100">
                                <Link to='/college-dashboard/enquiry' title=" Leads | All Application View " >
                                <div className="body ">
                                    <div id="icon">
                                        <FaRegClipboard className="" />
                                    </div>
                                    <div id="data">
                                        <h1 id="total">{total}</h1>
                                        <p>Total Leads</p>
                                    </div>
                                </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-3 view">
                            <div className="card hover:bg-gray-100">
                            <Link to='/college-dashboard/pending' title="Pending | Pending Application View " >
                                <div className="body">
                                    <div id="icon">
                                        <MdOutlinePending />
                                    </div>
                                    <div id="data">
                                        <h1 id="on"> {getLeadCount("Pending")}</h1>
                                        <p>Pending Lead</p>
                                    </div>
                                </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-3 view">
                            <div className="card hover:bg-gray-100">
                            <Link to='/college-dashboard/accepted' title="Accepted | Accepted Application View " >
                                <div className="body">
                                    <div id="icon">
                                        <FaCheck />
                                    </div>
                                    <div id="data">
                                        <h1 id="accept">{getLeadCount("Accepted")}</h1>
                                        <p>Accepted Lead</p>
                                    </div>
                                </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-3 view">
                            <div className="card hover:bg-gray-100">
                            <Link to='/college-dashboard/rejected' title="Rejected | Rejected Application View " >
                                <div className="body">
                                    <div id="icon">
                                        <FaTimes className="" />
                                    </div>
                                    <div id="data">
                                        <h1 id="reject">{getLeadCount("reject")}</h1>
                                        <p>rejected leads</p>
                                    </div>
                                </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="card">
                                <div className="college_pic">
                                    <img src={collegeData.image || " "} title={collegeData.name} alt="college_image" />
                                    <div className="college_log">
                                        <img src={collegeData.logo || " "} title={collegeData.name} alt="collegelogo" />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="my-3 first">
                                        <div className="col_name">
                                            <h5 className="card-title">{collegeData.name}</h5>
                                            {/* <p id="icon">
                                                <FaUniversity />
                                            </p> */}
                                        </div>
                                        <p className="">
                                            Established year :{" "}
                                            <span>{collegeData.established_year}</span>
                                        </p>
                                        <div className="social">
                                            <div className="face">
                                                <FaFacebook />
                                            </div>
                                            <div className="insta">
                                                <FaInstagram />
                                            </div>
                                            <div className="linked">
                                                <FaLinkedinIn />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="edit_form ">

                                                <Button onClick={updateopen} className="text-white"><FaEdit /></Button>

                                            </div>
                                            <div className="view_button ms-2">
                                                <button className="btn btnn">
                                                    <Link
                                                        className="text-white edit-link"
                                                        to={`/colleges/${collegeData.slug}`}>
                                                        {" "}
                                                        <FaEye />
                                                    </Link>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="second">
                                        <div className="add">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h1 className="my-1.5">Address :- </h1>

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-5 col-md-12">
                                                    <h2>Country : </h2></div>
                                                <div className="col-lg-7 col-md-12">

                                                    <p className="lowercase ml-1.5">
                                                        {collegeData.country === 103 ? "india" : '-'}
                                                    </p></div>
                                            </div>
                                            {/* <div className="row">
                                                <div className="col-lg-5 col-md-12">

                                                    <h2>State : </h2></div>
                                                <div className="col-lg-7 col-md-12">

                                                    <p className="lowercase ml-1.5">
                                                        {collegeData.state}
                                                    </p></div>
                                            </div> */}
                                            <div className="row">
                                                <div className="col-lg-5 col-md-12">

                                                    <h2>City : </h2></div>
                                                <div className="col-lg-7 col-md-12">

                                                    <p className="ml-1.5">{collegeData.city}</p></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-5 col-md-12">

                                                    <h2>Current Address: </h2></div>
                                                <div className="col-lg-7 col-md-12">

                                                    <p className="capitalize ml-1.5">
                                                        {collegeData.current_address}
                                                    </p></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-5 col-md-12">

                                                    <h2>Permanent Address: </h2></div>
                                                <div className="col-lg-7 col-md-12">

                                                    <p className="lowercase ml-1.5">
                                                        {collegeData.permanent_address}
                                                    </p></div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-5 col-md-12">

                                                    <h2>Zipcode : </h2></div>
                                                <div className="col-lg-7 col-md-12">
                                                    <p className="lowercase ml-1.5">
                                                        {collegeData.zipcode}
                                                    </p></div>
                                            </div>
                                            {/* <div className="d-flex">
                                                <h2>Country : </h2>
                                                <p>{collegeData.country}</p>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="hidden row md:flex mb-5 ">
                        <div className="col-md-8 md:mt-10">
                            <div className="card ">
                                <PieChart
                                    series={[
                                        {
                                            arcLabel: (item) => (`${item.value}`),
                                            arcLabelMinAngle: 45,
                                            data,
                                        },
                                    ]}
                                    sx={{
                                        [`& .${pieArcLabelClasses.root}`]: {
                                            fill: "white",
                                            fontWeight: "bold",
                                        },
                                    }}
                                    {...size}
                                />
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="overview">
                                    <h1>overview</h1>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: collegeData.overview,
                                        }}
                                    />

                                </div>

                            </div>
                        </div>
                    </div>


                </div>
            )}
        </div>

    );
};

export default CollegeProfile;
