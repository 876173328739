import { useEffect, useState, useMemo } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { LinearProgress } from "@mui/material";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
const AcceptedLeads = () => {
    const token = useMemo(() => localStorage.getItem('token') || '', []);
    const [collegeData, setCollegeData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedLead, setSelectedLead] = useState(null);
    const [newStatus, setNewStatus] = useState('');
    const navigate = useNavigate();
    const headers = useMemo(() => ({
        Authorization: `JWT ${token}`,
    }), [token]);

    const handleStatusChange = async (leadId, status) => {
        setSelectedLead(leadId);
        setNewStatus(status);
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to change the status to ${status}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
        });
        if (result.isConfirmed) {
            await handleSubmit();
        } else {
            setSelectedLead(null);
            setNewStatus('');
        }
    };
    const handleSubmit = async () => {
        try {
            const response = await axios.patch(`https://portal.mycollegepedia.com/counsellor/counsellor-leads/${selectedLead}/`, {
                status: newStatus,
            }, {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            });

            const updatedLead = response.data;
            setCollegeData((prevData) => prevData.map((lead) => (lead.id === updatedLead.id ? updatedLead : lead)));
            navigate('/counsellor-dashboard/leads')
            Swal.fire({
                position: 2500,
                icon: 'success',
                text: 'Status Update successfully!',
            });
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://portal.mycollegepedia.com/counsellor/counsellor-leads/', {
                    headers,
                });
                const data = response.data;
                const AcceptedData = data.filter(item => item.status === "Accepted");
                setCollegeData(AcceptedData)
                setLoading(false);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('token');
                }
                Swal.fire({
                    icon: 'error',
                    title: 'Please try again Login',
                    text: 'There was an error.'
                });
                navigate('/login')
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [headers, navigate]);
    return (
        <div className="p-3 common_dash">
            <div className="row align-items-center top">
                <div className="col-lg-6">
                    <h3>Accepted</h3>
                </div>
                <div className="col-lg-6">
                    <Breadcrumb className="bread">
                        <Breadcrumb.Item ><NavLink to="/counsellor-dashboard">Home</NavLink></Breadcrumb.Item>
                        <Breadcrumb.Item active>Accepted</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>

            <div className='bg-white'>
                {loading ? (
                    <p className="my-5"> <LinearProgress /></p>
                ) : (
                    <div className="table-responsive thin_scroll">
                        {collegeData.length === 0 ? (
                            <table className="table">
                                <thead>
                                    <tr className="bg-[whitesmoke]">
                                        <th>Email</th>
                                        <th>Student Name</th>
                                        <th>Course</th>
                                        <th>Subcategory</th>
                                        <th>Stream</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-[white]">
                                    <p className="text-center my-3 py-1 text-red-600 ">! No records </p>
                                </tbody>
                            </table>
                        ) : (

                            <table className="table">
                                <thead>
                                    <tr className="bg-[whitesmoke]">

                                        <th>Email</th>
                                        <th>Student name</th>
                                        <th>Course</th>
                                        <th>Subcategory</th>
                                        <th>Stream</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-[white]">
                                    {collegeData && collegeData.map((clg, i) => (
                                        <tr key={i}>
                                            <th className="width_define">{clg.student.email}</th>
                                            <th className="width_define">{clg.student.name}</th>
                                            <th className="width_define">{clg.course_category.name}</th>
                                            <th className="width_define">{clg.course_subcategory.course_name}</th>
                                            <th className="width_define">{clg.course_stream.name}</th>
                                            <th className="flex items-center gap-2">

                                                <button
                                                    className="text-white btn-sm bg-warning"
                                                    onClick={() => handleStatusChange(clg.id, 'Pending')}
                                                >
                                                    Pending
                                                </button>
                                                <button
                                                    className="text-white btn-sm bg-danger"
                                                    onClick={() => handleStatusChange(clg.id, 'Rejected')}
                                                >
                                                    Rejected
                                                </button>
                                            </th>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
export default AcceptedLeads;