import React, { useState, useEffect, useMemo, useContext } from 'react';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';
import Apicontext from '../../Page/Contextapi';
import Swal from 'sweetalert2';
import { NavLink, useNavigate } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { TiArrowBack } from 'react-icons/ti';

const CourseAdd = () => {
    const [selectsubcategory, setSelectedSubCategory] = useState('');
    const [stream, setStream] = useState([]);
    const token = useMemo(() => localStorage.getItem('token') || '', []);
    const collegeRecord = useMemo(() => JSON.parse(localStorage.getItem('collegerecord')) || [], []);
    const academic = useMemo(() => JSON.parse(localStorage.getItem('academic')) || [], []);
    const collegeId = collegeRecord.length > 0 ? collegeRecord[0].id : null;
    const contextdata = useContext(Apicontext);
    const Data = contextdata.APIdata;
    const category = Data.subcategory;
    const [formData, setFormData] = useState({
        id: '',
        college: collegeId || '',
        academic_year: academic[0]?.id || '',
        course_subcategory: '',
        course_stream: '',
        year_fees: '',
    });

    const headers = useMemo(() => ({
        Authorization: `JWT ${token}`,
    }), [token]);

    useEffect(() => {
        const fetchData = async () => {
            if (selectsubcategory) {
                try {
                    const streamRes = await axios.get(`https://portal.mycollegepedia.com/general-setting/all_course_stream/?course_subcategory=${selectsubcategory}`, {
                        headers: {
                            Authorization: `JWT ${token}`,
                            'Content-Type': 'application/json',
                        },
                    });
                    setStream(streamRes.data || []);
                } catch (error) {
                    console.error('Error fetching subcategories:', error);
                }
            }
        };
        fetchData();
    }, [headers, selectsubcategory]);

    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        const selectedstream = category.find((cat) => cat.id == value);
        if (name === 'course_subcategory') {
     
            setSelectedSubCategory(selectedstream ? selectedstream.course_name : '');
        }
    };

    const handleSubmitAdd = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://portal.mycollegepedia.com/college/course-fees/', formData, {
                headers: {
                    Authorization: `JWT ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            console.log(response);
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            });
            Toast.fire({
                icon: "success",
                title: "Add course fee successfully"
            });
            navigate('/college-dashboard/college-course')
            setFormData({});

        } catch (error) {
            console.error('Error adding data:', error);

            Swal.fire({
                icon: 'error',
                title: 'Error',
                position: 'center-end',
                timer: 2000,
                text: 'Failed to add data. Please try again.',
            });
        }
    };

    return (
        <div className='container px-4 common_dash'>
            <div className="row align-items-center top">
                <div className="col-lg-6">
                    <h3>Add Course Fee</h3>
                </div>
                <div className="col-lg-6">
                    <Breadcrumb className="bread">
                        <Breadcrumb.Item><NavLink to="/college-dashboard">Home</NavLink></Breadcrumb.Item>
                        <Breadcrumb.Item active>College Add Course</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className='row'>
                <div className='float-right grid ml-auto mx-5'>
                    <NavLink to={'/college-dashboard/college-course'} className="btn-sm shadow-sm flex m-3 gap-1.5 float-right text-white items-center border bg-[#052963]">
                        <TiArrowBack /> Back
                    </NavLink>
                </div>
            </div>
            <Modal.Body>
                <Form onSubmit={handleSubmitAdd}>
                    <div className="col-12">
                        <div className="form-group">
                            <label>Academic Year</label>
                            <input
                                type="text"
                                name="academic_year"
                                className="form-control disble"
                                readOnly
                                placeholder="Academic"
                                value={academic[0].year}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <Form.Group controlId="courseSubcategory">
                            <Form.Label>Course Subcategory</Form.Label>
                            <Form.Control
                                as="select"
                                name="course_subcategory"
                                onChange={handleChange}
                            >
                                <option value="">Select Subcategory</option>
                                {category.map((subcategory) => (
                                        <option key={subcategory.id} value={subcategory.id}>
                                            {subcategory.course_name}
                                        </option>
                                    ))}
                            </Form.Control>
                        </Form.Group>
                    </div>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label htmlFor='stream'>Course Stream</label>
                            <select
                                className='form-control'
                                id='stream'
                                name='course_stream'
                                onChange={handleChange}
                            >
                                <option value=''>Select Stream</option>
                                {stream.map((stream) => (
                                    <option key={stream.id} value={stream.id}>
                                        {stream.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label>Year Fees</label>
                            <input
                                type="number"
                                name="year_fees"
                                className="form-control"
                                placeholder="Yearly fees"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <Button variant="primary" className='text-black' type="submit">
                        Add Course
                    </Button>
                </Form>
            </Modal.Body>
        </div>
    );
};

export default CourseAdd;
