import React from "react";
import { LinearProgress } from "@mui/material";


const CounDetails = ({ counsellordata }) => {
    if (!counsellordata) {
        return (
            <div className="my-12 p-4"><LinearProgress /></div>
        )
    }
    return (


        <div className='container'>
            <div className="row p-0">
                <div className='col-lg-12 p-0'>
                    <div className="table-responsive">
                        <h3 className='single_page_heading'>{counsellordata.company_name}</h3>
                        <table class="table table-striped">
                            <thead>   
                                <tr>
                                    <th scope="col tableheadingcolor">Particulars</th>
                                    <th scope="col tableheadingcolor">Statistics </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td> Company Name</td>
                                    <td>{counsellordata.company_name}</td>
                                </tr>
                                {counsellordata.registration_date &&
                                    <tr>

                                        <td>Rgistration year </td>
                                        <td>{counsellordata.registration_date}</td>
                                    </tr>
                                }
                               
                                {counsellordata.area_of_operation &&
                                    <tr>
                                        <td>area of operation</td>
                                        <td>
                                            {counsellordata.area_of_operation?.map((cn) => (
                                                <span key={cn.id}> {cn.name || "-"} {' | '}</span>
                                            ))}
                                        </td>
                                    </tr>
                                }
                                {counsellordata.primary_mobile &&
                                    <tr>

                                        <td>contact</td>
                                        <td>{counsellordata.primary_mobile || '-'}</td>
                                    </tr>
                                }
                                {counsellordata.email &&
                                    <tr>
                                        <td>email </td>
                                        <td>{counsellordata.email || '-'}</td>
                                    </tr>
                                }
                                <tr>
                                    <td>
                                        Current Address
                                    </td>
                                    <td>
                                        <div className='row'>
                                            <div className='col-lg-6 table_row_color'>
                                                City:
                                            </div>
                                            <div className='col-lg-6 table_row_color'>
                                                {counsellordata.city}
                                            </div>
                                            <div className='col-lg-6'>
                                                State:
                                            </div>
                                            <div className='col-lg-6'>
                                            {counsellordata.state}
                                            </div>
                                            <div className='col-lg-6 table_row_color'>
                                                Zip Code:
                                            </div>
                                            <div className='col-lg-6 table_row_color'>
                                               {counsellordata.zipcode}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CounDetails