import React, { useEffect, useMemo } from "react";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import Swal from "sweetalert2";
import { TiArrowBack } from "react-icons/ti";

const DireactorUpdate = ({ selectRecord, handleFunction }) => {
    const token = useMemo(() => localStorage.getItem("token") || "", []);
    console.log("data", selectRecord)
    const [formData, setFormData] = useState({ ...selectRecord });
    console.log("formdata", formData)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            profile: imageFile,
        }));
    };
    const handleIdproofChange = (e) => {
        const id_proofs = e.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            id_proof: id_proofs,
        }));
    };
    const handleUpdate = async (e) => {
        e.preventDefault();
        const id = formData.id;
        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.name || "");
        formDataToSend.append("counsellor", formData.counsellor || "");
        formDataToSend.append("email", formData.email || "");
        formDataToSend.append("primary_mobile", formData.primary_mobile || "");
        formDataToSend.append("secondary_mobile", formData.secondary_mobile || "");
        formDataToSend.append("website", formData.website || "");
        formDataToSend.append("whatsapp", formData.whatsapp || "");
        formDataToSend.append("facebook", formData.facebook || "");
        formDataToSend.append("instagram", formData.instagram || "");
        formDataToSend.append("linkedin", formData.linkedin || "");
        formDataToSend.append("twitter", formData.twitter || "");
        formDataToSend.append("youtube", formData.youtube || "");
        formDataToSend.append("telegram", formData.telegram || "");

        if (formData.id_proof && typeof formData.id_proof !== "string") {
            formDataToSend.append("id_proof", formData.id_proof);
        }
        if (formData.profile && typeof formData.profile !== "string") {
            formDataToSend.append("profile", formData.profile);
        }

        try {
            const response = await axios.patch(
                `https://portal.mycollegepedia.com/counsellor/director-detail/${id}/`,
                formDataToSend,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `JWT ${token}`,
                    },
                }
            );
            // console.log(response)
            setFormData(response.data);
            if (response.status === 200) {
                Swal.fire({
                    icon: "success",
                    text: "Data updated successfully!",
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "There was an error in updating the data. Please try again.",
                });
            }

            handleFunction(null);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "There was an error. Please try again.",
            });
            handleFunction(null);
            console.error("Error updating:", error);
        }
    };
    return (
        <div>
            <div className='justify-end row'>
                <button onClick={() => handleFunction()} title="back" className="btn-sm shadow-sm flex m-3 gap-1.5 md:float-right text-white items-center border bg-[#052963]">
                    <TiArrowBack />
                    back
                </button>
            </div>
            <div className='row'>
                <Modal.Body>
                    <form
                        className="p-3 common_dash_form"
                        onSubmit={handleUpdate}
                        encType="multipart/form-data"
                    >
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        placeholder="Jhon"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        className="form-control lowercase"
                                        name="email"
                                        placeholder="email@email.com"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Primary no.</label>
                                    <input
                                        type="tel"
                                        className="form-control cm-number"
                                        name="primary_mobile"
                                        value={formData.primary_mobile}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Secondary no.</label>
                                    <input
                                        type="tel"
                                        className="form-control"
                                        name="secondary_mobile"
                                        placeholder="+9124578259"
                                        value={formData.secondary_mobile}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Profile Image</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        name="image"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                    />
                                    {/* {formData.profile && <span > {formData.profile}</span>} */}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Instagram</label>
                                    <input
                                        type="url"
                                        className="form-control"
                                        name="instagram"
                                        placeholder="https://"
                                        value={formData.instagram}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Twitter</label>
                                    <input
                                        type="url"
                                        className="form-control"
                                        name="twitter"
                                        placeholder="https://"
                                        value={formData.twitter}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>LinkedIn</label>
                                    <input
                                        type="url"
                                        className="form-control"
                                        name="linkedin"
                                        placeholder="https://"
                                        value={formData.linkedin}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Whatsapp</label>
                                    <input
                                        type="url"
                                        className="form-control"
                                        name="whatsapp"
                                        placeholder="1123456789"
                                        value={formData.whatsapp}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Youtube</label>
                                    <input
                                        type="url"
                                        className="form-control"
                                        name="youtube"
                                        placeholder="https://"
                                        value={formData.youtube}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Telegram</label>
                                    <input
                                        type="url"
                                        className="form-control"
                                        name="telegram"
                                        placeholder="https://"
                                        value={formData.telegram}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Website</label>
                                    <input
                                        type="url"
                                        className="form-control"
                                        name="website"
                                        placeholder="https://"
                                        value={formData.website}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Facebook</label>
                                    <input
                                        type="url"
                                        className="form-control"
                                        name="facebook"
                                        placeholder="https://"
                                        value={formData.facebook}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <Modal.Footer>
                            <Button className="close_director" onClick={() => handleFunction()}>
                                Close
                            </Button>
                            <Button className="save_director" type="submit">
                                Save
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
            </div>
        </div>
    )
}

export default DireactorUpdate