import React from 'react'
import Header from './Header'

const CounselorLayout = () => {
  return (
    <>
        <Header />
    </>
  )
}

export default CounselorLayout