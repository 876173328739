import axios from 'axios';
import React, { useState, useContext, useEffect, useMemo } from 'react';
import Swal from 'sweetalert2';
import Apicontext from '../../Page/Contextapi';
import { useNavigate } from 'react-router-dom';

const Applyform = ({ collegeData, closeModal, selectedCourse }) => {
  const contextdata = useContext(Apicontext);
  const userData = JSON.parse(localStorage.getItem('userprofile')) || {};
  const token = useMemo(() => localStorage.getItem('token') || '', []);
  const [selectcategory, setSelectedCategory] = useState('');
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const [selectsubcategory, setSelectedSubCategory] = useState('');
  const [streamOptions, setStreamOptions] = useState([]);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    student: userData.id,
    college: collegeData.id,
    course_category: '',
    course_subcategory: '',
    course_stream: selectedCourse ? selectedCourse.course_stream : '',
  });
  const data = contextdata.APIdata;
  const category = data.category;

  useEffect(() => {
    const fetchSubcategories = async () => {
      if (selectcategory) {
        try {
          const subcategoryRes = await axios.get(`https://portal.mycollegepedia.com/general-setting/all_course_subcategory/?course_category=${selectcategory}`, {
            headers: {
              Authorization: `JWT ${token}`,
              'Content-Type': 'application/json',
            },
          });
          setSubcategoryOptions(subcategoryRes.data || []);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
            Swal.fire({
              icon: 'error',
              title: 'User Unauthorized',
              text: 'Please try again.',
            });
            navigate('/login');
          }
          console.error('Error fetching subcategories:', error);
        }
      }
      if (selectsubcategory) {
        try {
          const streamRes = await axios.get(`https://portal.mycollegepedia.com/general-setting/all_course_stream/?course_subcategory=${selectsubcategory}`, {
            headers: {
              Authorization: `JWT ${token}`,
              'Content-Type': 'application/json',
            },
          });
          setStreamOptions(streamRes.data || []);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
            Swal.fire({
              icon: 'error',
              title: 'User Unauthorized',
              text: 'Please try again.',
            });
            navigate('/login');
          }
          console.error('Error fetching streams:', error);
        }
      }
    };
    fetchSubcategories();
  }, [selectcategory, selectsubcategory, token, navigate]);

  const handleApply = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://portal.mycollegepedia.com/college/apply/', formData, {
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      });
      Swal.fire({
        icon: 'success',
        title: 'Successfully Applied!',
        text: 'Your application has been submitted successfully.',
        toast: true,
        position: 'top-end', 
        timer: 5000, 
        timerProgressBar: true,
      });
      closeModal();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        Swal.fire({
          icon: 'error',
          title: 'User Unauthorized',
          text: 'Please try again.',
          toast: true,
          position: 'top-end', 
          timer: 5000, 
          timerProgressBar: true,
        });
        navigate('/login');
      }
      const errorMessage = error.response?.data?.message || 'Something went wrong with the application submission!';
      Swal.fire({
        icon: 'error',
        title: 'Application Error',
        text: errorMessage,
        toast: true,
        position: 'top-end', 
        timer: 5000, 
        timerProgressBar: true,
      });
      console.error('Error submitting application:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const selected = category.find((cat) => cat.id == value);
    const selectedstream = subcategoryOptions.find((cat) => cat.id == value);

    setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
    }));
    if (name === 'course_category') {
        setSelectedCategory(selected ? selected.name : '');
    }
    if (name === 'course_subcategory') {
        setSelectedSubCategory(selectedstream ? selectedstream.course_name : '');
    }
};

// console.log("selectcourse",selectedCourse)
  return (
    <div className='container'>
      <div className='max-w-[600px]'>
        <section className="pdf pb-3 border-b-4 border-[lightgray]">
          <div className="row align-items-center">
            <div className="col-lg-3 pic">
              <img src={collegeData ? collegeData.logo : "images/banner.jpg"} alt={collegeData.name}  title={collegeData.name} className='h-20 max-w-20' />
            </div>
            
            <div className="flex flex-wrap justify-center col-lg-9 text-end">
              <h2>{collegeData.name} </h2>
            </div>
          </div>
        </section>
        <form onSubmit={handleApply} className='grid py-2 mx-auto'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='courseCategory'>Course Category</label>
                <select
                  className='form-control'
                  id='Category'
                  name='course_category'
                  onChange={handleChange}
                  value={formData.course_category}
                >
                  <option value=''>Select Category</option>
                  {category &&
                    category.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='subcategory'>Course Subcategory</label>
                <select
                  className='form-control'
                  id='subcategory'
                  name='course_subcategory'
                  onChange={handleChange}
                  value={formData.course_subcategory}
                >
                  <option value=''>Select Subcategory</option>
                  {subcategoryOptions &&
                    subcategoryOptions.map((subcategory) => (
                      <option key={subcategory.id} value={subcategory.id}>
                        {subcategory.course_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='form-group'>
                <label htmlFor='stream'>Course Stream</label>
                <select
                  className='form-control'
                  id='stream'
                  name='course_stream'
                  onChange={handleChange}
                  value={formData.course_stream}
                >
                  <option value=''>Select Stream</option>
                  {streamOptions &&
                    streamOptions.map((stream) => (
                      <option key={stream.id} value={stream.id}>
                        {stream.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
          <button type='submit' className='btn bg-[#06c3ff] text-white hover:text-gray-300'>
            Apply
          </button>
        </form>
      </div>
    </div>
  );
};

export default Applyform;