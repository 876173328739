 import React, { useEffect, useState } from 'react';
import './index.css';
import './assets/css/style.css';
import './assets/css/newresponsive.css';
import './assets/css/responsive.css';
import { BrowserRouter as Router, Route, Routes, } from 'react-router-dom';
import Home from './Component/Homepages/Home';
import About from './Component/About/About';
import Layout from './Component/Layout';
import Nopage from './Component/Nopage';
// import AllExams from './Component/Exams/AllExams';
import Courses from './Component/Course/Courses';
import Registration from './Component/Registration/Registration';
import Dashboard from './Component/Dashboard/Dashborad';
import Userprofile from './Component/Dashboard/Profile'; 
import Studentprofile from './Component/Dashboard/Studentprofile';
import Stepform from './Component/Dashboard/Stepform';
import Appliedcollege from './Component/Dashboard/Appliedcollege';
import SignupPage from './Component/Login/SignupPage';
import Loginpage from './Component/Login/Loginpage';
import Collegespage from './Component/Colleges/Collegespage';
import Contact from './Component/Contact-us/Contact';
import VerifyOtp from './Component/Login/VerifyOtp';
import CollegeCategory from './Component/Colleges/CollegeCategory';
import Examdetails from './Component/Exams/Examdetails';
import Collegesingle from './Component/Colleges/Collegesingle';
import CollegeState from './Component/Colleges/CollegeState';
import Termandcondition from './Component/Term&condition/Term&condition';
import Privacy from './Component/Term&condition/Policy';
import Broucher from './Component/Colleges/Broucher';
import CollegeLayout from './Component/CollegeDashboard/CollegeLayout';
import CounselorLayout from './Component/Counselordashboard/CounselorLayout';
import CollegeProfile from './Component/CollegeDashboard/CollegeProfile';
import Leds from './Component/CollegeDashboard/Leds';
import CollegeApplication from './Component/CollegeDashboard/CollegeApplication';
import Eligibility from './Component/CollegeDashboard/Eligibility';
import CollegeVideos from './Component/CollegeDashboard/CollegeVideos';
import CollegeExam from './Component/CollegeDashboard/CollegeExam';
import FAQ from './Component/CollegeDashboard/FAQ';
import Colleges from './Component/CollegeDashboard/College';
import Awards from './Component/CollegeDashboard/Awards';
import Blog from './Component/CollegeDashboard/Blog';

import Pending from './Component/CollegeDashboard/Pending';
import Accepted from './Component/CollegeDashboard/Accepted';
import Rejected from './Component/CollegeDashboard/Rejected';
import UpcomingExam from './Component/CollegeDashboard/UpcomingExam';
import UserProfile from './Component/CollegeDashboard/UserProfile';
import ScrollToTop from './ScrollTopage';
import Courseadd from './Component/CollegeDashboard/Courseadd';
import PendingLeads from './Component/Counselordashboard/Pendingleads';
import AllLeads from './Component/Counselordashboard/AllLeds';
import AcceptedLeads from './Component/Counselordashboard/Acceptedleds';
import RejectedLeads from './Component/Counselordashboard/Rejectedleads';
import Profile from './Component/Counselordashboard/Profile';
import CompanyProfile from './Component/Counselordashboard/CompanProfile';
import Directors from './Component/Counselordashboard/Director';
import Testimonial from './Component/Counselordashboard/Testimonial';
import StudentPending from './Component/Dashboard/StudentPending';
import StudentAccepet from './Component/Dashboard/StudentAccepet';
import StudentRejected from './Component/Dashboard/StudentRejected';
import StudentProfile from './Component/CollegeDashboard/StudentPrpfile';
import Newpassword from './Component/Login/Newpassword';
// import Lmshome from './Component/Lms_collegepedia/Lmshome';
import Notificationmsg from './Component/Notification/Notification';
import Notificationclg from './Component/Notification/Notificationclg';
import Blogpage from './Component/Blogs/Blogpage';
import AddFAQ from './Component/CollegeDashboard/FAQsAdd';
import UpdateFAQ from './Component/CollegeDashboard/FAQsUpdate';
import CollegeAddblog from './Component/CollegeDashboard/CollegeAddblog';
import CollegeCourseFee from './Component/CollegeDashboard/CollegeCourseFee';
import AddAwardachivement from './Component/CollegeDashboard/AddAwardachivement';
import Collegepredictor from './Component/Collegepredictor/Collegepredictor';
import BlogSingle from './Component/Blogs/Blogsingle';
import Directoradd from './Component/Counselordashboard/Directoradd';
import TesitmonialAdd from './Component/Counselordashboard/TesitmonialAdd';
import NewsandAlert from './Component/CollegeDashboard/News&alert/NewsandAlert';
import AddNewsandAleret from './Component/CollegeDashboard/News&alert/AddNewsandAleret';
import ChatBot from './Component/ChatBot/Chatbot';
import Everyexam from './Component/Exams/Everyexam';
import NewsandAlertCounslr from './Component/Counselordashboard/News&alert/NewsandAlertcounslr';
import AddNewsandAleretCounslr from './Component/Counselordashboard/News&alert/AddNewsandAleretcounslr';
import SingleCounsellor from './Component/councellorfrontend/SingleCounsellor';
import Counsellorpage from './Component/councellorfrontend/CounsellorAll/Counsellorpage';
import Counsellor_reg from './Component/Registration/Counsellor_reg';
import Registration_Counslr from './Component/Registration/Registration_Counslr';
// import FeedbackPopup from './Page/FeedbackPopup';
// import Allcoursecards from './Component/Lms_collegepedia/Allcoursecards';


const App = () => {
  // const [showPopup, setShowPopup] = useState(false);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowPopup(true);
  //   }, 10000);
  //   return () => clearTimeout(timer);
  // }, []);

  // const handleClosePopupmodal = () => {
  //   setShowPopup(false);
  // };
 
  return (
    <>
      <Router>
        <ScrollToTop />
        {/* {showPopup && (
          <FeedbackPopup onClose={handleClosePopupmodal} />
        )} */}
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/all-colleges" element={<Collegespage />} />
            <Route path="/all-exams" element={<Everyexam />} />
            <Route path="/counsellor/:slug" element={<SingleCounsellor />} />
            <Route path="/all-counsellor" element={<Counsellorpage />} />
            <Route path="/contact-us" element={<Contact />} />

            <Route path="/college/predictor/" element={<Collegepredictor />} />
            <Route path={`/exam/:slug`} element={<Examdetails />} />
            <Route path={`/college/:slug`} element={<Collegesingle />} />
            <Route path="/category/:courseCategory" element={<CollegeCategory />} />
            <Route path="/state/:state_name" element={<CollegeState />} />
            <Route path={`/broucher/:slug`} element={<Broucher />} />
            <Route path={`/blog/:blog`} element={<BlogSingle />} />
            <Route path={`/blogs`} element={<Blogpage />} />
            {/* <Route path="/course" element={<Course />} /> */}
            <Route path="/courses" element={<Courses />} />
            {/* <Route path="/lmshome" element={<Lmshome />} /> */}
            {/* <Route path="/lmscards" element={<Allcoursecards />} /> */}

            <Route path="/terms-condition" element={<Termandcondition />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/login" element={<Loginpage />} />
            <Route path="/counsellor/registration" element={<Counsellor_reg />} />
            <Route path="/counsellor/register" element={<Registration_Counslr />} />

            <Route path="/counsellor/verifyotp" element={<VerifyOtp />} />
            <Route path="/verifyotp" element={<VerifyOtp />} />
            <Route path={`/password-reset/:email/:token`} element={<Newpassword />} />

            <Route path="/chatbot" element={<ChatBot />} />
            <Route path='/notification' element={<Notificationmsg />} />
            <Route path='notificationclg' element={<Notificationclg />} />
            <Route path="*" element={<Nopage />} /> 
          </Route>
          <Route path="/student-dashboard" element={<Dashboard />}>
            <Route index path="/student-dashboard/home" element={<Userprofile />} />
            <Route path="/student-dashboard/profile" element={<Stepform />} />
            <Route path="/student-dashboard/student-profile" element={<Studentprofile />} />
            <Route path="/student-dashboard/reg" element={<Registration />} />
            <Route path="/student-dashboard/appliedcollege" element={<Appliedcollege />} />
            <Route path="/student-dashboard/student-pending" element={<StudentPending />} />
            <Route path="/student-dashboard/student-accepted" element={<StudentAccepet />} />
            <Route path="/student-dashboard/student-rejected" element={<StudentRejected />} />
          </Route>

          <Route path="/counsellor-dashboard" element={<CounselorLayout />}>
            <Route index path='/counsellor-dashboard/home' element={<Profile />} />
            <Route path="/counsellor-dashboard/company-profile" element={<CompanyProfile />} />
            <Route path="/counsellor-dashboard/director" element={<Directors />} />

            <Route path="/counsellor-dashboard/add-director" element={<Directoradd />} />

            <Route path="/counsellor-dashboard/Testimonial" element={<Testimonial />} />
            <Route path="/counsellor-dashboard/add-testimonial" element={<TesitmonialAdd />} />
            <Route path='/counsellor-dashboard/leads' element={<AllLeads />} />
            <Route path="/counsellor-dashboard/pending" element={<PendingLeads />} />
            <Route path="/counsellor-dashboard/accepted" element={<AcceptedLeads />} />
            <Route path="/counsellor-dashboard/rejected" element={<RejectedLeads />} />

            <Route path='/counsellor-dashboard/news-articles' element={<NewsandAlertCounslr />} />
            <Route path='/counsellor-dashboard/add-news-articles' element={<AddNewsandAleretCounslr />} />
          </Route>

          <Route path='/college-dashboard' element={<CollegeLayout />} >
            <Route index path='/college-dashboard/home' element={<CollegeProfile />} />
            <Route path='/college-dashboard/user-profile' element={<UserProfile />} />
            <Route path='/college-dashboard/college' element={<Colleges />} />
            <Route path='/college-dashboard/enquiry' element={<Leds />} />
            <Route path='/college-dashboard/enquiry/Student-profile/:id' element={<StudentProfile />} />
            <Route path='/college-dashboard/pending/Student-profile/:id' element={<StudentProfile />} />
            <Route path='/college-dashboard/accepted/Student-profile/:id' element={<StudentProfile />} />
            <Route path='/college-dashboard/rejected/Student-profile/:id' element={<StudentProfile />} />
            <Route path="/college-dashboard/pending" element={<Pending />} />
            <Route path="/college-dashboard/accepted" element={<Accepted />} />
            <Route path="/college-dashboard/rejected" element={<Rejected />} />
            <Route path='/college-dashboard/Application' element={<CollegeApplication />} />
            <Route path='/college-dashboard/eligibility' element={<Eligibility />} />
            <Route path='/college-dashboard/college-videos' element={<CollegeVideos />} />
            <Route path='/college-dashboard/college-faqs' element={<FAQ />} />
            <Route path='/college-dashboard/college-faqs/college-add-faqs' element={<AddFAQ />} />
            <Route path='/college-dashboard/college-faqs/update-faqs/:id' element={<UpdateFAQ />} />
            <Route path='/college-dashboard/exams' element={<CollegeExam />} />
            <Route path='/college-dashboard/upcoming-exams' element={<UpcomingExam />} />
            <Route path='/college-dashboard/awards-achivements' element={<Awards />} />
            <Route path='/college-dashboard/add-awards-achivements' element={<AddAwardachivement />} />
            <Route path='/college-dashboard/news-articles' element={<NewsandAlert />} />
            <Route path='/college-dashboard/add-news-articles' element={<AddNewsandAleret />} />
            <Route path='/college-dashboard/college-course' element={<CollegeCourseFee />} />
            <Route path='/college-dashboard/add-course-fee' element={<Courseadd />} />
            <Route path='/college-dashboard/blog' element={<Blog />} />
            <Route path='/college-dashboard/add-blog' element={<CollegeAddblog />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
};
export default App;