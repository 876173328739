import { useEffect, useState, useMemo } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { LinearProgress } from "@mui/material";
import { FaPen, FaTimes, } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const AllLeads = () => {
    const token = useMemo(() => localStorage.getItem('token') || '', []);
    const [collegeData, setCollegeData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [Lead_id, setLead_id] = useState(null);
    const [newStatus, setNewStatus] = useState('');
    const navigate = useNavigate();
    const handleModalClose = () => {
        setShowModal(false);
        setLead_id(null);
    };

    const handleModalShow = (lead) => {
        setLead_id(lead);
        setShowModal(true);
    };

    const handleChange = (e) => {
        setNewStatus(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.patch(`https://portal.mycollegepedia.com/counsellor/counsellor-leads/${Lead_id}/`, {
                status: newStatus,
            }, {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            });

            const updatedLead = response.data;
            setCollegeData((prevData) => prevData.map((lead) => (lead.id === updatedLead.id ? updatedLead : lead)));
            Swal.fire({
                timer:2500,
                position: 'top-end',
                icon: 'success',
                text: 'Status Update successfully!',
            });
            handleModalClose();
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://portal.mycollegepedia.com/counsellor/counsellor-leads/', {
                    headers: {
                        Authorization: `JWT ${token}`,
                    },
                });
                const data = response.data;
                setCollegeData(data);
                setLoading(false);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('token');
                    Swal.fire({
                        icon: 'error',
                        title: 'Please try again Login',
                        text: 'There was an error.'
                    });
                    navigate('/login');
                }


                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [token, navigate]);

    const handleStatusColor = (status) => {
        switch (status) {
            case 'Pending':
                return 'btn-warning';
            case 'Accepted':
                return 'btn-success';
            case 'Rejected':
                return 'btn-danger';
            default:
                return '';
        }
    };

    return (
        <div className="common_dash p-3">
            <div className="row align-items-center top">
                <div className="col-lg-6">

                    <h3>Application</h3>
                </div>
                <div className="col-lg-6">
                    <Breadcrumb className="bread">
                        <Breadcrumb.Item ><NavLink to="/counsellor-dashboard">Home</NavLink></Breadcrumb.Item>

                        <Breadcrumb.Item active>Application</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>

            <div className='bg-white'>
                {loading ? (
                    <p className="my-5"> <LinearProgress /></p>
                ) : (
                    <div className="table-responsive thin_scroll">
                        {collegeData.length === 0 ? (
                            <table className="table">
                                <thead>
                                    <tr className="bg-[whitesmoke]">
                                        <th>Email</th>
                                        <th>Student name</th>
                                        <th>Course</th>
                                        <th>Subcategory</th>
                                        <th>Stream</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-[white]">
                                    <p className="text-center text-red-600 my-4 py-3 ">! No records </p>
                                </tbody>
                            </table>
                        ) : (
                            <table className="table">
                                <thead>
                                    <tr className="bg-[whitesmoke]">
                                        <th>Email</th>
                                        <th>Student name</th>
                                        <th>Course</th>
                                        <th>Subcategory</th>
                                        <th>Stream</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-[white]">
                                    {collegeData.map((clg, i) => (
                                        <tr key={i}>
                                            <th className="width_define">{clg.student.email}</th>
                                            <th className="width_define">{clg.student.name}</th>
                                            <th className="width_define">{clg.course_category.name}</th>
                                            <th className="width_define">{clg.course_subcategory.course_name}</th>
                                            <th className="width_define">{clg.course_stream.name}</th>
                                            <th className="width_define"><button className={`text-white btn-sm ${handleStatusColor(clg.status)} ms-2`} >{clg.status} </button></th>
                                            <th className="flex items-center gap-2"><button className="px-3 mt-2 py-2 text-white bg-[#052963] btn-sm" onClick={() => handleModalShow(clg.id)}><FaPen /></button> </th>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                )}
            </div>
            <Modal show={showModal} onHide={handleModalClose}>
                <div className='!justify-end float-right flex m-1.5'>
                    <button variant="secondary" className='text-black max-w-fit hover:text-white' onClick={handleModalClose}>
                        <FaTimes />
                    </button>
                </div>
                <Modal.Header closeButton>
                    <h5 className="modal-title">Leads Update</h5>
                </Modal.Header>
                <div className="modal-body">
                    <form className="p-2 bg-gray-50" onSubmit={handleSubmit} encType="multipart/form-data">
                        <div className="row form-row">
                            <div className="col-12 col-sm-6">
                                <div className="py-3 form-group">
                                    <label>Enquiry Leads</label>
                                    <select
                                        name="Status"
                                        className="form-control form-select"
                                        value={newStatus}
                                        onChange={handleChange}
                                    >
                                        <option value=''>Select  Status</option>
                                        <option value="Pending">Pending</option>
                                        <option value="Accepted">Accepted</option>
                                        <option value="Rejected">Rejected</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="text-[blue] btn btn-primary btn-block"
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </Modal>
        </div>
    )
}

export default AllLeads;