import React, { useEffect, useMemo, useState } from 'react';

import Button from 'react-bootstrap/Button';

import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { TiArrowBack } from 'react-icons/ti';
import StarRating from './Subpages/StarRatingInput';
import Swal from 'sweetalert2';

const TestimonialUpdate = ({ handlefunction, selectedata }) => {
    const token = useMemo(() => localStorage.getItem("token") || "", []);
    const [formData, setFormData] = useState({ ...selectedata });

    const headers = useMemo(() => ({
        Authorization: `JWT ${token}`,
    }), [token]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleSave = async (e) => {
        e.preventDefault()
        const id = formData.id
        try {
            await axios.patch(
                `https://portal.mycollegepedia.com/counsellor/counsellor-testimonial/${id}/`,
                formData,
                { headers }
            );
            Swal.fire({
                position: "top-end",
                timer: 2500,
                icon: 'success',
                text: 'Upated successfully!',
            });
            handlefunction();
        } catch (error) {
            Swal.fire({
                position: "center",
                icon: 'error',
                text: 'Opps! try again',
            });
            console.error("Error updating testimonial:", error);
        }
    };

    return (
        <div>
            <div className='justify-end row'>
                <button onClick={() => handlefunction()} title="back" className="btn-sm shadow-sm flex m-3 gap-1.5 md:float-right text-white items-center border bg-[#052963]">
                    <TiArrowBack />
                    back
                </button>
            </div>
            <div className='row'>
                <Modal.Body>
                    <div className="card border shadow">
                        <form onSubmit={handleSave} className='common_dash_form p-3' >
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="name">Name</label>
                                            <input type="text" className="form-control" id="name" placeholder="Enter your Company Name" name="name" value={formData.name} onChange={handleInputChange} />
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="rating">Rating</label>
                                            <StarRating onChange={(value) => setFormData({ ...formData, rating: value })} />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <label htmlFor="description">Description</label>
                                        <textarea className="form-control" id="description" rows="5" name="description" value={formData.description} onChange={handleInputChange} ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="d-flex justify-content-end">
                                        <Button className="save_director mr-2" type='submit'>
                                            Save
                                        </Button>
                                        <Button className="close_director" onClick={handlefunction}>
                                            Close
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>

            </div>
        </div>
    )
}

export default TestimonialUpdate