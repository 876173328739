import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { FaRegUserCircle, FaHome, FaInfoCircle, FaPen, FaUniversity, } from 'react-icons/fa';
import Apicontext from '../../Page/Contextapi';
import mycollegepedialogo from "../../assets/images/Logo.png";
import { MdMenu } from 'react-icons/md';
import Swal from 'sweetalert2';
import { IoIosArrowDroprightCircle } from "react-icons/io";
import blogimg from '../../assets/images/blogcollege.png'
// import logoutpng from '../../assets/images/log-out-iconpng.png'
import feedbackbanner from '../../assets/images/Feedback.jpg'
import exambanner from '../../assets/images/Exams.jpg'
import Modal from 'react-modal';
import FeedbackForm from '../Feedbackform/Feedback';
import { LogoutOutlined } from '@mui/icons-material';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1100,
  },
};
const Header2 = () => {
  const token = useMemo(() => localStorage.getItem('token') || '', []);
  const [isOpen, setIsOpen] = useState(false);
  const [Alldata, setAlldata] = useState([]);
  const [blog, setBlogs] = useState([]);
  const [Sitecongfig, setSiteconfig] = useState([]);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [BlogCategory, setBlogCategory] = useState(null);
  const [Topblog, setTopBlog] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [modalIsFeedback, setIsFeedback] = useState(false);
  const contextdata = useContext(Apicontext);
  const path = localStorage.getItem('path');
  const pathdashboard = path && path ? path.replace(/"/g, '') : '';
  const dropdownRef = useRef(null);
  const isUserLoggedIn = () => {
    return localStorage.getItem('token') !== null;
  };
  const handlefeedback = () => {
    setIsFeedback(!modalIsFeedback);
  };

  const navigate = useNavigate()

  // explore programing open close
  // const handleMouseclick = () => {
  //   setDropdownOpen(!isDropdownOpen);
  // };
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('path');
    localStorage.removeItem('userprofile');

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2500,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    });
    Toast.fire({
      position: 'top-end',
      icon: "success",
      timer: 2500,
      title: "Log-out successfully"
    });
    navigate('/')
  };

  // console.log("name collegepeifcollege",filteredColleges)  // this filter only  name and title filtering
  //curre 


  useEffect(() => {
    const alldata = contextdata.APIdata;
    setAlldata(alldata)
    setBlogs(alldata.blog)
    setSiteconfig(alldata.detail)
    if (Alldata && Alldata.menu) {
      setHoveredCategory(Alldata.menu[1]);
    }
  }, [contextdata, Alldata]);



  useEffect(() => {
    if (BlogCategory) {
      const categoryBlogs = blog.filter(blog => blog.category.id === BlogCategory);
      setFilteredBlogs(categoryBlogs);

      if (categoryBlogs.length > 0) {
        const sortedBlogs = categoryBlogs.sort((a, b) => b.views - a.views);
        setTopBlog(sortedBlogs[0]);
      } else {
        setTopBlog(null);
      }
    } else {
      setFilteredBlogs(blog);
      if (blog.length > 0) {
        const sortedBlogs = [...blog].sort((a, b) => b.views - a.views);
        setTopBlog(sortedBlogs[0]);
      } else {
        setTopBlog(null);
      }
    }
  }, [BlogCategory, blog]);
  if (!Sitecongfig || Sitecongfig.length > 0) {
    return null;
  }
  return (
    <>

      <nav className="sticky -top-1  items-center container-fluid z-50 w-full text-white main_nav shadow-sm transition-all duration-400">
        <div className="row z-[9999] align-items-center">
          <div className="col-lg-3 col">
            <div className='pedia_logo'>
              <Link to='/' title='Homepage' aria-label='home page link'>
                <img src={Sitecongfig.logo || mycollegepedialogo} alt={Sitecongfig.title || "MycollegePedia"} title={Sitecongfig.title || "MycollegePedia"} loading='lazy' className="w-full md:ml-4 ml-1.5 brightness-150" />
              </Link>
            </div>
          </div>
          <div className='col-lg-6 col'>
            <div className="hidden lg:block ruby-wrapper main__navbar">
              <ul className="flex justify-between items-center my-auto hover:text-[whitesmoke]">
                <li className='cool-link'>
                  <Link to="/" aria-label="homepage" className="transition duration-300 ease-in-out" title="Home">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about-us" title="About Us" className="transition duration-300 ease-in-out">
                    About Us
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link to="/all-colleges" title="View Colleges" className="nav-link" id="navbarDropdown">
                    Colleges
                  </Link>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="category">
                          <div className="row">
                            <div className="col-md-3">
                              <h3>Category</h3>
                              <div className="h_of_megamenu">
                                <ul>

                                  {Alldata && Alldata.menu && Alldata.menu.map((category) => (
                                    <li
                                      className={hoveredCategory === category ? 'text-[orange] ' : ''}
                                      key={category.course_category.id}
                                      onMouseEnter={() => setHoveredCategory(category)}
                                    >
                                      <IoIosArrowDroprightCircle /> {category.course_category.name}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <h3>College list</h3>
                              <div className="h_of_megamenu pr-3 pl-1">

                                {hoveredCategory && hoveredCategory.colleges.map((college, index) => (
                                  <div key={index} className="cards mb-2 hover:bg-[whitesmoke]">
                                    <Link to={`/college/${college.slug}`} className='flex items-center gap-3' title={college.name}>
                                      <div className="pic object-fill w-10 h-10 overflow-hidden rounded-full">
                                        <img src={`https://portal.mycollegepedia.com${college.logo}`} className="img-fluid text-base" alt={college.name} title={college.name} loading='lazy' />
                                      </div>
                                      <div className="content">
                                        <p className="title">{college.name}</p>
                                      </div>
                                    </Link>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="h_of_cards">
                                <div className="add_banner">

                                  <div className="pic">
                                    <button onClick={handlefeedback} title='Feedback'>
                                      <img src={feedbackbanner} alt="feeedback" title={'feedback '} />
                                    </button>
                                  </div>
                                  <div className="pic">
                                    <Link to={'/all-exams'} title='exam view'> <img src={exambanner} alt={"exam"} title={'view exams'} /></Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link to="/all-counsellor" title="View Counsellors" className="nav-link">
                    Counsellor
                  </Link>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="category">
                          <div className="row">
                            <div className="col-md-7">
                              <h3>Counsellor</h3>
                              <div className="h_of_megamenu pr-3 pl-1">
                                {Alldata.counsellors && Alldata.counsellors.slice(0, 20).map((item) => (
                                  <div key={item.id} className="cards mb-2 hover:bg-[whitesmoke] " >
                                    <div className="pic">
                                      <img src={item.logo || blogimg} className="img-fluid" alt={item.company_name} title={item.company_name} loading='lazy' />
                                    </div>
                                    <div className="content">
                                      <p className="title"> <Link to={`/counsellor/${item.slug}`} title={item.company_name}>{item.company_name} </Link></p>
                                      <p className="date">City : {item.city}</p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="h_of_cards">
                                <div className="add_banner">
                                  <div className="pic">
                                    <button onClick={handlefeedback} title='Feedback'>
                                      <img src={feedbackbanner} alt="Banner" title={'baanner static'} />
                                    </button>
                                  </div>
                                  <div className="pic">
                                    <Link to={'/all-exams'} title='exam view'> <img src={exambanner} alt={"exam"} title={'view exams'} /></Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link to="/all-exams" title="View Exams" className="nav-link" id="navbarDropdown">
                    Exams
                  </Link>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="category">
                          <div className="row">
                            <div className="col-md-7">
                              <h3>All Exams</h3>
                              <div className="h_of_megamenu pr-3 pl-1">
                                {Alldata.exam && Alldata.exam.slice(0, 20).map((exam) => (
                                  <div key={exam.id} className="cards mb-2 hover:bg-[whitesmoke]">
                                    <div className="pic">
                                      <img src={exam.exam.image || blogimg} className="img-fluid" alt={exam.title} title={exam.title} loading='lazy' />
                                    </div>
                                    <div className="content">
                                      <p className="title"> <Link to={`/exam/${exam.slug}`} title={exam.title}>{exam.exam.title} ({exam.exam.full_form})</Link></p>
                                      <p className="date">{exam.exam_start_date}</p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="h_of_cards">
                                <div className="add_banner ">
                                  <div className="pic hover:bg-[whitesmoke]">
                                    <button onClick={handlefeedback} title='Feedback'>
                                      <img src={feedbackbanner} alt="Banner" title={'baanner static'} />
                                    </button>
                                  </div>
                                  <div className="pic">
                                    <Link to={'/all-exams'} title='exam view'> <img src={exambanner} alt={"exam"} title={'view exams'} /></Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link to="/blogs" title="View Blogs" className="nav-link" id="navbarDropdown">
                    Blogs
                  </Link>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="category">
                          <div className="row">
                            <div className="col-md-3">
                              <h3>Category</h3>
                              <div className="h_of_megamenu">
                                <ul>
                                  <li className={BlogCategory === null ? 'text-[orange] ' : ''} onMouseEnter={() => setBlogCategory(null)}>
                                    <IoIosArrowDroprightCircle /> {"All blogs"}
                                  </li>
                                  {Alldata && Alldata.category && Alldata.category.map((data) => (
                                    <li
                                      key={data.id}
                                      className={BlogCategory === data.id ? 'text-[orange] ' : ''}
                                      onMouseEnter={() => setBlogCategory(data.id)}
                                    >
                                      <IoIosArrowDroprightCircle /> {data.name}
                                    </li>
                                  ))}

                                </ul>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <h3>Blogs</h3>
                              <div className="h_of_megamenu pr-3 pl-1">
                                {filteredBlogs.map((data) => (
                                  <div key={data.id} className="cards mb-2 hover:bg-[whitesmoke]">
                                    <Link to={`/blog/${data.slug}`} title={data.title}>
                                      <div className="pic">
                                        <img src={data.image || 'default-blog-image.jpg'} className="img-fluid" alt={data.title} title={data.title} loading='lazy' />
                                      </div>
                                      <div className="content">
                                        <p className="title">{data.title}</p>
                                        <p className="date">{new Date(data.published_date).toLocaleDateString('en-GB')}</p>
                                      </div>
                                    </Link>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <h3>Top Blog</h3>
                              <div className="h_of_cards">
                                {Topblog && (
                                  <div className="top_blog">
                                    <Link to={`/blog/${Topblog.slug}`} title={Topblog.title}>
                                      <div className="pic">
                                        <img src={Topblog.image || blogimg} alt={Topblog.title} title={Topblog.title} loading='lazy' />
                                      </div>
                                      <div className="content">
                                        <p className="title">{Topblog.title || 'Unveiling the Benefits and Insights of the Engineering World'}</p>

                                        <p className="date">{new Date(Topblog.published_date).toLocaleDateString('en-GB')}</p>
                                      </div>
                                    </Link>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <Link to="/contact-us" title='contact us' className="transition duration-300 ease-in-out">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col flex justify-end  md:px-10  ">

            <div className="text-center relative items-center d-flex ">
              {/* <Tooltip title="Explor" placement="bottom" className='text-white rounded-lg'>
                <button  aria-label="Submit Form"  className='hidden ml-4 text-lg font-semibold md:flex' onClick={handleMouseclick}>:::</button>
              </Tooltip> */}
              <div className="justify-end  mx-auto">
                {isUserLoggedIn() ? (
                  <div className='flex gap-2 items-center'>

                    <Link to={pathdashboard} aria-label='access dashbaord ' title={pathdashboard || 'Dashbaord'} className='hover:text-[whitesmoke] text-white '>
                      <button aria-label="Submit Form" >
                        <FaRegUserCircle className="gap-1 mx-auto text-3xl" />
                      </button>
                    </Link>
                    <button className="btn-sm w-12 h-10" title='Logout | User can be Log out' aria-label="Submit Form" onClick={handleLogout}><LogoutOutlined /> </button>
                  </div>
                ) : (
                  <Link to="/signup" title='sign up ' aria-label="user" className='hover:text-white' >
                    <FaRegUserCircle className="gap-1 mx-auto text-3xl" />
                    <span className="hidden mx-1 md:block">SignUp</span>
                  </Link>
                )}
              </div>

              <div className="block text-3xl lg:hidden">
                <button className="navbar-toggler lg:hidden" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <MdMenu className='text-2xl' />
                </button>
              </div>
            </div>
            {/* {isDropdownOpen && (
              <div className="absolute w-full h-auto mx-auto mt-10 transform btnisdropdown">
                <Explorem />
              </div>
            )} */}
          </div>
        </div>
        <div className={`collapse navbar-collapse ${isOpen ? '' : 'lg:hidden'}`} id="navbarNav" ref={dropdownRef}>

          <ul className="px-8 py-2 mx-auto space-y-3 font-mono text-white lg:hidden navbar-nav ">
            <li onClick={() => { setIsOpen(!isOpen) }} >
              <Link to="/" title='home' className="flex items-center visible gap-2 text-white">
                <FaHome />Home
              </Link>
            </li>
            <li className='visible'>
              <Link to="/all-colleges" title='colleges' className="flex gap-2 transition duration-300 align-items-center hover:text-[whitesmoke]" onClick={() => { setIsOpen(!isOpen) }}>
                <FaUniversity /> College
              </Link>
            </li>
            {/* <li className='visible'>
              <Link to="/courses" className="flex gap-2 transition duration-300 align-items-center hover:text-[whitesmoke]" onClick={() => { setIsOpen(!isOpen) }}>
                <FaBook /> Course
              </Link>
            </li> */}
            <li className='visible'>
              <Link to="/all-exams" title='exmas' className="flex gap-2 transition duration-300 align-items-center hover:text-[whitesmoke]" onClick={() => { setIsOpen(!isOpen) }}>
                <FaPen /> Exam
              </Link>
            </li>
            {/* <li className='visible'>
              <Link to="careers" className="flex gap-2 transition duration-300 align-items-center hover:text-[whitesmoke]" onClick={() => { setIsOpen(!isOpen) }}>
                <FaCashRegister /> Careers
              </Link>
            </li> */}
            <li className='visible'>
              <Link to="/about-us" title='about us' className="flex gap-2 transition duration-300 ease-in-out align-items-center hover:text-[whitesmoke]" onClick={() => { setIsOpen(!isOpen) }}>
                <FaInfoCircle /> About us
              </Link>
            </li>
            <li className='visible'>
              <Link to="/contact-us" title='contact' className="flex gap-2 transition duration-300 ease-in-out align-items-center hover:text-[whitesmoke]" onClick={() => { setIsOpen(!isOpen) }}>
                <FaInfoCircle /> Contact us
              </Link>
            </li>

          </ul>
        </div>
      </nav >

      <Modal
        isOpen={modalIsFeedback}
        onRequestClose={handlefeedback}
        style={customStyles}
        contentLabel="Feedback Modal"
      >
        <FeedbackForm closeModal={handlefeedback} token={token} />
      </Modal>
    </>
  );
};
export default Header2;