import React, { useState, useEffect } from 'react';
import mycollegepedialogo from "../../assets/images/Logo.png";
import regimg from "../../assets/images/log-reg-img.avif";
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const Registration = () => {
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    fathernumber: '',
    linked_in_profile: '',
    lastqualification: '',
    course: '',
    country: '',
    state: '',
    city:'',
    address:''
    });
  const navigate = useNavigate()
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCountryChange = (e) => {
    const { value } = e.target;
    setSelectedCountry(value);
    setFormData({ ...formData, country: value });
  };

  const handleStateChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, state: value });
  };

  const handleSubmit =async (e) => {
    e.preventDefault();
    try{
      const response = await axios.post('https://mycollegepedia.com/backend/api/user-profile', formData);
      const data = response.data;
      if (data.error === true) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true, 
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'success',
            text: response.data.message,
            title: 'Regstration is successfully'
        })
       navigate("/dashboard")
    } else {
        Swal.fire({
            icon: 'error',
            title: 'error try again',
            text: data.message
        });
    }
    }catch(error){
      Swal.fire({
        icon: 'error',
        title: 'server error try again',
        text: error
    });
        console.log("apierror",error)
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countryResponse = await axios.get('https://mycollegepedia.com/backend/api/country');
        const countryData = countryResponse.data;
        setCountryList(countryData);
      } catch (error) {
        console.error('Fetch Error:', error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (selectedCountry) {
      const fetchStates = async () => {
        try {
          const response = await axios.get(`https://mycollegepedia.com/backend/api/states/${selectedCountry}`);
          const stateData = response.data;
          setStateList(stateData);
        } catch (error) {
          console.error('Server error. Please try again:', error);
        }
      };
      fetchStates();
    } else {

      setStateList([]);
    }
  }, [selectedCountry]);

  return (
    <div className="flex flex-col items-center justify-center">
      <div className='grid w-full mx-auto '>

        <div className="!z-0 flex flex-1 w-full max-w-screen-xl gap-6 ml-3 bg-white ">
          <div className="hidden w-full h-full lg:flex">
            <img
              src={regimg}
              style={{ objectFit: 'cover', height: '100%', width: '100%', transform: 'scaleX(-1)' }}
              className='z-0'
              alt="RegistrationPicture"
            />
          </div>
          <form onSubmit={handleSubmit} className="flex flex-col w-full p-4 mx-auto rounded-lg">
            <div className="justify-center mx-auto mb-6 text-center">
              <h2 className="text-[#0099ff] text-2xl font-semibold">
                <img src={mycollegepedialogo} alt="College Logo" className="w-32" />
              </h2>
            </div>
            <div className="flex flex-col space-y-4">
              <div className='gap-3 md:flex'>
                <div className="flex flex-col ">
                  <label htmlFor="name" className="text-sm text-gray-700">
                    Full Name <span className="text-red-500">*</span>
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      name="name"
                      onChange={handleChange}
                      required
                      placeholder="Full Name"
                      id="name"
                      value={formData.name}
                      className="w-[250px] h-[37px] rounded-md pl-2 border border-gray-300 text-base focus:text-gray-600 focus:border-gray-400"
                    />

                  </div>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="lastname" className="text-sm text-gray-700">Last Name </label>
                  <input
                    type="text"
                    id="lastname"
                    name="lastname"
                    placeholder='Lastname'
                    value={formData.lastname}
                    onChange={handleChange}
                    className="w-[250px] h-[37px] rounded-md pl-2 border border-gray-300 text-base focus:text-gray-600 focus:border-gray-400"
                  />
                </div>
              </div>


              <div className='gap-3 md:flex'>
                <div className="flex flex-col">
                  <label htmlFor="email" className="text-sm text-gray-700">Email <span className="text-red-500">*</span></label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    placeholder='mycollegepedia@gmail.com'
                    onChange={handleChange}
                    required
                    className="w-[250px] h-[37px] rounded-md pl-2 border border-gray-300 text-base focus:text-gray-600 focus:border-gray-400"
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="mobile" className="text-sm text-gray-700">Mobile <span className="text-red-500">*</span></label>
                  <input
                    type="tel"
                    id="mobile"
                    name="mobile"
                    placeholder='+91'
                    value={formData.mobile}
                    onChange={handleChange}
                    required
                    className="w-[250px] h-[37px] rounded-md pl-2 border border-gray-300 text-base focus:text-gray-600 focus:border-gray-400"
                  />
                </div>
              </div>
              <div className='gap-3 md:flex'>
                <div className="flex flex-col">
                  <label htmlFor="fathernumber" className="text-sm text-gray-700">Father's Number</label>
                  <input
                    type="tel"
                    id="fathernumber"
                    name="fathernumber"
                    value={formData.fathernumber}
                    onChange={handleChange}
                    placeholder='+91'

                    className="w-[250px] h-[37px] rounded-md pl-2 border border-gray-300 text-base focus:text-gray-600 focus:border-gray-400"
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="linked_in_profile" className="text-sm text-gray-700">linked_in_profile </label>
                  <input
                    type="text"
                    id="linked_in_profile"
                    name="linked_in_profile"
                    value={formData.linked_in_profile}
                    onChange={handleChange}
                    placeholder='linked_in_profile-profile-Link'

                    className="w-[250px] h-[37px] rounded-md pl-2 border border-gray-300 text-base focus:text-gray-600 focus:border-gray-400"
                  />
                </div>
              </div>
              <div className='gap-3 md:flex'>
                <div className="flex flex-col">
                  <label htmlFor="lastqualification" className="text-sm text-gray-700 ">Last Qualification <span className="text-red-500">*</span></label>
                  <input
                    type="text"
                    id="lastqualification"
                    name="lastqualification"
                    placeholder='Lastqualifiction'
                    value={formData.lastqualification}
                    onChange={handleChange}
                    required
                    className="w-[250px] h-[37px] rounded-md pl-2 border border-gray-300 text-base focus:text-gray-600 focus:border-gray-400"
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="course" className="text-sm text-gray-700">
                    Course of Interest <span className="text-red-500">*</span>
                  </label>
                  <select
                    id="course"
                    name="course"
                    value={formData.course}
                    onChange={handleChange}
                    required
                    className="w-[250px] h-[37px] pl-2 text-base border border-gray-300 rounded-md focus:text-gray-600 focus:border-gray-400"
                  >
                    <option value="">Select a course </option>
                    <option value="Course 1">Course 1</option>
                    <option value="Course 2">Course 2</option>
                    <option value="Course 3">Course 3</option>
                  </select>
                </div>
              </div>

              <div className='gap-3 md:flex'>
                <div className="flex flex-col">
                  <label htmlFor="country" className="text-sm text-gray-700">
                    Country
                  </label>
                  <select
                    id="country"
                    name="country"
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    className="w-[250px]  h-[37px] pl-2 text-base border border-gray-300 rounded-md focus:text-gray-600 focus:border-gray-400"
                  >
                    <option value="">Select a country</option>
                    {countryList && countryList.map((data, id) => (
                      <option key={id} value={data.id}>{data.name}</option>
                    ))}
                  </select>
                </div>


                <div className="flex flex-col">
                  <label htmlFor="state" className="text-sm text-gray-700">
                    State
                  </label>
                  <select
                    id="state"
                    name="state"
                    value={formData.state}
                    onChange={handleStateChange}
                    className=" h-[37px] pl-2 w-[250px]  text-base border border-gray-300 rounded-md focus:text-gray-600 focus:border-gray-400"
                  >
                    <option value="">Select a state</option>
                    {stateList && stateList.map((data, id) => (
                      <option key={id} value={data.name}>{data.name}</option>
                    ))}
                  </select>
                </div>

              </div>
              
              <div className='gap-3 md:flex'>
                <div className="flex flex-col">
                  <label htmlFor="city" className="text-sm text-gray-700 ">City <span className="text-red-500">*</span></label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    placeholder='City'
                    value={formData.city}
                    onChange={handleChange}
                    required
                    className="w-[250px] h-[37px] rounded-md pl-2 border border-gray-300 text-base focus:text-gray-600 focus:border-gray-400"
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="address" className="text-sm text-gray-700 ">Address </label>
                  <input
                    type="text"
                    id="address"
                    name="Address"
                    placeholder='address'
                    value={formData.adress}
                    onChange={handleChange}
                    required
                    className="w-[250px] h-[37px] rounded-md pl-2 border border-gray-300 text-base focus:text-gray-600 focus:border-gray-400"
                  />
                </div>
               
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full px-4 py-2 font-semibold text-white bg-blue-500 rounded-full hover:bg-blue-600"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Registration;
