import React, { useState,  useMemo } from 'react';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { LinearProgress } from "@mui/material";
import { TiArrowBack } from 'react-icons/ti';

const UpdateCourseFee = ({ handleCloseModal, formData: initialFormData, category, stream, token, Data }) => {
    const [Loading, setLoading] = useState(true);
    const [formData, setFormData] = useState(initialFormData);
    const academic = useMemo(() => JSON.parse(localStorage.getItem('academic')) || [], []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        // Update the formData state
        setFormData(updatedFormData);
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        setLoading(true);
        const id = formData.id;
        try {
            const response = await axios.patch(`https://portal.mycollegepedia.com/college/course-fees/${id}/`, formData, {
                headers: {
                    Authorization: `JWT ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            setLoading(false)
            console.log(response);
            Swal.fire({
                position:"top-end",
                timer:2500,
                icon: 'success',
                text: 'Data updated successfully!',
            });
            handleCloseModal();
            
        } catch (error) {
            console.error('Error updating data:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to update data. Please try again.',
            });
        } finally {
            setLoading(false); 
        }
    };

    return (
        <div>
              <div className='justify-end row'>
                    <button onClick={handleCloseModal} className="btn-sm shadow-sm flex m-3 gap-1.5 float-right text-white items-center border bg-[#052963]">
                        <TiArrowBack />
                        Back
                    </button>
                </div>
            <Modal.Body>
                {Loading && <LinearProgress />} 
                <Form onSubmit={handleSaveChanges} className='p-3 common_dash_form'>
                    <div className="col-12">
                        <div className="form-group">
                            <label>Academic Year</label>
                            <input
                                type="text"
                                name="academic_year"
                                className="form-control disable"
                                readOnly
                                placeholder="Academic"
                                value={academic[0].year}
                            />
                        </div>
                    </div>
                    <div className="col-12 ">
                        <Form.Group controlId="courseSubcategory">
                            <Form.Label>Course Subcategory</Form.Label>
                            <Form.Control
                                as="select"
                                name="course_subcategory"
                                value={formData.course_subcategory}
                                onChange={handleChange}
                            >
                                <option value="">Select Subcategory</option>
                                {category.map((subcategory) => (
                                    <option key={subcategory.id} value={subcategory.id}>
                                        {subcategory.course_name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </div>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label htmlFor='stream'>Course Stream</label>
                            <select
                                className='form-control'
                                id='stream'
                                name='course_stream'
                                value={formData.course_stream}
                                onChange={handleChange}
                            >
                                <option value=''>Select Stream</option>
                                {Data.stream &&
                                    stream.map((stream) => (
                                        <option key={stream.id} value={stream.id}>
                                            {stream.name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="form-group">
                            <label>Year Fees</label>
                            <input
                                type="number"
                                name="year_fees"
                                className="form-control"
                                placeholder="Yearly fees"
                                value={formData.year_fees}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>

                    
                    <Button className='button_of_submit' onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button  className='button_of_close ml-2' type="submit">
                        Save Changes
                    </Button>
                 </div>
                </Form>
            </Modal.Body>
        </div>
    );
};

export default UpdateCourseFee;