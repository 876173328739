import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
const EducationForm = ({ handleBack, handleSaveAndContinue }) => {
    const userData = JSON.parse(localStorage.getItem('userprofile')) || {};
    const token = localStorage.getItem('token')
    const [educationList, setEducationList] = useState([
        {
            user: userData.id ? userData.id : '',
            institute: '',
            institute_name: '',
            degree: '',
            grade_system: '',
            score: '',
            field_of_study: '',
            start_date: '',
            end_date: '',
            description: '',
        },
    ]);
    const handleAddForm = () => {
        setEducationList((prevList) => [
            ...prevList,
            {
                user: userData.id,
                institute: '',
                degree: '',
                grade_system: '',
                score: '',
                field_of_study: '',
                start_date: '',
                end_date: '',
                description: '',
            },
        ]);
    };

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        setEducationList((prevList) => {
            const newList = [...prevList];
            newList[index][name] = value;
            return newList;
        });
    };

    const instituteOptions = ['School', 'College', 'University'];
    // const instituteOptions = ['10th', '12th', 'Under-Graduation', 'Post-Graduation'];
    const grade_system = ['CGPA', 'PERCENTAGE'];

    const handleInstituteChange = (selectedInstitute, index) => {
        setEducationList((prevList) => {
            const newList = [...prevList];
            newList[index].institute = selectedInstitute;
            return newList;
        });
    };
    const headers = {
        Authorization: `JWT ${token}`,
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        handleSaveAndContinue();
        const educationListWithUserId = educationList.map((education) => ({
            ...education,
            userid: userData.id,
        }));
        console.log(educationListWithUserId);

        try {
            const educationApiResponse = await axios.post(
                'https://portal.mycollegepedia.com/account/education/',
                educationListWithUserId,
                { headers }
            );
            const data = educationApiResponse;
            console.log(data)
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'server error try again',
                text: error
            });
            console.log("apierror", error)
        }
    };

    return (
        <div>
            {educationList.map((educationInfo, index) => (
                <form key={index} className='form' encType='multipart/form-data'>
                    <div className='education-form'>
                        <div className='row'>
                            <div className="form-group col-md-4">
                                <label>Institute Type <span className='font-semibold text-red-500'>*</span></label>
                                <select
                                    name="institute"
                                    className="form-control form-select"
                                    value={educationInfo.institute}
                                    onChange={(e) => handleInstituteChange(e.target.value, index)}
                                >
                                    <option value="">Select Institute</option>
                                    {instituteOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-group col-md-4">
                                <label className='gap-2'>Institute name <span className='font-semibold text-red-500'>*</span></label>
                                <input
                                    type='text'
                                    name="institute_name"
                                    className="form-control "

                                    placeholder='school/college/universtiy'
                                    onChange={(e) => handleChange(e, index)}
                                />
                            </div>

                            <div className="form-group col-md-4">
                                <label>Degree(10th/12th/UG/PG/PHD)<span className='font-semibold text-red-500'>*</span></label>
                                <input
                                    type='text'
                                    name="degree"
                                    className="form-control "
                                    placeholder='10th/ 12th/ Ug/ PG /PHD'
                                    onChange={(e) => handleChange(e, index)}
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <label>field of study <span className='font-semibold text-red-500'>*</span></label>
                                <input
                                    type="text"
                                    name="field_of_study"
                                    className="form-control"
                                    placeholder='Computer Science'
                                    onChange={(e) => handleChange(e, index)}
                                />
                            </div>

                            <div className="form-group col-md-4">
                                <label>Grade System </label>
                                <select
                                    name="grade_system"
                                    className="form-control form-select"
                                    value={educationInfo.grade_system}
                                    onChange={(e) => handleChange(e, index)}
                                >
                                    <option value=''>Selcet grade system</option>
                                    {grade_system.map((i) => (
                                        <option key={i} value={i}>
                                            {i}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-group col-md-4">
                                <label>Score <span className='font-semibold text-red-500 cm'>*</span></label>
                                <input
                                    type="number"
                                    name="score"
                                    pattern='[!/^\d{3}$/]'
                                    className="form-control cm-number"
                                    onChange={(e) => handleChange(e, index)}
                                />
                            </div>

                            <div className="form-group col-md-4">
                                <label>start-year *</label>
                                <input
                                    type='month'
                                    name='start_date'
                                    value={educationInfo.start_date}
                                    onChange={(e) => handleChange(e, index)}
                                    className="form-control"
                                />                      </div>
                            <div className="form-group col-md-4">
                                <label>end-year <span className='font-semibold text-red-500'>*</span></label>
                                <input type='month'
                                    name='end_date'
                                    selected={educationInfo.end_date}
                                    onChange={(e) => handleChange(e, index)}

                                    className="form-control fa-yammer"
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="form-group col-md-12">
                                <label>description *</label>
                                <textarea type='text'
                                    rows={2}
                                    name='description'
                                    onChange={(e) => handleChange(e, index)}
                                    className="form-control "
                                />
                            </div>

                        </div>
                    </div>


                    <div className='row'>
                        <div className='form-group col-3'>
                            {index === educationList.length - 1 && (
                                <button onClick={handleAddForm} className='border btn-sm text-[#052963] hover:text-white  hover:bg-[#052963]'>
                                    + Add Education Form
                                </button>
                            )}
                        </div>
                    </div>
                </form>
            ))}
            <ul className="list-inline pull-right">
                <li>
                    <button
                        type="button"
                        className="default-btn prev-step"
                        onClick={handleBack}
                    >
                        Back
                    </button>
                </li>
                <li>
                    <button
                        type="button"
                        className="text-white default-btn next-step"
                        onClick={handleSubmit}
                    >
                        Save & Next Continue
                    </button>
                </li>

            </ul>
        </div>
    );
};

export default EducationForm;
