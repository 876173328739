import React, { useState } from 'react';
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input';
import { AiFillEye } from 'react-icons/ai';
import mycollegepedialogo from "../../assets/images/Logo.png";
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { FaEyeSlash } from 'react-icons/fa';

const SignupPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        password: '',
        confirmpassword: ''
    });
    const [warning, setWarning] = useState('');
    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormData({
            ...formData,
            [name]: newValue,
        });
    };

    const [viewPassword, setViewPassword] = useState(false);
    const [confirmPassword, setconfirmPassword] = useState(false);

    const passwordViewHandler = () => {
        setViewPassword(!viewPassword);
    };
    const confirmpasswordViewHandler = () => {
        setconfirmPassword(!confirmPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.password !== formData.confirmpassword) {
            setWarning('Password and confirm password do not match.');
            return;
        }
        try {
            const response = await axios.post('https://portal.mycollegepedia.com/account/register/', formData);
            // console.warn(response);

            if (response.status === 201) {
                setWarning(''); 
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    },
                });
                Toast.fire({
                    icon: 'success',
                    text: response.data.message,
                    title: 'Registration is successful',
                });
                const number = formData.mobile;
                navigate(`/verifyotp/`, { state: { number } });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'try again ',
                    text: response.data.message ,
                  });
                setWarning(response.data.message || 'Server error. Please try again.');
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'credentials',
                text: 'try again ' ,
              });
            setWarning(error.message || 'An error occurred.');
            console.error('Axios Error:', error);
        }
    };
    return (
        <>
            <div className="main-wrapper">
                <div className="login-page">
                    <div className="container login-body">
                        <div className="loginbox">
                            <div className="login-right-wrap">
                                <div className="account-header">
                                    <div className="flex justify-center mb-4 text-center account-logo">
                                        <Link to="/">
                                            <img src={mycollegepedialogo} alt="clgLogo" title='homepage' className="w-full brightness-110" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="login-header">
                                    <h3>
                                        Register <span>MyCollegePedia</span>
                                    </h3>
                                </div>
                                <form onSubmit={handleSubmit} className="max-w-md mx-auto">
                                    <div className="mb-4 form-group">
                                        <label className="control-label">Full Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="name"
                                            onChange={handleChange}
                                            placeholder="Enter your full name."
                                            required
                                        />
                                    </div>
                                    <div className="mb-4 form-group">
                                        <label className="control-label">Contact Number</label>
                                        <div className="">
                                            <PhoneInput
                                                placeholder="Enter your contact number."
                                                value={formData.mobile}
                                                onChange={(value) =>
                                                    setFormData({
                                                        ...formData,
                                                        mobile: value,
                                                    })
                                                }
                                                className="form-control"
                                            />
                                            {formData.mobile && (
                                                <span className="text-sm text-gray-500">
                                                    National: {formatPhoneNumber(formData.mobile)}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="mb-4 form-group">
                                        <label className="control-label">Email Address</label>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Enter your email address."
                                            id="email"
                                            autoComplete="username"
                                            value={formData.email}
                                            className="h-12 form-control rounded-[4px] border border-[#222222] pr-2 text-[#222222] text-[1em]"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>

                                    <div className="relative mb-4 form-group">
                                        <label htmlFor="password" className="control-label">
                                            Password
                                        </label>
                                        <input
                                            type={viewPassword ? 'text' : 'password'}
                                            name="password"
                                            placeholder="Enter password."
                                            id="password"
                                            required
                                            autoComplete="new-password" 
                                            value={formData.password}
                                            onChange={handleChange}
                                            className="h-12 form-control rounded-[4px] border border-[#222222] pl-2 text-[#222222] text-[1em]"
                                        />
                                        <div className="absolute transform -translate-y-1/2 cursor-pointer right-4 top-[72%]">
                                            {viewPassword ? (
                                                <AiFillEye
                                                onClick={passwordViewHandler}
                                                    size="20px"
                                                    className="text-[#707070]"
                                                />
                                            ) : (
                                                <FaEyeSlash
                                                onClick={passwordViewHandler}
                                                    size="20px"
                                                    color="#909090"
                                                />
                                            )}
                                        </div>
                                    </div>

                                    <div className="relative mb-4 form-group">
                                        <label className="control-label">Confirm Password</label>
                                        <input
                                            type={confirmPassword ? 'text' : 'password'}
                                            name="confirmpassword"
                                            placeholder="Enter confirm password."
                                            id="confirmpassword"
                                            required
                                            autoComplete="new-password"
                                            value={formData.confirmpassword}
                                            onChange={handleChange}
                                            className="h-12 form-control rounded-[4px] border border-[#222222] pr-2 text-[#222222] text-[1em]"
                                        />
                                        <div className="absolute transform -translate-y-1/2 cursor-pointer right-4 top-[72%]">
                                            {confirmPassword ? (
                                                <AiFillEye
                                                onClick={confirmpasswordViewHandler}
                                                    size="20px"
                                                    className="text-[#707070]"
                                                />
                                            ) : (
                                                <FaEyeSlash
                                                onClick={confirmpasswordViewHandler}
                                                    size="20px"
                                                    color="#909090"
                                                />
                                            )}
                                        </div>
                                    </div>

                                    {warning && (
                                        <div className="mb-4 text-red-500">
                                            {warning}
                                        </div>
                                    )}

                                    <div className="text-center">
                                        <button
                                            className="!text-[#052963] btn hover:!text-white btn-primary btn-block account-btn"
                                            type="submit"
                                        >
                                            Register
                                        </button>
                                    </div>
                                </form>
                                <div className="text-center dont-have">
                                    Already have an account? <Link to="/login">Login</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default SignupPage;
