import {
    FaCheck,
    FaFacebookF,
    FaInstagram,
    FaLinkedin,
    FaRegClipboard,
    FaTelegramPlane,
    FaTimes,
    FaTwitter,
    FaWhatsapp,
    FaYoutube,
} from "react-icons/fa";
import Apicontext from "../../Page/Contextapi"
import { Link, useNavigate } from "react-router-dom";
import { MdOutlinePending } from "react-icons/md";
import { useEffect, useState, useMemo, useContext } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { NavLink } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import Button from "react-bootstrap/Button";
import { FaEye } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { TbWorldWww } from "react-icons/tb";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { MdBackHand } from "react-icons/md";
import Profileupdate from "./Profileupdate";

const Profile = () => {
    const [showModal, setShowModal] = useState(false);

    const token = useMemo(() => localStorage.getItem("token") || "", []);
    const [CounsellorProfile, setCounsellorProfile] = useState("");
    const [category, setCategory] = useState("");
    const [Alldata, setAllData] = useState([]);
    const [country, setCountry] = useState(null);
    const [state, setState] = useState(null);
    const [LeadsData, setLeadsData] = useState({});
    const [Loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);
    const handlefunction = () => setShowModal(!showModal);
    localStorage.setItem("counsellor_id",CounsellorProfile?.id);
    const contextdata = useContext(Apicontext);
    const headers = useMemo(() => ({
        Authorization: `JWT ${token}`,
    }), [token]);

    useEffect(() => {
        const fetchData = async () => {
            const data = contextdata.APIdata;
            setAllData(data)
            setCategory(data.category)
            setCountry(data.Country)
            try {
                const Response = await axios.get('https://portal.mycollegepedia.com/counsellor/counsellor-detail/', { headers, });
                const leadsResponse = await axios.get("https://portal.mycollegepedia.com/counsellor/counsellor-leads/", { headers, });
                setLoading(false);
                setCounsellorProfile(Response.data[0]);
                setLeadsData(leadsResponse.data);

                if (CounsellorProfile.country) {
                    const stateResponse = await axios.get(
                        `https://portal.mycollegepedia.com/main/state/?country_id=${CounsellorProfile.country}`
                    );
                    setState(stateResponse.data);
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem("token");
                    Swal.fire({
                        icon: "error",
                        title: "Please try again Login",
                        text: "There was an error.",
                    });
                    navigate("/login");
                }
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [navigate, showModal]);

    const getLeadCount = (status) => {
        if (LeadsData && LeadsData.length) {
            const filteredLeads = LeadsData.filter((lead) => lead.status === status);
            return filteredLeads.length;
        } else {
            return 0;
        }
    };
    const total = LeadsData.length;
    const data = [
        { value: total, label: "Total" },
        { value: getLeadCount("Pending"), label: "Pending" },
        { value: getLeadCount("Accepted"), label: "Accepted" },
        { value: getLeadCount("Rejected"), label: "Rejected" },
    ];

    const size = {
        width: 450,
        height: 240,
    };

// console.log("profile",CounsellorProfile)
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    if (Loading) {

        return (
            <div>
                <p className="my-10">
                    {" "}
                    <LinearProgress variant="determinate" value={progress} />
                </p>
            </div>
        );
    }
    return (
        <div>
            <div className="college_profile college_profile_counsellor common_dash my-3">
                {showModal && showModal ? (
                    <Profileupdate handlefunction={handlefunction} CounsellorProfile={CounsellorProfile} Alldata={Alldata} />
                ) : (
                    <div className="container-fluid">
                        <div className="row align-items-center top ">
                            <div className="col-lg-6">

                                <h3>Welcome <MdBackHand className="text-warning anim" /> !</h3>

                            </div>
                            <div className="col-lg-6">
                                <Breadcrumb className="bread">
                                    <Breadcrumb.Item ><NavLink to="/counsellor-dashboard">Home</NavLink></Breadcrumb.Item>

                                    <Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-3 view">
                                <div className="card">
                                    <div className="body">
                                        <div id="icon"> <FaRegClipboard /> </div>
                                        <div id="data">
                                            <h1 id="total">{total}</h1>
                                            <p>All leads</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 view">
                                <div className="card ">
                                    <div className="body">
                                        <div id="icon"><MdOutlinePending /></div>
                                        <div id="data">
                                            <h1 id="on"> {getLeadCount("Pending")}</h1>
                                            <p>Pending leads</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 view">
                                <div className="card ">
                                    <div className="body">
                                        <div id="icon"><FaCheck /></div>
                                        <div id="data">
                                            <h1 id="accept">{getLeadCount("Accepted")}</h1>
                                            <p>accepted leads</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 view">
                                <div className="card ">
                                    <div className="body">
                                        <div id="icon"><FaTimes className="" /></div>
                                        <div id="data">
                                            <h1 id="reject">{getLeadCount("Rejected")}</h1>
                                            <p>rejected leads</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {CounsellorProfile &&
                            <div className="row">
                                <div className="col-lg-12 ">
                                    <div className="card ">
                                        <div className="college_pic">
                                            <img src={CounsellorProfile.image || " "} alt="college_image" />
                                            <div className="college_log">
                                                <img src={CounsellorProfile.logo || " "} alt="collegelogo" />
                                            </div>
                                        </div>
                                        <div className="card-body d-block">
                                            <div className="card-heading">
                                                <h1 className="mt-5"><strong>{CounsellorProfile.company_name}</strong></h1>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-lg-6 s_column">
                                                    <div className="first">
                                                        <div className="col_name">
                                                            <h5 className="card-title">{CounsellorProfile.name}</h5>
                                                        </div>
                                                        <div className="d-flex align-items-center common_wrap">
                                                            <h2>  Registration Date : </h2>
                                                            <p> {CounsellorProfile.registration_date}</p>
                                                        </div>
                                                        <div className="d-flex align-items-center common_wrap">
                                                            <h2>  Email : </h2>
                                                            <p> {CounsellorProfile.email}</p>
                                                        </div>
                                                        <div className="d-flex align-items-center common_wrap">
                                                            <h2>  Contact Details : </h2>
                                                            <p> {CounsellorProfile.primary_mobile} {CounsellorProfile.secondary_mobile}</p>
                                                        </div>
                                                        <div className="social_links">
                                                            <div id="website"> <Link to={CounsellorProfile.whatsapp || ''}><TbWorldWww /></Link></div>
                                                            <div id="whatsapp"><Link to={CounsellorProfile.whatsapp || ''}><FaWhatsapp /></Link></div>
                                                            <div id="facebook"> <FaFacebookF /><a href={CounsellorProfile.facebook || ''} target="_blank" rel="noopener noreferrer"></a> </div>
                                                            <div id="instagram"> <a href={CounsellorProfile.instagram || ''} target="_blank" rel="noopener noreferrer"><FaInstagram /></a></div>
                                                            <div id="linkedin"><a href={CounsellorProfile.linkedin || ''} target="_blank" rel="noopener noreferrer"><FaLinkedin /></a> </div>
                                                            <div id="twitter"><a href={CounsellorProfile.twitter || ''} target="_blank" rel="noopener noreferrer"><FaTwitter /> </a></div>
                                                            <div id="youtube"><a href={CounsellorProfile.youtube || ''} target="_blank" rel="noopener noreferrer"><FaYoutube /></a></div>
                                                            <div id="telegram"><a href={CounsellorProfile.telegram || ''} target="_blank" rel="noopener noreferrer"><FaTelegramPlane /> </a></div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="edit_form ">
                                                                <Button onClick={() => handlefunction()}> <FaEdit /> </Button>
                                                            </div>
                                                            <div className="view_button  ms-2">
                                                                <button className="btn btnn">
                                                                    <Link className="edit-link text-white" to={`/colleges/${CounsellorProfile.slug}`}>
                                                                        <FaEye />
                                                                    </Link>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6"><div className="second">
                                                    <div className="add">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <h1 className="my-1.5 text-center">Address :- </h1>
                                                            </div></div>
                                                        <div className="row align-items-center">
                                                            <div className="col-lg-5 col-md-12">
                                                                <h3>Country : </h3></div>
                                                            {country && country.map((india, i) =>
                                                                india.id === CounsellorProfile.country ? (
                                                                    <div className="col-lg-7 col-md-12" key={i}>
                                                                        <span key={i}>

                                                                            <span> {india.name ? india.name : 'India'}</span>

                                                                        </span></div>
                                                                ) : null
                                                            )}

                                                        </div>
                                                        <div className="row align-items-center">
                                                            <div className="col-lg-5 col-md-12">

                                                                <h3>State : </h3></div>
                                                            {state && state.map((india, i) =>
                                                                india.id === CounsellorProfile.state ? (
                                                                    <div className="col-lg-7 col-md-12">

                                                                        <span key={i}>

                                                                            <span className="lowercase"> {india.name ? india.name : 'Mp'}</span>

                                                                        </span></div>
                                                                ) : null
                                                            )}

                                                        </div>
                                                        <div className="row align-items-center">
                                                            <div className="col-lg-5 col-md-12">

                                                                <h3>City : </h3></div>
                                                            <div className="col-lg-7 col-md-12">

                                                                <p className=" ">{CounsellorProfile.city}</p></div>
                                                        </div>
                                                        <div className="row align-items-start">
                                                            <div className="col-lg-5 col-md-12">

                                                                <h3>Current address : </h3></div>
                                                            <div className="col-lg-7 col-md-12">

                                                                <p className="lowercase ">
                                                                    {CounsellorProfile.current_address}
                                                                </p></div>
                                                        </div>
                                                        <div className="row align-items-start">
                                                            <div className="col-lg-5 col-md-12">

                                                                <h3>permanent address :</h3></div>
                                                            <div className="col-lg-7 col-md-12">
                                                                <p className="lowercase ">
                                                                    {CounsellorProfile.permanent_address}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="row align-items-start">
                                                            <div className="col-lg-5 col-md-12">

                                                                <h2>Zipcode : </h2></div>
                                                            <div className="col-lg-7 col-md-12">

                                                                <p className="lowercase ">
                                                                    {CounsellorProfile.zipcode}
                                                                </p></div>
                                                        </div>

                                                    </div>
                                                </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        }
                        <div className="row mt-4">
                            <div className="col-lg-12">
                                <div className="middle mt-2 mb-2 align-items-center d-flex pl-3">
                                    <h1><strong>Area of Opration : </strong></h1>

                                    <p className="middle_para"> Medical</p>

                                </div>


                                <div className="row hidden md:flex mb-5">


                                    <div className="col-md-6 md:mt-10">
                                        <div className="card p-2">
                                            <PieChart
                                                series={[
                                                    {
                                                        arcLabel: (item) => (`${item.value}`),
                                                        arcLabelMinAngle: 45,
                                                        data,
                                                    },
                                                ]}
                                                sx={{
                                                    [`& .${pieArcLabelClasses.root}`]: {
                                                        fill: "white",
                                                        fontWeight: "bold",
                                                    },
                                                }}
                                                {...size}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {CounsellorProfile && <div className="col-lg-12">
                                <div className="card">
                                    <div className="overview">
                                        <h1>Succes Story</h1>
                                        <p dangerouslySetInnerHTML={{ __html: CounsellorProfile.success_story }} />
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Profile;
