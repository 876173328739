import { useEffect, useState, useMemo } from "react";
import { Link, useNavigate ,NavLink} from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { LinearProgress } from "@mui/material";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const Pending = () => {
    const token = useMemo(() => localStorage.getItem('token') || '', []);
    const [collegeData, setCollegeData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedLead, setSelectedLead] = useState(null);
    const [newStatus, setNewStatus] = useState('');
    const navigate = useNavigate();
    const headers = useMemo(() => ({
        Authorization: `JWT ${token}`,
    }), [token]);

    const handleStatusChange = async (leadId, status) => {
        setSelectedLead(leadId);
        setNewStatus(status);
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to change the status to ${status}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
        });

        if (result.isConfirmed) {
            await handleSubmit();
        } else {

            setSelectedLead(null);
            setNewStatus('');
        }
    };


    const handleSubmit = async () => {
        try {
            const response = await axios.patch(`https://portal.mycollegepedia.com/college/college-leads/${selectedLead}/`, {
                status: newStatus,
            }, {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            });

            const updatedLead = response.data;
            setCollegeData((prevData) => prevData.map((lead) => (lead.id === updatedLead.id ? updatedLead : lead)));
            navigate('/college-dashboard/enquiry')
            Swal.fire({
                position:'top-end',
                icon: 'success',
                text: 'Status Update successfully!',
            });
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://portal.mycollegepedia.com/college/college-leads/', {
                    headers,
                });
                const data = response.data;
                const pendingData = data.filter(item => item.status === "Pending");
                setCollegeData(pendingData);
                setLoading(false);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('token');
                }
                Swal.fire({
                    icon: 'error',
                    title: 'Please try again Login',
                    text: 'There was an error.'
                });
                navigate('/login');
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [headers, navigate]);

    return (
        <div className="common_dash px-3">
           <div className="row align-items-center top   ">
                        <div className="col-lg-6">

                            <h3>Pending</h3>


                        </div>
                        <div className="col-lg-6">
                            <Breadcrumb className="bread">
                            <Breadcrumb.Item ><NavLink to="/college-dashboard">Home</NavLink></Breadcrumb.Item>

                                <Breadcrumb.Item active>Pending</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>

            <div className='bg-white'>
                {loading ? (
                    <p className="my-5"><LinearProgress />  </p>
                ) : (
                    <div className="table-responsive thin_scroll">
                        {collegeData.length === 0 ? (
                            <table className="table">
                                <thead>
                                    <tr className="bg-[whitesmoke]">
                                        <th>Logo</th>
                                        <th>College name</th>
                                        <th>Course</th>
                                        <th>Subcategory</th>
                                        <th>Stream</th>
                                        <th>Action</th>
                                        {/* <th>Image</th> */}
                                    </tr>
                                </thead>
                                <tbody className="bg-[white]">
                                    <p className="text-center my-4 text-red-600 ">! No records </p>
                                </tbody>
                            </table>
                        ) : (
                            <table className="table">
                                <thead>
                                    <tr className="bg-[whitesmoke]">
                                        <th>Profile</th>
                                        <th>Student name</th>
                                        <th>Course</th>
                                        <th>Subcategory</th>
                                        <th>Stream</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-[white]">
                                    {collegeData && collegeData.map((clg, i) => (
                                        <tr key={i}>
                                            <th><img src={`${clg.student.profile}`} alt={clg.student.name} title={clg.student.name} /></th>
                                            <th className="student_clr"><Link aria-label={clg.student.name} to={`student-profile/${clg.student.id}`}>{clg.student.name}</Link></th>
                                            <th>{clg.course_category.name}</th>
                                            <th className="width_define">{clg.course_subcategory.course_name}</th>
                                            <th className="width_define">{clg.course_stream.name}</th>
                                            <th className="flex items-center gap-2">
                                                <button
                                                    className="text-white btn-sm bg-success" title="Accepted"
                                                    onClick={() => handleStatusChange(clg.id, 'Accepted')}
                                                >
                                                    Accepted
                                                </button>
                                                <button
                                                    className="text-white btn-sm bg-danger" title="Rejected"
                                                    onClick={() => handleStatusChange(clg.id, 'Rejected')}
                                                >
                                                    Rejected
                                                </button>
                                            </th>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Pending;
