import React, { useState, useEffect } from 'react';
import CNF from "../../assets/images/clgnotfound.png";
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { FaAngleDoubleRight, FaRegCalendarAlt, FaSearch, FaArrowRight } from 'react-icons/fa';
import StarRating from '../Counselordashboard/Subpages/StarRatingInput';

const CollegeState = () => {
    const [collegeData, setCollegeData] = useState({});
    const { state_name } = useParams();

    useEffect(() => {
        const fetchCollegeData = async () => {
            try {
                const response = await axios.get(`https://portal.mycollegepedia.com/college/all-college/?state=${state_name}`);
                setCollegeData(response.data);
            } catch (error) {
                console.error('Error fetching college data:', error);
            }
        };
        fetchCollegeData();
    }, [state_name]);
    if (!collegeData || Object.keys(collegeData).length === 0) {
        return (
            <>
                <section className="breadcrumb-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2 className="ab-1">State College</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">{state_name}</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='mx-auto text-center col-lg-6 col-md-12'>
                    <div className='pt-10 text-2xl text-red-500'>
                        <p>No found for college that state.<span className='font-bold text-green-600'>Try Again</span></p>
                    </div>
                    <div className=''>
                        <img src={CNF} alt='errorimg' title='error' className="mx-auto text-6xl text-red-500 " />
                    </div>
                </div>
            </>
        );
    }
    return (
        <>
            <section className="breadcrumb-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="ab-1">State College</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/" title='home page'>Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{state_name}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container college_cards">
                <div className="row ">
                    <div className='col-md-9 '>
                        <div className='row' >
                            {collegeData && collegeData.map((college) => (
                                <div className="col-md-4" key={college.id}>
                                    <div className="card_college" >
                                        <img className="card-img-top mba-img" src={college.image}
                                            alt="Collegeimage" title={college.name} />
                                        <div className="card-body">
                                            <div className="main d-flex align-item-center">
                                                <div className="logocard">
                                                    <img className="" src={college.logo} alt={college.name} title={college.name} />
                                                </div>

                                            </div>
                                            <div className="d-flex head">
                                                <div className="mba">
                                                    <h6 className="title"> {college.course_category[0].name}</h6>
                                                    {/* <p>25.00 Lacks Total Fees</p> */}
                                                </div>

                                                <div className="mba1">
                                                    <h6 className="title d-flex text-warning"><StarRating rating={college.rating} /></h6>
                                                    {/* <p>51 Reviews</p> */}
                                                </div>
                                                <small className='small-c'> {college.organization_type.name}</small>
                                            </div>
                                            <div className="card-pera">
                                                <h4>{college.name}</h4>
                                            </div>
                                            <div>
                                                <small className="pt-1 adress">
                                                    <span > {college.current_address}</span>
                                                </small>
                                                <small className="pt-1 lowercase adress">
                                                    {/* 
                                       {CollegeData.State.map((state) =>
                                          state.id === college.state ? (
                                             <>
                                                {college.city}
                                                <span key={state.id}> {state.name}</span>
                                                <span className="web-loction-name"> India</span>
                                             </>
                                          ) : null
                                       )} */}

                                                </small>
                                            </div>
                                            <div className="link">
                                                <Link to={`/college/${college.slug}`} title='more-view' className="py-2">View All Course and Fees <FaArrowRight /></Link>
                                                {/* <Link to={`/broucher/college.brochure`} className="py-2">Download Broucher<FaArrowRight /></Link> */}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='sticky h-full pt-6 overflow-x-hidden col-md-3 top-16'>

                        <div className="search-s">
                            <div className="flex items-center gap-2 form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="search-s"
                                    placeholder="Search Courses"
                                />
                                <button className="text-xl text-[#052963]">
                                    <FaSearch className='mx-auto itmes-center' />
                                </button>
                            </div>
                        </div>
                        <div className="category-blog">
                            <h4 className='py-3'>All Categories</h4>
                            <ul className="gap-2 pl-2 space-y-2 list-unstyled">
                                <li>
                                    <Link to="" className='flex gap-2'>
                                        <FaAngleDoubleRight className="mt-1" /> Business{" "}
                                        <span>(7)</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="" className='flex gap-2'>
                                        <FaAngleDoubleRight className="mt-1" /> Courses{" "}
                                        <span>(20)</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="" className='flex gap-2'>
                                        <FaAngleDoubleRight className="mt-1" /> Languages{" "}
                                        <span>(4)</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="" className='flex gap-2'>
                                        <FaAngleDoubleRight className="mt-1" /> IT &amp; Software{" "}
                                        <span>(22)</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="" className='flex gap-2'>
                                        <FaAngleDoubleRight className="mt-1" /> Collages{" "}
                                        <span>(120)</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="r-post ">
                            <h4 className='py-2 text-black'>Recent Posts</h4>
                            <div className="py-3 post-dis">
                                <img
                                    src="https://www.ecologytheme.com/theme/eduwise/images/blog/side_blog_2.jpg"
                                    alt="msm"
                                    title='sm'
                                    className='w-full md:w-[64%] h-[45%] '
                                />
                                <div className="post-c">
                                    <h6 className='py-2 '>Lorem ipsum dolor sit amet.</h6>
                                    <span className="flex gap-2 date">
                                        <FaRegCalendarAlt className='mt-1 text-[#052963]' /> 6 0ctomber 2023
                                    </span>
                                </div>
                            </div>
                            <div className="py-3 post-dis">
                                <img
                                    src="https://www.ecologytheme.com/theme/eduwise/images/blog/side_blog_1.jpg"
                                    alt=""
                                    className='w-full md:w-[64%] h-[45%] '
                                />
                                <div className="post-c">
                                    <h6 className='py-2 '>Lorem ipsum dolor sit amet.</h6>
                                    <span className="flex gap-2 date">
                                        <FaRegCalendarAlt className='mt-1 text-[#052963]' /> 6 0ctomber 2023
                                    </span>
                                </div>
                            </div>
                            <div className="py-3 post-dis">
                                <img
                                    src="https://www.ecologytheme.com/theme/eduwise/images/blog/side_blog_2.jpg"
                                    alt=""
                                    className='w-full md:w-[64%] h-[45%] '
                                />
                                <div className="post-c">
                                    <h6 className='py-2 '>Lorem ipsum dolor sit amet.</h6>
                                    <span className="flex gap-2 date">
                                        <FaRegCalendarAlt className='mt-1 text-[#052963]' /> 6 0ctomber 2023
                                    </span>
                                </div>
                            </div>
                            <div className="py-3 post-dis ">
                                <img
                                    src="https://www.ecologytheme.com/theme/eduwise/images/blog/side_blog_1.jpg"
                                    alt=""
                                    className='w-full md:w-[64%] h-[45%] '
                                />
                                <div className="post-c">
                                    <h6 className='py-2 '>Lorem ipsum dolor sit amet.</h6>
                                    <span className="flex gap-2 date">
                                        <FaRegCalendarAlt className='mt-1 text-[#052963]' /> 6 0ctomber 2023
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CollegeState;
