import React, { useState, useMemo } from 'react';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import RichText from './Subpages/Richtext';
import { TiArrowBack } from 'react-icons/ti';

const Collegevideosadd = ({ handleupdate }) => {
    const token = useMemo(() => localStorage.getItem('token') || '', []);
    const college = JSON.parse(localStorage.getItem('collegerecord'));
    const [newVideo, setNewVideo] = useState({
        college: college[0].id,
        title: "",
        videofile: "",
        videourl: "",
        alt: "",
        description: ""
    });

    const handleRichTextChange = (name, value) => {
        setNewVideo({
            ...newVideo,
            [name]: value,
        });
    };

    const handlevideo = (e) => {
        const file = e.target.files[0];
        setNewVideo({
            ...newVideo,
            videofile: file,
        });
    };

    const handleChange = (e) => {
        const { name, value, } = e.target;
        setNewVideo((prevVideo) => ({
            ...prevVideo,
            videofileError: '',
        }));

        setNewVideo((prevVideo) => ({ ...prevVideo, [name]: value }));
    };
    const handleAddVideo = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('college', newVideo.college);
        formData.append('title', newVideo.title);
        formData.append('videourl', newVideo.videourl);
        formData.append('alt', newVideo.alt);
        formData.append('description', newVideo.description);
        if (newVideo.videofile && typeof newVideo.videofile !== "string") {
            formData.append('videofile', newVideo.videofile);
        }
        try {
            const response = await axios.post('https://portal.mycollegepedia.com/college/intro-video/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `JWT ${token}`,
                },
            });
            // console.log(response)
            setNewVideo({
                college: college[0].id,
                title: '',
                videofile: '',
                videourl: '',
                alt: '',
                description: ''
            });
            handleupdate();
            Swal.fire({
                position: 'top-end',
                timer: 3000,
                progressSteps: true,
                icon: 'success',
                text: 'Video added successfully!',
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'There was an error. Please try again.',
            });
            console.error('Error adding video:', error);
        }
    };
    return (
        <div className='conatiner'>
       
                <div className='justify-end row'>
                    <button onClick={()=>handleupdate()} className="btn-sm shadow-sm flex m-3 gap-1.5 float-right text-white items-center border bg-[#052963]">
                        <TiArrowBack />
                        Back
                    </button>
                </div>
                <form className="p-3 common_dash_form" onSubmit={handleAddVideo} encType="multipart/form-data">
                    <div className="row form-row">
                        <div className="col-12 col-sm-6">
                            <div className="form-group">
                                <label>Title</label>
                                <input
                                    type="text"
                                    name="title"
                                    className="form-control"
                                    placeholder="video-title"
                                    value={newVideo.title}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <Form.Group>
                                <label>Video file:</label>
                                <Form.Control
                                    type="file"
                                    name="videofile"
                                    onChange={handlevideo}

                                />
                                <Form.Control.Feedback type="invalid">
                                    {newVideo.videofileError}
                                </Form.Control.Feedback>
                            </Form.Group>

                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="form-group">
                                <label>Video URL:</label>
                                <Form.Control placeholder='video path' type="text" name='videourl' value={newVideo.videourl} onChange={handleChange} />

                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="form-group">
                                <label>Alt:</label>
                                <Form.Control type="text" placeholder='Alt text' name='alt' value={newVideo.alt} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label>Description:</label>
                                <RichText
                                    name="description"
                                    placeholder={"description"}
                                    value={newVideo.description}
                                    onChange={handleRichTextChange}
                                />
                            </div>
                        </div>
                    </div>
                    <Modal.Footer>
                        <Button  className='button_of_close' onClick={() => handleupdate()}>
                            Close
                        </Button>
                        <Button  type='submit' className='button_of_submit' >
                            Add Video
                        </Button>
                    </Modal.Footer>
                </form>
            </div>
      
    )
}

export default Collegevideosadd