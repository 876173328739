import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import CNF from "../../assets/images/clgnotfound.png";
import { FaArrowRight, FaCaretDown, FaCaretUp, FaStar } from 'react-icons/fa';
import { BiSliderAlt } from 'react-icons/bi';
import Collapsible from 'react-collapsible';
import Apicontext from '../../Page/Contextapi';
import img from "../../assets/images/bgcollege.jpg";
import axios from 'axios';
import StarRating from '../Homepages/Startrating';
const Collegespage = () => {
  const [data, setData] = useState('');
  const [collegeData, setCollegeData] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
 
  // const [filteredColleges, setFilteredColleges] = useState([]);
  const contextData = useContext(Apicontext);
  const [selectedState, setSelectedState] = useState([]);
  // const [serachstate, setSearchstate] = useState('');
  // const [Serachstatedata, setSearchStatedata] = useState(data.State);
  var colleges = contextData.APIdata.college;
  useEffect(() => {
    setData(contextData.APIdata);
    setCollegeData(colleges);
  }, [contextData, colleges]);

  const [locationOpen, setLocationOpen] = useState(true);
  const [coursesOpen, setCoursesOpen] = useState(true);
  const [feesOpen, setFeesOpen] = useState(true);
  const [InstituteOpen, setInstituteOpen] = useState(true);
  const [clevelOpen, setClevelOpen] = useState(false);
  const [ownershipOpen, setOwnershipOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [selectedFees, setSelectedFees] = useState([]);
  const [selectedInstitute, setselectInstitute] = useState([]);
  const [selectClevel, setselectClevel] = useState([]);
  const [selectOwnership, setselectOwnership] = useState([]);
  const [filterVisible, setFilterVisible] = useState(false);

  const handleClearAll = () => {
    setSelectedState([]);
    setSelectedCourse([]);
    setSelectedFees([]);
    setselectInstitute([]);
    // setSelectClevel([]);
    // setSelectOwnership([]);
    const checkboxes = document.querySelectorAll('.Filtersection input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  };

  const toggleLocation = () => {
    setLocationOpen(!locationOpen);
  };
  const toggleCourses = () => {
    setCoursesOpen(!coursesOpen);
  };
  const toggleFees = () => {
    setFeesOpen(!feesOpen);
  };
  const toggleInstitute = () => {
    setInstituteOpen(!InstituteOpen);
  };
  const toggleClevel = () => {
    setClevelOpen(!clevelOpen);
  };
  const toggleOwnership = () => {
    setOwnershipOpen(!ownershipOpen);
  };

  const handleLocation = (location) => {
    setSelectedState((prevSelected) => {
      if (prevSelected.includes(location.id)) {
        return prevSelected.filter((item) => item !== location.id);
      } else {
        return [...prevSelected, location.id];
      }
    });
  };
  const handleCourse = (course) => {
    setSelectedCourse((prevSelected) => {
      if (prevSelected.includes(course)) {
        return prevSelected.filter((item) => item !== course);
      } else {
        return [...prevSelected, course];
      }
    });
  };

  const handleFees = (Fees) => {
    setSelectedFees((prevSelected) => {
      if (prevSelected.includes(Fees)) {
        return prevSelected.filter((item) => item !== Fees);
      } else {
        return [...prevSelected, Fees];
      }
    });
  };
  const handleInstitute = (Institute) => {
    setselectInstitute((prevSelected) => {
      if (prevSelected.includes(Institute)) {
        return prevSelected.filter((item) => item !== Institute);
      } else {
        return [...prevSelected, Institute];
      }
    });
  };
  const handleClevel = (Clevel) => {
    setselectClevel((prevSelected) => {
      if (prevSelected.includes(Clevel)) {
        return prevSelected.filter((item) => item !== Clevel);
      } else {
        return [...prevSelected, Clevel];
      }
    });
  };
  const filterBySearchQuery = (collegeData, searchQuery) => {
    return collegeData.filter((college) =>
      college.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };
  const handleOwnership = (Ownership) => {
    setselectOwnership((prevSelected) => {
      if (prevSelected.includes(Ownership)) {
        return prevSelected.filter((item) => item !== Ownership);
      } else {
        return [...prevSelected, Ownership];
      }
    });
  }
  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     try {
  //       const response = await axios.get(`https://portal.mycollegepedia.com/college/all-college/?search=${searchQuery}`);
  //       setSearchResult(response.data);
  //     } catch (error) {
  //       console.error('Error fetching user data:', error);
  //     }
  //   };
  //   fetchUserData();
  // }, [searchQuery]);


  const applyFilters = (collegeData, filters) => {
    let filteredColleges = collegeData;
    const {
      searchQuery,
      selectedState,
      selectedCourse,
      selectedFees,
      selectedInstitute,
     
      selectOwnership,
    } = filters;


    if (selectedState.length > 0) {
      filteredColleges = filteredColleges.filter((college) =>
        selectedState.includes(college.state)
      );
    }
    if (selectedCourse.length > 0) {
      filteredColleges = filteredColleges.filter((college) =>
        college.course_category.some((category) =>
          selectedCourse.includes(category.id)
        )
      );
    }
    if (selectedFees.length > 0) {
      filteredColleges = filteredColleges.filter((college) =>
        college.course_fee.some((fees) =>
          parseFloat(fees.total_fees) >= selectedFees &&
          selectedFees.includes(parseFloat(fees.total_fees))
        )
      );
    }

    if (selectedInstitute.length > 0) {
      filteredColleges = filteredColleges.filter((college) =>
        selectedInstitute.includes(college.organization_type.id)
      );
    }
    if (selectOwnership.length > 0) {
      filteredColleges = filteredColleges.filter((college) =>
        selectOwnership.includes(college.college_type.id)
      );
    }
    if (searchQuery) {
      filteredColleges =  filterBySearchQuery(filteredColleges, searchQuery);
    }
    return filteredColleges;
  };
  const filteredColleges = useMemo(() => {
    const filters = {
      searchQuery,
      selectedState,
      selectedCourse,
      selectedFees,
      selectedInstitute,
      selectClevel,
      selectOwnership,
    };
    return applyFilters(collegeData, filters);
  }, [searchQuery, collegeData, selectedState, selectedCourse, selectedFees, selectedInstitute, selectClevel, selectOwnership]);

  if (!filteredColleges) {
    return (
      <div className='container'>
        <div className='mx-auto text-center col-lg-6 col-md-12'>
          <div className='!pt-10 !text-2xl !text-red-500'><p>No found Matching Exams...<span className='font-bold text-green-600'>Try Again</span></p></div>
          <div className=''>
            <img src={CNF} alt='errorimg' title='page not found' className="!mx-auto text-6xl text-red-500 " />
          </div>
        </div>
      </div>
    );
  }
  const totalcolleges = filteredColleges.length;
  // console.log("collegepage",collegeData)
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>All Colleges in India and International - MyCollegePedia</title>
        <meta name='description' content='Explore a comprehensive list of colleges in India and internationally. Find information about admissions, exams, and more on MyCollegePedia.' />
        <meta name='keywords' content='collegePedia, college, Indian colleges, international colleges, admissions, exams' />
        <meta name='author' content='mycollegePedia Team Name Gurjar Rohit Patel' />
        <meta name='publisher' content='sion datamatics pvt lt indore india ' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <meta name='robots' content='index, follow' />
      </Helmet>
     

      <div className='container-fluid p-0 m-0 colleges-main-section'>
      {/* <button className='filter-btn' onClick={() => setFilterVisible(!filterVisible)}>
          <BiSliderAlt /> 
        </button> */}
        
        <div className={`row m-0 p-0 all_cards ${filterVisible ? 'filter-visible' : 'filter-hidden'}`}>
          <div className='allcollege_sidebar all_cards items-center justify-center h-auto col-md-3 col-12 px-4  all_cards'>
            <div className='justify-center !bg-[whitesmoke] all_cards !h-[100%] !w-full  border-inherit outline-offset-4  Filtersection'>
              <div className='flex justify-between'><strong className='flex items-center p-1'> <span className='px-2 m-1 text-blue-700'><BiSliderAlt /></span>All Filter</strong>
                <button className='p-2 !text-blue-700' onClick={handleClearAll}> Clear All</button>
              </div>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='s_bar'>
                    <form  method="get" class="search-bar mt-1" traget="_blank">
                      <input
                        type="text"
                        placeholder="Search by Colleges..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="w-100 p-2"
                      />
                    
                    </form>
                  </div>
                </div>
              </div>
              <div className='m-2'>
                {selectedState.map((location) => (
                  <button className='p-1 m-1 border-2 rounded-xl' key={location}>
                    {data.State.find((item) => item.id === location)?.name}
                  </button>
                ))}
                {selectedCourse.map((course) => (
                  <button className='p-1 m-1 border-2 rounded-xl' key={course}>
                    {data.category.find((item) => item.id === course)?.name}
                  </button>
                ))}
                {selectedFees.map((fees) => (
                  <button className='p-1 m-1 border-2 rounded-xl' key={fees}>{fees}</button>
                ))}
                {selectedInstitute.map((Institute) => (
                  <button key={Institute} className='p-1 border'>
                    {Institute === 1 ? 'University' : Institute === 2 ? 'College' : 'Unknown'}
                  </button>
                ))}
                {selectClevel.map((Clevel) => (
                  <button className='p-1 m-1 border-2 rounded-xl' key={Clevel}>{Clevel}</button>
                ))}
                {selectOwnership.map((Ownership) => (
                  <button className='p-1 m-1 border-2 rounded-xl' key={Ownership}>{Ownership}</button>
                ))}
              </div>

              <div className='location all_cards'>
                <b className='flex justify-between m-2 ' onClick={toggleLocation}>
                  <span>State</span>
                  {locationOpen ? <FaCaretUp /> : <FaCaretDown />}
                </b>

                <Collapsible
                  trigger=''
                  open={locationOpen}
                  onTriggerOpening={toggleLocation}
                  onTriggerClosing={toggleLocation}
                >
                  <div className='m-2 overflow-y-auto max-h-40 webkitsc'>
                    <div className="relative">
                      <input className="pl-8 border-double bg-slate-100 rounded-2xl" type="search" placeholder="Location" />
                      <i className="absolute text-gray-600 transform -translate-y-1/2 fa fa-search left-2 top-1/2"></i>
                    </div>
                    <ul className='m-2'>
                      {data && data.State.map((item) => (
                        <li key={item.id}>
                          <input type='checkbox' value={item.id} onChange={() => handleLocation(item)} />
                          {item.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Collapsible>
                <hr />
              </div >

              <div className='Courses all_cards'>
                <b className='flex justify-between m-2' onClick={toggleCourses}>
                  <span>Courses</span>
                  {coursesOpen ? <FaCaretUp /> : <FaCaretDown />}
                </b>
                <Collapsible
                  open={coursesOpen}
                  onTriggerOpening={toggleCourses}
                  onTriggerClosing={toggleCourses}
                >
                  <div className='m-2 overflow-y-auto Courses max-h-40 webkitsc'>
                    <div className="relative">
                      {/* <input className="pl-8 border-double rounded-2xl bg-slate-100" onChange={handde} type="search" placeholder="Courses" />
                      <i className="absolute text-gray-600 transform -translate-y-1/2 fa fa-search left-2 top-1/2"></i> */}
                    </div>
                    <ul className='m-3'>
                      {data && data.category.map((item) => (
                        <li key={item.id}> <input type='checkbox' value={item.id} onChange={() => handleCourse(item.id)} /> {item.name}</li>
                      ))}
                    </ul>
                  </div>
                </Collapsible>
                <hr />
              </div>
              <div className='Fees all_cards'>
                <b className='flex justify-between m-2' onClick={toggleFees}>
                  <span>Total Fees</span>
                  {feesOpen ? <FaCaretUp /> : <FaCaretDown />}
                </b>
                <Collapsible
                  open={feesOpen}
                  onTriggerOpening={toggleFees}
                  onTriggerClosing={toggleFees}
                >
                  <div className='m-2 overflow-y-auto max-h-40 webkitsc'>
                    <div className="relative">
                      <input className="pl-8 border-double rounded-2xl bg-slate-100" type="search" placeholder="Fees" />
                      <i className="absolute text-gray-600 transform -translate-y-1/2 fa fa-search left-2 top-1/2"></i>
                    </div>
                    <ul className='m-2'>
                      <li> <input type='checkbox' onChange={() => handleFees(100000)} /> 1 Lakh</li>
                      <li> <input type='checkbox' onChange={() => handleFees(200000)} /> 1-2 Lakh</li>
                      <li> <input type='checkbox' onChange={() => handleFees(300000)} /> 2-3 Lakh</li>
                      <li> <input type='checkbox' onChange={() => handleFees(500000)} /> 3-5 Lakh</li>
                      <li> <input type='checkbox' onChange={() => handleFees(1000000)} /> 5-10 Lakh</li>
                      <li> <input type='checkbox' onChange={() => handleFees(999999999)} /> 11 Lakh</li>
                    </ul>
                  </div>
                </Collapsible>
                <hr />
              </div>

              <div className='Institute all_cards'>
                <b className='flex justify-between m-2' onClick={toggleInstitute}>
                  <span>Institute</span>
                  {InstituteOpen ? <FaCaretUp /> : <FaCaretDown />}
                </b>
                <Collapsible
                  open={InstituteOpen}
                  onTriggerOpening={toggleInstitute}
                  onTriggerClosing={toggleInstitute}
                >
                  <div className='m-2 overflow-y-auto max-h-40 webkitsc'>
                    <div className="relative">
                      <input className="pl-8 border-double rounded-2xl bg-slate-100" type="search" placeholder="Institute" />
                      <i className="absolute text-gray-600 transform -translate-y-1/2 fa fa-search left-2 top-1/2"></i>
                    </div>
                    <ul className='m-2'>
                      <li> <input type='checkbox' onChange={() => handleInstitute(2)} /> College </li>
                      <li> <input type='checkbox' onChange={() => handleInstitute(1)} /> University </li>
                    </ul>
                  </div>
                </Collapsible>
                <hr />
              </div>
              <div className='Clevel all_cards'>
                <b className='flex justify-between m-2' onClick={toggleClevel}>
                  <span>Course Level</span>
                  {clevelOpen ? <FaCaretUp /> : <FaCaretDown />}
                </b>
                <Collapsible
                  open={clevelOpen}
                  onTriggerOpening={toggleClevel}
                  onTriggerClosing={toggleClevel}
                >
                  <div className='m-2 overflow-y-auto max-h-40 webkitsc'>
                    <div className="relative">
                      <input className="pl-8 border-double rounded-2xl bg-slate-100" type="search" placeholder="Course Level" />
                      <i className="absolute text-gray-600 transform -translate-y-1/2 fa fa-search left-2 top-1/2"></i>
                    </div>
                    <ul className='m-2'>
                      <li><input type='checkbox' onChange={() => handleClevel('UG')} /> UG</li>
                      <li><input type='checkbox' onChange={() => handleClevel('PG')} /> PG</li>
                      <li><input type='checkbox' onChange={() => handleClevel('PHD')} /> PHD</li>
                      <li><input type='checkbox' onChange={() => handleClevel('Diploma')} /> Diploma</li>
                      <li><input type='checkbox' onChange={() => handleClevel('Certificate')} /> Certificate</li>
                    </ul>
                  </div>
                </Collapsible>
                <hr />
              </div>
              <div className='Ownership all_cards'>
                <b className='flex justify-between m-2' onClick={toggleOwnership}>
                  <span>Ownership</span>
                  {ownershipOpen ? <FaCaretUp /> : <FaCaretDown />}
                </b>
                <Collapsible
                  open={ownershipOpen}
                  onTriggerOpening={toggleOwnership}
                  onTriggerClosing={toggleOwnership}
                >
                  <div className='m-2 overflow-y-auto max-h-40 webkitsc'>
                    <div className="relative">
                      <input className="pl-8 border-double rounded-2xl bg-slate-100" type="search" placeholder="Ownership" />
                      <i className="absolute text-gray-600 transform -translate-y-1/2 fa fa-search left-2 top-1/2"></i>
                    </div>
                    <ul className='m-2'>
                      <li><input type='checkbox' onChange={() => handleOwnership(2)} /> Private</li>
                      <li><input type='checkbox' onChange={() => handleOwnership(1)} /> Public/Government</li>
                      <li><input type='checkbox' onChange={() => handleOwnership(2)} /> Public/Private</li>
                    </ul>
                  </div>
                </Collapsible>
                <hr />
              </div>
            </div >
          </div>

          <div className='md:px-2 col-12 col-md-9'>

            <div className="grid !grid-cols-1 sm:!grid-cols-2 md:!grid-cols-3 lg:!grid-cols-3 md:gap-3 bg-[#fff]">
              {filteredColleges && filteredColleges.length === 0 ? (
                <>
                  <div></div>
                  <div className="bg-[#fff]">
                    <div className="grid items-center text-2xl text-red-500">
                      No matching colleges found...<span className="font-bold text-center text-green-600 border">Try Again</span>
                    </div>
                    <img src={CNF} alt="errorimg" className="mx-auto text-6xl text-red-500" />
                  </div>
                </>
              ) : (filteredColleges.map((college) => (
                <div className="" key={college.id}>
                  <div className="card_college" >
                    <div className='all_college_img'>

                      <img className="card-img-top mba-img" src={college.image ? college.image : img}

                        alt={college.name} title={college.name} loading='lazy' />
                    </div>
                    <div className="card-body">
                      <div className="main d-flex align-item-center">
                        <div className="logocard">
                          <img className="" loading='lazy'  src={college.logo} alt={college.name} title={college.name} />
                        </div>

                      </div>
                      <div className="d-flex head">
                        <div className="mba">
                          <h6 className="title"> {college.course_category[0].name}</h6>
                          {/* <p>25.00 Lacks Total Fees</p> */}
                        </div>
                        <div className="mba1">
                          <h6 className="title d-flex text-warning"><StarRating rating={college.rating} /></h6>
                          {/* <p>51 Reviews</p> */}
                        </div>
                        <small className='small-c'> {college.organization_type.name}</small>
                      </div>
                      <div className="card-pera">
                        <h4> <Link to={`/college/${college.slug}`} className="py-2" title={college.name}>{college.name}</Link></h4>
                      </div>
                      <div>
                        <small className="pt-1 adress">
                          <span > {college.current_address}</span>
                        </small>
                        {/* <small className="pt-1 lowercase adress">

                          {data && data.State.map((state, i) =>
                            state.id === college.state ? (
                              <p key={i}>
                                {college.city}
                                <span key={state.id}> {state.name}</span>
                                <span className="web-loction-name"> India</span>
                              </p>
                            ) : null
                          )}
                        </small> */}
                      </div>
                      <div className="link">
                        <Link to={`/college/${college.slug}`} className="py-2" title={college.name}>View All Course and Fees <FaArrowRight /></Link>
                        {/* <Link to={`/broucher/${college.slug}`} className="py-2">Download Broucher<FaArrowRight /></Link> */}
                      </div>

                    </div>
                  </div>

                </div>
              ))
              )}
            </div>
            <div className='totalnumberofcollege '> colleges <button className='btn'>{totalcolleges}</button></div>
          </div>
        </div>
      </div>
      <button className="filter-btn" onClick={() => setFilterVisible(!filterVisible)}> <BiSliderAlt /></button>
    </>
  )
}
export default Collegespage