import React, { useEffect } from "react";
import { useNavigate, } from "react-router-dom";
import Headerdashboard from "./Header";
import axios from "axios";
import Swal from "sweetalert2";
const Dashboard = () => {
    const navigate = useNavigate();
    const usertoken = localStorage.getItem('token');
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = usertoken;
                const headers = {
                    Authorization: `JWT ${token}`,
                };
                const profile = await axios.get('https://portal.mycollegepedia.com/account/student-profile', { headers });
                const profileString = JSON.stringify(profile.data);
                localStorage.setItem('userprofile', profileString)
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    Swal.fire({
                        icon: 'error',
                        title: 'User Unauthorized',
                        text: 'There was an error try again.'
                    });
                    navigate('/login')
                }
                console.error("",error);
            }
        };
        fetchUserData();
    }, [usertoken,navigate]);
    useEffect(() => {
        if (!usertoken) {
            navigate('/signup');
        }
    }, [usertoken, navigate]);
    return (
        <>
            <header className="sticky z-10 w-full border-b border-gray-300 -top-4 ">
                <Headerdashboard />
            </header>
          
        </>
    );
};

export default Dashboard;
