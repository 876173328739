import React, { useContext, useState, useEffect } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Apicontext from "../../Page/Contextapi";
import { Link } from "react-router-dom";
import { BsEnvelope, BsFacebook, BsInstagram, BsLinkedin, BsTwitterX, BsWhatsapp, BsYoutube } from "react-icons/bs";
import mycollegepedialogo from "../../assets/images/Logo.png";
import { LinearProgress } from '@mui/material';
import Loading from "../../Page/Loading";

const NewFooter = () => {
  const [data, setData] = useState('');
  const [exams, setExams] = useState([]);
  const [colleges, setColleges] = useState([]);
  const [university, setUniversity] = useState([]);
  const [loading, setLoading] = useState(true);
  const [examsWithin24Hours, setExamsWithin24Hours] = useState([]);
  const [application24Hours, setApplication24Hours] = useState([]);
  const contextdata = useContext(Apicontext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = contextdata.APIdata;
        const responsExam = contextdata.APIdata.exam;
        setData(responseData);
        setExams(responsExam);
        checkExams(responsExam);
        if (responseData.college) {
          const filteredUniversities = responseData.college.filter(college => 
            college.college_type.name === "Government"
          );
          const reversedUniversities = [...filteredUniversities].reverse();
          setUniversity(reversedUniversities);

          // Filter private colleges
          const filteredPrivateColleges = responseData.college.filter(college => 
            college.college_type.name === 'Private'
          );
          const reversedPrivateColleges = [...filteredPrivateColleges].reverse();
          setColleges(reversedPrivateColleges);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [contextdata]);

  const checkExams = (examData) => {
    const currentTime = new Date();
    const twentyFourHoursLater = new Date(currentTime);
    twentyFourHoursLater.setDate(currentTime.getDate() + 2);

    const examsWithin24Hours = examData.filter(exam => {
      const examStartDate = new Date(exam.exam_start_date);
      return examStartDate <= twentyFourHoursLater && examStartDate > currentTime;
    });
    setExamsWithin24Hours(examsWithin24Hours);

    const application24Hours = examData.filter(exam => {
      const applicationStartDate = new Date(exam.application_start_date);
      return applicationStartDate <= twentyFourHoursLater && applicationStartDate > currentTime;
    });
    setApplication24Hours(application24Hours);
  };
  const notifymsg = () => {
    examsWithin24Hours.forEach((exam, index) => {
      setTimeout(() => {
        toast.info(
          <div className='exam-notification' key={exam.id}>
            <h2 className="text-center my-1">Exam-update</h2>
            <Link className="examlinks" to={`/exam/${exam.slug}`} title={exam.title}>
              <h2 className='divheading'>{exam.title}</h2>
              <p>Start Date: {exam.exam_start_date}</p>
              {exam.exam_end_date && <p>End Date: {exam.exam_end_date}</p>}
            </Link>
          </div>,
          {
            position: "bottom-left",
            autoClose: true,
            closeOnClick: false,
            onClose: true,
            toastId: `exam-${exam.id}`,
            hideProgressBar: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          }
        );
      }, index * 5000);
    });
    application24Hours.forEach((exam, index) => {
      setTimeout(() => {
        toast.info(
          <div className='exam-notification' key={exam.id}>
            <h2 className="text-center my-1">Application form-update</h2>
            <Link className="examlinks" to={`/exam/${exam.slug}`} title={exam.title}>
              <h3 className='divheading'>{exam.title}</h3>
              <p>Start Date: {exam.exam_start_date}</p>
              {exam.exam_end_date && <p>End Date: {exam.exam_end_date}</p>}
            </Link>
          </div>,
          {
            position: "bottom-left",
            autoClose: true,
            closeOnClick: false,
            onClose: true,
            toastId: `exam-${exam.id}`,
            hideProgressBar: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          }
        );
      }, index * 5000);
    });
  };
  useEffect(() => {
    notifymsg();
  }, []);

 

  if (loading) {
    return (
      <div className='container'>
        <div className='row p-5 my-5'>
          <LinearProgress />
        </div>
      </div>
    );
  }
  if (!examsWithin24Hours || !application24Hours) {
    return (
      <div className='container'>
        <div className='row p-5 my-5'>
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <footer id="footer">
      <div className="popup_notification">
        <ToastContainer />
        </div>
      {/* footer-section start */}
      <div className="footer-top">
        <div className="container mb-2 separation">
          <div className="row">
            <div className="col-lg-3 footer-links">
              <h4>Top Private Colleges</h4>
              <ul>
                {data && colleges.slice(0,5).map((item,i)=>
                <li className="line-clamp-1" key={i}> <Link to={`college/${item.slug}/`} title={item.name}><i className="fa-solid fa-chevron-right" /> {item.name} </Link></li>
                )} 
              </ul>
            </div>
            <div className="col-lg-3 footer-links">
              <h4>Top Government Colleges</h4>
              <ul>
                {university && university.slice(0,5).map((item,i)=>
                <li className="line-clamp-1" key={i}> <Link to={`college/${item.slug}/`} title={item.name}><i className="fa-solid fa-chevron-right" /> {item.name} </Link></li>
                )} 
              </ul>
            </div>
            <div className="col-lg-3 footer-links">
              <h4>Exams</h4>
              <ul>
                {exams && exams.slice(0,5).map((item,i)=>
                <li className="line-clamp-1" key={i}> <Link to={`exam/${item.slug}/`} title={item.title}><i className="fa-solid fa-chevron-right" /> {item.exam.title} </Link></li>
                )} 
              </ul>
            </div>
            <div className="col-lg-3 footer-links">
              <h4>Top Counsellor</h4>
              <ul>
              <ul>
                {data && data.counsellors.slice(0,5).map((item,i)=>
                <li className="line-clamp-1" key={i}> <Link to={`counsellor/${item.slug}/`} title={item.company_name}><i className="fa-solid fa-chevron-right" /> {item.company_name} </Link></li>
                )} 
              </ul>
              </ul>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-info">
              <img src={data.detail.logo || mycollegepedialogo} alt={data.detail.title || "collegepedia logo"} className="brightness-150" title={data.detail.title || "mycollegepedia-logo"} />
              <h4>India's No.1 College Finding Platform</h4>
              <h6 className="py-3">
                A university usually comprising a college graduate and
                professional having the authority
                <p>1, 5, Nath Mandir Rd, South Tukoganj,
                  Indore, MP 452001</p>
              </h6>
            </div>
            <div className="col-lg-2 footer-links -mb-1">
              <h4>Useful Links</h4>
              <ul>
                <li className="line-clamp-1"><Link to={'/'} title="Home" ><i className="fa-solid fa-chevron-right" /> Home </Link></li>
                <li className="line-clamp-1"><Link to={'/about-us'} title="about us" ><i className="fa-solid fa-chevron-right" /> About Us</Link></li>
                <li className="line-clamp-1"><Link to={'/all-colleges'} title="colllege" ><i className="fa-solid fa-chevron-right" /> Colleges</Link></li>
                <li className="line-clamp-1"><Link to={'/all-exams'} title="exams" ><i className="fa-solid fa-chevron-right" /> Exams</Link></li>
                <li className="line-clamp-1"><Link to={'/all-counsellor'} title="Counsellors" ><i className="fa-solid fa-chevron-right" /> Counsellors</Link></li>
                <li className="line-clamp-1"><Link to={'/blogs'} title="blogs" ><i className="fa-solid fa-chevron-right" /> Blogs</Link></li>
                <li className="line-clamp-1"><Link to={'/contact-us'} title="contact-us" ><i className="fa-solid fa-chevron-right" /> Contact Us</Link></li>
              </ul>
            </div>
            <div className="col-lg-3 footer-links">
              <h4>Join Us</h4>
              <ul>
                <li className="line-clamp-1">
                   <Link to={'/counsellor/registration'} title="Counsellor-Registration"> <i className="fa-solid fa-chevron-right" />
                   Register As Counsellor
                    </Link>
                </li>
                <li className="line-clamp-1"><i className="fa-solid fa-chevron-right" />Register As Colleges</li>
              </ul>
              <h4>Blogs</h4>
              <ul>
                {data.blog.slice(0,3).map((item)=>
                <li className="line-clamp-1" key={item.id}>
                   <Link to={`/blog/${item.slug}`} aria-label={item.title} title={item.title}> <i className="fa-solid fa-chevron-right" />
                    {item.title}
                    </Link>
                </li>
                )}

              </ul>
            </div>
            {/* <div className="col-lg-2 col-md-6 footer-links">
              <h4>Courses </h4>
              <ul>
                {data && data.category.slice(0, 5).map((course) => (
                  <li key={course.id} className="line-clamp-1">
                    <i className="fa-solid fa-chevron-right" /> <Link to={`/category/${course.name}`} className="">{course.name}</Link>
                  </li>
                ))}

              </ul>
            </div>
            <div className="col-lg-4 col-md-6 footer-links">
              <h4>Upcoming Exam </h4>
              <ul>
                {data && data.exam.slice(0, 5).map((exam) => (
                  <li key={exam.id} className="line-clamp-1">
                    <i className="fa-solid fa-chevron-right" />{" "}
                    <Link to={`/exam/${exam.slug}`} title={exam.title}>{exam.exam.title} ({exam.exam.full_form})</Link>
                  </li>
                ))}
              </ul>
            </div> */}
            
            
            <div className="col-lg-4 col-md-6 footer-contact footer-links">
              <h4>Contact Us</h4>
              <p>
                <strong>Phone:</strong>{data ? data.detail.primary_mobile : "7880110983"}{" "}
              </p>
              <p><strong>Email:</strong> {data ? data.detail.email : 'mycollegepedia@gmail.com'}</p>
              <ul className="py-2 foot-sec-bt">

                <li className="footer-bot-list">
                  <a href={`${data ? data.detail.whatsapp : 'https://wa.link/enygey'}`} target="_blank" className="foot-icon-click" rel="noopener noreferrer">
                    <BsWhatsapp size={18} color="green" />
                  </a>
                </li>
                <li className="footer-bot-list">
                  <a href={`${data ? data.detail.facebook : "https://www.facebook.com/mycollegepediaofficial"}`} target="_blank" className="foot-icon-click" rel="noopener noreferrer">
                    <BsFacebook size={18} color="#1877F2" />
                  </a>
                </li>
                <li className="footer-bot-list">
                  <a href='https://www.instagram.com/my_college_pedia/' target="_blank" className="foot-icon-click" rel="noopener noreferrer">
                    <BsInstagram size={18} color="#fa7e1e" />
                  </a>
                </li>
                <li className="footer-bot-list">
                  <a href='https://twitter.com/mycollegepedia' target="_blank" className="foot-icon-click" rel="noopener noreferrer">
                    <BsTwitterX size={18} color="#000000" />
                  </a>
                </li>
                <li className="footer-bot-list">
                  <a href='https://www.linkedin.com/in/my-college-pedia/' target="_blank" className="foot-icon-click" rel="noopener noreferrer">
                    <BsLinkedin size={18} color="	#0077B5" />
                  </a>
                </li>
                <li className="footer-bot-list">
                  <a href={'https://www.youtube.com/channel/UCv5bPlaTmohOljrZU1xXEJg'} target="_blank" className="foot-icon-click" rel="noopener noreferrer">
                    <BsYoutube size={18} color="#CD201F" />
                  </a>
                </li>
                <li className="footer-bot-list">
                  <a href={'mycollegepedia@gmail.com'} target="_blank" className="foot-icon-click" rel="noopener noreferrer">
                    <BsEnvelope size={18} color="#d9c505" />
                  </a>
                </li>
              </ul>
              <h4 className="mt-3">Quick Links</h4>
              <ul>
                <li className="line-clamp-1"><Link to={'/terms-condition'} title="terms-condition" ><i className="fa-solid fa-chevron-right" /> Privacy & Policy</Link></li>
                <li className="line-clamp-1"> <Link to={'/terms-condition'} title="terms-condition" ><i className="fa-solid fa-chevron-right" /> Terms & Conditions</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          &copy; 2023 Copyright All Rights Reserved.
          {/* <span className=" ml-1 border-l" >
            <Link to="/privacy-policy" className="hover:[orange]"  title="Privacy-ploicy">{' '} Privacy & Policy </Link>
          </span>
          <span className=" px-1 mx-1 border-x">
            <Link to="/terms-condition" className="hover:[orange]" title="Terms & Condition" > {' '} Term & Condition  </Link>
          </span>
          <span className="mx-1 "><Link to={'/contact-us'} className="hover:[orange]" title="contact-us">Contact us</Link> </span> */}
        </div>
      </div>
    </footer>

  );
};

export default NewFooter;