import { useEffect, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { FaEye } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";

const Colleges = () => {
    const token = useMemo(() => localStorage.getItem('token') || '', []);
    const [collegeData, setCollegeData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);
    const navigate = useNavigate();
    console.log(collegeData)
    const headers = useMemo(() => ({
        Authorization: `JWT ${token}`,
    }), [token]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://portal.mycollegepedia.com/account/college-profile/', {
                    headers,
                });
                const data = response.data;
                setCollegeData(data);
                setLoading(false);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('token');
                }
                Swal.fire({
                    icon: 'error',
                    title: 'Please try again Login',
                    text: 'There was an error.'
                });
                navigate('/login')
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [headers, navigate]);
    // if (collegeData.length) {
    //     return (
    //         <>
    //             <nav aria-label="breadcrumb" className='bg-slate-200'>
    //                 <ol className="breadcrumb !justify-start">
    //                     <li className="breadcrumb-item"><Link to="/college-dashboard">Home</Link></li>
    //                     <li className="breadcrumb-item"><Link to={''}>College</Link></li>
    //                     <li className="breadcrumb-item active" aria-current="page">Colleges</li>
    //                 </ol>
    //             </nav>
    //             <p className="p-10">No data available.</p>
    //         </>
    //     );
    // }
    if (!token) { return null; }
    return (
        <div>
            <nav aria-label="breadcrumb" className='bg-slate-200'>
                <ol className="breadcrumb !justify-start">
                    <li className="breadcrumb-item"><Link to="/college-dashboard">Home</Link></li>
                    <li className="breadcrumb-item"><Link to={''}>College</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Colleges</li>
                </ol>
            </nav>
            <div className='bg-white'>
                {loading ? (
                    <table className="table">
                        <thead>
                            <tr>
                                <th>College name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>College type</th>
                                <th>Established year</th>
                                <th>College rank</th>
                                <th>Logo</th>
                                <th>View more</th>
                            </tr>
                        </thead>
                        <tbody>
                            <p>Loading...</p>
                        </tbody>
                    </table>
                ) : (
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>College name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>College type</th>
                                    <th>Established year</th>
                                    <th>College rank</th>
                                    <th>Logo</th>
                                    <th>View more</th>
                                </tr>
                            </thead>
                            <tbody>
                                {collegeData && (
                                    <tr >
                                        <td>{collegeData.name}</td>
                                        <td>{collegeData.email}</td>
                                        <td>{collegeData.mobile}</td>
                                        <td>{collegeData.department}</td>
                                        <td>{collegeData.year}</td>
                                        <td>{collegeData.rank}</td>
                                        <td><img src={collegeData.profile} title={collegeData.name} alt={`Logo for ${collegeData.name}`} /></td>
                                        <td>
                                            <button className="" onClick={handleModalShow}><FaEye /></button>
                                            <Modal show={showModal} onHide={handleModalClose}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>College Details</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    {/* Display additional college details here */}
                                                    <p>Name: {collegeData.name}</p>
                                                    <p>Email: {collegeData.email}</p>
                                                    <p>Mobile: {collegeData.mobile}</p>
                                                    {/* Add more details as needed */}
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" className='text-black hover:text-white' onClick={handleModalClose}>
                                                        Close
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    )
}
export default Colleges;