import React, { useMemo, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import RichText from './Subpages/Richtext';
import { TiArrowBack } from 'react-icons/ti';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const AddAwardachivement = () => {
    const token = useMemo(() => localStorage.getItem('token') || '', []);
    const college = JSON.parse(localStorage.getItem('collegerecord'));
    const [formData, setFormData] = useState({
        college: college[0].id || '',
        title: '',
        description: '',
        image: '',
        alt: '',
    });
const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleRichTextChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            image: imageFile,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('college', formData.college)
            formDataToSend.append('title', formData.title);
            formDataToSend.append('alt', formData.alt);
            formDataToSend.append('description', formData.description);
            formDataToSend.append('image', formData.image);

            const response = await axios.post('https://portal.mycollegepedia.com/college/awards-achivements/', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `JWT ${token}`,
                },
            });
           console.log(response)
            if (response.status === 201) {
                Swal.fire({
                    position: 'top-end',
                    timer: 2500,
                    icon:"success",
                    text: 'Data submitted successfully!',
                });
                navigate('/college-dashboard/awards-achivements')
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'There was an error in form submission. Please try again.',
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'There was an error. Please try again.',
            });
        }
    };

    return (
        <div className='container common_dash py-3'>
          <div className="row align-items-center top">
                    <div className="col-lg-6">
                        <h3>Awards & Achievements</h3>
                    </div>
                    <div className="col-lg-6">
                        <Breadcrumb className="bread">
                            <Breadcrumb.Item><NavLink to="/college-dashboard">Home</NavLink></Breadcrumb.Item>
                            <Breadcrumb.Item active> Add Awards & Achievements</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
        
            <div className='md:justify-end row'>
                <Link to={"/college-dashboard/awards-achivements"} className="btn-sm shadow-sm flex m-3 gap-1.5 md:float-right text-white items-center border bg-[#052963]">
                    <TiArrowBack />
                    back
                </Link>
            </div>
            <form onSubmit={handleSubmit} className='p-3 common_dash_form' encType="multipart/form-data">
                <div className="form-group">
                    <label>Title</label>
                    <input
                        type="text"
                        name="title"
                        className="form-control"
                        placeholder="Title"
                        required
                        value={formData.title}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Image</label>
                    <input
                        type="file"
                        name="image"
                        className="form-control"
                        accept="image/*"
                        required
                        onChange={handleImageChange}
                    />
                </div>
                <div className="form-group">
                    <label>Alt-Text</label>
                    <input
                        type="text"
                        className="form-control"
                        name="alt"
                        required
                        placeholder="Alt-text"
                        value={formData.alt}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Description</label>
                    <RichText
                        name="description"
                        placeholder="Description"
                        value={formData.description}
                        onChange={handleRichTextChange}
                    />
                </div>
                <div className='d-flex justify-content-end'>
                <button type="submit" className="btn button_of_submit">Add</button>
                </div>
            </form>
        </div>
    );
};

export default AddAwardachivement;
