import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import Nopage from '../Nopage';
import { LinearProgress } from '@mui/material';
import examlogo from "../../assets/images/predictor.jpg"
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Apicontext from '../../Page/Contextapi';
import { useContext } from 'react';

const Examdetails = () => {
  const [Singleexam, setSingleexam] = useState(null);
  const { slug } = useParams();
  const [loading, setLoading] = useState(true);
  const [examsWithinWeek, setExamsWithinWeek] = useState([]);
  const [applicationWithinWeek, setAppliWithinWeek] = useState([]);
  const [result, setResult] = useState([]);
  const contextdata = useContext(Apicontext);


  useEffect(() => {
    const fetchSingleexam = async () => {
      try {
        const response = await axios.get(`https://portal.mycollegepedia.com/exam/upcoming-exam/${slug}/`);
        setSingleexam(response.data);
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching college data:', error);
        setSingleexam(null);
      } finally {
        setLoading(false);
      }
    };

    fetchSingleexam();
  }, [slug]);
  console.log("single",Singleexam)
  useEffect(() => {
    const responseData = contextdata.APIdata;
    const data = responseData.exam;

    const currentTime = new Date();
    const weekDaysLater = new Date(currentTime);
    weekDaysLater.setDate(currentTime.getDate() + 8);

    const examsWithin7 = data.filter(exam => {
      const examStartDate = new Date(exam.exam_start_date);
      return examStartDate > currentTime && examStartDate <= weekDaysLater;
    });
    setExamsWithinWeek(examsWithin7);

    const appWithin7 = data.filter(exam => {
      const appStartDate = new Date(exam.application_start_date);
      return appStartDate > currentTime && appStartDate <= weekDaysLater;
    });
    setAppliWithinWeek(appWithin7);
    const resultdate = data.filter(exam => {
      const result = new Date(exam.result);
      return result > currentTime && result <= weekDaysLater;
    });
    setResult(resultdate);

    setLoading(false);
  }, [contextdata]);

  useEffect(() => {
    const fetchSingleexam = async () => {
      try {
        const response = await axios.get(`https://portal.mycollegepedia.com/exam/upcoming-exam/${slug}/`);
        setSingleexam(response.data);
        console.log(response)
      } catch (error) {
        console.error('Error fetching college data:', error);
        setSingleexam(null);
      } finally {
        setLoading(false);
      }
    };

    fetchSingleexam();
  }, [slug]);
  const owlOptions = {
    autoplay: true,
    rewind: true,
    transition: 500,
    dots: false,
    responsiveClass: true,
    // autoheight: true, 
    autoplayTimeout: 5000,
    smartSpeed: 5000,
    nav: true,
    navText: ["<", ">"],
    responsive: {
        0: { items: 1 },
        600: { items: 2 },
        1024: { items: 3 },
        1366: { items: 2 },
    },
};

  if (loading) {
    return <div className='my-12 p-3 '> <LinearProgress /></div>;
  }

  if (!Singleexam) {
    return (
      <div>
        <Nopage />
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>{Singleexam && Singleexam.meta_title ? Singleexam.meta_title : 'MyCollegePedia: India s Top College Platform'}</title>
        <meta name="description" content={Singleexam && Singleexam.meta_description ? Singleexam.meta_description : "Admission with College Pedia: Experts helping secure the best colleges in India for your child's future."} />
        <meta property="og:title" content={Singleexam && Singleexam ? Singleexam.title : "MyCollegePedia"} />
        <meta name="keywords" content={Singleexam && Singleexam.meta_keyword ? Singleexam.meta_keyword : "collegepedia,collegeduniya, colleges, exams, courses ,All india colleges, MyCollegePedia"} />

      </Helmet>
      <section className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="ab-1">Exam</h1>
              <nav >
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item " aria-current="page"><Link to="/all-exams" title='view-exams'>Exams</Link></li>
                  <li className="breadcrumb-item active lowercase" aria-current="page">{Singleexam.title || '---'}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <div className="container college_cards all_exams_page mt-5">
        {/* left side */}
        <div className="row">
          <div className='col-lg-9'>
            <div className='row'>
              <div className='col-md-12'>
                <div className="main__card mb-5">
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='long_border'>
                        <h3>UpComing Exam</h3>
                      </div>
                    </div>
                  </div>
                  {/*exam card */}
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='card_exams my-3'>
                        <div className='top'>
                          <div className='pic'>
                           
                            <img src={Singleexam.exam.image || examlogo} alt={Singleexam.exam.title} title={Singleexam.exam.title || Singleexam.title} />
                          </div>
                          <div className='title'>
                            <p>{Singleexam.exam && ` ${Singleexam.exam.full_form} (${Singleexam.exam.title})`}</p>
                          </div>
                          <div className='mode'>
                            <p>{Singleexam.exam_mode}</p>
                          </div>
                        </div>
                        <div className='middle'>
                          <h4 className='cate'><span>Course Category</span><span>{Singleexam.course_category || '--------'}</span></h4>
                          <h4 className='desc my-3'><span>Exam Description</span>{Singleexam.description && <span dangerouslySetInnerHTML={{ __html: Singleexam.exam.description }} />}</h4>
                          <h3 className='my-2'>Application Date</h3>
                          <p className='my-1'><span>Start Date:</span><span>{Singleexam.application_start_date || '--------'}</span></p>
                          <p className='my-1'><span>End Date:</span><span>{Singleexam.application_end_date || '--------'}</span></p>
                          <h3 className='my-2'>Exam Date</h3>
                          <p className='my-1'><span>Start Date:</span><span>{Singleexam.exam_start_date || '--------'}</span></p>
                          <p className='my-1'><span>End Date:</span><span>{Singleexam.exam_end_date || '--------'}</span></p>
                          <h4 className='my-2'><span>Result Date</span><span>{Singleexam.result || '--------'}</span></h4>
                          <h4 className='desc my-3'>{Singleexam.description && <span dangerouslySetInnerHTML={{ __html: Singleexam.description }} />}</h4>

                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            {/* exams within a week */}
          
              <div className="row">
              <div className='col-md-12'>
                <div className="main__card mb-5">
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='long_border'>
                        <h3>Exams within a week</h3>
                      </div>
                    </div>
                  </div>
                  {examsWithinWeek && examsWithinWeek.length > 0 && (
                    <OwlCarousel className="owl-theme" {...owlOptions}>
                      {examsWithinWeek.map((ex) =>

                        <div className="gap-5 mx-2" key={ex.id}>
                          <div className='card_exams my-3'>
                            <div className='top'>
                              <div className='pic'>
                                <img src={ex.exam.image || examlogo} alt={ex.exam.title} title={ex.exam.title} />
                              </div>
                              <div className='title'>
                                <p>{ex.title}</p>
                              </div>
                              <div className='mode'>
                                <p>{ex.exam_mode}</p>
                              </div>
                            </div>
                            <div className='middle'>
                              <h3 className='my-2'>Application Date</h3>
                              <p className='my-1'><span>Start Date:</span><span> {ex.application_start_date || '---'}</span></p>
                              <p className='my-1'><span>End Date:</span><span>{ex.application_end_date || '---'}</span></p>
                              <h3 className='my-2'>Exam Date</h3>
                              <p className='my-1'><span>Start Date:</span><span>{ex.exam_start_date || '---'}</span></p>
                              <p className='my-1'><span>End Date:</span><span>{ex.exam_end_date || '---'}</span></p>

                              <h4 className='my-2'><span>Result Date</span><span>{ex.result || "---"}</span></h4>
                            </div>
                            <div className='bottom float-right'>
                              <Link to={`/exam/${ex.slug}`} title={ex.title}><button className='btn view_button'>View More</button></Link>
                            </div>
                          </div>
                        </div>
                      )}

                    </OwlCarousel>
                  )}
                </div>
           
              </div>
            </div>
          </div>
          {/* right side */}
          <div className='col-lg-3'>
            <div className='row'>
              <div className='col-md-12'>
                <div className="main__card mb-5">
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='long_border_red'>
                        <h3>Alerts</h3>
                      </div>
                    </div>
                  </div>
                  {/* application alerts */}
                  {applicationWithinWeek.length > 0 &&
                    <div className='h_of_card_alerts mt-3'>
                      <div className='row mt-2'>
                        <div className='col-lg-12'>
                          <div className='long_border'>
                            <h3>Application Alerts</h3>
                          </div>
                        </div>
                      </div>
                      <marquee class="marq"
                        direction="Up" >

                        {applicationWithinWeek && applicationWithinWeek.map((data) =>

                          <div className='alert_card' key={data.id}>
                            <div className='logo'>
                              <img src={data.exam.image || examlogo} alt={data.exam.title} title={data.exam.title} loading='lazy' />
                            </div>
                            <div className='content'>
                              <Link to={`/exam/${data.slug}`} title={data.title}>
                                <h3>{data.title || "---"}</h3>
                                <p><span>Application StartDate</span><span>{data.application_start_date || '---'}</span></p>
                              </Link>
                            </div>
                          </div>
                        )}

                      </marquee>
                    </div>
                  }
                  {/* exam alerts */}
                  {examsWithinWeek.length > 0 &&
                    <div className='h_of_card_alerts mt-3'>
                      <div className='row mt-2'>
                        <div className='col-lg-12'>
                          <div className='long_border'>
                            <h3>Exams Alerts</h3>
                          </div>
                        </div>
                      </div>
                      <marquee class="marq" onMouseOver="this.stop()" onMouseOut="this.start()"
                        direction="Up">
                        {examsWithinWeek.length > 0 ? (examsWithinWeek.map((data) =>

                          <div className='alert_card' key={data.id}>
                            <div className='logo'>
                              <img src={data.exam.image || examlogo} alt={data.exam.title} title={data.exam.title} loading='lazy' />
                            </div>
                            <div className='content'>
                              <Link to={`/exam/${data.slug}`} title={data.title}>
                                <h3>{data.title || "---"}</h3>
                                <p><span>Exam StartDate</span><span>{data.exam_start_date || '---'}</span></p>
                              </Link>
                            </div>
                          </div>
                        )):(
                          <div>No exam</div>
                        )
                      }
                      </marquee>
                    </div>
                  }
                  {/* Result alerts */}
                  {result &&
                    <div className='h_of_card_alerts mt-3'>
                      <div className='row mt-2'>
                        <div className='col-lg-12'>
                          <div className='long_border'>
                            <h3>Result Alerts</h3>
                          </div>
                        </div>
                      </div>
                      <marquee class="marq"
                        direction="Up" onMouseOver="this.stop()" onMouseOut="this.start()">
                        {result && result.map((data) =>

                          <div className='alert_card' key={data.id}>
                            <div className='logo'>
                              <img src={data.exam.image || examlogo} alt={data.exam.title} title={data.exam.title} loading='lazy' />
                            </div>
                            <div className='content'>
                              <Link to={`/exam/${data.slug}`} title={data.title}>
                                <h3>{data.title || "---"}</h3>
                                <p><span>Result Date</span><span>{data.result || '---'}</span></p>
                              </Link>
                            </div>
                          </div>
                        )}

                      </marquee>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Examdetails;