
import React from 'react'
import Topsection from './Top_section'
import Aboutmission from "./About_mission";

function About() {
    return (
      <>
        <Topsection />
        <Aboutmission />
        {/* <Aboutforms /> */}
        {/* <Ourteamsection />  */}
      </>
    )
}
export default About