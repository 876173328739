import React, { useState } from "react";
import { FaMapLocationDot} from "react-icons/fa6";
import axios from "axios";
import { MdNumbers } from "react-icons/md";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";
import { LinearProgress } from "@mui/material";

const Studentprofile = () => {
  const user = JSON.parse(localStorage.getItem('userprofile')) || {};
  const token = localStorage.getItem('token') || {};
  const [showModal, setShowModal] = useState(false);
  const [Loading, setLoading] = useState(true);
  
  const [formData, setFormData] = useState({
    id: user.id || '',
    name: user.name || '',
    mobile: user.mobile || '',
    email: user.email || '',
    profile: user.profile || '',
    gender: user.gender || '',
    dob: user.dob || '',
    religion: user.religion || '',
    category: user.category || '',
    course_interest: user.course_interest || '',
    country: user.country || '',
    state: user.state || '',
    city: user.city || '',
    current_address: user.current_address || "",
    permanent_address: user.permanent_address || "",
    zipcode: user.zipcode || '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  const headers = {
    Authorization: `JWT ${token}`,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    try {
      const Response = await axios.patch(
        'https://portal.mycollegepedia.com/account/student-profile/',
        formData,
        { headers }
      );
      
      const data = Response.data;

      if (Response.status === 200) {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: 'success',
          text: 'Data submitted successfully!',
          title: data.name
        });
      } else {
        if (Response.status === 401) {
          localStorage.removeItem('token');
        }
        
        Swal.fire({
          icon: 'error',
          title: data.message,
          text: 'There was an error in form submission. Please try again.'
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem('token');

        Swal.fire({
          icon: 'error',
          title: 'Please try again',
          text: 'There was an error accessing your profile.'
        });
      }

      console.error('Error submitting data:', error);

      Swal.fire({
        icon: 'error',
        title: error.message,
        text: 'Something went wrong. Please try again later.'
      });
    }
  };

  if (Loading) {
    return (
      <div className="my-12">
        <LinearProgress/>
      </div>
    ); 
  }

  return (
    <>
      <div className="mt-5 mb-5 profile_of_student ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col">
              <div className=" card px-auto hit">
                <div className="row align-items-center">
                  <div className="col-md-6 col">
                    <div className="student_image">
                      <img src={user.profile ? user.profile : " "} alt="student_image" />
                    </div>
                    <div id="name">
                      <h1>
                        Name : <span>{user.name}</span>
                      </h1>
                    </div>
                    <div className="location ">
                      <span className="icon">
                        <FaMapLocationDot />
                      </span>
                      <span className="ltext">{user.designation}</span>
                    </div>
                  </div>
                  <div className="col-md-6 col ">
                    <div className="second">
                      <div className="mb-2">
                        {" "}
                        <span id="dob">D.O.B : </span> <span>{user.dob} </span>
                      </div>
                      <div id="display" className="mb-2">
                        {" "}
                        <span id="email">Email : </span>
                        <span>{user.email}</span>
                      </div>
                      <div className="mb-2">
                        {" "}
                        <span id="gender">Gender : </span> <span>{user.gender}</span>
                      </div>
                      <div className="mb-2">
                        {" "}
                        <span id="id">Mobile : </span> <span>{user.mobile}</span>
                      </div>
                      <div className="mb-2">
                        {" "}
                        <span id="rel">city : </span> <span>{user.city}</span>
                      </div>
                      <div className="mb-2">
                        {" "}
                        <span id="rel">zipcode : </span> <span>{user.zipcode}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Button variant="primary" onClick={showModal}>
            Update Profile
          </Button>

          {/* Modal for updating profile */}
          <Modal show={showModal} onHide={handleModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Update Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Update profile form */}
              <form className="p-2 bg-gray-50" onSubmit={handleSubmit} encType="" >
                <div className="row form-row">
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Patel"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Email ID</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="mycollege@gmail.com"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>Date of Birth</label>
                      <div className="cal-icon">
                        <input
                          type="text"
                          name="dob"
                          className="form-control"
                          defaultValue="24-07-1983"
                          value={formData.dob}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">

                    <div className="form-group">
                      <label>Gender *</label>
                      <select
                        name="gender"
                        className="form-control form-select"
                        value={formData.gender}
                        onChange={handleChange}
                      >
                        <option value="">Select Gender</option>
                        <option value='MALE'>MALE</option>
                        <option value='FEMALE'>FEMALE</option>
                        <option value='OTHERS'>OTHERS</option>

                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>religion</label>
                      <input
                        type="religion"
                        className="form-control"
                        defaultValue="Hindu"
                        value={formData.religion}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Mobile</label>
                      <input
                        type="tel"
                        defaultValue="+1 202-555-0125"
                        className="form-control "
                        value={formData.mobile}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <h5 className="form-title">
                      <span>Address</span>
                    </h5>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>Address</label>
                      <input
                        type="text"
                        name="current_address"
                        className="form-control"
                        defaultValue="4663 MP India"
                        value={formData.current_address}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>City</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue="Indore"
                        value={formData.city}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Zip Code</label>
                      <input
                        type="text"
                        name="zipcode"
                        className="form-control"
                        value={formData.zipcode}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                </div>
                <button
                  type="submit"
                  className="text-[blue] btn btn-primary btn-block"
                >
                  Save Changes
                </button>
              </form>
            </Modal.Body>
          </Modal>

          <div className="mt-5 down-cards">
            <div className="row ">
              <div className="col-lg-4 col-md-6 ">
                <div className="card ">
                  <div className="body">
                    <div id="icon"><MdNumbers /></div>
                    <div id="data"><h1>556</h1>
                      <p>colleges</p></div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 ">
                <div className="card ">
                  <div className="body">
                    <div id="icon"><MdNumbers /></div>
                    <div id="data"><h1>556</h1>
                      <p>colleges</p></div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card ">
                  <div className="body">
                    <div id="icon"><MdNumbers /></div>
                    <div id="data"><h1>556</h1>
                      <p>colleges</p></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Studentprofile;
