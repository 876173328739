// RichText.jsx
import React, { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';

const RichText = ({ placeholder, name, onChange,value }) => {
	const editor = useRef(null);
	const [content, setContent] = useState('');
	useEffect(()=>{
			setContent(value);
	},[value])
	const config = useMemo(() => ({
		readonly: false,
		placeholder: placeholder || 'Start typing...',
		removeButtons: ['source', 'formats'], 
		enter: 'br', 
		defaultActionOnPaste: 'paste_plain',
	}), [placeholder]);

	const handleContentChange = (newContent) => {
		setContent(value);
		onChange(name, newContent); 
	};

	return (
		<JoditEditor
			ref={editor}
			value={content}
			config={config}
			
			onChange={(newContent) => handleContentChange(newContent)}
		/>
	);
};

export default RichText;
