import React, { useContext, useEffect, useState } from 'react';
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input';
import { AiFillEye } from 'react-icons/ai';
import { FaEyeSlash } from 'react-icons/fa';
// import mycollegepedialogo from '../../assets/images/Logo.png';
// import axios from 'axios';
// import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import Apicontext from '../../Page/Contextapi';

const Counsellor_reg = () => {
    const [religion, setResligion] = useState([])
    const [category, setCategory] = useState([])
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        password: '',
        confirmpassword: '',
        profile: '',
        gender: '',
        dob: '',
        religion: '',
        category: '',
        brand_name: '',
    })
    const [warning, setWarning] = useState('');
    const navigate = useNavigate();
    const contextData = useContext(Apicontext)
    useEffect(() => {
        const data = contextData.APIdata;
        setResligion(data.religion);
        setCategory(data.castecategory);

    }, [contextData])
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormData({
            ...formData,
            [name]: newValue,
        });
    };
    const handleProfile = (e) => {
        const { files } = e.target;
        const selectedFile = files[0];
        setFormData({
            ...formData,
            profile: selectedFile,
        });
    };
    const [viewPassword, setViewPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(false);

    const passwordViewHandler = () => {
        setViewPassword(!viewPassword);
    };
    const confirmPasswordViewHandler = () => {
        setConfirmPassword(!confirmPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.password !== formData.confirmpassword) {
            setWarning('Password and confirm password do not match.');
            return;
        }
        console.log("formdata", formData)
        const number = formData.mobile;
        navigate("/counsellor/register/", {state : formData})
        // navigate(`/verifyotp/`, { state: { number } });
        // navigate(`/verifyotp?mobile=${formData.mobile}`);
        // try {
        // const response = await axios.post('https://portal.mycollegepedia.com/account/counsellor-register/', formData);
        //         if (response.status === 201) {
        //             setWarning('');
        //             const Toast = Swal.mixin({
        //                 toast: true,
        //                 position: 'top-end',
        //                 showConfirmButton: false,
        //                 timer: 3000,
        //                 timerProgressBar: true,
        //                 didOpen: (toast) => {
        //                     toast.addEventListener('mouseenter', Swal.stopTimer);
        //                     toast.addEventListener('mouseleave', Swal.resumeTimer);
        //                 },
        //             });
        //             Toast.fire({
        //                 icon: 'success',
        //                 text: response.data.message,
        //                 title: 'Registration is successful',
        //             });
        //             navigate(`/verifyotp?mobile=+${formData.mobile}`);
        //         } else {
        //             Swal.fire({
        //                 icon: 'error',
        //                 title: 'Try again',
        //                 text: response.data.message,
        //             });
        //         }
        //     } catch (error) {
        //         Swal.fire({
        //             icon: 'error',
        //             title: 'Credentials',
        //             text: 'Try again',
        //         });
        //         console.error('Axios Error:', error);
        //     }
    };

    return (
        <>


            <div className=" login-body my-5">
                <div className="  counsellor_reg ">
                    <div className="container login-right-wrap row">
                        {/* <div className="account-heade col-12">
                                    <div className="flex justify-center mb-4 text-center account-logo">
                                        <Link to="/" title='Home'>
                                            <img src={mycollegepedialogo} alt="counsellor registration" title='Counsellor registration' className="w-full brightness-110" />
                                        </Link>
                                    </div>
                                </div> */}
                        <div className="login-header col-12 mb-4 pb-3 border-b-2 border-blue-300 border-dashed">
                            <h3 className='text-center mx-auto'>
                                Counsellor Register <span>MyCollegePedia</span>
                            </h3>
                        </div>
                        <form onSubmit={handleSubmit} className="row">
                            <div className="mb-4 col-md-4 form-group">
                                <label className="control-label">Full Name</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Enter your full name"
                                    required
                                />
                            </div>
                            <div className="mb-4 col-md-4 form-group">
                                <label className="control-label">Contact Number</label>
                                <PhoneInput
                                    placeholder="Enter your contact number"
                                    value={formData.mobile}
                                    onChange={(value) => setFormData({ ...formData, mobile: value })}
                                    className="form-control"
                                    required
                                />
                                {formData.mobile && (
                                    <span className="text-sm text-gray-500">
                                        National: {formatPhoneNumber(formData.mobile)}
                                    </span>
                                )}
                            </div>
                            <div className="mb-4 col-md-4 form-group">
                                <label className="control-label">Email Address</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Enter your email address"
                                    className="form-control"
                                    required
                                />
                            </div>

                            <div className="mb-4 col-md-4 form-group">
                                <label className="control-label">Profile</label>
                                <input
                                    className="form-file form-control"
                                    type="file"
                                    name="profile"
                                    onChange={handleProfile}
                                    placeholder="Enter your profile"
                                    required
                                />
                            </div>
                            <div className="mb-4 col-md-4 form-group">
                                <label className="control-label">Gender</label>
                                <select
                                    className="form-control"
                                    name="gender"
                                    value={formData.gender}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                            <div className="mb-4  col-md-4 form-group">
                                <label className="control-label">Date of Birth</label>
                                <input
                                    className="form-control"
                                    type="date"
                                    name="dob"
                                    value={formData.dob}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-4   col-md-4 form-group">
                                <label className="control-label">Religion</label>

                                <select
                                    className="form-control"
                                    name="religion"
                                    value={formData.religion}
                                    onChange={handleChange}
                                >
                                    <option value='' >Select religion</option>
                                    {religion &&
                                        religion.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="mb-4  col-md-4 form-group">
                                <label className="control-label">Category</label>
                                <select
                                    className="form-control form-select"
                                    name="category"
                                    value={formData.category}
                                    onChange={handleChange}
                                >
                                    <option value='' >Select category</option>
                                    {category &&
                                        category.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="mb-4  col-md-4 form-group">
                                <label className="control-label">Brand Name</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="brand_name"
                                    value={formData.brand_name}
                                    onChange={handleChange}
                                    placeholder="Enter your brand name"
                                    required
                                />
                            </div>
                            <div className="relative  col-md-6 mb-4 form-group">
                                <label htmlFor="password" className="control-label">
                                    Password
                                </label>
                                <input
                                    type={viewPassword ? 'text' : 'password'}
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    placeholder="Enter password"
                                    className="form-control"
                                    required
                                />
                                <div className="absolute transform -translate-y-1/2 cursor-pointer right-6 top-[72%]">
                                    {viewPassword ? (
                                        <AiFillEye
                                            onClick={passwordViewHandler}
                                            size="20px"
                                            className="text-[#707070]"
                                        />
                                    ) : (
                                        <FaEyeSlash
                                            onClick={passwordViewHandler}
                                            size="20px"
                                            color="#909090"
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="relative  col-md-6 mb-4 form-group">
                                <label className="control-label">Confirm Password</label>
                                <input
                                    type={confirmPassword ? 'text' : 'password'}
                                    name="confirmpassword"
                                    value={formData.confirmpassword}
                                    onChange={handleChange}
                                    placeholder="Enter confirm password"
                                    className="form-control"
                                    required
                                />
                                <div className="absolute transform -translate-y-1/2 cursor-pointer right-6 top-[72%]">
                                    {confirmPassword ? (
                                        <AiFillEye
                                            onClick={confirmPasswordViewHandler}
                                            size="20px"
                                            className="text-[#707070]"
                                        />
                                    ) : (
                                        <FaEyeSlash
                                            onClick={confirmPasswordViewHandler}
                                            size="20px"
                                            color="#909090"
                                        />
                                    )}
                                </div>
                            </div>
                            {warning && (
                                <div className="mb-4 text-red-500">
                                    {warning}
                                </div>
                            )}

                            <div className="text-end col-md-12 dont-have">
                                Already have an account? <Link title='login' to="/login">Login</Link>
                            </div>
                            <div className="md:w-[25%] mx-auto items-center ">
                                <button
                                title='Register-counsellor'
                                    className="btn bg-[#052963] hover:bg-[blue] account-btn"
                                    type="submit"
                                >
                                    Register
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Counsellor_reg;