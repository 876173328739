import React, { useContext, useEffect, useMemo, useState } from "react";

import axios from "axios";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";
import { LinearProgress } from "@mui/material";
import Apicontext from "../../Page/Contextapi";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { NavLink } from "react-router-dom";
import CollegeuserUpdate from "./CollegeuserUpdate";

const UserProfile = () => {
  const token = useMemo(() => localStorage.getItem('token') || '', []);
  const [collegeData, setCollegeData] = useState('');
  const [Loading, setLoading] = useState(true);
  const [category, setCategory] = useState([]);
  const [religion, setReligion] = useState([]);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const handlefunction = () => setShowModal(!showModal);

  const contextdata = useContext(Apicontext);
  // console.log("user-profile", collegeData)

  const headers = useMemo(() => ({
    Authorization: `JWT ${token}`,
  }), [token]);


  useEffect(() => {
    const data = contextdata.APIdata;
    setReligion(data.religion)
    setCategory(data.castecategory)
    setCountry(data.Country)
    setState(data.State)

    const fetchData = async () => {
      try {

        const collegeResponse = await axios.get('https://portal.mycollegepedia.com/account/college-profile/', {
          headers,
        });
        setLoading(false)
        setCollegeData(collegeResponse.data);

      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          Swal.fire({
            icon: 'error',
            title: 'Please try again Login',
            text: 'There was an error.'
          });
          navigate('/login');
        }
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [headers, navigate, contextdata,showModal]);

  if (Loading) {
    return <div>
      <div className="p-2 m-5 ">
        <LinearProgress />
      </div>
    </div>
  }
  return (

    <div className="profile_of_student px-3  mb-5 common_dash ">
      <div className="container">
        <div className="row align-items-center top   ">
          <div className="col-lg-6">

            <h3>College User Profile</h3>

          </div>
          <div className="col-lg-6">
            <Breadcrumb className="bread">
              <Breadcrumb.Item ><NavLink to="/college-dashboard">Home</NavLink></Breadcrumb.Item>
              <Breadcrumb.Item active>College user profile</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        {showModal ?
          <CollegeuserUpdate handlefunction={handlefunction} /> :
          <div>
            <div className="row">
              <div className="col-lg-12">
                <Button className="float-right mb-3 text-success" title="Update profile" onClick={handlefunction}>
                  <FaEdit className="text-xl "  />
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col">
                <div className=" card px-auto hit">

                  <div className="row">
                    <div className="col-lg-4 col-md-12 col">

                      <div className="student_image">
                        <img src={collegeData.profile ? collegeData.profile : " "} alt={collegeData.name} title={collegeData.name} />
                      </div>
                      <div className="name mt-2">
                        <h3>
                          Name : <span>{collegeData.name}</span>
                        </h3>
                      </div>
                      <div className="mt-2 name">
                        <h3>
                          Department : <span>{collegeData.department}</span>
                        </h3>
                      </div>
                      <div className="mt-2 name">
                        <h3>
                          Designation : <span>{collegeData.designation}</span>
                        </h3>
                      </div>
                      {/* <div className="location ">
                    <span className="icon">
                      <FaMapLocationDot />
                    </span>
                    <span className="ltext">{collegeData.designation}</span>
                  </div> */}

                    </div>
                    <div className="col-lg-8 col-md-12 col ">
                      <h3 className="hback">Personal Information</h3>
                      <div className="second ">
                        <div id="display" className="mb-2 borderbt row">
                          {" "}
                          <div className="col-lg-5 col-md-4">

                            <span id="email">Email : </span>
                          </div>
                          <div className="col-lg-7 col-md-8">

                            <span>{collegeData.email}</span>
                          </div>
                        </div>
                        <div className="mb-2 borderbt row">
                          {" "}
                          <div className="col-lg-5 col-md-4">

                            <span id="id">Mobile : </span>
                          </div>
                          <div className="col-lg-7 col-md-8">

                            <span>{collegeData.mobile}</span>
                          </div>
                        </div>
                        <div className="mb-2 borderbt row">
                          <div className="col-lg-5 col-md-4">
                            <span id="dob">Date of Birth: </span>
                          </div>
                          <div className="col-lg-7 col-md-8">
                            <span>{collegeData.dob} </span>
                          </div>
                        </div>
                        <div className="mb-2 borderbt row">
                          <div className="col-lg-5 col-md-4">

                            <span id="gender">Gender : </span>
                          </div>
                          <div className="col-lg-7 col-md-8">

                            <span className="lowercase">{collegeData.gender}</span>
                          </div>
                        </div>
                        <div className="mb-2 borderbt row">
                          <div className="col-lg-5 col-md-4">

                            <span id="gender">Religion : </span>
                          </div>
                          <div className="col-lg-7 col-md-8">


                            <span className="lowercase">
                              {religion.map((data) => data.id === collegeData.religion && data.name)}
                            </span>
                            {/* <span className="lowercase">{collegeData.religion}</span> */}
                          </div>
                        </div>
                        <div className="mb-2 borderbt row">
                          <div className="col-lg-5 col-md-4">

                            <span id="gender">Catogory : </span>
                          </div>
                          <div className="col-lg-7 col-md-8">

                          <span className="lowercase">
                              {category.map((data) => data.id === collegeData.category && data.name)}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 ">
                          <h3 className="hback">Address</h3>

                          <div className="second">

                            <div className="mb-2 borderbt row">
                              <div className="col-lg-5 col-md-4">

                                <span id="rel">country : </span>
                              </div>
                              <div className="col-lg-7 col-md-8">
                              <span className="lowercase">
                              {country.map((data) => data.id === collegeData.country && data.name)}
                            </span>
                               
                              </div>
                            </div>
                            <div className="mb-2 borderbt row">
                              <div className="col-lg-5 col-md-4">

                                <span id="rel">State : </span>
                              </div>
                              <div className="col-lg-7 col-md-8">
                              <span className="lowercase">
                              {state.map((data) => data.id === collegeData.state && data.name)}
                            </span>
                              </div>
                            </div>
                            <div className="mb-2 borderbt row">
                              <div className="col-lg-5 col-md-4">

                                <span id="rel">city : </span>
                              </div>
                              <div className="col-lg-7 col-md-8">

                                <span className="lowercase">{collegeData.city}</span>
                              </div>
                            </div>
                            <div className="mb-2 borderbt row">
                              <div className="col-lg-5 col-md-4">

                                <span id="rel">Current Add : </span>

                              </div>
                              <div className="col-lg-5 col-md-4">

                                <span className="lowercase">{collegeData.current_address}</span>
                              </div>
                            </div>
                            <div className="mb-2 borderbt row">
                              <div className="col-lg-5 col-md-4">

                                <span id="rel">Permanent Add : </span>
                              </div>
                              <div className="col-lg-7 col-md-8">

                                <span className="lowercase">{collegeData.permanent_address}</span>
                              </div>
                            </div>
                            <div className="mb-2 borderbt row">
                              <div className="col-lg-5 col-md-4">

                                <span id="rel">zipcode : </span>
                              </div>
                              <div className="col-lg-5 col-md-4">

                                <span>{collegeData.zipcode}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4 col-md-12 col">
              <div className="card hit">
                <div className="course_catogory">
                  <img src={''} alt="student_image" />
                </div>
                <div className="course">
                  <div>
                    <span>course-catogory - </span> <span>Engineering</span>
                  </div>
                  <div>
                    <span>sub-catogory - </span> <span>B-tech</span>
                  </div>
                  <div>
                    <span>course-interest - </span>{" "}
                    <span>Computer science</span>
                  </div>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        }
      </div >

    </div >

  );
};

export default UserProfile;