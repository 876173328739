
import Apicontext from "../../Page/Contextapi";
import { useNavigate } from "react-router-dom";
import { useState, useMemo, useContext, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { TiArrowBack } from "react-icons/ti";
import { LinearProgress } from "@mui/material";
import RichText from "../CollegeDashboard/Subpages/Richtext";

const Profileupdate = ({ handlefunction }) => {
    const token = useMemo(() => localStorage.getItem("token") || "", []);
    const [CounsellorProfile, setCounsellorProfile] = useState("");
    const [category, setCategory] = useState("");
    const [country, setCountry] = useState(null);
    const [state, setState] = useState(null);
    const [Loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        city: CounsellorProfile.city || "",
        current_address: CounsellorProfile.current_address || "",
        permanent_address: CounsellorProfile.permanent_address || "",
        zipcode: CounsellorProfile.zipcode || "",
        primary_mobile: CounsellorProfile.primary_mobile || "",
        secondary_mobile: CounsellorProfile.secondary_mobile || "",
        email: CounsellorProfile.email || "",
        website: CounsellorProfile.website || "",
        whatsapp: CounsellorProfile.whatsapp || "",
        facebook: CounsellorProfile.facebook || "",
        instagram: CounsellorProfile.instagram || "",
        linkedin: CounsellorProfile.linkedin || "",
        twitter: CounsellorProfile.twitter || "",
        youtube: CounsellorProfile.youtube || "",
        telegram: CounsellorProfile.telegram || "",
        company_name: CounsellorProfile.company_name || "",
        logo: null,
        image: null,
        success_story: CounsellorProfile.success_story || "",
        registration_date: CounsellorProfile.registration_date || "",
        country: CounsellorProfile.country || null,
        state: CounsellorProfile.state || null,
        area_of_operation: CounsellorProfile.area_of_operation || [],
        newcategory: ''
    });
    const contextdata = useContext(Apicontext);
    const navigate = useNavigate();
    const headers = useMemo(() => ({
        Authorization: `JWT ${token}`,
    }), [token]);


    const handlechange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleRichTextChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            image: file,
        });
    };
    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            logo: file,
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            const data = contextdata.APIdata;
            setCategory(data.category)
            setCountry(data.Country)
            try {
                const Response = await axios.get('https://portal.mycollegepedia.com/counsellor/counsellor-detail/', { headers, });
                setLoading(false);
                setCounsellorProfile(Response.data[0]);
                setFormData(Response.data[0]);

                if (formData.country) {
                    const stateResponse = await axios.get(
                        `https://portal.mycollegepedia.com/main/state/?country_id=${formData.country}`
                    );
                    setState(stateResponse.data);
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem("token");
                    Swal.fire({
                        icon: "error",
                        title: "Please try again Login",
                        text: "There was an error.",
                    });
                    navigate("/login");
                }
                console.error("Error fetching data:", error);
            }
        }
        fetchData();
    }, [contextdata]);

    const handleUpdate = async (e) => {
        e.preventDefault();
        console.log("formdata",formData)
        try {
            const formDataCopy = new FormData();
            Object.keys(formData).forEach((key) => {
                if (key !== 'image' && key !== 'logo' && key !== "facebook" && key !== 'instagram' && key !== 'website' && key !== 'telegram' && key !== 'linkedin' && key !== 'twitter' && key !== 'youtube' && key !== 'whatsapp') {
                        formDataCopy.append(key, formData[key]);
                }
            });

            if (formData.image && typeof formData.image !== 'string') {
                formDataCopy.append('image', formData.image);
            }

            if (formData.logo && typeof formData.logo !== 'string') {
                formDataCopy.append('logo', formData.logo);
            }
            const slug = CounsellorProfile.slug;
            const response = await axios.patch(`https://portal.mycollegepedia.com/counsellor/counsellor-detail/${slug}/`, formDataCopy, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `JWT ${token}`,
                },
            });

            console.log("response",response)
            Swal.fire({
                position: "top-end",
                icon: 'success',
                text: 'Data updated successfully!',
            });
            handlefunction();
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'There was an error. Please try again.',
            });
            console.error('Error updating college details:', error);
        }
    };
    if (Loading) {
        return (
            <div>
                <p className="my-10 p-3">
                    {" "}
                    <LinearProgress />
                </p>
            </div>
        );
    }
    return (
        <div className='container'>
            <div className="row">
                <div className="col-12 text-end d-flex justify-content-end ">
                    <button className="btn bg-[#052963] d-flex  align-items-center text-white" onClick={handlefunction}>
                        <TiArrowBack /> back
                    </button>
                </div>
            </div>
            <div className=" row">
                <Modal.Body className="edit_modal">
                    <form className="pp-3 common_dash_form" onSubmit={handleUpdate} encType="multipart/form-data">
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className="rounded">
                                    <div className="card-body">
                                        <h5 className="mb-3">Company Informaion</h5>
                                        <div className=" border shadow">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label for="exampleFormControlInputcomname">Comapny Name</label>
                                                            <input type="text" className="form-control" id="exampleFormControlInputcomname"
                                                                placeholder="Enter your Company Name" onChange={handlechange} name="company_name" value={formData.company_name} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label for="email">Email</label>
                                                            <input type="email" className="form-control" id="eml" onChange={handlechange} name="email" value={formData.email} />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                                        <div className="form-group d-flex flex-column">
                                                            <label for="exampleFormControlInputLogo">Logo</label>
                                                            <input type="file" name="logo" className="form-control" onChange={handleLogoChange} id="exampleFormControlInputLogo" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                                        <div className="form-group d-flex flex-column">
                                                            <label for="exampleFormControlInputTmg">Image</label>
                                                            <input type="file" className="form-control" name="image" onChange={handleImageChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label for="exampleFormControlInputRd">Registration Date</label>
                                                            <input type="date" className="form-control" id="exampleFormControlInputRd" onChange={handlechange} name="registration_date" value={formData.registration_date} />
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <h5 className=' mt-3 mb-3'>Social Media</h5>
                                        <div className=" border shadow">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Facebook</label>
                                                            <input type="url" className="form-control" name="facebook" placeholder="https://" value={formData.facebook} onChange={handlechange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Instagram</label>
                                                            <input type="url" className="form-control" name="instagram" placeholder="https://" value={formData.instagram} onChange={handlechange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Twitter</label>
                                                            <input type="url" className="form-control" name="twitter" placeholder="https://" value={formData.twitter} onChange={handlechange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>LinkedIn</label>
                                                            <input type="url" className="form-control" name="linkedin" placeholder="https://" value={formData.linkedin} onChange={handlechange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Whatsapp</label>
                                                            <input type="number" className="form-control" name="whatsapp" placeholder="1123456789" value={formData.whatsapp} onChange={handlechange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Youtube</label>
                                                            <input type="url" className="form-control" name="youtube" placeholder="https://" value={formData.youtube} onChange={handlechange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Telegram</label>
                                                            <input type="url" className="form-control" name="telegram" placeholder="https://" value={formData.telegram} onChange={handlechange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Website</label>
                                                            <input type="url" className="form-control" name="website" placeholder="https://" value={formData.website} onChange={handlechange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h5 className=' mt-3 mb-3'>Address</h5>
                                        <div className="card border shadow">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label htmlFor="exampleFormControlInputCountry">Country</label>
                                                            <select
                                                                className="form-select form-control"
                                                                aria-label="Select country"
                                                                name="country"
                                                                value={formData.country}
                                                                onChange={handlechange}
                                                            >
                                                                <option value="">---</option>
                                                                {country && country.map((country) => (
                                                                    <option key={country.id} value={country.id}>
                                                                        {country.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>

                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label htmlFor="exampleFormControlInputState">State</label>
                                                            <select
                                                                className="form-select form-control"
                                                                aria-label="Select state"
                                                                name="state"
                                                                value={''}
                                                                onChange={handlechange}
                                                            >
                                                                <option value="">---</option>
                                                                {state && state.map((state) => (
                                                                    <option key={state.id} value={state.id}>
                                                                        {state.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label htmlFor="exampleFormControlInputCity">City</label>
                                                            <input type="text" className="form-control"
                                                                placeholder="City" onChange={handlechange} name="city" value={formData.city} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleFormControlInputZC">Zip Code</label>
                                                                <input type="number" className="form-control"
                                                                    name="zipcode" onChange={handlechange} value={formData.zipcode} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="exampleFormControlInputAds">Current Address</label>
                                                            <textarea className="form-control" id="exampleFormControlInputAds"
                                                                rows="3" name="current_address" onChange={handlechange} value={formData.current_address}></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="exampleFormControlInputAds">Address</label>
                                                            <textarea className="form-control" id="exampleFormControlInputAds"
                                                                rows="3" name="permanent_address" onChange={handlechange} value={formData.permanent_address}></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h5 className=' mt-3 mb-3'>Success Story</h5>
                                        <div className="">
                                            <RichText 
                                            value={formData.success_story} name="success_story"
                                                placeholder={"enter your success_story"}
                                                onChange={handleRichTextChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal.Footer>
                            <Button className="button_of_close" onClick={handlefunction}>
                                Close
                            </Button>
                            <Button className="button_of_submit" type="submit">
                                Submit
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
            </div>
        </div>
    );
};

export default Profileupdate;
