import React, { useState } from 'react';

const MessageParser = ({ children, actions }) => {
  const [isTyping, setIsTyping] = useState(false);

  const handleTyping = () => {
    setIsTyping(true);
    
  };

  const handleSendMessage = async (message) => {
    setIsTyping(false);

    if (message.toLowerCase().includes('interested in admission')) {
      actions.handleAdmissionInterest();
    } else if (message.toLowerCase().includes('yes')) {
      actions.handleYes();
    } else if (message.toLowerCase().includes('no')) {
      actions.handleNo();
    } else if (message.toLowerCase().includes('hello')) {
      actions.handleHello();
    } else if (message.toLowerCase().includes('college')) {
      actions.CollegesList();
    } else if (message.toLowerCase().includes('colleges')) {
      actions.CollegesList();
    } else if(message.toLowerCase().includes('exam')) {
      actions.examSelected();
    } else {
      actions.unknown();
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: handleSendMessage,
          actions: actions,
          onTyping: handleTyping, 
        });
      })}
    
    </div>
  );
};

export default MessageParser;