import React, { useState } from 'react'
import { FaMinus, FaPlus } from 'react-icons/fa';

const CollegeFQs = ({ faqItem, isFirst }) => {
    const [isOpen, setIsOpen] = useState(isFirst);
  
    const toggleCollapsible = () => {
      setIsOpen(!isOpen);
    };
    const FaqQ = faqItem.question && faqItem.question.replace(/style="[^"]*"/g, '');
    const FaqA = faqItem.answer && faqItem.answer.replace(/style="[^"]*"/g, '');
    return (
      <div className="collapsible">
        <div className="flex justify-between px-3 py-3 bg-gray-300 collapsible-header" onClick={toggleCollapsible}>
          {'Q.'} {FaqQ} {isOpen ? <FaMinus /> : <FaPlus />}
        </div>
        {isOpen && (
          <div className="collapsible-body bg-[white] text-justify py-2">
            <p dangerouslySetInnerHTML={{ __html: FaqA }} />
          </div>
        )}
      </div>
    );
  };
export default CollegeFQs
