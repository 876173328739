import axios from 'axios';
import React, { useState, useContext, useEffect, useMemo } from 'react';
import Swal from 'sweetalert2';
import Apicontext from '../../Page/Contextapi';
import { useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';

const CounsellorApply = ({ counsellordata, closeModal }) => {
    const contextdata = useContext(Apicontext);
    const userData = JSON.parse(localStorage.getItem('userprofile')) || {};
    const token = useMemo(() => localStorage.getItem('token') || '', []);
    const [selectcategory, setSelectedCategory] = useState('');
    const [subcategoryOptions, setSubcategoryOptions] = useState([]);
    const [selectsubcategory, setSelectedSubCategory] = useState('');
    const [stream, setStreamOptions] = useState([]);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        student: userData.id,
        college: counsellordata.id,
        course_category: '',
        course_subcategory: '',
        course_stream: '',
    });
    const data = contextdata.APIdata;
    const category = data.category;

    useEffect(() => {
        const fetchSubcategories = async () => {
            if (selectcategory) {
                try {
                    const subcategoryRes = await axios.get(`https://portal.mycollegepedia.com/general-setting/all_course_subcategory/?course_category=${selectcategory}`, {
                        headers: {
                            Authorization: `JWT ${token}`,
                            'Content-Type': 'application/json',
                        },
                    });
                    setSubcategoryOptions(subcategoryRes.data || []);
                } catch (error) {
                    if (error.status === 401) {
                        localStorage.removeItem('token');
                        Swal.fire({
                            icon: 'user Unauthorized',
                            title: 'Please try again.',
                        });
                        navigate('/login');
                    }
                    console.error('Error fetching subcategories:', error);
                }
            }
            if (selectsubcategory) {
                try {
                    const streamRes = await axios.get(`https://portal.mycollegepedia.com/general-setting/all_course_stream/?course_subcategory=${selectsubcategory}`, {
                        headers: {
                            Authorization: `JWT ${token}`,
                            'Content-Type': 'application/json',
                        },
                    });
                   
                    setStreamOptions(streamRes.data || []);
                } catch (error) {
                    if (error.status === 401) {
                        localStorage.removeItem('token');
                        Swal.fire({
                            icon: 'user Unauthorized',
                            title: 'Please try again.',
                        });
                        navigate('/login');
                    }
                    console.error('Error fetching subcategories:', error);
                }
            }
        };
        fetchSubcategories();
    }, [selectcategory, token,selectsubcategory,navigate]);

    const handleApply = async (e) => {
        e.preventDefault();
        try {
           const res = await axios.post('https://portal.mycollegepedia.com/counsellor/apply/', formData, {
                headers: {
                    Authorization: `JWT ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            console.log('Application submitted successfully:', res);
            Swal.fire({
                icon: 'success',
                position:"top-end",
                timer:2500,
                title: 'Successfully Applied  !',
                text: 'Your application has been submitted successfully     .',
            });

            closeModal();
        } catch (error) {
            if (error.status === 401) {
                localStorage.removeItem('token');
                Swal.fire({
                    icon: 'user Unauthorized',
                    title: 'Please try again.',
                });
                navigate('/login');
            }
            const errorMessage = error.response?.data?.message || 'Something went wrong with the application submission!';
            Swal.fire({
                icon: 'error',
                title: 'Application Error',
                text: errorMessage,
            });
            console.error('Error submitting application:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const selected = category.find((cat) => cat.id === value);
        const selectedstream = subcategoryOptions.find((cat) => cat.id === value);

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        if (name === 'course_category') {
            setSelectedCategory(selected ? selected.name : '');
        }
        if (name === 'course_subcategory') {
            setSelectedSubCategory(selectedstream ? selectedstream.course_name : '');
        }
    };

    return (
        <div className='container'>
            <div className='max-w-[600px]'>
                <section className="pdf pb-3 border-b-4 border-[lightgray]">
                    <div className="row  align-items-center">
                        <div className="col-lg-3 pic">
                            <img src={counsellordata ? counsellordata.logo : "images/banner.jpg"} alt={counsellordata.name} title={counsellordata.name}  className='h-20 max-w-20' />
                        </div>
                        <div className=" col-lg-8">
                            <h2>{counsellordata.company_name} </h2>
                        </div>
                    <div className='col-1'><FaTimes onClick={closeModal} /> </div>
                    </div>
                </section>
                <form onSubmit={handleApply} className='grid py-2 mx-auto'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label htmlFor='courseCategory'>Course Category</label>
                                <select
                                    className='form-control'
                                    id='Category'
                                    name='course_category'
                                    onChange={handleChange}
                                    value={formData.course_category}
                                >
                                    <option value=''>Select Category</option>
                                    {category &&
                                        category.map((category) => (
                                            <option key={category.id} value={category.id}>
                                                {category.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label htmlFor='subcategory'>Course Subcategory</label>
                                <select
                                    className='form-control'
                                    id='subcategory'
                                    name='course_subcategory'
                                    onChange={handleChange}
                                    value={formData.course_subcategory}
                                >
                                    <option value=''>Select Subcategory</option>
                                    {subcategoryOptions &&
                                        subcategoryOptions.map((subcategory) => (
                                            <option key={subcategory.id} value={subcategory.id}>
                                                {subcategory.course_name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label htmlFor='stream'>Course Stream</label>
                                <select
                                    className='form-control'
                                    id='stream'
                                    name='course_stream'
                                    onChange={handleChange}
                                    value={formData.course_stream}
                                >
                                    <option value=''>Select Stream</option>
                                    {stream &&
                                        stream.map((stream) => (
                                            <option key={stream.id} value={stream.id}>
                                                {stream.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <button title='Apply now' type='submit' className='btn bg-[#06c3ff] text-white hover:text-gray-300'>
                        Apply
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CounsellorApply