import React, { useState, useEffect } from 'react';
import Apicontext from './Contextapi';
import axios from 'axios';
import Swal from 'sweetalert2';
import Loading from './Loading';

const Appprovider = ({ children }) => {
  const [apidata, setData] = useState('');
  const [loading, setLoading] = useState(true);
  const [APIdata, setApiData] = useState('');
  const usertoken = localStorage.getItem('token')
  
  const updateData = (newData) => {
    setData(newData);
  } ;
  useEffect(() => {
    
    const fetchData = async () => {
      try {
      
        const [Detailresponse, collegeResponse,counsres, menuResponse, subcategoryResponse, streamResponse, examResponse, up_examResponse,categoryResponse, countryResponse, stateResponse, religionres, castecategory, Blogresponse, sliderres] = await Promise.all([
          axios.get('https://portal.mycollegepedia.com/site/site-config/'),
          axios.get('https://portal.mycollegepedia.com/college/all-college/'),
          axios.get('https://portal.mycollegepedia.com/counsellor/counsellor/'),
          axios.get('https://portal.mycollegepedia.com/college/category-colleges/'),
          axios.get('https://portal.mycollegepedia.com/general-setting/all_course_subcategory/'),
          axios.get('https://portal.mycollegepedia.com/general-setting/all_course_stream/'),
          axios.get('https://portal.mycollegepedia.com/exam/all-exam/'),
          axios.get('https://portal.mycollegepedia.com/exam/all-upcoming-exam/'),
          axios.get('https://portal.mycollegepedia.com/general-setting/all_course_category/'),
          axios.get('https://portal.mycollegepedia.com/main/country/'),
          axios.get('https://portal.mycollegepedia.com/main/state/?country_id=103'),
          axios.get('https://portal.mycollegepedia.com/main/religion/'),
          axios.get('https://portal.mycollegepedia.com/main/category/'),
          axios.get('https://portal.mycollegepedia.com/blog/all-blog/'),
          axios.get('https://portal.mycollegepedia.com/site/slider/'),
        ]);

        
        const today = new Date();
        const filteredUpcomingExams = up_examResponse.data.filter(exam => {
          const examDate = new Date(exam.exam_start_date); 
          return examDate >= today;
        });
        setApiData({
          detail: Detailresponse.data,
          college: collegeResponse.data,
          counsellors: counsres.data,
          menu: menuResponse.data,
          subcategory: subcategoryResponse.data,
          stream: streamResponse.data,
          prev_exam: examResponse.data,
          exam: filteredUpcomingExams,
          category: categoryResponse.data,
          Country: countryResponse.data,
          State: stateResponse.data,
          religion: religionres.data,
          castecategory: castecategory.data,
          blog: Blogresponse.data,
          slider: sliderres.data,
        });

      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops, try again',
          text: `Server error `,
        });
        console.log(error || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [usertoken]);

  if (loading) {
    return (
      <Loading />
    );
  }
  return (
    <Apicontext.Provider value={{ apidata, APIdata, updateData }}>
      {children}
    </Apicontext.Provider>
  );
};

export default Appprovider;