// AddFAQ.js
import React, { useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import RichText from './Subpages/Richtext';
import { TiArrowBack } from 'react-icons/ti';
import Breadcrumb from 'react-bootstrap/Breadcrumb';


const AddFAQ = () => {
    const token = useMemo(() => localStorage.getItem('token') || '', []);
    const collegeRecord = useMemo(() => JSON.parse(localStorage.getItem('collegerecord') || ''), []);
    const collegeId = collegeRecord.length > 0 ? collegeRecord[0].id : null;
    const [newFAQ, setNewFAQ] = useState({
        college: collegeId,
        answer: '',
        question: '',
    });
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewFAQ((prevFAQ) => ({
            ...prevFAQ,
            [name]: value,
        }));
    };

    const handleRichTextChange = (name, value) => {
        setNewFAQ({
            ...newFAQ,
            [name]: value,
        });
    };

    const handleAddFAQ = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://portal.mycollegepedia.com/college/college-faq/', newFAQ, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `JWT ${token}`,
                },
            });
            if (response.status === 201) {
                Swal.fire({
                    position: 'top-end',
                    timer: 3000,
                    icon: 'success',
                    text: 'Data submitted successfully!',
                });
                navigate('/college-dashboard/college-faqs');
            }
        } catch (error) {
            console.error('Error adding FAQ:', error);
        }
    };

    return (
        <div className='container common_dash px-3'>
            <div className="row align-items-center top   ">
                <div className="col-lg-6">
                    <h3>FAQs</h3>
                </div>
                <div className="col-lg-6">
                    <Breadcrumb className="bread">
                        <Breadcrumb.Item ><Link to="/college-dashboard">Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item active>FAQ's</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>

            <div className='justify-end row'>
                <Link to="/college-dashboard/college-faqs" className="btn-sm shadow-sm flex m-3 gap-1.5 float-right text-white items-center border bg-[#052963]">
                    <TiArrowBack />
                    Back
                </Link>
            </div>
            <Form onSubmit={handleAddFAQ} className='common_dash_form p-3'>
                <Form.Group controlId="question">
                    <Form.Label>Question:</Form.Label>
                    <Form.Control
                        type="text"
                        onChange={handleChange}
                        name="question"
                        value={newFAQ.question}
                    />
                </Form.Group>
                <Form.Group controlId="answer">
                    <Form.Label>Answer:</Form.Label>
                    <RichText
                        name="answer"
                        placeholder={"answer"}
                        value={newFAQ.answer}
                        onChange={handleRichTextChange}
                    />
                </Form.Group>
                <div className='d-flex justify-content-end'>
                <Button  type="submit"  className='button_of_submit mt-2'  >
                    Add FAQ
                </Button>
                </div>
             
            </Form>
        </div>
    );
};

export default AddFAQ;
