import React from 'react'

const CounSuccess = ({counsellordata}) => {
  return (
    <>
     <div className='container'>
            <div className="row">
                <div className='col-lg-12'>
                    <div dangerouslySetInnerHTML={{__html:counsellordata.success_story}} />
                </div>
            </div>
        </div>
    </>
  )
}

export default CounSuccess