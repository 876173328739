import React, { useEffect, useMemo } from 'react'
import Header from './Header'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'
const CollegeLayout = () => {
  const navigate = useNavigate();
  const usertoken = localStorage.getItem('token');

  useEffect(() => {
    if (!usertoken) {
      navigate('/login');
    }
  }, [usertoken, navigate]);
  const headers = useMemo(() => ({
    Authorization: `JWT ${usertoken}`,
  }), [usertoken]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://portal.mycollegepedia.com/college/college-detail/', {
          headers,
        });
        const acadmic = await axios.get('https://portal.mycollegepedia.com/general-setting/academic-year/', {
          headers,
        });

        localStorage.setItem("collegerecord", JSON.stringify(response.data))
        localStorage.setItem("academic", JSON.stringify(acadmic.data))

      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
        }
        Swal.fire({
          icon: 'error',
          title: 'User Unauthorized',
          text: 'There was an error try again.'
        });
        navigate('/login')
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [headers, navigate]);
  return (
    <div>
      <Header />
    </div>
  )
}

export default CollegeLayout