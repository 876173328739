
import React from "react";
import { LinearProgress } from "@mui/material";

import StarRating from "../Homepages/Startrating";

const Information = ({ counsellordata }) => {

    if (!counsellordata) {
        return (
            <div className="my-12 p-4"><LinearProgress /></div>
        )
    }
    return (
        <section className="contentone">
            <div className="container">
                {counsellordata.overview && (
                    <div className="row">
                        <div className="col-lg-12">
                            {/* <h4 className='font-bold overview'>Overview</h4> */}
                            <p className='font_f' dangerouslySetInnerHTML={{ __html: counsellordata.overview }} />
                        </div>

                    </div>
                )}
                <div className="table-responsive">
                    <h3 className='single_page_heading'>{counsellordata.company_name || "-"}</h3>
                    <table className="table table-striped">

                        <thead>
                            <tr>
                                <th scope="col tableheadingcolor">Particulars</th>
                                <th scope="col tableheadingcolor">Statistics </th>

                            </tr>
                        </thead>
                        <tbody>
                            {counsellordata.counsellor_user &&
                                <tr>
                                    <td>user </td>
                                    <td>{counsellordata.counsellor_user || '-'}</td>
                                </tr>
                            }
                            {counsellordata.email &&
                                <tr>
                                    <td>user </td>
                                    <td>{counsellordata.email || '-'}</td>
                                </tr>
                            }

                            {counsellordata.area_of_operation &&
                                <tr>
                                    <td>area of operation</td>
                                    <td>
                                        {counsellordata.area_of_operation?.map((cn) => (
                                            <span key={cn.id}> {cn.name || "-"} {' | '}</span>
                                        ))}
                                    </td>
                                </tr>
                            }

                            {counsellordata.primary_mobile &&
                                <tr>

                                    <td>contact </td>
                                    <td>{counsellordata.primary_mobile || '-'}</td>
                                </tr>
                            }
                            {counsellordata.rating &&
                                <tr>

                                    <td>rating </td>
                                    <td> <StarRating rating={counsellordata.rating} /></td>
                                </tr>
                            }
                            {counsellordata.registration_date &&
                                <tr>

                                    <td>Registerd year </td>
                                    <td>{counsellordata.registration_date}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>

                {counsellordata.success_story && (
                    <div className="row">
                        <div className="  col-lg-12">
                            <h3 className='single_page_heading'>Success Story</h3>
                            <p className='!text-black list_st' dangerouslySetInnerHTML={{ __html: counsellordata.success_story }} />
                        </div>
                    </div>
                )}
            </div>
            &nbsp;&nbsp;
        </section>

    );
}
export default Information;