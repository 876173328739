// UpdateFAQ.js
import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import RichText from './Subpages/Richtext';
import { TiArrowBack } from 'react-icons/ti';
import { LinearProgress } from "@mui/material";
import Breadcrumb from 'react-bootstrap/Breadcrumb';


const UpdateFAQ = () => {
    const token = useMemo(() => localStorage.getItem('token') || '', []);
    const [Loading, setLoading] = useState(true);
    const { id } = useParams();
    const [selectedFAQ, setSelectedFAQ] = useState({
        id: id || null,
        question: '',
        answer: '',
    });
    const navigate = useNavigate();

    const handleRichTextChange = (name, value) => {
        setSelectedFAQ({
            ...selectedFAQ,
            [name]: value,
        });
    };
    const headers = useMemo(() => ({
        Authorization: `JWT ${token}`,
    }), [token]);
    useEffect(() => {
        const fetchFAQ = async () => {
            try {
                const response = await axios.get(`https://portal.mycollegepedia.com/college/college-faq/${id}`, { headers });
                setSelectedFAQ(response.data);
                setLoading(false)
            } catch (error) {
                console.error('Error fetching FAQ:', error);
            }
        };

        fetchFAQ();
    }, [id, headers]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSelectedFAQ((prevFAQ) => ({
            ...prevFAQ,
            [name]: value,
        }));
    };

    const handleUpdateFAQ = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.patch(`https://portal.mycollegepedia.com/college/college-faq/${id}/`, selectedFAQ, { headers });
            if (response.status === 200) {
                Swal.fire({
                    position: 'top-end',
                    timer: 3000,
                    icon: 'success',
                    text: 'FAQ updated successfully!',
                });
                navigate('/college-dashboard/college-faqs');
            }
        } catch (error) {
            console.error('Error updating FAQ:', error);
        }
    };
    if (Loading) {
        return (
            <>
                <p className="my-10 p-3">
                    {" "}
                    <LinearProgress />
                </p>
            </>
        );
    }
    return (
        <div className='container common_dash px-3'>
            <div className="row align-items-center top">
                <div className="col-lg-6">
                    <h3>FAQs</h3>
                </div>
                <div className="col-lg-6">
                    <Breadcrumb className="bread">
                        <Breadcrumb.Item ><Link to="/college-dashboard">Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item active>Edit FAQ's</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>


            <div className='justify-end row'>
                <Link to="/college-dashboard/college-faqs" className="btn-sm shadow-sm flex m-3 gap-1.5 float-right text-white items-center border bg-[#052963]">
                    <TiArrowBack />
                    Back
                </Link>
            </div>
            <Form onSubmit={handleUpdateFAQ}   className='common_dash_form p-3'   >
                <Form.Group controlId="question">
                    <Form.Label>Question:</Form.Label>
                    <Form.Control
                        type="text"
                        onChange={handleChange}
                        name="question"
                        value={selectedFAQ.question}
                    />
                </Form.Group>
                <Form.Group controlId="answer">
                    <Form.Label>Answer:</Form.Label>
                    <RichText
                        name="answer"
                        placeholder={"answer"}
                        value={selectedFAQ.answer}
                        onChange={handleRichTextChange}
                    />
                </Form.Group>
               <div className='d-flex justify-content-end'>
               <Button  type="submit" className='button_of_submit mt-2'>
                    Update FAQ
                </Button>
               </div>
            </Form>
        </div>
    );
};

export default UpdateFAQ;
