import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { FaMousePointer, FaInstagram, FaFacebook, FaLinkedin, FaTwitter, FaYoutube, FaTelegram, FaWhatsapp } from 'react-icons/fa';
import smallbanner from '../../assets/images/predictor.jpg'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { NavLink, useParams } from 'react-router-dom';
import { RiDoubleQuotesR, RiDoubleQuotesL } from "react-icons/ri";
import { IoLocationOutline } from "react-icons/io5";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { CiCalendarDate, CiStar } from 'react-icons/ci';
import StarRating from '../Homepages/Startrating';
import { Button, Stack } from 'react-bootstrap';
import CounDetails from './CounDetails';
import CounOverview from './CounOverview';
import CounSuccess from './CounSuccess';

import CollegeFQs from '../Colleges/CollegeFQs';
import Apicontext from '../../Page/Contextapi';
import Information from './Info_counsellor';
import CounsellorApply from './CounsellorApply';
import Modal from 'react-modal';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        zIndex: 1000,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',

        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1100,
    },
};

const SingleCounsellor = () => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [counsellordata, setCounsellor] = useState([]);
    const [Loading, setLoading] = useState(true);
    // const [data, setData] = useState([]);
    const [testimonial, setTesti] = useState([]);

    const { slug } = useParams();
    const contextdata = useContext(Apicontext);

    useEffect(() => {
        // const Alldata = contextdata.APIdata;
        // setData(Alldata)

        const fetchCollegeData = async () => {
            try {
                const response = await axios.get(`https://portal.mycollegepedia.com/counsellor/counsellor/${slug}/`);
                setCounsellor(response.data);
                if (counsellordata.id) {
                    const res = await axios.get(`https://portal.mycollegepedia.com/counsellor/testimonial/${counsellordata.id}`);
                    setTesti(res.data);
                }
                setLoading(false)
            } catch (error) {
                console.error('Error fetching college data:', error);
            }
        };
        fetchCollegeData();
    }, [slug, contextdata, counsellordata.id]);

    console.log("single counsellor", counsellordata)
    let subtitle;
    function afterOpenModal() {
        subtitle.style.color = 'black';
    }
    function closeModal() {
        setIsOpen(false);
    }
    function openmodal() {
        setIsOpen(true);
    }
    const owlOptions = {
        autoplay: true,
        rewind: true,
        transition: 500,
        dots: false,
        responsiveClass: true,
        autoplayTimeout: 13000,
        smartSpeed: 5000,
        nav: true,
        navText: ["<", ">"],
        responsive: {
            0: { items: 1 },
            600: { items: 2 },
            1024: { items: 2 },
            1366: { items: 2 },
        },
    };


    const AllInfo = () => {
        return (
            <div>
                <Information counsellordata={counsellordata} />
                {counsellordata.faq && (
                    <div className="row">
                        <div className="col-lg-12 px-0">
                            <h3 className='single_page_heading ml-4'>FAQs</h3>
                            <p>{renderFAQ()}</p>
                        </div>
                    </div>
                )}
            </div>
        )
    }
    const counsellordetails = () => {
        return (
            <div>
                <CounDetails counsellordata={counsellordata} />
            </div>
        )
    }
    const counselloroverview = () => {
        return (
            <div>
                <CounOverview counsellordata={counsellordata} />
            </div>
        )
    }
    const counsellorsuccess = () => {
        return (
            <div>
                <CounSuccess counsellordata={counsellordata} />
            </div>
        )
    }
    const renderFAQ = () => {
        return counsellordata.faq?.map((faqItem, index) => (
            <div key={index}>
                <CollegeFQs key={faqItem.id} faqItem={faqItem} isFirst={index === 0} />
            </div>
        ));
    };


    const tabs = [
        { id: 'Information', label: 'Info', content: AllInfo() },
        { id: 'Overview', label: 'Overview', content: counselloroverview() },
        { id: 'Details', label: 'Details', content: counsellordetails() },
        { id: 'Successstory', label: 'Success Story', content: counsellorsuccess() },
        { id: 'FAQ', label: 'FAQ', content: renderFAQ() },
    ];

    if (Loading) {
        return (
            <div className="min-h-screen p-3 flex items-center justify-center">
                <div className="w-16 h-16 border-4 border-dashed rounded-full animate-spin border-[#052963] dark:border-[whitesmoke]"></div>
            </div>
        )
    }
    return (
        <div className='single_college_section'>
            <Helmet>
                <title>{counsellordata ? counsellordata.meta_title : "mycollegepedia - College"}</title>
                <meta name="description" content={counsellordata.meta_description ? counsellordata.meta_description : "company in Indore, delivering innovative solutions for businesses"} />
                <meta name="keywords" content={counsellordata.meta_keyword ? counsellordata.meta_keyword : "company in Indore, delivering innovative solutions for businesses counselling "} />
            </Helmet>
            <div className="container-fluid" id="college-content-section">

                <div className='row'>
                    <div className='px-0 col-lg-12'>
                        <div className="img-section ">
                            <div className='titlecollege'>
                                <div className='single_college_bread'>
                                    <div className="row align-items-center">
                                        <div className="col-lg-12op;./ol.">
                                            <Breadcrumb className="bread">
                                                <Breadcrumb.Item >
                                                    <NavLink to="/" title='home'>Home</NavLink>
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item active>{counsellordata.company_name || 'mycollegepedia'}</Breadcrumb.Item>
                                            </Breadcrumb>
                                        </div>
                                    </div>
                                </div>
                                <div className='college_main_details'>
                                    <div className='logo_pic'>
                                        <img src={counsellordata.logo} alt={counsellordata.company_name || "company"} title={counsellordata.company_name} loading='lazy' className='hidden singlelogo md:block' />
                                    </div>
                                    <div className='college_name'>
                                        <div className='c_name'>
                                            <h1 className='name'>{counsellordata.company_name} </h1>
                                        </div>
                                        <div className='other_details'>
                                            <p className='d-flex align-items-center gap-1'><IoLocationOutline />{counsellordata.city}{counsellordata.current_address}</p></div>
                                        <div className='other_details'>

                                            <p className='d-flex align-items-center gap-1'><CiCalendarDate /> {counsellordata.registration_date}</p>
                                            <p className='d-flex align-items-center gap-1'><CiStar />{counsellordata.area_of_operation?.map((cn) => (
                                                <span key={cn.id}> {cn.name} {' | '}</span>
                                            ))}
                                            </p>
                                        </div>
                                        <div className='other_details'>
                                            <div className='social_d mt-3'>
                                                <div className='row align-items-center'>
                                                    <div className='col-md-8'>
                                                        <div className='personal_d_college '>
                                                            <p className='conte'><span>Primary:</span><span> {counsellordata.primary_mobile || "6265861847"} </span></p>

                                                            <p className='conte'><span>Email:</span><span>{counsellordata.email}</span></p>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='social_college'>
                                                            <span><FaWhatsapp /></span>
                                                            <span><FaInstagram /></span>
                                                            <span><FaFacebook /></span>
                                                            <span><FaLinkedin /></span>
                                                            <span><FaTwitter /></span>
                                                            <span><FaYoutube /></span>
                                                            <span><FaTelegram /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="div-img">
                                <img src={counsellordata.image || smallbanner} alt={counsellordata.company_name} title={counsellordata.company_name} loading='lazy' className='main_h_fluid_content' />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <h4 ref={(_subtitle) => (subtitle = _subtitle)}> </h4>
                <CounsellorApply closeModal={closeModal} counsellordata={counsellordata} />
            </Modal>

            <div className='container'>
                <div className="row ">
                    <div className=" col-md-9">
                        <Tabs>
                            <div className="tab-container" style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                                {/* <button className='btn tabs_prev_btn'>
                                    <FaArrowLeft />

                                </button> */}
                                <TabList>
                                    {tabs && tabs.map((tab) => (
                                        tab && tab.label && tab.content ? (
                                            <Tab key={tab.id}>{tab.label}</Tab>
                                        ) : null
                                    ))}
                                </TabList>
                                {/* <button className='btn tabs_next_btn'>
                                    <FaArrowRight />
                                </button> */}
                            </div>


                            {tabs && tabs.map((tab) => (
                                tab && tab.label && tab.content ? (
                                    <TabPanel key={tab.id}>
                                        {typeof tab.content === 'string' ? (
                                            <div>
                                                <div className='tab_contents'>{tab.content} </div>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className='tab_contents'>{tab.content} </div>

                                            </div>
                                        )}
                                    </TabPanel>
                                ) : null
                            ))}
                        </Tabs>
                        {/* owl random college */}
                        <div className='owl_slider_rec'>
                            <div className='main__card'>
                                <div className='row'>
                                    <div className='col-lg-12 p'>
                                        <div className='long_border'>
                                            <h3>Testimonial </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>

                                        {testimonial && testimonial.length > 0 && (
                                            <OwlCarousel className="owl-theme " {...owlOptions}>
                                                {testimonial.map((testi) => (
                                                    <div className="gap-5 mx-2">
                                                        <div key={testi.id} className='grid testi_card '>
                                                            <h4>{testi.name}</h4>
                                                            <div className="mb-1">
                                                                <div className='description'>
                                                                    <RiDoubleQuotesL />
                                                                    <h6 className="title"><div className='line-clamp-4' dangerouslySetInnerHTML={{ __html: testi.description }} /> </h6>

                                                                    <RiDoubleQuotesR />
                                                                </div>
                                                            </div>

                                                            <ul className="flex justify-center mb-0">
                                                                <h6><StarRating rating={testi.rating} /></h6>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                ))}
                                            </OwlCarousel>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3 '>

                        <div className='row' >
                            <div className='col-md-12'>
                                <Stack spacing={2} className='btnapply'>
                                    <Button className='btn' variant="outlined" onClick={openmodal}>
                                        Apply <FaMousePointer className='' />
                                    </Button>
                                </Stack>
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='small_banner'>
                                    <img src={smallbanner} alt={"counsellorbanner"} title={'counsellorbanner'} />
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className='small_banner'>
                                    <img src={smallbanner} alt={"counsellorbanner"} title={'counsellorbanner'} />
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className='small_banner'>
                                    <img src={smallbanner} alt={"counsellorbanner"} title={'counsellorbanner'} />
                                </div>
                            </div>
                        </div>
                        {/* photos */}
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='gallery'>
                                    <div className='long_border'>
                                        <h3 className='text-center'>Gallery</h3>
                                    </div>
                                    <div className='h_of_card'>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='picc mt-2'>
                                                    <img src={smallbanner} alt={"counsellorbanner"} title={'counsellorbanner'} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='picc mt-2'>
                                                    <img src={smallbanner} alt={"counsellorbanner"} title={'counsellorbanner'} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='picc mt-2'>
                                                    <img src={smallbanner} alt={"counsellorbanner"} title={'counsellorbanner'} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='picc mt-2'>
                                                    <img src={smallbanner} alt={"counsellorbanner"} title={'counsellorbanner'} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='picc mt-2'>
                                                    <img src={smallbanner} alt={"counsellorbanner"} title={'counsellorbanner'} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* videos */}
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='gallery'>
                                    <div className='long_border'>
                                        <h3 className='text-center'>Videos</h3>
                                    </div>
                                    <div className='h_of_card'>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='picc mt-2'>
                                                    <img src={smallbanner} alt={"counsellorbanner"} title={'counsellorbanner'} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='picc mt-2'>
                                                    <img src={smallbanner} alt={"counsellorbanner"} title={'counsellorbanner'} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='picc mt-2'>
                                                    <img src={smallbanner} alt={"counsellorbanner"} title={'counsellorbanner'} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='picc mt-2'>
                                                    <img src={smallbanner} alt={"counsellorbanner"} title={'counsellorbanner'} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='picc mt-2'>
                                                    <img src={smallbanner} alt={"counsellorbanner"} title={'counsellorbanner'} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='picc mt-2'>
                                                    <img src={smallbanner} alt={"counsellorbanner"} title={'counsellorbanner'} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* news and alerts */}
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='gallery'>
                                    <div className='long_border'>
                                        <h3 className='text-center'>News and Alerts</h3>
                                    </div>
                                    <div className='h_of_card'>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='main_side_card'>

                                                    <div className='popular_col'>
                                                        <div className='pic'>
                                                            <img src={smallbanner} alt='smaller' title='description' />
                                                        </div>
                                                        <div className='content'>
                                                            <p className='title'>fhecef fjbefh bqfwbfuqok efqfio qnfiohfi klnioioqef njqioq </p>
                                                            <p className='description'>fhecef fjbefh bqfwbfuqok efqfio qnfiohfi klnioioqef njqioq </p>
                                                            <p className='published_on'> <span>Published on</span><span>01/01/2024</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='main_side_card'>
                                                    <div className='popular_col'>
                                                        <div className='pic'>
                                                            <img src={smallbanner} alt='smaller' title='description' />
                                                        </div>
                                                        <div className='content'>
                                                            <p className='title'>fhecef fjbefh bqfwbfuqok efqfio qnfiohfi klnioioqef njqioq </p>
                                                            <p className='description'>fhecef fjbefh bqfwbfuqok efqfio qnfiohfi klnioioqef njqioq </p>
                                                            <p className='published_on'> <span>Published on</span><span>01/01/2024</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='main_side_card '>
                                                    <div className='popular_col'>
                                                        <div className='pic'>
                                                            <img src={smallbanner} alt='smaller' title='description' />
                                                        </div>
                                                        <div className='content'>
                                                            <p className='title'>fhecef fjbefh bqfwbfuqok efqfio qnfiohfi klnioioqef njqioq </p>
                                                            <p className='description'>fhecef fjbefh bqfwbfuqok efqfio qnfiohfi klnioioqef njqioq </p>
                                                            <p className='published_on'> <span>Published on</span><span>01/01/2024</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='main_side_card '>
                                                    <div className='popular_col'>
                                                        <div className='pic'>
                                                            <img src={smallbanner} alt='smaller' title='description' />
                                                        </div>
                                                        <div className='content'>
                                                            <p className='title'>fhecef fjbefh bqfwbfuqok efqfio qnfiohfi klnioioqef njqioq </p>
                                                            <p className='description'>fhecef fjbefh bqfwbfuqok efqfio qnfiohfi klnioioqef njqioq </p>
                                                            <p className='published_on'> <span>Published on</span><span>01/01/2024</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='main_side_card'>
                                                    <div className='popular_col'>
                                                        <div className='pic'>
                                                            <img src={smallbanner} alt='smaller' title='description' />
                                                        </div>
                                                        <div className='content'>
                                                            <p className='title'>fhecef fjbefh bqfwbfuqok efqfio qnfiohfi klnioioqef njqioq </p>
                                                            <p className='description'>fhecef fjbefh bqfwbfuqok efqfio qnfiohfi klnioioqef njqioq </p>
                                                            <p className='published_on'> <span>Published on</span><span>01/01/2024</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* popular college */}
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='gallery'>
                                    <div className='long_border'>
                                        <h3 className='text-center'>Popular Consultancy</h3>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <div className='main_side_card h_of_card'>
                                                <div className='popular_col'>
                                                    <div className='pic'>
                                                    </div>
                                                    <div className='content'>
                                                        <p className='title'></p>
                                                        <p className='rating'><StarRating /> </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* popular college */}
                        <div className='for_sticky'>
                            <div className='row '>
                                <div className='col-md-12'>
                                    <div className='gallery'>
                                        <div className='long_border'>
                                            <h3 className='text-center'>Recommended Consultancy</h3>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='main_side_card h_of_card'>
                                                    <div className='popular_col' >
                                                        <div className='content'>
                                                            <p className='title'></p>
                                                            <p className='published_on'><span>Published On:</span><span></span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default SingleCounsellor