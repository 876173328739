import React, { useContext, useEffect, useState } from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaTelegram, FaYoutube } from "react-icons/fa";

import { Helmet } from 'react-helmet';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { FaXTwitter } from "react-icons/fa6";
import AvatarF from "../../assets/images/AvatarF1.gif"
import AvatarM from "../../assets/images/Avatarboy.gif"


import { Link } from 'react-router-dom';
import Apicontext from '../../Page/Contextapi';
import { LinearProgress } from '@mui/material';


const Blogpage = () => {
    const [Loading, setLoading] = useState(true);
    const [blogData, setBlogData] = useState('');
    const [filteredBlogData, setFilteredBlogData] = useState(null);
    const [category, setCategory] = useState([]);
    const [categoryName, setCategoryName] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [blogsPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState('');

    const contextData = useContext(Apicontext);
    const [randomBlogs, setRandomBlogs] = useState([]);
    const [populerBlog, setPopulerBlog] = useState(null);

    const handlePagination = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const handlePrevPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    const handleNextPage = () => {
        const maxPage = Math.ceil((filteredBlogData || blogData).length / blogsPerPage);
        setCurrentPage((prev) => Math.min(prev + 1, maxPage));
    };

    const indexOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    const currentBlogs = filteredBlogData
        ? filteredBlogData.slice(indexOfFirstBlog, indexOfLastBlog)
        : blogData.slice(indexOfFirstBlog, indexOfLastBlog);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil((filteredBlogData || blogData).length / blogsPerPage); i++) {
        pageNumbers.push(i);
    }

    useEffect(() => {
        if (blogData.length > 0) {
            const randomBlogsArray = [];
            for (let i = 0; i < 3; i++) {
                const randomIndex = Math.floor(Math.random() * blogData.length);
                randomBlogsArray.push(blogData[randomIndex]);
            }
            setRandomBlogs(randomBlogsArray);
        }
    }, [blogData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await contextData.APIdata;
                setBlogData(data.blog);
                setCategory(data.category)
                setLoading(false)
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [contextData]);

    useEffect(() => {

        if (blogData.length > 0) {
            const sortedBlogs = [...blogData].sort((a, b) => b.views - a.views);
            const mostViewedBlogs = sortedBlogs.slice(0, 5);
            setPopulerBlog(mostViewedBlogs);
        }

    }, [blogData]);

    const filterByCategory = (categoryId) => {
        if (categoryId === null) {
            setFilteredBlogData(null);
            setCategoryName('');
        } else {
            const filteredBlogs = blogData.filter(blog => blog.category.id === categoryId);
            const categoryName = blogData.find(blog => blog.category.id === categoryId)?.category?.name || "";
            setFilteredBlogData(filteredBlogs);
            setCategoryName(categoryName)
        }
    };
    const handleSearchInputChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        const filtered = blogData.filter(blog => blog.title.toLowerCase().includes(query.toLowerCase()));
        setFilteredBlogData(filtered);
        setCategoryName(query || '')
        if (query.length === 0) {
            setFilteredBlogData(null)
        }
    };

    if (Loading) {
        return <div className='my-12 p-3'><LinearProgress /> </div>;
    }

    return (

        <div className="blog-c mt-5 mb-5">
            <Helmet>
                <title>MyCollegePedia India s No. 1 Platform to Find Colleges - Blogs</title>
                <meta name="description" content="Explore informative blogs about colleges, exams, and educational insights at MyCollegePedia." />
                <meta name="keywords" content="colleges, exams, education, blogs, MyCollegePedia" />
            </Helmet>
            <div className="container">
                <div className='row '>
                    <div className='col-lg-12'>
                        <div className='s_bar'>
                            <form onSubmit={(e) => e.preventDefault()} className="search-bar" target="_blank">
                                <input
                                    type="text"
                                    placeholder="Search blog"
                                    value={searchQuery}
                                    onChange={handleSearchInputChange}
                                    name="q"
                                />
                                <button type="submit">
                                    <i className="fas fa-search"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className='row mb-5'>
                    <div className='col-lg-12'>
                        {filteredBlogData === null ?

                            <div className='random_blog'>
                                <div className='long_border'>
                                    <h3>Random Blogs</h3>
                                </div>

                                <div className="row">
                                    {randomBlogs && randomBlogs.map((data) =>
                                        <div className="col-lg-4 col-md-6 mt-4" key={data.id}>
                                            <div className="event-data">

                                                <div className="thumb">
                                                    <Link to={`/blog/${data.slug}`} title={data.title}>
                                                        <img
                                                            src={data.image || "https://templates.hibootstrap.com/sanu/default/assets/images/events/events-1.jpg"}
                                                            className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                                            alt={data.title}
                                                            title={data.title}
                                                            loading='lazy'
                                                        />
                                                    </Link>
                                                </div>
                                                <div className="event-content">
                                                    <div className="date">
                                                        <div className="time leading-1">
                                                            {new Date(data.published_date).toLocaleDateString('en-GB', {
                                                                day: '2-digit',
                                                                month: 'long',
                                                                year: 'numeric'
                                                            })}
                                                        </div>
                                                    </div>
                                                    <h3>
                                                        <Link to={`/blog/${data.slug}`} title={data.title}>{data.title || 'blog title'}</Link>
                                                    </h3>
                                                    <p>
                                                        {data.category.name}
                                                    </p>
                                                    <div className='author_details d-flex align-datas-center  mt-3'>
                                                        <div className='pic mr-2'>
                                                            <img
                                                                src={data.author.profile ? data.author.profile : data.author.gender === "MALE" ? AvatarM : AvatarF}
                                                                alt={`author by ${data.author && data.author.name}`}
                                                                width="100"
                                                                title={data.author.name}
                                                                height="100"
                                                                loading="lazy"
                                                                className="shadow-sm rounded-full dark:shadow-black/20"
                                                            />
                                                        </div>
                                                        <div className='name'>
                                                            <h5>{data.author.name}</h5>
                                                            <p>{new Date(data.published_date).toLocaleDateString('en-GB')}</p>
                                                        </div>
                                                        <div className='readbtn'>
                                                            <button className=''> <Link to={`/blog/${data.slug}`} title={data.title}>Read More..</Link></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            : (
                                " "
                            )}
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-lg-12'>
                        <div className='row'>
                            <div className='col-lg-9'>
                                <div className='popular_blog'>
                                    <div className='long_border'>
                                        <h3>{filteredBlogData == null ? "Recent Blogs" : categoryName}</h3>
                                    </div>
                                    {filteredBlogData === null ? (
                                        <div className='row mt-3'>
                                            {blogData && currentBlogs.map((data) =>
                                                <div className='col-lg-12 mt-3' key={data.id}>
                                                    <div className='blog_recent'>
                                                        <div className='row'>
                                                            <div className='col-lg-5'>
                                                                <div className='pic'>
                                                                    <img
                                                                        src={data.image || "https://templates.hibootstrap.com/sanu/default/assets/images/events/events-1.jpg"}
                                                                        className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                                                        alt={data.title}
                                                                        title={data.title}
                                                                    />
                                                                </div>


                                                            </div>
                                                            <div className='col-lg-7'>
                                                                <div className='content_blog'>
                                                                    <div className='heading'>
                                                                        <h4> <Link to={`/blog/${data.slug}`} title={data.title}>{data.title || 'blog title'}</Link></h4>
                                                                    </div>
                                                                    <div className="category my-3">
                                                                        <p>{data.category.name}</p>
                                                                    </div>
                                                                    <div className="description">
                                                                        <p dangerouslySetInnerHTML={{ __html: data.post }} />
                                                                    </div>
                                                                </div>
                                                                <div className='author_details d-flex align-datas-center  mt-3'>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className='pic mr-2'>
                                                                            <img
                                                                                src={data.author.profile ? data.author.profile : data.author.gender === "MALE" ? AvatarM : AvatarF}
                                                                                alt={`author by ${data.author && data.author.name}`}
                                                                                width="100"
                                                                                title={data.author.name}
                                                                                height="100"
                                                                                loading="lazy"
                                                                                className="shadow-sm rounded-full dark:shadow-black/20"
                                                                            />

                                                                        </div>
                                                                        <div className='name'>
                                                                            <h5>{data.author.name}</h5>
                                                                            <p>{new Date(data.published_date).toLocaleDateString('en-GB')}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className=''>
                                                                        <div className='readbtn'>
                                                                            <button className='' > <Link to={`/blog/${data.slug}`} title={data.title}>Read More..</Link></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            )}

                                            <div className='col-lg-12'>
                                                <ul className="pagination">
                                                    <button className='btn' onClick={handlePrevPage} disabled={currentPage === 1}>
                                                        Prev
                                                    </button>
                                                    {pageNumbers.map((number) => (
                                                        <li key={number} className="page-item">
                                                            <button onClick={() => handlePagination(number)} className={`${currentPage === number ? "bg-primary text-white" : '' } page-link `}>
                                                               {number}
                                                            </button>
                                                        </li>
                                       ))}
                                                    <button className='btn' onClick={handleNextPage} disabled={currentPage === pageNumbers.length}>
                                                        Next
                                                    </button>
                                                </ul>
                                            </div>

                                        </div>
                                    ) :
                                        (
                                            <div className='row'>
                                                {filteredBlogData && filteredBlogData.map((data) =>
                                                    <div className='col-lg-12 mt-3' key={data.id}>
                                                        <div className='blog_recent'>
                                                            <div className='row'>
                                                                <div className='col-lg-5'>
                                                                    <div className='pic'>
                                                                        <img
                                                                            src={data.image || "https://templates.hibootstrap.com/sanu/default/assets/images/events/events-1.jpg"}
                                                                            className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                                                            alt={data.title}
                                                                            title={data.title}
                                                                        />
                                                                    </div>


                                                                </div>
                                                                <div className='col-lg-7'>
                                                                    <div className='content_blog'>
                                                                        <div className='heading'>
                                                                            <h4> <Link to={`/blog/${data.slug}`} title={data.title}>{data.title || 'blog title'}</Link></h4>
                                                                        </div>
                                                                        <div className="category my-3">
                                                                            <p>{data.category.name}</p>
                                                                        </div>
                                                                        <div className="description">
                                                                            <p dangerouslySetInnerHTML={{ __html: data.post }} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='author_details d-flex align-datas-center  mt-3'>
                                                                        <div className='pic mr-2'>
                                                                            <img
                                                                                src={data.author.profile ? data.author.profile : data.author.gender === "MALE" ? AvatarM : AvatarF}
                                                                                alt={`author by ${data.author && data.author.name}`}
                                                                                width="100"
                                                                                title={data.author.name}
                                                                                height="100"
                                                                                loading="lazy"
                                                                                className="shadow-sm rounded-full dark:shadow-black/20"
                                                                            />

                                                                        </div>
                                                                        <div className='name'>
                                                                            <h5>{data.author.name}</h5>
                                                                            <p>{new Date(data.published_date).toLocaleDateString('en-GB')}</p>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className='popular_blog'>
                                    <div className='main__card'>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='long_border'>
                                                    <h3>Popular Blogs</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='main_side_card h_of_card'>
                                                    <marquee direction="up" className="marquee-container">
                                                        {populerBlog && populerBlog.map((data) =>
                                                            <div className='popular_col' key={data.id}>
                                                                <div className='pic'>
                                                                    <img
                                                                        src={data.image || "https://templates.hibootstrap.com/sanu/default/assets/images/events/events-1.jpg"}
                                                                        className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                                                        alt={data.title}
                                                                        title={data.title}
                                                                    />
                                                                </div>
                                                                <div className='content'>
                                                                    <p className='title'><Link to={`/blog/${data.slug}`} title={data.title}>{data.title || 'blog title'}</Link></p>
                                                                    <p className='published_on'><span>Published On:</span><span>{new Date(data.published_date).toLocaleDateString('en-GB')}</span></p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </marquee>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='main__card'>


                                        <div className=' row mt-3'>
                                            <div className='col-md-12'>
                                                <div className='popular_blog'>
                                                    <div className='long_border'>
                                                        <h3>Social Media</h3>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-12'>
                                                            <div className='social_media mt-3'>
                                                                <span className='icon'><FaFacebook /></span>
                                                                <span className='icon'><FaInstagram /></span>
                                                                <span className='icon'><FaXTwitter /></span>
                                                                <span className='icon'><FaLinkedin /></span>
                                                                <span className='icon'><FaYoutube /></span>
                                                                <span className='icon'><FaTelegram /></span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='main__card'>
                                        <div className=' row mt-3'>
                                            <div className='col-md-12'>
                                                <div className='popular_blog'>
                                                    <div className='long_border'>
                                                        <h3>Category</h3>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-12'>
                                                            <div className='category_filter  my-3'>
                                                                <p className='btn my-2' onClick={() => filterByCategory(null)}>All Categories</p>
                                                                {category && category.map((data) => (
                                                                    <p className='btn my-2' key={data.id} onClick={() => filterByCategory(data.id)}> <MdKeyboardDoubleArrowRight /> {data.name}</p>
                                                                ))}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blogpage