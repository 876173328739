import React, { useContext, useEffect, useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import AvatarF from "../../assets/images/AvatarF1.gif"
import AvatarM from "../../assets/images/Avatarboy.gif"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import Apicontext from '../../Page/Contextapi';
import { LinearProgress } from '@mui/material';
import ReactOwlCarousel from 'react-owl-carousel';

function Blog() {
  const [blogData, setBlogData] = useState('');
  const [error, setError] = useState(null);
  const contextData = useContext(Apicontext);

  const owlOptions = {
    autoplay: true,
    rewind: true,
    transition: 500,
    dots: false,
    responsiveClass: true,
    // autoheight: true, 
    autoplayTimeout: 13000,
    smartSpeed: 5000,
    nav: true,
    navText: ["<", ">"],
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1024: { items: 3 },
      1366: { items: 3 },
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await contextData.APIdata;
        setBlogData(data.blog);
      } catch (error) {
        setError(error);
      }
    };
    fetchData();
  }, [contextData]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  if (!blogData) {
    return <div className='my-12 px-10'><LinearProgress /> </div>;
  }

  return (
    <section className="sion-blog-sec">
      <div className="container md:my-6">
        <div className="row">
          <h5 data-aos="zoom-in" className="text-[24px] p-3 md:p-0 font-bold  md:text-[36px] text-[#052963]">
            Our Latest <span className="md:text-[36px] text-[24px] text-[orange]">Blog</span>
          </h5>
          {blogData && blogData.length > 0 && (
            <ReactOwlCarousel className="owl-theme" {...owlOptions}>
              {blogData.map((item) => (
                <div className="gap-5 mx-2" key={item.id}>
                  <Link className='hover:!text-black' to={`/blog/${item.slug}`} aria-label={item.title} title={item.title} >
                    <div className="event-item">
                      <div className="thumb">
                        <img src={`${item.image || 'https://edumen.codelayers.net/images/blog/02.png'}`} alt={item.title} title={item.title} />
                      </div>
                      <div className="event-content md:mt-7  ">
                        {/* <div className="date">published : {new Date(item.published_date).toLocaleDateString('en-GB')}</div> */}
                        {/* <img src={authorimage} alt='author-image' /> */}
                        <h3 className='leading-5 font-semibold my-1'>{item.title}</h3>
                        <div className='d-flex align-items-center gap-2 '>
                          <img
                            src={item.author.profile ? item.author.profile : item.author.gender === "MALE" ? AvatarM : AvatarF}
                            alt={`author by ${item.author && item.author.name}`}
                            width="100"
                            title={item.author.name}
                            height="100"
                            loading="lazy"
                            className="shadow-sm rounded-full dark:shadow-black/20"
                          />
                         
                          <div>{item.author.name}</div>
                        </div>
                        <div className="time leading-1"> Published date : {new Date(item.published_date).toLocaleDateString('en-GB')}</div>
                        <p className='category_of_blog'> {item.category.name}</p>
                        <Link className="flex gap-2 blog-btn " to={`/blog/${item.slug}`} aria-label={item.title} title={item.title}  role='button'>
                          Read More <BsArrowRight className="items-center pt-1 text-xl hover:font-bold" />
                        </Link>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </ReactOwlCarousel>
          )}
        </div>
      </div>
    </section>
  );
}
export default Blog;