import React, { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import Apicontext from "../../Page/Contextapi";
import { FaArrowRight } from 'react-icons/fa';
import img from "../../assets/images/banner.jpg"
import LazyLoad from 'react-lazyload';

const Counsellorcards = () => {
    const [Card, setCard] = useState(3);
    const [Loading, setLoading] = useState(true);
    const [counsellor, setCounsellor] = useState([]);
    const contextdata = useContext(Apicontext);

    const handleViewMore = () => {
        setCard(Card + 3);
    };

    useEffect(() => {
        const APIdata = contextdata.APIdata;
        setCounsellor(APIdata.counsellors);
        setLoading(false)
    }, [contextdata]);

    if (Loading) {
        return (<div>Loading....</div>);
    }

    return (
        <>
            <div id="Colleges" className="container ">
                <p className="web-colleges-hadding reveal mt-5 mb-3 ">Best Counsellors</p>
            </div>
            <div className="container  colleges-main-section">
                {counsellor && counsellor.length > 0 && (
                    <div className="row">
                        {counsellor.slice(0, Card).map((data) => (
                            <div className="col-md-6 col-lg-4" key={data.id}>
                                <div className="card_college">
                                    <img className="card-img-top mba-img" src={data.image || img} title={data.company_name} alt={data.company_name} loading='lazy' key={data.id + "_image"} />
                                    <div className="card-body">
                                        <div className="main d-flex align-item-center">
                                            <div className="logocard">
                                                <LazyLoad height={200} offset={100} once key={data.id + "_lazyload"}>
                                                    <img src={data.logo || img} title={data.company_name} alt={data.company_name} loading='lazy' key={data.id + "_image"} />
                                                </LazyLoad>
                                            </div>
                                        </div>
                                        <div className='content_of_card'>
                                            <div className='counslrname'>
                                                <h3>{data.company_name}</h3>
                                            </div>
                                            <div className='area_of_operation'>
                                                <h3>
                                                    
                                                    {data.area_of_operation?.map((A)=><span key={A.id}>{A.name || '---'}</span>)}
                                                    
                                                </h3>
                                            </div>
                                            <div className='address'>
                                                <h3>{data.current_address} </h3>

                                            </div>
                                        </div>
                                        <div className="link">
                                        <Link to={`/counsellor/${data.slug}`} className="py-2" title={data.company_name}>View More.. <FaArrowRight /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                <div className="overflow-hidden look-more-srction">
                {counsellor.length > 3 && <button onClick={handleViewMore} className="look-more-button" >View More</button> }
                </div>
            </div>


        </>
    )
}

export default Counsellorcards