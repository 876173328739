import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import landingimg from '../../assets/images/landing1.jpg';
import landingimg1 from '../../assets/images/landing2.jpg';
import landingimg2 from '../../assets/images/landing3.jpg';
import landingimg3 from '../../assets/images/landing4.jpg';
import ReactOwlCarousel from 'react-owl-carousel';

const owlOptions = {
    autoplay: true,
    rewind: true,
    dots: true,
    autoplayTimeout: 13000,
    smartSpeed: 500,
    nav: true,
    navText: ["<", ">"],
    responsive: {
        0: { items: 1 },
        600: { items: 1 },
        1024: { items: 1 },
        1366: { items: 1 },
    },
};

const Landingsection = () => {
    return (
        <section className='landing_section'>
            <ReactOwlCarousel className="owl-theme" {...owlOptions}>
                <div className="slide">
                    <figure className='landing_pic'>
                        <img 
                            src={landingimg} 
                            alt="Top BBA Colleges in India" 
                            title='Mycollegepedia | Explore Limitless Opportunities with MyCollegePedia' 
                            loading='lazy' 
                        />
                    </figure>
                    <div className='landing_content'>
                        <div className='content'>
                            <h1>Top BBA Colleges in India, Ranking, Fees, Admission</h1>
                            <p>Unlock Your Future: Explore Limitless Opportunities with MyCollegePedia</p>
                        </div>
                        <div className='landing_btn'>
                            <Link to="/bba-colleges" className="btn"  role='button' title='View Colleges'>
                                Read More <BsArrowRight className="icon" />
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Repeat for other slides with respective images and content */}
                <div className="slide">
                    <figure className='landing_pic'>
                        <img 
                            src={landingimg1} 
                            alt="Best MBA Colleges in India" 
                            title='Mycollegepedia | Top MBA Colleges' 
                            loading='lazy' 
                        />
                    </figure>
                    <div className='landing_content'>
                        <div className='content'>
                            <h2>Top MBA Colleges in India, Ranking, Fees, Admission</h2>
                            <p>Discover the Best MBA Programs at MyCollegePedia</p>
                        </div>
                        <div className='landing_btn'>
                            <Link to="/mba-colleges" className="btn"  role='button' title='View MBA Colleges'>
                                Read More <BsArrowRight className="icon" />
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="slide">
                    <figure className='landing_pic'>
                        <img 
                            src={landingimg2} 
                            alt="Engineering Colleges in India" 
                            title='Mycollegepedia | Best Engineering Colleges' 
                            loading='lazy' 
                        />
                    </figure>
                    <div className='landing_content'>
                        <div className='content'>
                            <h2>Top Engineering Colleges in India, Ranking, Fees, Admission</h2>
                            <p>Explore Premier Engineering Institutions with MyCollegePedia</p>
                        </div>
                        <div className='landing_btn'>
                            <Link to="/engineering-colleges" className="btn"  role='button' title='View Engineering Colleges'>
                                Read More <BsArrowRight className="icon" />
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="slide">
                    <figure className='landing_pic'>
                        <img 
                            src={landingimg3} 
                            alt="Medical Colleges in India" 
                            title='Mycollegepedia | Best Medical Colleges' 
                            loading='lazy' 
                        />
                    </figure>
                    <div className='landing_content'>
                        <div className='content'>
                            <h2>Top Medical Colleges in India, Ranking, Fees, Admission</h2>
                            <p>Find the Best Medical Colleges with MyCollegePedia</p>
                        </div>
                        <div className='landing_btn'>
                            <Link to="/medical-colleges" className="btn"  role='button' title='View Medical Colleges'>
                                Read More <BsArrowRight className="icon" />
                            </Link>
                        </div>
                    </div>
                </div>
            </ReactOwlCarousel>
        </section>
    );
};

export default Landingsection;