import React, { useContext, useEffect, useState, Suspense } from 'react'
import { FaRegCalendarAlt } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { FaFacebook, FaInstagram, FaLinkedin, FaTelegram, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Helmet } from 'react-helmet';
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";
import Apicontext from '../../Page/Contextapi';
import Swal from 'sweetalert2';
import { useMemo } from 'react';
import { LinearProgress } from '@mui/material';
import ShareButtons from './ShareSocialmedia';

const BlogSingle = () => {
    const { blog } = useParams();
    const [Singleblog, setSingleblog] = useState('')
    const [blogdata, setBlogData] = useState([])
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState('');
    const [comments, setComments] = useState([]);

    const [populerBlog, setPopulerBlog] = useState(null);
    const [Recentblog, setRecentblog] = useState(null);

    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user')) || {};
    const navigate = useNavigate();
    const contextData = useContext(Apicontext);
    const headers = useMemo(
        () => ({
            Authorization: `JWT ${token}`,
        }),
        [token]
    );
    const url = window.location.href;
    const title = Singleblog ? Singleblog.title : '';
    useEffect(() => {
        const fetchData = async () => {
            const data = await contextData.APIdata;
            setBlogData(data.blog);
            setLoading(true)
            try {
                const response = await axios(`https://portal.mycollegepedia.com/blog/blog-detail/${blog}/`);
                const data = response.data;

                setSingleblog(data);
                setLoading(false)

            } catch (error) {

                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [blog, contextData, comments]);

    useEffect(() => {
        if (blogdata.length > 0) {
            const sortedBlogs = [...blogdata].sort((a, b) => b.views - a.views);
            const recentblogs = [...blogdata].sort((a, b) => b.updated_at - a.updated_at);
            setRecentblog(recentblogs.slice(0, 10))
            const mostViewedBlogs = sortedBlogs.slice(0, 5);
            setPopulerBlog(mostViewedBlogs);
        }

    }, [blogdata]);


    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const handlePostComment = async () => {
        const formData = {
            blog: Singleblog.id,
            user: user.id,
            text: comment,
        };
        try {
            const response = await axios.post('https://portal.mycollegepedia.com/blog/comments/', formData, { headers });
            setComments(response.data);
            Swal.fire({
                position: 'top-end',
                timer: 3000,
                icon: 'success',
                text: 'Comment successfully!',
            });
            setComment('');

        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                Swal.fire({
                    icon: 'error',
                    title: 'User Unauthorized',
                    text: 'Please try again.'
                });
                navigate('/login');
            }
            console.error('Error posting comment:', error);
        }
    };
    if (loading) {
        return (
            <div className='container-fluid'>
                <div className='row my-10'>
                    <div className='col-12'>
                        <LinearProgress />
                    </div>
                </div>
            </div>
        );
    }
    if (!Singleblog) {
        return (
            <div className="error-container">
                <p>Blog not found or an error occurred.</p>
            </div>
        );
    }
    const BlogData = Singleblog && Singleblog.post.replace(/style="[^"]*"/g, '');

    return (
        <section>
            <Helmet>
                <title>{Singleblog && Singleblog.meta_title ? Singleblog.meta_title : 'India s No. 1 Platform to Find Colleges and College Websites - MyCollegePedia'}</title>
                <meta name="description" content={Singleblog && Singleblog.meta_description ? Singleblog.meta_description : "Discover India's top platform for finding colleges and college websites. Mycollegepedia provides comprehensive information to help you make informed decisions about your education."} />
                <meta property="og:title" content={Singleblog && Singleblog ? Singleblog.title : "MyCollegePedia"} />
                <meta name="keywords" content={Singleblog && Singleblog.meta_keyword ? Singleblog.meta_keyword : "India, colleges, college websites, education, Mycollegepedia,Collegepedia"} />
                <meta property="og:image" content={Singleblog && Singleblog.logo ? Singleblog.logo : ''} />
            </Helmet>
            <section className=" blog-details-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-8 blog-sec all_cards mt-2">
                            <div className="card !mb-0">
                                <div className="card-body">
                                    {/* <h2 className="card-text">Author - <span>{Singleblog && Singleblog.author.name} </span> </h2> */}
                                    <h1 className='headingblog'>{Singleblog && Singleblog.title}</h1>
                                    <div className="topdivbar">
                                        <div className="profile d-flex">
                                            <div className="pic">
                                                <Suspense fallback={<div>Loading image...</div>}>
                                                    <img
                                                        src={`${Singleblog && Singleblog.category.image ? Singleblog.category.image : ' https://edumen.codelayers.net/images/blog/3.png'}`}
                                                        alt={Singleblog.title} title={Singleblog.title} className='w-12 h-12 overflow-hidden'
                                                    />
                                                </Suspense>
                                            </div>
                                            <div className="detailes">
                                                <p>Author - <span>{Singleblog && Singleblog.author.name}</span></p>
                                                <div className="justt">
                                                    <p> {new Date(Singleblog.published_date).toLocaleDateString('en-GB')}
                                                        <i className="fa-solid fa-clock icon"></i>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <p className='justify-center'>
                                            {/* {Singleblog.title} */}
                                            {/* <p className='mx-3'>
                                                        {Singleblog.category.name}
                                                    </p> */}
                                        </p>
                                        <p className=' gap-2'>
                                            <span className='mx-2'>{Singleblog.views}</span>
                                            <i className="fa-regular fa-eye icon"></i>
                                           <ShareButtons url={url} title={title} />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="blog-dis">
                                <div className="blog-img mt-3">
                                    <Suspense fallback={<div>Loading image...</div>}>
                                        <img
                                            src={Singleblog && Singleblog.image ? Singleblog.image : ' https://edumen.codelayers.net/images/blog/3.png'}
                                            alt={Singleblog.title} title={Singleblog.title}
                                        />
                                    </Suspense>
                                </div>
                                <div className="py-3 blog-detailsall">
                                    <span className="flex gap-2 date">
                                        <FaRegCalendarAlt className='mt-1 text-[#052963]' /> published : {new Date(Singleblog.published_date).toLocaleDateString('en-GB')}
                                    </span>

                                    <div className="cap-content">
                                        <p dangerouslySetInnerHTML={{ __html: BlogData }} />
                                    </div>

                                    {/* <div className="my-4 media-b">
                                        
                                        <div className="flex gap-3 px-2 share-icon">
                                            <Link to=""> <BsFacebook size={20} color="#1877F2" className="" /></Link>
                                            <Link to=""><BsInstagram size={20} color="#E4405F" className="" /></Link>
                                            <Link to=""><BsTwitter size={20} color='#1DA1F2' className="" /></Link>
                                            <Link to=""><BsLinkedin size={20} color='#0A66C2' className="" /></Link>
                                        </div>
                                    </div> */}
                                </div>
                                <div className='row align-items-center'>

                                    <div className='col-lg-6'>

                                        <div className="card">
                                            <div className="card-b">
                                                <div className='p-2 w-[100%] group-control position-relative'>
                                                    <textarea type='text' className='h-10  form-control' rows={3} placeholder='Post a Comment' value={comment} onChange={handleCommentChange} />
                                                </div>
                                                <div className="fright">
                                                    {Singleblog.comments && <p className='p-2 d-flex align-items-center mt-4'><span>({Singleblog.comments.length}) </span> Comment{Singleblog.comments.length !== 1 ? 's' : ''}</p>}
                                                </div>
                                            </div>
                                            <button type="button" className="btn" onClick={handlePostComment}>
                                                Post
                                            </button>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        {/* Display Comments */}
                                        <div className=" comments-section border gird float-right w-[100%] h-[200px] overflow-auto ">
                                            {Singleblog.comments.length > 0 ? (
                                                Singleblog.comments && Singleblog.comments.length >= 0 && Singleblog.comments.map((comment, index) => (
                                                    <div className='comment_card d-flex align-items-center' key={index}>

                                                        <div className='px-2'>
                                                            <div className='pic'>
                                                                <img src={comment.user.profile} alt={comment.user.name} title={comment.user.name} />
                                                            </div>
                                                        </div>
                                                        <div className='contents_comment'>
                                                            <p className='commentsa'><span id='icon'><RiDoubleQuotesL /></span>{' '}{comment.text} {' '}<span id='icon'><RiDoubleQuotesR /></span></p>
                                                            <p className='username'>{comment.user.name}</p>
                                                        </div>
                                                        <div className='date'>
                                                            <p> Posted On : {new Date(comment.created_at).toLocaleDateString('en-GB')}</p>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <p className='p-5'>No comments available for this blog.</p>
                                            )}
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='popular_blog'>
                                <div className='main__card'>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <div className='long_border'>
                                                <h3>Popular Blogs</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <div className='main_side_card h_of_card'>

                                                {populerBlog && populerBlog.map((data) =>
                                                    <div className='popular_col' key={data.id}>
                                                        <div className='pic'>
                                                            <img
                                                                src={data.image || "https://templates.hibootstrap.com/sanu/default/assets/images/events/events-1.jpg"}
                                                                className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                                                alt={data.title}
                                                                title={data.title}
                                                            />
                                                        </div>
                                                        <div className='content'>
                                                            <p className='title'><Link to={`/blog/${data.slug}`} title={data.title}>{data.title || 'blog title'}</Link></p>
                                                            <p className='published_on'><span>Published On:</span><span>{new Date(data.published_date).toLocaleDateString('en-GB')}</span></p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* recent blogs */}
                                <div className='main__card'>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <div className='long_border'>
                                                <h3>Recent Blogs</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 for_sticky'>
                                            <div className='main_side_card h_of_card'>
                                                {Recentblog && Recentblog.map((data) =>
                                                    <div className='popular_col' key={data.id}>
                                                        <div className='pic'>
                                                            <img
                                                                src={data.image || "https://templates.hibootstrap.com/sanu/default/assets/images/events/events-1.jpg"}
                                                                className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                                                alt={data.title}
                                                                title={data.title}
                                                            />
                                                        </div>
                                                        <div className='content'>
                                                            <p className='title'><Link to={`/blog/${data.slug}`} title={data.title}>{data.title || 'blog title'}</Link></p>
                                                            <p className='published_on'><span>Published On:</span><span>{new Date(data.published_date).toLocaleDateString('en-GB')}</span></p>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='main__card'>


                                    <div className=' row mt-3'>
                                        <div className='col-md-12'>
                                            <div className='popular_blog'>
                                                <div className='long_border'>
                                                    <h3>Social Media</h3>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className='social_media mt-3'>
                                                            <span className='icon'><FaFacebook /></span>
                                                            <span className='icon'><FaInstagram /></span>
                                                            <span className='icon'><FaXTwitter /></span>
                                                            <span className='icon'><FaLinkedin /></span>
                                                            <span className='icon'><FaYoutube /></span>
                                                            <span className='icon'><FaTelegram /></span>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className=' row mt-3'>
                                        <div className='col-md-12'>
                                            <div className='popular_blog'>
                                                <div className='long_border'>
                                                    <h3>Category</h3>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className='category_filter mt-3'>
                                                            <p className='btn '>All Categories</p>
                                                         
                                                                <p className='btn' > <MdKeyboardDoubleArrowRight /></p>
                          
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                <div className='main__card for_sticky '>

                                    <div className=' row mt-3'>
                                        <div className='col-md-12'>
                                            <div className='popular_blog'>
                                                <div className='long_border'>
                                                    <h3>Tags</h3>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className='tags mt-3'>

                                                            <p className="tag_back">Filter</p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                &nbsp;&nbsp;&nbsp;
            </section>
        </section>
    )
}

export default BlogSingle