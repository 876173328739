import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import CNF from "../../../assets/images/clgnotfound.png";
import { FaArrowRight, FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { BiSliderAlt } from 'react-icons/bi';
import Collapsible from 'react-collapsible';
import Apicontext from '../../../Page/Contextapi';



const Counsellorpage = () => {
  const [data, setData] = useState('');
  const [CounsData, setCounsData] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const contextData = useContext(Apicontext);
  const [selectedState, setSelectedState] = useState([]);

  useEffect(() => {
    const record = contextData.APIdata;
    setData(record);
    setCounsData(record.counsellors);
  }, [contextData]);

  const [locationOpen, setLocationOpen] = useState(true);
  const [InstituteOpen, setInstituteOpen] = useState(true);
  const [selectedInstitute, setselectInstitute] = useState([]);


  const handleClearAll = () => {
    setSelectedState([]);

    setselectInstitute([]);

    const checkboxes = document.querySelectorAll('.Filtersection input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  };

  const toggleLocation = () => {
    setLocationOpen(!locationOpen);
  };

  const toggleInstitute = () => {
    setInstituteOpen(!InstituteOpen);
  };


  const handleLocation = (location) => {
    setSelectedState((prevSelected) => {
      if (prevSelected.includes(location.id)) {
        return prevSelected.filter((item) => item !== location.id);
      } else {
        return [...prevSelected, location.id];
      }
    });
  };

  const filterBySearchQuery = (filteredCounsellor, searchQuery) => {
    return filteredCounsellor.filter((college) =>
      college.company_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };


  const handleInstitute = (Institute) => {
    setselectInstitute((prevSelected) => {
      if (prevSelected.includes(Institute)) {
        return prevSelected.filter((item) => item !== Institute);
      } else {
        return [...prevSelected, Institute];
      }
    });
  };


  const applyFilters = (CounsData, filters) => {
    let filteredCounsellor = CounsData;
    const {
      searchQuery,
      selectedState,
      selectedInstitute,
    } = filters;


    if (selectedState.length > 0) {
      filteredCounsellor = filteredCounsellor.filter((college) =>
        selectedState.includes(college.state)
      );
    }
    if (selectedInstitute.length > 0) {
      filteredCounsellor = filteredCounsellor.filter((college) =>
        selectedInstitute.includes(college.organization_type.id)
      );
    }

    if (searchQuery) {
      filteredCounsellor = filterBySearchQuery(filteredCounsellor, searchQuery);
    }
    return filteredCounsellor;
  };
  const filteredCounsellor = useMemo(() => {
    const filters = {
      searchQuery,
      selectedState,
      selectedInstitute,

    };
    return applyFilters(CounsData, filters);
  }, [searchQuery, CounsData, selectedState, selectedInstitute,]);



  if (!filteredCounsellor) {
    return (
      <div className='container'>
        <div className='mx-auto text-center col-lg-6 col-md-12'>
          <div className='!pt-10 !text-2xl !text-red-500'><p>No found Matching Exams...<span className='font-bold text-green-600'>Try Again</span></p></div>
          <div className=''>
            <img src={CNF} alt='errorimg' title='page not found' className="!mx-auto text-6xl text-red-500 " />
          </div>
        </div>
      </div>
    );
  }
  const totalCounsellor = filteredCounsellor.length;
  console.log("counsellors page", CounsData)
  console.log("filteredCounsellor ", filteredCounsellor)
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Counsellors for Student Counseling - MyCollegePedia</title>
        <meta name="description"  content="Discover expert counsellors for student counseling. Get guidance on admissions, exams, and more at MyCollegePedia."/>
        <meta name="keywords" content="collegePedia, student counseling, counsellors, admissions, exams, student guidance" />
        <meta name='author' content='mycollegePedia Team Name Gurjar Rohit Patel' />
        <meta name='publisher' content='sion datamatics pvt lt indore india ' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <meta name='robots' content='index, follow' />

      </Helmet>


      <div className='!w-full container-fluid p-0 m-0 Counsellor-main-section'>
        <div className='row !m-0 !p-0 all_cards'>

          <div className='allcollege_sidebar all_cards items-center justify-center h-auto col-md-3 col-12 px-4  all_cards'>
            <div className='justify-center !bg-[whitesmoke] all_cards !h-[100%] !w-full  border-inherit outline-offset-4  Filtersection'>
              <div className='flex justify-between'><strong className='flex items-center p-1'> <span className='px-2 m-1 text-blue-700'><BiSliderAlt /></span>All Filter</strong>
                <button className='p-2 !text-blue-700' onClick={handleClearAll}> Clear All</button>
              </div>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='s_bar'>
                    <form action="https://www.mycollegepedia.com/" method="get" className="search-bar mt-1" traget="_blank">
                      <input
                        type="text"
                        placeholder="Search by Counsellor..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="w-100 p-2"
                      />

                    </form>
                  </div>
                </div>
              </div>
              <div className='m-2'>
                {selectedState.map((location) => (
                  <button className='p-1 m-1 border-2 rounded-xl' key={location}>
                    {data.State.find((item) => item.id === location)?.name}
                  </button>
                ))}

                {selectedInstitute.map((Institute) => (
                  <button key={Institute} className='p-1 border'>
                    {Institute === 1 ? 'University' : Institute === 2 ? 'College' : 'Unknown'}
                  </button>
                ))}

              </div>

              <div className='location all_cards'>
                <b className='flex justify-between m-2 ' onClick={toggleLocation}>
                  <span>State</span>
                  {locationOpen ? <FaCaretUp /> : <FaCaretDown />}
                </b>

                <Collapsible
                  trigger=''
                  open={locationOpen}
                  onTriggerOpening={toggleLocation}
                  onTriggerClosing={toggleLocation}
                >
                  <div className='m-2 overflow-y-auto max-h-40 webkitsc'>
                    <div className="relative">
                      <input className="pl-8 border-double bg-slate-100 rounded-2xl" type="search" placeholder="Location" />
                      <i className="absolute text-gray-600 transform -translate-y-1/2 fa fa-search left-2 top-1/2"></i>
                    </div>
                    <ul className='m-2'>
                      {data && data.State.map((item) => (
                        <li key={item.id}>
                          <input type='checkbox' value={item.id} onChange={() => handleLocation(item)} />
                          {item.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Collapsible>
                <hr />
              </div >


              <div className='Institute all_cards'>
                <b className='flex justify-between m-2' onClick={toggleInstitute}>
                  <span>Institute</span>
                  {InstituteOpen ? <FaCaretUp /> : <FaCaretDown />}
                </b>
                <Collapsible
                  open={InstituteOpen}
                  onTriggerOpening={toggleInstitute}
                  onTriggerClosing={toggleInstitute}
                >
                  <div className='m-2 overflow-y-auto max-h-40 webkitsc'>
                    <div className="relative">
                      <input className="pl-8 border-double rounded-2xl bg-slate-100" type="search" placeholder="Institute" />
                      <i className="absolute text-gray-600 transform -translate-y-1/2 fa fa-search left-2 top-1/2"></i>
                    </div>
                    <ul className='m-2'>
                      <li>  <input type='checkbox' onChange={() => handleInstitute(2)} /> College </li>
                      <li>  <input type='checkbox' onChange={() => handleInstitute(1)} /> University </li>
                    </ul>
                  </div>
                </Collapsible>
                <hr />
              </div>

            </div >
          </div>

          <div className='md:px-2 col-12 col-md-9'>


            <div className="grid !grid-cols-1 sm:!grid-cols-2 md:!grid-cols-3 lg:!grid-cols-3 md:gap-3 bg-[#fff]">

              {filteredCounsellor && filteredCounsellor.length === 0 ? (
                <>
                  <div></div>
                  <div className="bg-[#fff]">
                    <div className="grid items-center text-2xl text-red-500">
                      No matching counsellor found...<span className="font-bold text-center text-green-600 border">Try Again</span>
                    </div>
                    <img src={CNF} alt="errorimg" title='not found data' className="mx-auto text-6xl text-red-500" />
                  </div>
                </>
              ) : (filteredCounsellor.map((data) => (
                <div className="" key={data.id}>
                  <div className="card_college" >
                    <div className='all_college_img'>

                      <img className="card-img-top mba-img" src={data.image || ""}
                        alt={data.company_name} title={data.company_name} loading='lazy' />
                    </div>
                    <div className="card-body">
                      <div className="main d-flex align-item-center">
                        <div className="logocard">
                          <img className="" loading='lazy' src={data.logo} alt={data.company_name} title={data.company_name} />
                        </div>

                      </div>

                      <div className="card-pera">
                        <h4> <Link to={`/counsellor/${data.slug}`} className="py-2" title={data.company_name}>{data.company_name}</Link></h4>
                      </div>
                      <div className="d-flex head">
                        <div className="area_of_operation">
                          <h3 className="title"> {data.area_of_operation?.map((A) => <span key={A.id}>{A.name || '---'}</span>)}</h3>
                        </div>
                      </div>
                      <div>
                        {/* <small className="pt-1 adress">
                          <span > {data.email}</span>
                        </small> */}
                        <small className="pt-1 adress">
                          <span > Address :{data.city}</span>
                        </small>

                      </div>
                      <div className="link">
                        <Link to={`/counsellor/${data.slug}`} className="py-2" title={data.name}>View More<FaArrowRight /></Link>
                        {/* <Link to={`/broucher/${data.slug}`} className="py-2">Download Broucher<FaArrowRight /></Link> */}
                      </div>

                    </div>
                  </div>

                </div>
              ))
              )}
            </div>
            <div className='totalnumberofcollege '> Showing Counsellor <button className='btn'>{totalCounsellor}</button></div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Counsellorpage