import React from 'react'
import { Link } from 'react-router-dom';

function Top_section() {
    return (


        <>
            <section class="breadcrumb-section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <h1 class="ab-1">About mycollegepedia</h1>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li class="breadcrumb-item active" aria-current="page"><Link to="/about">about</Link></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <div className="about-sec px-2 py-8 md:!py-[20px] ">
                {/* <div className="about-sec-1">
                    <p className="wel-about-sec p">
                        Welcome to <span className="wel-about-sec2">College Pedia</span>
                    </p>
                </div> */}
            </div>
        </>
    )
}

export default Top_section;