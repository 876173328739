import guidanceimage from "../../assets/images/admp.webp"
import stepchoice from "../../assets/images/step.png"
import choice from "../../assets/images/choice.png"
import counselling from "../../assets/images/counselling.png"

const Guidance = () => {
    return (
        <>
            <section className="admission mt-5">
                <div className="container-fluid">
                    <div className="mt-5 row">
                        <div className="col-lg-6 image">
                            <img src={guidanceimage} alt="guidanceimage" title="guidance" />
                        </div>
                        <div className="p-5 col-lg-6">
                            <div className="main_content">

                           
                            <div className="pt-5 main-text">
                                <h4>
                                    Mentorship &amp;<span> Guidance</span>
                                </h4>
                                <p>
                                    <strong>Articles and Guides: </strong>Access articles and guides on
                                    topics such as college applications, financial aid, and test
                                    preparation.
                                </p>
                                <p>
                                    <strong>Student Community: </strong> Connect with other students who
                                    are going through similar experiences, sharing advice and support.
                                </p>
                            </div>
                            <div className="pt-4 procedure ">
                                <div className="main-step">
                                    <div className="steps">
                                        <img src={stepchoice} alt="step" title="stepchoice" />
                                        <p> Step by Step Counseling with Expert</p>
                                    </div>
                                    <div className="steps">
                                        <img src={choice} alt="choice" title="step_collegepeddia" />
                                        <p> Choice Filling by Expert</p>
                                    </div>
                                </div>
                                <div className="steps">
                                    <img src={counselling} alt="counselling" title="counsellinng" />
                                    <p> 1 to 1 Counseling by Expert on Call / Zoom Meet</p>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Guidance;