import React, {  useState } from 'react'
import Predictorform from '../Collegepredictor/Predictorform';
const Collegeperdictorfrom = () => {

  const [show, setShow] = useState(false);
  const handleform = () => { setShow(!show) }


  return (
    <>
      <section className='predict_your_college'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='pre_btn'>
                <button type="button" onClick={() => handleform()} className="btn btn-primary"  >
                  Predit Now
                </button>
              </div>
              {show ? (
                <div className="bg-black">
                  {/* body */}
                 <Predictorform handleform={handleform} />
                </div>

              ) : (
                null
              )}
            </div>
          </div>
        </div>
      </section>

    </>
  );
}
export default Collegeperdictorfrom
