import React, { useEffect, useMemo, useState } from "react";
import { FaBars, FaAward, FaUser, FaUserCircle, FaPen } from "react-icons/fa";
import { FaDatabase, FaCheck, FaTimes, } from "react-icons/fa";
import { IoMdNotifications } from "react-icons/io";
import collegepedia from "../../assets/images/Logo.png";
import { NavLink, useNavigate, Outlet, Link } from "react-router-dom";
import { FaUniversity } from "react-icons/fa";
import "../CollegeDashboard/collegedahboard.css"
import { MdOutlinePending, MdSettingsSuggest } from "react-icons/md";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Swal from "sweetalert2";
import picimage from '../../assets/images/Bangalore.png';
import { IoLogOut } from "react-icons/io5";
import { TbWorldWww } from "react-icons/tb";
import Loading from "../../Page/Loading";
import axios from "axios";

const Header = () => {
    const [isOpen, setIsOpen] = useState(true);
    const [isopen1, setIsOpen1] = useState(true);
    const user = JSON.parse(localStorage.getItem('user')) || {};
    const token = useMemo(() => localStorage.getItem('token') || '', []);
    const [Profiledata, setProfiledata] = useState('');
    const [loading, setLoading] = useState(true);
    const toggle = () => setIsOpen(!isOpen);

    const toggleCollapsible1 = () => {
        setIsOpen1(!isopen1);
    };
    const navigate = useNavigate();
    const logoutfun = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('path');
        localStorage.removeItem('userprofile');
        Swal.fire({
            position:'top-end',
            icon: 'success',
            timer:3000,
            text: 'Logout successfully!',
        });
        navigate('/')
    };
    const headers = useMemo(() => ({
        Authorization: `JWT ${token}`,
    }), [token]);

    useEffect(() => {
        const fetchData = async () => {
            const id = user.id;
            try {
                const Response = await axios.get(`https://portal.mycollegepedia.com/account/student/${id}/`, {
                    headers,
                });
                setProfiledata(Response.data);
                setLoading(false)

            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('token');
                    Swal.fire({
                        icon: 'error',
                        title: 'Please try again Login',
                        text: 'There was an error.'
                    });
                    navigate('/login');
                }
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [headers, navigate,  user.id]);
    if (loading) {
        return <div>
            <div className="p-5">
                <Loading />
            </div>
        </div>;
    }
    return (
        <>
            <div className="Newtopbar_dashbord">
                <Navbar expand="lg" className='Newtopbar'>
                    <Navbar id="basic-navbar-nav">
                    <div style={{ marginLeft: isOpen ? "250px" : "50px" }} className="bars outerbar">
                            <FaBars onClick={toggle} />
                        </div>
                        <Nav className="ms-auto">
                            <div className="first_dropdown">
                                <NavDropdown title={<span className="usericon"><IoMdNotifications />
                                </span>}>
                                    <NavDropdown.Item >
                                        <div className="comments">
                                            <div className="pic">
                                                <img src={picimage} title="student profile" alt="static profile" />
                                                <div className="content-wrapper">
                                                    <p>comment saaaaaa sfgsfg sg dgg aaaa</p>
                                                </div>
                                            </div>
                                        </div>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item  className="card">
                                        <div className="comments">
                                            <div className="pic">
                                                <img src={picimage} title="student profile" alt="static" />
                                                <div className="content-wrapper">
                                                    <p>comments aaaaaa sfgsfg sg dgg aaaa</p>
                                                </div>
                                            </div>
                                        </div>
                                    </NavDropdown.Item>
                                 
                                 
                                    <NavDropdown.Item >
                                        <button className="btn" >view more</button>
                                    </NavDropdown.Item>

                                </NavDropdown></div>


                            <div className="second_dropdown">
                                <NavDropdown title={<span className="usericon">  <img src={Profiledata.profile || <FaUser /> } alt={Profiledata.name || 'profile'} title={Profiledata.name || "user-profile"} className="w-[30px] h-[30px]  " /></span>}>
                                    <NavDropdown.Item className="flex items-center gap-2" as={Link} to="/student-dashboard/profile">
                                        {Profiledata.name || '----'}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item className="flex items-center gap-2" as={Link} to="/student-dashboard/home">
                                        <FaUserCircle />   Profile
                                    </NavDropdown.Item>
                                    <NavDropdown.Item className="flex items-center gap-2" as={Link} to="/">
                                        <TbWorldWww />   Visit site
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} onClick={logoutfun} className="flex items-center">
                                        <IoLogOut />  Log-out
                                    </NavDropdown.Item>
                                </NavDropdown>
                                </div>
                        </Nav>
                    </Navbar>
                </Navbar>
            </div>
            <div className="containe">
                <div style={{ width: isOpen ? "270px" : "75px" }} className="sidebarbar">
                    <div className="top_section">
                        <Link to="/" className="logo">
                            <img src={collegepedia} title="student profile" alt='Mycollegepedia' />
                        </Link>
                        <div style={{ marginLeft: isOpen ? "10px" : "10px" }} className="bars innerbar">
                        <FaBars onClick={toggle} />
                    </div>
                    </div>
                    <NavLink to="/student-dashboard/home" className="mt-2 link" activeclassName="active">
                        <div className="icon"><FaUniversity className="text-[22px]" /> </div>
                        <div className={` link_text ${isOpen ? "block" : "none"}`} >  Dashboard </div>
                    </NavLink>

                    <h2 className="headings text-muted  ">
                        <div className="icon">  <MdSettingsSuggest className="text-[22px]" />  </div>
                        <div className={` ${isOpen ? "block" : "none"}`} >  <strong> Genral Settings</strong>  </div>
                    </h2>
                    <NavLink to='/student-dashboard/profile' className="mt-2 link" activeclassName="active">
                        <div className="icon">    <FaAward className="text-[22px]" />  </div>
                        <div className={`bottomline link_text ${isOpen ? "block" : "none"}`} >  Profile-update
                        </div>
                    </NavLink>
                    {/* <NavLink to='/student-dashboard/student-profile' className="mt-2 link" activeclassName="active">
                    <div className="icon">    <FaAward className="text-[22px]" />  </div>
                    <div  className={`bottomline link_text ${isOpen ? "block" : "none"}`} >  student-Profile
                    </div>
                </NavLink> */}
                <h2 className="headings text-muted  ">
                        <div className="icon">  <FaDatabase />   </div>
                        <div className={` ${isOpen ? "block" : "none"}`} >  <strong> Appliaction Enquiry</strong>  </div>
                    </h2>
                    <NavLink to='/student-dashboard/appliedcollege' onClick={toggleCollapsible1}  className="mt-2 link" activeclassName="active">
                        <div className="icon">   <FaPen className="text-[23px]" /> </div>
                        <div className={`bottomline link_text ${isOpen ? "block" : "none"}`} id="basic-nav-dropdown"  >Application</div>
                        {/* <div className="arrow-icon-dash "> {isopen1 ? <IoIosArrowUp /> : <IoIosArrowDown />}  </div> */}
                    </NavLink>
                    
                      
                        <NavLink
                        to="/student-dashboard/student-pending"
                        className="link mt-2"
                        activeClassName="active"
                    >
                        <div className="icon"><MdOutlinePending /></div>

                        <span className="link_text">Pending</span>
                    </NavLink>
                    <NavLink
                        to="/student-dashboard/student-accepted"
                        className="link  mt-2"
                        activeClassName="activelink"
                    >
                        <div className="icon"> <FaCheck /></div>
                        <span className="link_text">Accepted</span>
                    </NavLink>
                    <NavLink
                        to="/student-dashboard/student-rejected"
                        className="link mt-2"
                        activeClassName="activelink"
                    >

                        <div className="icon"> <FaTimes /></div>

                        <span className="link_text">Rejected</span>
                    </NavLink>
                

                </div>
                <main className={`ml-0 ${isOpen ? "md:!ml-[270px]" : "md:!ml-[75px]"}`} >
                    <Outlet />
                </main>
            </div>
        </>

    );
};

export default Header;
