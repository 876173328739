import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

import { TiArrowBack } from 'react-icons/ti';
import RichText from '../../CollegeDashboard/Subpages/Richtext';

const UpdateNewsArticles = ({ handleFunction, token, Slug, category }) => {


    const [formData, setFormData] = useState({
        college: null,
        title: '',
        post: '',
        image: null,
        status: '',
        course_category: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://portal.mycollegepedia.com/counsellor/news/${Slug}/`, {
                    headers: {
                        Authorization: `JWT ${token}`,
                    },
                });
                const { title, post, image, status, course_category, college } = response.data;
                setFormData({ title, post, image, status, course_category, college });
            } catch (error) {
                console.error('Error fetching award:', error);
            }
        };
        fetchData();
    }, [Slug, token]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            image: imageFile,
        }));
    };

    const handleRichTextChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('title', formData.title);
            formDataToSend.append('status', formData.status);
            formDataToSend.append('course_category', formData.course_category);
            formDataToSend.append('post', formData.post);
            if (formData.image && typeof formData.image !== 'string') {
                formDataToSend.append('image', formData.image);
            }

            const response = await axios.patch(`https://portal.mycollegepedia.com/counsellor/news/${Slug}/`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `JWT ${token}`,
                },
            });
            handleFunction();
            if (response.status === 200) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    }
                });
                Toast.fire({
                    icon: "success",
                    title: "Update news&article successfully"
                });

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'There was an error in updating the data. Please try again.',
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'There was an error. Please try again.',
            });
        }
    };

    return (
        <div>
            <div className='justify-end d-flex my-2'>
                <button title='no-update' onClick={() => handleFunction()} className="btn-sm shadow-sm flex gap-1.5  text-white items-center border bg-[#052963]">
                    <TiArrowBack />
                    Back
                </button>

            </div>

            <form onSubmit={handleSubmit} encType="multipart/form-data" className='common_dash_form p-3'>
                <div className="row">
                    <div className='col-lg-6 col-md-12'>
                        <div className="form-group">
                            <label>Title</label>
                            <input
                                type="text"
                                name="title"
                                className="form-control"
                                placeholder="Title"
                                required
                                value={formData.title}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                        <div className="form-group">
                            <label>Image</label>
                            <input
                                type="file"
                                name="image"
                                className="form-control"
                                accept="image/*"
                                onChange={handleImageChange}
                            />
                            {formData.image && (
                                <div className="mb-3">
                                    <img src={formData.image} title={formData.title} alt="ImagePreview" className="img-preview" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                        <div className="form-group">
                            <label>Course Category</label>
                            <select
                                name="course_category"
                                className="form-control"
                                value={formData.course_category}
                                onChange={handleChange}
                            >
                                <option value="">Select Category</option>
                                {category.map((category) => (
                                    <option key={category.id} value={category.id}>
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>

                        <div className="form-group">
                            <label>Status </label>
                            <select
                                name="status"
                                className="form-control"
                                value={formData.status}
                                onChange={handleChange}
                            >
                                <option value="PUBLIC">Public</option>
                                <option value="DRAFT">Draft</option>
                            </select>
                        </div>

                    </div>
                    <div className='col-lg-12 col-md-12'>
                        <div className="form-group">
                            <label>post</label>
                            <RichText
                                name="post"
                                placeholder="post"
                                value={formData.post}
                                onChange={handleRichTextChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">

                    <div className='col-lg-12 col-md-12'>
                        <div className='d-flex justify-content-end col-lg-12 col-sm-6'>
                            <button type="submit" className="btn button_of_submit">Update</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default UpdateNewsArticles;