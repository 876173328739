import React, { useState, useEffect, useContext, Suspense } from 'react';
import { Link } from "react-router-dom";
import Apicontext from "../../Page/Contextapi";
// import Collegeperdictorfrom from './Collegeperdictorfrom';
import { FaArrowRight } from 'react-icons/fa';
import LazyLoad from 'react-lazyload';
import StarRating from './Startrating';

const University = () => {
   const [Card, setCard] = useState(3);
   const [Loading, setLoading] = useState(true);
   const [filterCollege, setFilteredColleges] = useState([]);
   const { APIdata } = useContext(Apicontext);

   const handleViewMore = () => {
      setCard(Card + 3);
   };

   useEffect(() => {
      if (APIdata?.college) {
         const filtered = APIdata.college
            .filter(college => college.organization_type.name === 'University')
            .reverse();
         setFilteredColleges(filtered);
         setLoading(false);
      }
   }, [APIdata]);

   if (Loading) {
      return null;
   }

   return (
      <>
         <div id="Colleges" className="container ">
            <p className="web-colleges-hadding reveal md:my-6 md:mx-3">Best Autonomus Colleges</p>
         </div>
         <div className="container colleges-main-section">
            <div className="container">
           
                  <div className="row">
                     { filterCollege && filterCollege.length > 0 && filterCollege.slice(0, Card).map((college) => (
                        <div className="col-lg-4 col-md-6" key={college.id}>
                           <div className="card_college">
                              <img className="card-img-top mba-img" src={college.image} alt={college.name} title={college.name} />
                              <div className="card-body">
                                 <div className="main d-flex align-item-center">
                                    <div className="logocard">
                                       <Suspense key={college.id + "_suspense"}>
                                          <LazyLoad height={200} offset={100} once key={college.id + "_lazyload"}>
                                             <img src={college.logo} title={college.name} alt={college.name} loading='lazy' key={college.id + "_image"} />
                                          </LazyLoad>
                                       </Suspense>
                                    </div>
                                 </div>
                                 <div className="d-flex head">
                                    <div className="mba">
                                       <h6 className="title">{college.course_category[0].name}</h6>
                                    </div>
                                    <div className="mba1">
                                       <h6 className="title d-flex text-warning">
                                          <StarRating rating={college.rating} />
                                       </h6>
                                    </div>
                                    <small className='small-c'>{college.college_type.name}</small>
                                 </div>
                                 <div>
                                    <div className="card-pera" key={college.id + "_cardpera"}>
                                       <Link to={`college/${college.slug}`} title={college.name} className=''>
                                          <h4>{college.name}</h4>
                                       </Link>
                                    </div>
                                    <small className="pt-1 adress">
                                       <span>{college.current_address}</span>
                                    </small>
                                    <div className="link">
                                       <Link to={`college/${college.slug}`} title={college.name} className="py-2">View More.. <FaArrowRight /></Link>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     ))}
                  </div>
            
            </div>
            <div className="overflow-hidden look-more-srction">
               <button onClick={handleViewMore} className="look-more-button">View More</button>
            </div>

         </div>
      </>
   );
}
export default University;