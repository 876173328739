
import TopSection from './Top_section';
import College from '../Colleges/Collegesection';
import Confusedsection from './Confusedsection';
// import Exploresection from './Exploresection';
// import Topstudy from './Topstudy';
import Guidance from './Guidance';
import Blog from './Blog';
// import ScrollReveal from 'scrollreveal';
import { useContext, useEffect, useState } from 'react';
import Testimonials from './Testimonials';
// import Sidebar from './sidebar';
import { Helmet } from 'react-helmet';
import Herosection from './Herosection';
// import Predictwork from "./Predictedwork";
import Collegeperdictorfrom from '../Colleges/Collegeperdictorfrom';
// import Exams from '../Exams/Exams';
// import Collegegovt from './Collegegovt';
// import Category from '../Course/Categrory';
import Apicontext from '../../Page/Contextapi';
import Landingsection from './Landingsection';
import University from './University';
import Counsellorcards from './Counsellorcards';

const Home = () => {
  const [detail, setDetails] = useState(null);
  const contextData = useContext(Apicontext);


  useEffect(() => {
    const data = contextData.APIdata;
    setDetails(data.detail);
  }, [contextData]);

  useEffect(() => {
    const allNodes = document.querySelectorAll('*');
    allNodes.forEach(node => {
      node.removeAttribute('style');
    });
  }, []);
  // console.log(detail)
  return (
    <>
      <Helmet>
        <title>{detail && detail.meta_title ? detail.meta_title : 'MyCollegePedia: India s Top College Platform'}</title>
        <meta name="description" content={detail && detail.meta_description ? detail.meta_description : ""} />
        <meta property="og:title" content={detail && detail ? detail.title : "MyCollegePedia"} />
        <meta name="keywords" content={detail && detail.meta_keyword ? detail.meta_keyword : "collegepedia,collegeduniya, colleges, exams, courses ,All india colleges, MyCollegePedia"} />
        {/* <meta property="og:image" content={detail && detail.logo ? detail.logo : ''} /> */}
        {detail && detail.author ? <meta name="author" content={detail.author} /> : <meta name="author" content="Gurjar Rohit Patel" />}
      </Helmet>

      {/* <Sidebar /> */}
     
      <Landingsection />
      <TopSection />
      <Herosection />
      <College />
      <Collegeperdictorfrom />
      <University />
      {/* <Predictwork /> */}
      {/* <Exploresection /> */}
      {/* <Topstudy /> */}
      {/* <Category /> */}
      {/* <Collegegovt /> */}
      <Confusedsection />
      <Counsellorcards />
      <Guidance />
      <Blog />
      <Testimonials />
    </>
  );
}
export default Home;