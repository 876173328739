
import React from "react";
import { createChatBotMessage } from "react-chatbot-kit";
import { Link } from "react-router-dom";
import UserProfileWidget from "./UserProfileBot";
// import CollegeList from "./CollegeList";
const botAvatarUrl = 'https://img.freepik.com/free-vector/creative-nerd-logo-template_23-2149218771.jpg?t=st=1716555147~exp=1716558747~hmac=99f3e22489e24eaf9de2901be073a0f51f7af41aa7d8fb6e3feaa497d77d1730&w=740';
const botName = "Vedvani";

const config = {
  botName: botName,

  customAction: {
    setCollegeList: (state, setState, colleges) => {
      setState({ ...state, collegeList: colleges });
    },
    setStateData: (state, setState, Statelist) => {
      setState({ ...state, StateData: Statelist });
    },
  },

  state: {
    CollegeList: [],
    StateData: [],
  },

  customComponents: {
  botAvatar: (props) => <img src={botAvatarUrl} title={botName} alt={botName} style={{ width: '50px', height: '50px', borderRadius: '50%' }} />,

    header: () => (
      <div className="chatbot_main_heading">
        MYcollegePedia{" "}
      </div>
    ),
   
  },

  customStyles: {
    botMessageBox: {
      backgroundColor: "#052960",
    },
    chatButton: {
      backgroundColor: "#376B7E",
    },
  },
  initialMessages: [
    createChatBotMessage(`Hi I'm ${botName}`, {
      loading: 100,
    }),
    createChatBotMessage("What do you want to know about ? ", {
      widget: "intialmessge_chat",
      delay: 500,
    }),
  ],

  widgets: [
    {
      widgetName: "intialmessge_chat",
      widgetFunc: (props) => (
        <div className="text-end">
          <button className="btn chatbot_ans_btn mx-2" onClick={() => props.actions.college_option()}>
            College
          </button>
          <button className="btn chatbot_ans_btn mx-2" onClick={() => props.actions.exam_option()}>
            Exam
          </button>
        </div>
      ),
    },
    {
      widgetName: "types_of_college",
      widgetFunc: (props) => (
        <div className="text-end">
          <button className="btn chatbot_ans_btn mx-2" onClick={() => props.actions.selectCollegeType('Government')}>
            Government
          </button>
          <button className="btn chatbot_ans_btn mx-2" onClick={() => props.actions.selectCollegeType('Private')}>
            Private
          </button>
        </div>
      ),
    },
    {
      widgetName: "location_college",
      widgetFunc: (props) => (
        <div className="text-end">
          <select className="form-select btn mx-1" onChange={(e) => props.actions.locationSelected(e.target.value)}>
            <option value="">Select Location</option>
            {props.state.StateData && props.state.StateData.map((location) => (
              <option value={location.name} key={location.id}>{location.name}</option>
            ))}
          </select>
        </div>
      ),
    },
    {
      widgetName: "course_college",
      widgetFunc: (props) => (
        <div className="text-end">
          <select className="form-select btn mx-1" onChange={(e) => props.actions.courseSelected(e.target.value)}>
            <option value="">Select Course</option>
            {props.state.courseData && props.state.courseData.map((data) => (
              <option value={data.name} key={data.id}>{data.name}</option>
            ))}
          </select>
        </div>
      ),
    },


    {
      widgetName: "colleges",
      widgetFunc: (props) => (
        <div>
          {console.log("college list filter ", props.state)}
          {props.state.CollegeData && props.state.CollegeData.length > 0 ? (
            props.state.CollegeData.map((college) => (
              <div key={college.id} className="py-2 ">
                 <p className="text-sm flex-wrap"><Link to={`/college/${college.slug}`} title={college.name} className="hover:text-[orange] hover:pl-1 duration-2 ease-in-out" >{college.name}</Link></p>
               
              </div>
            ))
          ) : (
            <p>No colleges found.</p>
          )}
        </div>
      ),
    },
    {
      widgetName: "all-colleges",
      widgetFunc: (props) => (
        <div>
          {props.state.collegeList && props.state.collegeList.length > 0 ? (
            props.state.collegeList.map((college) => (
              <div key={college.id} className="py-1">
                 <p className="text-md flex-wrap"><Link to={`/college/${college.slug}`} className="hover:text-[orange] hover:pl-1 duration-2 ease-in-out" title={college.name}>{college.name}</Link></p>
              </div>
            ))
          ) : (
            <p>No colleges found.</p>
          )}
        </div>
      ),
    },
    {
      widgetName: "degree_type",
      widgetFunc: (props) => (
        <div className="text-end">
          <button className="btn chatbot_ans_btn mx-2" onClick={() => props.actions.handleCollege()}>
            UG
          </button>
          <button className="btn chatbot_ans_btn mx-2" onClick={() => props.actions.handleCollege("PG")}>
            PG
          </button>
        </div>
      ),
    },
    {
      widgetName: "college_list",
      widgetFunc: (props) => (
        <div className="text-[blue] text-sm px-2">
          {props.state.collegeList && props.state.collegeList.map((college) => (
            <p key={college.id} className="text-sm flex-wrap"><Link to={`/college/${college.slug}`} title={college.name} >{college.name}</Link></p>
          ))}
        </div>
      ),
    },

    // exam widget start 
    {
      widgetName: "types_of_exam",
      widgetFunc: (props) => (
        <div className="text-end">
          <button className="btn chatbot_ans_btn mx-2" onClick={() => props.actions.examSelected('Government')}>
           govt-exam
          </button>
          <button className="btn chatbot_ans_btn mx-2" onClick={() => props.actions.examSelected('Private')}>
            Private-exam
          </button>
        </div>
      ),
    },
    {
      widgetName: "exams",
      widgetFunc: (props) => (
        console.log(props.state),
        <div>
          {props.state.exam && props.state.exam.length > 0 ? (
            props.state.exam.map((exam) => (
              <div key={exam.id} className="py-1 px-2">
                 <p className="text-sm flex-wrap"><Link to={`/exam/${exam.slug}`} title={exam.title} aria-label="exam view" className="hover:text-[orange] hover:pl-1 duration-2 ease-in-out" >{exam.exam.title}</Link></p>
                {/* <p>{exam.title}</p> */}
              </div>
            ))
          ) : (
            <p >No exams found.</p>
          )}
        </div>
      ),
    },


    // profile updaate 
    {
      widgetName: 'userProfile',
      widgetFunc: (props) => <UserProfileWidget {...props} />,
    },
    
  ],



};

export default config;