import React, { useState, useEffect, useContext, Suspense } from 'react';
import { Link } from "react-router-dom";
import Apicontext from "../../Page/Contextapi";
import { FaArrowRight } from 'react-icons/fa';
import LazyLoad from 'react-lazyload';
import StarRating from '../Homepages/Startrating';

const College = () => {
   const [Card, setCard] = useState(3);
   const [Loading , setLoading] = useState(true);
   const [filterCollege, setFilterCollege] = useState([]);
   const contextdata = useContext(Apicontext);

   const handleViewMore = () => {
      setCard(Card + 3);
   };

   useEffect(() => {
      const APIdata = contextdata.APIdata;

      // Filter the colleges by organization_type (only 'College' data)
      if (APIdata.college) {
         const filtered = APIdata.college.filter(college => college.organization_type.name === 'College');
         const reversedFiltered = [...filtered].reverse();
         setFilterCollege(reversedFiltered);
         setLoading(false);
      }
   }, [contextdata]);

   if (Loading) {
      return (<div>Loading....</div>);
   }

   return (
      <>
         <div id="Colleges" className="container">
            <p className="web-colleges-hadding reveal md:mb-5 mx-3">Colleges</p>
         </div>
         <div className="container colleges-main-section">
            <div className="container md:mt-5">
               {filterCollege && filterCollege.length > 0 && (
                  <div className="row">
                     {filterCollege.slice(0, Card).map((college,i) => (
                        <div className="col-md-6 col-lg-4" key={i}>
                           <div className="card_college">
                              <img className="card-img-top mba-img" src={college.image} alt={college.name} title={college.name} />
                              <div className="card-body">
                                 <div className="main d-flex align-item-center">
                                    <div className="logocard">
                                       <Suspense key={college.id + "_suspense"}>
                                          <LazyLoad height={200} offset={100} once key={college.id + "_lazyload"}>
                                             <img src={college.logo} title={college.name} alt={college.name} loading='lazy' key={college.id + "_image"} />
                                          </LazyLoad>
                                       </Suspense>
                                    </div>
                                 </div>
                                 <div className="d-flex head">
                                    <div className="mba">
                                       <h6 className="title">{college.course_category[0].name}</h6>
                                    </div>
                                    <div className="mba1">
                                       <h6 className="title d-flex text-warning">
                                       <StarRating rating={college.rating} />
                                       </h6>
                                    </div>
                                    <small className='small-c'>{college.college_type.name}</small>
                                 </div>
                                 <div>
                                    <div className="card-pera" key={college.id + "_cardpera"}>
                                    <Link to={`college/${college.slug}`} title={college.name} className=''>
                                       <h4>{college.name}</h4>
                                    </Link>
                                    </div>
                                    <small className="pt-1 adress">
                                       <span>{college.current_address}</span>
                                    </small>
                                    <div className="link">
                                       <Link to={`college/${college.slug}`} title={college.name} className="py-2">View More.. <FaArrowRight /></Link>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     ))}
                  </div>
               )}
            </div>
            <div className="overflow-hidden look-more-srction">
               <button onClick={handleViewMore} className="look-more-button">View More</button>
            </div>
            {/* <Collegeperdictorfrom /> */}
         </div>
      </>
   );
}

export default College;