const StarRating = ({ rating }) => {
    const maxRating = 5;
    const filledStars = Math.round(rating); // Round the rating to the nearest whole number
    const emptyStars = maxRating - filledStars;
  
    const renderFilledStars = () => {
      const stars = [];
      for (let i = 0; i < filledStars; i++) {
        stars.push(
          <li key={i}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 96 960 960"
              className="w-5 text-warning"
            >
              <path
                fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
              />
            </svg>
          </li>
        );
      }
      return stars;
    };
  
    const renderEmptyStars = () => {
      const stars = [];
      for (let i = 0; i < emptyStars; i++) {
        stars.push(
          <li key={filledStars + i}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 96 960 960"
              className="w-5 text-gray-300" // Use a different color for empty stars
            >
              <path
                fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
              />
            </svg>
          </li>
        );
      }
      return stars;
    };
  
    return (
      <ul className="flex">
        {renderFilledStars()}
        {renderEmptyStars()}
      </ul>
    );
  };
  export default StarRating;
  