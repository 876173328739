import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';

function About_mission() {
  const [aboutData, setAboutData] = useState({});

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await axios.get('https://portal.mycollegepedia.com/site/about/');
        setAboutData(response.data);
      } catch (error) {
        console.error('Error fetching about data:', error);
      }
    };
    fetchAboutData();
  }, []);

  return (
    <section>
      <Helmet>
        <title>{aboutData && aboutData.meta_title ? aboutData.meta_title : 'MyCollegePedia: India s Top College Platform'}</title>
        <meta name="description" content={aboutData && aboutData.meta_description ? aboutData.meta_description : ""} />
        <meta property="og:title" content={aboutData && aboutData ? aboutData.title : "MyCollegePedia"} />
        <meta name="keywords" content={aboutData && aboutData.meta_keyword ? aboutData.meta_keyword : "collegepedia,collegeduniya, colleges, exams, courses ,All india colleges, MyCollegePedia"} />
        <meta property="og:image" content={aboutData && aboutData.logo ? aboutData.logo : ''} />
        {aboutData && aboutData.author ? <meta name="author" content={aboutData.author} /> : <meta name="author" content="Gurjar Rohit Patel" />}
      </Helmet>
      <div className='container mb-5'>
        <div className='row'>
          <div className='about_heading' dangerouslySetInnerHTML={{ __html: aboutData.description }} />
        </div>
      </div>
    </section>
  );
}

export default About_mission;
