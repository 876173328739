import React, { useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Swal from 'sweetalert2';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { TiArrowBack } from 'react-icons/ti';
import StarRating from './Subpages/StarRatingInput';


const TesitmonialAdd = () => {
    const token = useMemo(() => localStorage.getItem("token") || "", []);
    const CounsellorId = useMemo(() => JSON.parse(localStorage.getItem("counsellor_id") || "", []));
    const [formData, setFormData] = useState({
        id: "",
        name: "",
        rating: "",
        counsellor: CounsellorId || '',
        description: "",
    });
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleRatingChange = (value) => {
        
        setFormData({
          ...formData,
          rating: value,
        });
      };
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('name', formData.name)
            formDataToSend.append('rating', formData.rating);
            formDataToSend.append('counsellor', CounsellorId);
            formDataToSend.append('description', formData.description);
            const response = await axios.post('https://portal.mycollegepedia.com/counsellor/counsellor-testimonial/', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `JWT ${token}`,
                },
            });
            console.log(response)

            if (response.status === 201) {
                Swal.fire({
                    position: "top-end",
                    icon: 'success',
                    timer:2500,
                    text: 'Data submitted successfully!',
                });
                setFormData(response.data)
            } else {
                if (response.status === 401) {
                    localStorage.removeItem('token');
                }
                Swal.fire({
                    icon: 'error',
                    title: 'There was an error in form submission. Please try again.',
                });
            }
            navigate("/counsellor-dashboard/Testimonial")
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'There was an error Please try again.',
            });
            navigate("/counsellor-dashboard/Testimonial")
            console.error('Error adding award:', error);
        }
    };
    return (
        <div className='container tes common_dash common_dash_test my-3'>
            <div className="row align-items-center top mb-2 ">
                <div className="col-lg-6">

                    <h3>Testimonials</h3>
                </div>
                <div className="col-lg-6">
                    <Breadcrumb className="bread">
                        <Breadcrumb.Item ><NavLink to="/counsellor-dashboard">Home</NavLink></Breadcrumb.Item>
                        <Breadcrumb.Item active>Add-director</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className="row">
                <div className="col-12 d-flex justify-content-end">
                    <Link to={"/counsellor-dashboard/Testimonial"} className='btn bg-[#052963] d-flex align-items-center text-white' title={"no add"}><TiArrowBack /> Back</Link>
                </div>
            </div>
            <div className='row'>
                <Modal.Body>
                    <div className="card border shadow">
                        <form onSubmit={handleSubmit} className='common_dash_form p-3' >
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="name">Name</label>
                                            <input type="text" className="form-control" id="name" placeholder="Enter your Company Name" name="name" onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="rating">Rating</label>
                                            
                                            <StarRating onChange={handleRatingChange} />
                                            
                                        </div>
                                    </div>

                                    <div className='col-md-12'>
                                        <label htmlFor="description">Description</label>
                                        <textarea className="form-control" id="description" rows="5" name="description" onChange={handleInputChange}  ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-end mr-3'>
                            <Button className="save_director" type='submit'>
                                Save
                            </Button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </div>
        </div>
    )
}

export default TesitmonialAdd