import React from 'react';

const CompanyInfo = ({ formData, handleInputChange}) => {

    
    return (
        <div className='row'>
            <div className="col-md-6 col-lg-4 form-group">
                <label>Company Name <span className='text-danger'>*</span></label>
                <input
                    className="form-control"
                    type="text"
                    name="company_name"
                    value={formData.company_name}
                    onChange={handleInputChange}
                    required
                />
            </div>
            <div className="form-group col-md-6 col-lg-4">
                <label>Logo</label>
                <input
                    className="form-control"
                    type="file"
                    name="logo"
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group col-md-6 col-lg-4 ">
                <label>Image</label>
                <input
                    className="form-control"
                    type="file"
                    name="image"
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group col-md-6 col-lg-4">
                <label>Email <span className='text-danger'>*</span></label>
                <input
                    className="form-control"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                />
            </div>
            <div className="form-group col-md-6 col-lg-4">
                <label>Primary Mobile <span className='text-danger'>*</span></label>
                <input
                    className="form-control"
                    type="number"
                    maxLength={12}
                    name="primary_mobile"
                    value={formData.primary_mobile}
                    onChange={handleInputChange}
                    required
                />
            </div>
            <div className="form-group col-md-6 col-lg-4">
                <label>Secondary Mobile</label>
                <input
                    className="form-control"
                    type="number"
                    maxLength={12}
                    name="secondary_mobile"
                    value={formData.secondary_mobile}
                    onChange={handleInputChange}
                />
            </div>

            <div className="form-group col-md-6 col-lg-4">
                <label>Website</label>
                <input
                    className="form-control"
                    type="url"
                    name="website"
                    placeholder='https://xyz.com/'
                    value={formData.website}
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group col-md-6 col-lg-4">
                <label>Registration Date</label>
                <input
                    className="form-control"
                    type="date"
                    name="registration_date"
                    value={formData.registration_date}
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group  col-md-6 col-lg-4">
                <label>GST</label>
                <input
                    className="form-control"
                    type="number"
                    maxLength={15}
                    name="gst"
                    value={formData.gst}
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group col-md-6 col-lg-4">
                <label>Company Certificate</label>
                <input
                    className="form-control"
                    type="file"
                    name="company_certificate"
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group col-md-6 col-lg-4">
                <label>PAN Number</label>
                <input
                    className="form-control"
                    type="text"
                    name="pan_no"
                    value={formData.pan_no}
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group col-md-6 col-lg-4">
                <label>PAN Card</label>
                <input
                    className="form-control"
                    type="file"
                    name="pan_card"
                    onChange={handleInputChange}
                />
            </div>

        </div>
    );
};

export default CompanyInfo;