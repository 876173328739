import best_career from "../../assets/images/best-career.png"
import shape2 from "../../assets/images/shape2.svg"
import shape3 from "../../assets/images/shape3.svg"
import shape1 from "../../assets/images/shape1.svg"
import { Link } from "react-router-dom"
const Confusedsection = () => {
 
   return (
  
     <section className="mt-5 best-career-section">
        {/* best-career start */}
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="best-career-heading">
                <h4>
                  Confused about the best <span>career-fit</span> for you?
                </h4>
                <p className="mt-5">
                  <strong> My Collegepedia :</strong> Offers a range of services and
                  resources to assist you on your path to higher education and a
                  rewarding career
                </p>
                <p className="mt-2">
                  <strong> College Search:</strong> Discover a database of colleges
                  and universities, including details on programs, admissions, and
                  campus life.
                </p>
                <p className="mt-2">
                  <strong>Career Exploration:</strong> Explore various career
                  options, learn about job trends, and find out which professions
                  align with your interests and skills.
                </p>
                <p className="mt-2">
                  <strong>Expert Advice:</strong> Benefit from the insights of
                  career counselors and academic advisors who can help you make
                  informed choices.
                </p>
              </div>
              <div className="mt-5 mb-2 best-career-btn">
                <Link  to={"/all-counsellor/"} title="View counsellor" className="btn btn-info">
                  Consult Now <i className="fa-solid fa-arrow-right-long" />{" "}
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="best-career-img" >
                <img src={best_career} className="img-fluid" alt="career" title="career" />
              </div>
              <div className="best-img3">
                <img src={shape1} className="img-fluid" alt="shape1" title="shape1" />
              </div>
              <div className="best-img">
                <img src={shape2} className="img-fluid" alt="shpae2" title="shape2" />
              </div>
              <div className="best-img1">
                <img src={shape3} className="img-fluid" alt="shape3" title="shape3" />
              </div>
            </div>
          </div>
        </div>
      </section>
     
   );
}
export default Confusedsection;