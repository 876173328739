import React, { useState, useEffect, useMemo, useContext } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Modal } from "react-bootstrap";
import Apicontext from '../../Page/Contextapi';
import JoditEditor from 'jodit-react';
import { LinearProgress } from '@mui/material';
import { TiArrowLeft } from "react-icons/ti";
import { useNavigate } from 'react-router-dom';

const CollelegeEligibilty = ({ handleopen }) => {
    const token = useMemo(() => localStorage.getItem('token') || '', []);
    const college = JSON.parse(localStorage.getItem('collegerecord'));
    const [exam, setExam] = useState([]);
    const [category, setCategory] = useState([]);
    const [subcategoryOptions, setSubcategoryOptions] = useState([]);
    const [selectsubcategory, setSelectedSubCategory] = useState('');
    const [stream, setStreamOptions] = useState([]);
    // const [streamall, setStreamall] = useState([]);
    const contextdata = useContext(Apicontext);

    const [formData, setFormData] = useState({
        id: '',
        category: '',
        course_subcategory: '',
        course_stream: '',
        college: college[0].id || '',
        exam: '',
        eligibility: '',
        description: '',
        application_start_date: null,
        application_end_date: null,
    });

    const navigate = useNavigate();
    const headers = useMemo(() => ({
        Authorization: `JWT ${token}`,
    }), [token]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("data ", formData)
        const formDataToSend = new FormData();
        formDataToSend.append('college', college.id)
        formDataToSend.append('exam', formData.exam);
        formDataToSend.append('category', formData.category);
        formDataToSend.append('course_subcategory', formData.course_subcategory);
        formDataToSend.append('course_stream', formData.course_stream);
        formDataToSend.append('eligibility', formData.eligibility);
        formDataToSend.append('description', formData.description);
        formDataToSend.append('application_start_date', formData.application_start_date);
        formDataToSend.append('application_end_date', formData.application_end_date);
        try {
            const response = await axios.post('https://portal.mycollegepedia.com/college/college-eligibility/', formData, { headers });
            if (response.status === 201) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    text: 'Data submitted successfully!',
                });
            } else {

                Swal.fire({
                    icon: 'error',
                    title: 'There was an error user not Unauthorized. Please try again.',
                });
            }
            handleopen()
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem("token");
                Swal.fire({
                    icon: "error",
                    title: "Please try again login.",
                    text: "There was  an error unauthorized.",
                });
                navigate("/login");
            }
            console.error('Error adding award:', error);
        }
    };
    useEffect(() => {
        const data = contextdata.APIdata;
        setSubcategoryOptions(data.subcategory)
        setCategory(data.castecategory)
        setExam(data.exam)
        // setStreamall(data.stream)
        const fetchSubcategories = async () => {
            if (selectsubcategory) {
                try {
                    const streamRes = await axios.get(`https://portal.mycollegepedia.com/general-setting/all_course_stream/?course_subcategory=${selectsubcategory}`, {
                        headers: {
                            Authorization: `JWT ${token}`,
                            'Content-Type': 'application/json',
                        },
                    });
                    setStreamOptions(streamRes.data || []);
                } catch (error) {
                    console.error('Error fetching subcategories:', error);
                }
            }
        };
        fetchSubcategories();
    }, [token, selectsubcategory, contextdata]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        const selectedstream = subcategoryOptions.find((cat) => cat.id == value);

        if (name === 'course_subcategory') {
            setSelectedSubCategory(selectedstream ? selectedstream.course_name : '');
        }
    };

    if (!exam) {
        return (
            <div className='container'>
                <div className='row'>
                    <p className="my-5 p-5"><LinearProgress />  </p>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className='justify-end row'>
            <button onClick={handleopen} title='back' className="btn-sm shadow-sm flex m-3 gap-1.5 float-right text-white items-center border bg-[#052963]">
            
                <TiArrowLeft /> back
            </button>
            </div>
            <Modal.Header closeButton>
            </Modal.Header>
            <div className="modal-body">
                <form className="p-3 common_dash_form" onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="row form-row">
                        <div className="col-12 col-sm-6">
                            <div className="form-group">
                                <label>Category</label>
                                <select
                                    className='form-control'
                                    id='Category'
                                    name='category'
                                    onChange={handleChange}
                                    value={formData.category}
                                >
                                    <option value=''>Select Category</option>
                                    {category &&
                                        category.map((category) => (
                                            <option key={category.id} value={category.id}>
                                                {category.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label htmlFor='subcategory'>Course Subcategory</label>
                                <select
                                    className='form-control'
                                    id='subcategory'
                                    name='course_subcategory'
                                    onChange={handleChange}
                                    value={formData.course_subcategory}
                                >
                                    <option value=''>Select Subcategory</option>
                                    {subcategoryOptions &&
                                        subcategoryOptions.map((subcategory) => (
                                            <option key={subcategory.id} value={subcategory.id}>
                                                {subcategory.course_name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label htmlFor='stream'>Course Stream</label>
                                <select
                                    className='form-control'
                                    id='stream'
                                    name='course_stream'
                                    onChange={handleChange}
                                    value={formData.course_stream}
                                >
                                    <option value=''>Select Stream</option>
                                    {stream &&
                                        stream.map((stream) => (
                                            <option key={stream.id} value={stream.id}>
                                                {stream.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="form-group">
                                <label>Exam</label>
                                <select
                                    className='form-control'
                                    name='exam'
                                    onChange={handleChange}
                                    value={formData.exam}
                                >
                                    <option value=''>Select exam</option>
                                    {exam &&
                                        exam.map((exam) => (
                                            <option key={exam.id} value={exam.id}>
                                                {exam.exam.title}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="form-group">
                                <label>Eligibility</label>
                                <input
                                    type="text"
                                    name="eligibility"
                                    className="form-control"
                                    placeholder="Eligibility Criteria"
                                    value={formData.eligibility}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-12 col-sm-6">
                            <div className="form-group">
                                <label>Start Date</label>
                                <input
                                    type="date"
                                    name="application_start_date"
                                    className="form-control"
                                    value={formData.application_start_date}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="form-group">
                                <label>End Date</label>
                                <input
                                    type="date"
                                    name="application_end_date"
                                    className="form-control"
                                    value={formData.application_end_date}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label>Description</label>

                                <JoditEditor value={formData.description} onChange={(value) => handleChange({ target: { name: 'description', value } })} placeholder="description a  here" />
                            </div>
                        </div>

                    </div>
                    <div className='d-flex justify-content-end'>
                    <button
                        type="submit"
                        className="btn button_of_submit">
                        Save Changes
                    </button>
                    </div>
                 
                </form>
            </div>
        </div>
    )
}

export default CollelegeEligibilty