import React, { useState, useEffect, useContext, Suspense } from 'react';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { FaArrowRight, FaFacebook, FaInstagram, FaLinkedin, FaStar, FaTelegram, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import LazyLoad from 'react-lazyload';
import colimg from "../../assets/images/Collegestaticimage.jpg"
import { FaXTwitter } from "react-icons/fa6";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Apicontext from '../../Page/Contextapi';
import StarRating from '../Homepages/Startrating';
const Collegepredictor = () => {
   const [exam, setExam] = useState([]);
   const [blogdata, setBlogdata] = useState([]);
   const [colleges, setColleges] = useState([]);
   const [populerBlog, setPopulerBlog] = useState(null);
   const [Topviewcollege, setTopviewcollege] = useState(null);
   const location = useLocation();
   const { collegeData } = location.state || '';
   const contextData = useContext(Apicontext);

   useEffect(() => {
      const fetch = async () => {
         const blogdata = contextData.APIdata.blog
         const clg = contextData.APIdata.college
         const exams = contextData.APIdata.exam
         setBlogdata(blogdata);
         setColleges(clg)
         setExam(exams)
      }
      fetch();
   }, [contextData])
   useEffect(() => {
      if (blogdata.length > 0) {
         const sortedBlogs = [...blogdata].sort((a, b) => b.views - a.views);
         const mostViewedBlogs = sortedBlogs.slice(0, 8);
         setPopulerBlog(mostViewedBlogs);
      }
      if (colleges.length > 0) {
         const sortedcollege = [...colleges].sort((a, b) => b.views - a.views);
         const mostViewedclg = sortedcollege.slice(0, 8);
         setTopviewcollege(mostViewedclg);
      }
   }, [blogdata, collegeData,colleges]);

   const owlOptions = {
      autoplay: true,
      rewind: true,
      transition: 500,
      dots: false,
      responsiveClass: true,
      // autoheight: true, 
      autoplayTimeout: 13000,
      smartSpeed: 5000,
      nav: true,
      navText: ["<", ">"],
      responsive: {
         0: { items: 1 },
         600: { items: 2 },
         1024: { items: 2 },
         1366: { items: 2 },
      },
   };

   return (
      <div>
         <div className="container pb-20 colleges-main-section">
            <div className="container mt-5">
               <div className='row'>
                  <div className='col-lg-9'>
                     <div className='main__card given_h'>
                        <div className='row'>
                           <div className='col-lg-12'>
                              <div className='long_border'>
                                 <h3>Predicted Colleges</h3>
                              </div>
                           </div>
                        </div>
                        {collegeData && collegeData.length > 0 && (
                           <div className="row">
                              {collegeData.map((college) => (
                                 <div className="col-md-6" key={college.id}>
                                    <div className="card_college">
                                       <img className="card-img-top mba-img" src={college.image || colimg} alt={college.name} title={college.name} loading='lazy' />
                                       <div className="card-body">
                                          <div className="main d-flex align-item-center">
                                             <div className="logocard">
                                                <Suspense key={college.id + "_suspense"}>
                                                   <LazyLoad height={200} offset={100} once key={college.id + "_lazyload"}>
                                                      <img src={college.logo || colimg} title={college.name} alt={college.name} loading='lazy' key={college.id + "_image"} />
                                                   </LazyLoad>
                                                </Suspense>
                                             </div>
                                          </div>
                                          <div className="d-flex head">
                                             <div className="mba">
                                                <h6 className="title"> {college.course_category[0].name}</h6>
                                                {/* <p>25.00 Lacks Total Fees</p> */}
                                             </div>
                                             <div className="mba1">
                                                <h6 className="title d-flex text-warning"> <StarRating rating={collegeData.rating} /></h6>
                                                {/* <p>51 Reviews</p> */}
                                             </div>

                                             <small className='small-c'> {college.organization_type.name}</small>
                                          </div>
                                          <div>
                                             <div className="card-pera" key={college.id + "_cardpera"}>
                                                <h4>{college.name}</h4>
                                             </div>
                                             <small className="pt-1 adress">
                                                <span > {college.current_address}</span>
                                             </small>
                                             <small className="pt-1 lowercase adress">

                                                {/* {collegeData.State.map((state, i) =>
                                          state.id === college.state ? (
                                             <span key={i}>
                                                {college.city}
                                                <span key={state.id}> {state.name}</span>
                                                <span className="web-loction-name"> India</span>
                                             </span>
                                          ) : null
                                       )} */}
                                             </small>
                                             <div className="link">
                                                <Link to={`college/${college.slug}`} title={college.name} className="py-2">View More <FaArrowRight /></Link>
                                                {/* <Link to={/broucher/${college.slug}} title="broucher_download" className="py-2">Download Broucher<FaArrowRight /></Link> */}
                                             </div>
                                          </div>
                                          <div className="link">
                                             <Link to={`college/${college.slug}`} title={college.name} className="py-2">View More <FaArrowRight /></Link>
                                             {/* <Link to={/broucher/${college.slug}} title="broucher_download" className="py-2">Download Broucher<FaArrowRight /></Link> */}
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              ))}
                           </div>
                        )}
                     </div>
                     <div className='owl_slider_rec'>
                        <div className='main__card'>
                           <div className='row'>
                              <div className='col-lg-12 p'>
                                 <div className='long_border'>
                                    <h3>Recommended Colleges</h3>
                                 </div>
                              </div>
                           </div>
                           <div className='row'>
                              <div className='col-12'>
                                 {collegeData && collegeData.length > 0 && (
                                    <OwlCarousel className="owl-theme " {...owlOptions}>
                                       {collegeData.map((college) => (
                                          <div className="gap-5 mx-2" key={college.id}>


                                             <div className="card_college">
                                                <img className="card-img-top mba-img" src={college.image} alt={college.name} title={college.name} />
                                                <div className="card-body">
                                                   <div className="main d-flex align-item-center">
                                                      <div className="logocard">
                                                         <Suspense key={college.id + "_suspense"}>
                                                            <LazyLoad height={200} offset={100} once key={college.id + "_lazyload"}>
                                                               <img src={college.logo} title={college.name} alt={college.name} loading='lazy' key={college.id + "_image"} />
                                                            </LazyLoad>
                                                         </Suspense>
                                                      </div>
                                                   </div>
                                                   <div className="d-flex head">
                                                      <div className="mba">
                                                         <h6 className="title"> {college.course_category[0].name}</h6>

                                                      </div>
                                                      <div className="mba1">
                                                         <h6 className="title d-flex text-warning"><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></h6>

                                                      </div>

                                                      <small className='small-c'> {college.organization_type.name}</small>
                                                   </div>
                                                   <div>
                                                      <div className="card-pera" key={college.id + "_cardpera"}>
                                                         <h4>{college.name}</h4>
                                                      </div>
                                                      <small className="pt-1 adress">
                                                         <span > {college.current_address}</span>
                                                      </small>
                                                      <small className="pt-1 lowercase adress">


                                                      </small>
                                                      <div className="link">
                                                         <Link to={`/college/${college.slug}`} title={college.name} className="py-2">View More.. <FaArrowRight /></Link>
                                                         {/* <Link to={/broucher/${college.slug}} title="broucher_download" className="py-2">Download Broucher<FaArrowRight /></Link> */}
                                                      </div>
                                                   </div>
                                                   <div className="link">
                                                      <Link to={`/college/${college.slug}`} title={college.name} className="py-2">View More.. <FaArrowRight /></Link>
                                                      {/* <Link to={/broucher/${college.slug}} title="broucher_download" className="py-2">Download Broucher<FaArrowRight /></Link> */}
                                                   </div>
                                                </div>
                                             </div>



                                          </div>
                                       ))}
                                    </OwlCarousel>
                                 )}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* side menu */}
                  <div className='col-lg-3'>
                     <div className='main__card'>
                        <div className='row'>
                           <div className='col-lg-12'>
                              <div className='long_border'>
                                 <h3>Popular colleges</h3>
                              </div>
                           </div>
                        </div>
                        <div className='row'>
                           <div className='col-lg-12'>
                              <div className='main_side_card h_of_card'>
                                 {Topviewcollege && Topviewcollege.map((data) =>
                                    <div className='popular_col' key={data.id}>
                                       <div className='pic'>
                                          <LazyLoad height={200} offset={100} once key={data.id + "_lazyload"}>
                                             <img src={data.logo} title={data.name} alt={data.name} loading='lazy' />
                                          </LazyLoad>
                                       </div>
                                       <div className='content'>
                                          <p className='title'>{data.name}</p>
                                          <p className='rating'><StarRating rating={data.rating} /> </p>
                                       </div>
                                    </div>
                                 )}

                              </div>
                           </div>
                        </div>
                     </div>
                     {/* upcoming exams */}
                     <div className='main__card'>
                        <div className='row '>
                           <div className='col-lg-12'>
                              <div className='long_border'>
                                 <h3>Upcoming exam </h3>
                              </div>
                           </div>
                        </div>
                        <div className='row'>
                           <div className='col-lg-12'>
                              <div className='main_side_card h_of_card'>
                                 {exam && exam.map((data) =>
                                    <div className='popular_col' key={data.id}>
                                       <div className='pic'>
                                          <img
                                             src={data.exam.image || "https://templates.hibootstrap.com/sanu/default/assets/images/events/events-1.jpg"}
                                             className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                             alt={data.title}
                                             title={data.title}
                                          />
                                       </div>
                                       <div className='content'>
                                          <p className='title'>{data.title}</p>
                                          <p className='published_on'><span>exam start_date : </span><span>{new Date(data.exam_start_date).toLocaleDateString('en-GB')}</span></p>
                                       </div>
                                    </div>
                                 )}
                              </div>
                           </div>
                        </div>
                     </div>
                     {/* populer blog section */}
                     <div className='main__card'>
                        <div className='row '>
                           <div className='col-lg-12'>
                              <div className='long_border'>
                                 <h3>Populer blogs</h3>
                              </div>
                           </div>
                        </div>
                        <div className='row'>
                           <div className='col-lg-12'>
                              <div className='main_side_card h_of_card'>
                                 {populerBlog && populerBlog.map((data) =>
                                    <div className='popular_col' key={data.id}>
                                       <div className='pic'>
                                          <img
                                             src={data.image || "https://templates.hibootstrap.com/sanu/default/assets/images/events/events-1.jpg"}
                                             className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                             alt={data.title}
                                             title={data.title}
                                          />
                                       </div>
                                       <div className='content'>
                                          <p className='title'>{data.title}</p>
                                          <p className='published_on'><span>Published On:</span><span>{new Date(data.published_date).toLocaleDateString('en-GB')}</span></p>
                                       </div>
                                    </div>
                                 )}
                              </div>
                           </div>
                        </div>
                     </div>
                     {/* social media */}
                     <div className='main__card for_sticky'>
                        <div className='row'>
                           <div className='col-lg-12'>
                              <div className='long_border'>
                                 <h3>Social Media</h3>
                              </div>
                           </div>
                        </div>
                        <div className='row'>
                           <div className='col-lg-12'>
                              <div className='main_side_card'>
                                 <div className='social_media'>
                                    <span className='icon'><FaWhatsapp /></span>
                                    <span className='icon'><FaFacebook /></span>
                                    <span className='icon'><FaInstagram /></span>
                                    <span className='icon'><FaXTwitter /></span>
                                    <span className='icon'><FaLinkedin /></span>
                                    <span className='icon'><FaYoutube /></span>
                                    <span className='icon'><FaTelegram /></span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            </div>

            {/* <Collegeperdictorfrom /> */}
         </div>
      </div>
   )
}

export default Collegepredictor