import React, { useState } from 'react';
import Collapsible from 'react-collapsible';
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { BiSliderAlt } from "react-icons/bi";
import { Link } from 'react-router-dom';
function Courses() {
  const [locationOpen, setLocationOpen] = useState(false);
  const [coursesOpen, setCoursesOpen] = useState(false);
  const [feesOpen, setFeesOpen] = useState(false);
  const [credentialOpen, setCredentialOpen] = useState(false);
  const [clevelOpen, setClevelOpen] = useState(false);
  const [ownershipOpen, setOwnership] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [selectedFees, setSelectedFees] = useState([]);
  const [selectedCredential, setselectCredential] = useState([]);
  const [selectClevel, setselectClevel] = useState([]);
  const [selectOwnership, setselectOwnership] = useState([]);
  const toggleLocation = () => {
    setLocationOpen(!locationOpen);
  };

  const toggleCourses = () => {
    setCoursesOpen(!coursesOpen);
  };
  const toggleFees = () => {
    setFeesOpen(!feesOpen);
  };

  const toggleCredential = () => {
    setCredentialOpen(!credentialOpen);
  };
  const toggleClevel = () => {
    setClevelOpen(!clevelOpen);
  };
  const toggleOwnership = () => {
    setOwnership(!ownershipOpen);
  };
  const handleLocation = (location) => {
    setSelectedLocations((prevSelected) => {
      if (prevSelected.includes(location)) {
        return prevSelected.filter((item) => item !== location);
      } else {
        return [...prevSelected, location];
      }
    });
  };
  const handleCourse = (course) => {
    setSelectedCourse((prevSelected) => {
      if (prevSelected.includes(course)) {
        return prevSelected.filter((item) => item !== course);
      } else {
        return [...prevSelected, course];
      }
    });
  };
  const handleFees = (Fees) => {
    setSelectedFees((prevSelected) => {
      if (prevSelected.includes(Fees)) {
        return prevSelected.filter((item) => item !== Fees);
      } else {
        return [...prevSelected, Fees];
      }
    });
  };
  const handleCredential = (Credential) => {
    setselectCredential((prevSelected) => {
      if (prevSelected.includes(Credential)) {
        return prevSelected.filter((item) => item !== Credential);
      } else {
        return [...prevSelected, Credential];
      }
    });
  };
  const handleClevel = (Clevel) => {
    setselectClevel((prevSelected) => {
      if (prevSelected.includes(Clevel)) {
        return prevSelected.filter((item) => item !== Clevel);
      } else {
        return [...prevSelected, Clevel];
      }
    });
  };
  const handleOwnership = (Ownership) => {
    setselectOwnership((prevSelected) => {
      if (prevSelected.includes(Ownership)) {
        return prevSelected.filter((item) => item !== Ownership);
      } else {
        return [...prevSelected, Ownership];
      }
    });
  }
  // const containerStyle = {

  //   backgroundImage: `url(${shape1})`,
  // }

  return (
    <>

      {/* change */}
      <section class="breadcrumb-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <h1 class="ab-1">Course </h1>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li class="breadcrumb-item active" aria-current="page">Courses</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="college_cards">

        <div className="container-fluid">
          <div className="justify-between row">
            <div className='justify-center h-[100%] col-md-3 border-inherit outline-offset-4 rounded-2xl'>
              <div className='flex justify-between'><strong className='flex items-center p-1'> <span className='px-2 m-1 text-blue-700'><BiSliderAlt /></span>All Filter</strong><butoon className='p-2 text-blue-700'>Clear All</butoon></div>

              <div className='m-2 '>
                {selectedLocations.map((location) => (
                  <button className='p-1 m-1 border-2 rounded-xl ' key={location}>{location}</button>
                ))}
                {selectedCourse.map((course) => (
                  <button className='p-1 m-1 border-2 rounded-xl' key={course}>{course}</button>
                ))}
                {selectedFees.map((fees) => (
                  <button className='p-1 m-1 border-2 rounded-xl' key={fees}>{fees}</button>
                ))}
                {selectedCredential.map((Credential) => (
                  <button key={Credential}>{Credential}</button>
                ))}
                {selectClevel.map((Clevel) => (
                  <button className='p-1 m-1 border-2 rounded-xl' key={Clevel}>{Clevel}</button>
                ))}
                {selectOwnership.map((Ownership) => (
                  <button className='p-1 m-1 border-2 rounded-xl' key={Ownership}>{Ownership}</button>
                ))}
              </div>

              <div className='location'>
                <b className='flex justify-between m-2 ' onClick={toggleLocation}>
                  <span>Location</span>
                  {locationOpen ? <FaCaretUp /> : <FaCaretDown />}
                </b>

                <Collapsible
                  trigger=''
                  open={locationOpen}
                  onTriggerOpening={toggleLocation}
                  onTriggerClosing={toggleLocation}
                >
                  <div className='m-2 overflow-y-auto max-h-40'>
                    <div className="relative">
                      <input className="pl-8 border-double bg-slate-100 rounded-2xl" type="search" placeholder="Location" />
                      <i className="absolute text-gray-600 transform -translate-y-1/2 fa fa-search left-2 top-1/2"></i>
                    </div>
                    <ul className='m-3'>
                      <li> <input type='checkbox' onChange={() => handleLocation('Indore')} /> Indore</li>
                      <li> <input type='checkbox' onChange={() => handleLocation('Bhopal')} /> Bhopal </li>
                      <li> <input type='checkbox' onChange={() => handleLocation('Pune')} /> Pune</li>
                      <li> <input type='checkbox' onChange={() => handleLocation('Bengalor')} /> Bengalor</li>
                      <li> <input type='checkbox' onChange={() => handleLocation('Delhi/NCR')} /> Delhi/NCR</li>
                      <li> <input type='checkbox' onChange={() => handleLocation('Hydrabad')} /> Hydrabad</li>
                      <li> <input type='checkbox' onChange={() => handleLocation('Kolkata')} /> Kolkata</li>
                      <li> <input type='checkbox' onChange={() => handleLocation('Mumbai')} /> Mumbai</li>
                      <li> <input type='checkbox' onChange={() => handleLocation('Maharstra')} /> Maharstra</li>
                    </ul>
                  </div>
                </Collapsible>
                <hr />
              </div >

              <div className='Courses'>
                <b className='flex justify-between m-2' onClick={toggleCourses}>
                  <span>Courses</span>
                  {coursesOpen ? <FaCaretUp /> : <FaCaretDown />}
                </b>
                <Collapsible
                  open={coursesOpen}
                  onTriggerOpening={toggleCourses}
                  onTriggerClosing={toggleCourses}
                >
                  <div className='m-2 overflow-y-auto Courses max-h-40'>
                    <div className="relative">
                      <input className="pl-8 border-double rounded-2xl bg-slate-100" type="search" placeholder="Courses" />
                      <i className="absolute text-gray-600 transform -translate-y-1/2 fa fa-search left-2 top-1/2"></i>
                    </div>
                    <ul className='m-3'>
                      <li><input type='checkbox' onChange={() => handleCourse('Meadical')} /> Meadical</li>
                      <li><input type='checkbox' onChange={() => handleCourse('Engineering')} /> Engineering</li>
                      <li><input type='checkbox' onChange={() => handleCourse('Management')} /> Management</li>
                      <li><input type='checkbox' onChange={() => handleCourse('Finance')} /> Finance</li>
                      <li><input type='checkbox' onChange={() => handleCourse('BCA')} /> BCA</li>
                      <li><input type='checkbox' onChange={() => handleCourse('MCA')} /> MCA</li>
                      <li><input type='checkbox' onChange={() => handleCourse('B.Com')} /> B.Com</li>
                      <li><input type='checkbox' onChange={() => handleCourse('Bsc')} /> Bsc</li>
                    </ul>
                  </div>
                </Collapsible>
                <hr />
              </div>

              <div className='Fees '>
                <b className='flex justify-between m-2' onClick={toggleFees}>
                  <span>Total Fees</span>
                  {feesOpen ? <FaCaretUp /> : <FaCaretDown />}
                </b>
                <Collapsible
                  open={feesOpen}
                  onTriggerOpening={toggleFees}
                  onTriggerClosing={toggleFees}
                >
                  <div className='m-2 overflow-y-auto max-h-40'>
                    <div className="relative">
                      <input className="pl-8 border-double rounded-2xl bg-slate-100" type="search" placeholder="Fees" />
                      <i className="absolute text-gray-600 transform -translate-y-1/2 fa fa-search left-2 top-1/2"></i>
                    </div>
                    <ul className='m-2'>
                      <li> <input type='checkbox' onChange={() => handleFees('1 Lakh')} /> 1 Lakh</li>
                      <li> <input type='checkbox' onChange={() => handleFees('1-2 Lakh')} /> 1-2 Lakh</li>
                      <li> <input type='checkbox' onChange={() => handleFees('2-3 Lakh')} /> 2-3 Lakh</li>
                      <li> <input type='checkbox' onChange={() => handleFees('3-5 Lakh')} /> 3-5 Lakh</li>
                      <li> <input type='checkbox' onChange={() => handleFees('5 Lakh')} /> 5 Lakh</li>
                    </ul>
                  </div>
                </Collapsible>
                <hr />
              </div>

              <div className='Credential'>
                <b className='flex justify-between m-2' onClick={toggleCredential}>
                  <span>Credential</span>
                  {credentialOpen ? <FaCaretUp /> : <FaCaretDown />}
                </b>
                <Collapsible
                  open={credentialOpen}
                  onTriggerOpening={toggleCredential}
                  onTriggerClosing={toggleCredential}
                >
                  <div className='m-2 overflow-y-auto max-h-40'>
                    <div className="relative">
                      <input className="pl-8 border-double rounded-2xl bg-slate-100" type="search" placeholder="Credential" />
                      <i className="absolute text-gray-600 transform -translate-y-1/2 fa fa-search left-2 top-1/2"></i>
                    </div>
                    <ul className='m-2'>
                      <li>  <input type='checkbox' onChange={() => handleCredential('Degree')} /> Degree </li>
                      <li>  <input type='checkbox' onChange={() => handleCredential('Ceritificates')} /> Ceritificates </li>
                    </ul>
                  </div>
                </Collapsible>
                <hr />
              </div>
              <div className='Clevel'>
                <b className='flex justify-between m-2' onClick={toggleClevel}>
                  <span>Course Level</span>
                  {clevelOpen ? <FaCaretUp /> : <FaCaretDown />}
                </b>
                <Collapsible
                  open={clevelOpen}
                  onTriggerOpening={toggleClevel}
                  onTriggerClosing={toggleClevel}
                >
                  <div className='m-2 overflow-y-auto max-h-40'>
                    <div className="relative">
                      <input className="pl-8 border-double rounded-2xl bg-slate-100" type="search" placeholder="Course Level" />
                      <i className="absolute text-gray-600 transform -translate-y-1/2 fa fa-search left-2 top-1/2"></i>
                    </div>
                    <ul className='m-2'>
                      <li><input type='checkbox' onChange={() => handleClevel('UG')} /> UG</li>
                      <li><input type='checkbox' onChange={() => handleClevel('PG')} /> PG</li>
                      <li><input type='checkbox' onChange={() => handleClevel('PHD')} /> PHD</li>
                      <li><input type='checkbox' onChange={() => handleClevel('Diploma')} /> Diploma</li>
                      <li><input type='checkbox' onChange={() => handleClevel('Certificate')} /> Certificate</li>
                    </ul>
                  </div>
                </Collapsible>
                <hr />
              </div>
              <div className='Ownership'>
                <b className='flex justify-between m-2' onClick={toggleOwnership}>
                  <span>Ownership</span>
                  {ownershipOpen ? <FaCaretUp /> : <FaCaretDown />}
                </b>
                <Collapsible
                  open={ownershipOpen}
                  onTriggerOpening={toggleOwnership}
                  onTriggerClosing={toggleOwnership}
                >
                  <div className='m-2 overflow-y-auto max-h-40'>
                    <div className="relative">
                      <input className="pl-8 border-double rounded-2xl bg-slate-100" type="search" placeholder="Ownership" />
                      <i className="absolute text-gray-600 transform -translate-y-1/2 fa fa-search left-2 top-1/2"></i>
                    </div>
                    <ul className='m-2'>
                      <li><input type='checkbox' onChange={() => handleOwnership('Private')} /> Private</li>
                      <li><input type='checkbox' onChange={() => handleOwnership('Public/Government')} /> Public/Government</li>
                      <li><input type='checkbox' onChange={() => handleOwnership('Public/Private')} /> Public/Private</li>
                    </ul>
                  </div>
                </Collapsible>
                <hr />
              </div>
            </div >
            {/* sec */}
            <div className="col-lg-8 col-md-12">
              <div className="card ">
                <div className="card-body">
                  <h4>
                    Bachelor of Technology [B.Tech] (Computer Science and Engineering)
                  </h4>
                  <div className="d-flex top-bar ">
                    <p className="bright" id="one">
                      <i className="mr-2 fa-regular fa-clock" />4 YEARS
                    </p>
                    <p className="bright" id="two">
                      <i className="mr-2 fa-brands fa-readme" />
                      DEGREE
                    </p>
                    <p className="bright" id="three">
                      <i className="mr-2 fa-solid fa-graduation-cap" />
                      GRADUATION
                    </p>
                    <p className="bright" id="four">
                      <i className="mr-2 fa-solid fa-equals" />
                      FULL TIME
                    </p>
                  </div>
                  <div className="third">
                    <span className="bright">Course Eligibility: 10+2</span>
                    <span>Entrance Exam: JEE Main</span>
                  </div>
                  <div className="info">
                    <p>
                      BTech CSE is a 4 year UG course that studies practical and
                      theoretical knowledge of computer hardware and software. This
                      course lays emphasis on the basics of computer programming and
                      networking while also comprising a plethora of topics.The
                      admission process for the B.tech CSE is to clear entrance exams
                      such as JEE at a national or state level.BTech CSE Entrance
                      Exams are JEE Main, SRMJEEE, etc.
                    </p>
                  </div>
                  <button className="btnn">read more..</button>
                  <div className="flex anchor ">
                    <span className="bright">
                      <img src="https://portal.mycollegepedia.com/media/college/logo/AVC_clg_TN_091QRPf.jpeg" className='w-8 h-8' alt="clg_img" /> VIT ,Vellore
                    </span>
                    <span>
                      <img src="https://portal.mycollegepedia.com/media/college/logo/AVC_clg_TN_091QRPf.jpeg" className='w-8 h-8' alt="clg_img" /> LPU , Jalandhar
                    </span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
}

export default Courses;
