import React, { useState } from 'react';
import NewFooter from './Footer/Newfooter';
import TopNav from './Homepages/Topnav';
import Header from './Homepages/Header2';
import { Outlet } from 'react-router-dom';
import { FaComments } from 'react-icons/fa';
import ChatBot from './ChatBot/Chatbot';

const MainLayout = () => {
  const [showChatbot, setShowChatbot] = useState(false);

  const handleclose = () => {
    setShowChatbot(!showChatbot);
  };

  return (
    <div>
      <TopNav />
      <Header />
      <Outlet />

      <div className="chat-icon text-primary btn font-bold m-5" onClick={handleclose}>
        <FaComments className='md:text-[30px]' />
      </div>

      {showChatbot && <ChatBot isOpen={showChatbot} handleclose={handleclose} />}

      <NewFooter />
    </div>
  );
};

export default MainLayout;