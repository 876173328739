import React from 'react';

const DirectorInfo = ({ formData, handleInputChange }) => {

    return (
        <div className='row'>
            <div className="form-group col-md-6 col-lg-4">
                <label>Director Name <span className='text-danger'>*</span></label>
                <input
                    className="form-control"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                />
            </div>
            <div className="form-group col-md-6 col-lg-4">
                <label>Primary Mobile <span className='text-danger'>*</span></label>
                <input
                    className="form-control"
                    type="text"
                    name="primary_mobile"
                    value={formData.primary_mobile}
                    onChange={handleInputChange}
                    required
                />
            </div>
            <div className="form-group col-md-6 col-lg-4">
                <label>Secondary Mobile</label>
                <input
                    className="form-control"
                    type="text"
                    name="secondary_mobile"
                    value={formData.secondary_mobile}
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group col-md-6 col-lg-4">
                <label>Email <span className='text-danger'>*</span></label>
                <input
                    className="form-control"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                />
            </div>
            <div className="form-group col-md-6 col-lg-4">
                <label>WhatsApp</label>
                <input
                    className="form-control"
                    type="url"
                    name="whatsapp"
                    value={formData.whatsapp}
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group col-md-6 col-lg-4">
                <label>Facebook</label>
                <input
                    className="form-control"
                    type="url"
                    name="facebook"
                    value={formData.facebook}
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group col-md-6 col-lg-4">
                <label>Instagram</label>
                <input
                    className="form-control"
                    type="url"
                    name="instagram"
                    value={formData.instagram}
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group col-md-6 col-lg-4">
                <label>LinkedIn</label>
                <input
                    className="form-control"
                    type="url"
                    name="linkedin"
                    value={formData.linkedin}
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group col-md-6 col-lg-4">
                <label>Twitter</label>
                <input
                    className="form-control"
                    type="url"
                    name="twitter"
                    value={formData.twitter}
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group col-md-6 col-lg-4">
                <label>YouTube</label>
                <input
                    className="form-control"
                    type="url"
                    name="youtube"
                    value={formData.youtube}
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group col-md-6 col-lg-4">
                <label>Telegram</label>
                <input
                    className="form-control"
                    type="url"
                    name="telegram"
                    value={formData.telegram}
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group col-md-6 col-lg-4">
                <label>Website</label>
                <input
                    className="form-control"
                    type="url"
                    name="website"
                    value={formData.website}
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group col-md-6 col-lg-4">
                <label>Profile Picture</label>
                <input
                    className="form-control"
                    type="file"
                    name="profile"
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group col-md-6 col-lg-4">
                <label>Identity</label>
                <select
                    className="form-control"
                    name="identity"
                    value={formData.identity}
                    onChange={handleInputChange}
                >
                    <option value="">Select Identity</option>
                    <option value="Aadhar">Aadhar</option>
                    <option value="Passport">Passport</option>
                    <option value="Driving License">Driving License</option>
                </select>
            </div>
            <div className="form-group col-md-6 col-lg-4">
                <label>ID Proof</label>
                <input
                    className="form-control"
                    type="file"
                    name="id_proof"
                    onChange={handleInputChange}
                />
            </div>
            <div className="form-group col-md-6 col-lg-4">
                <label>Organization Type</label>
                <select
                    className="form-control"
                    name="organization_type"
                    value={formData.organization_type}
                    onChange={handleInputChange}
                >
                    <option value="">Select Organization Type</option>
                    <option value="1">Type 1</option>
                    <option value="2">Type 2</option>
                   
                </select>
            </div>
        </div>
    );
};

export default DirectorInfo;