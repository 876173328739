import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Apicontext from '../../Page/Contextapi';
import "../../assets/css/Brochure.css";
import banner from "../../assets/images/banner.jpg";
import { LinearProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print'; // Import useReactToPrint hook

const Broucher = () => {
    const [collegeData, setCollegeData] = useState([]);
    const { slug } = useParams();
    const token = localStorage.getItem('token');
    const [Data, setData] = useState([]);
    const contextdata = useContext(Apicontext);
    const navigate = useNavigate();
    const [Loading, setLoading] = useState(true);

    useEffect(() => {
        const data = contextdata.APIdata;
        setData(data.stream);
        const fetchCollegeData = async () => {
            try {
                const response = await axios.get(`https://portal.mycollegepedia.com/college/college/${slug}`);
                setCollegeData(response.data);
            } catch (error) {
                console.error('Error fetching college data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchCollegeData();
    }, [slug, contextdata]);

    const handleDownload = useReactToPrint({
        content: () => targetRef.current, // Specify the content to be printed
    });

    const targetRef = React.useRef(); // Create a ref for the component to be printed

    const renderEligibility = () => {
        return collegeData.eligibility?.map((eligibilityItem) => (
            <div key={eligibilityItem.id}>
                <div className='p-5 bg-gray-100 border'>
                    <img src={`${eligibilityItem.exam.image ? eligibilityItem.exam.image : ""}`} alt={eligibilityItem.exam.title} className='w-20 h-20 singlelogo' />
                    <h4 className='my-1 text-lg font-semibold '>{eligibilityItem.exam.title}<span className='italic'>({eligibilityItem.exam.full_form}) </span></h4>
                    <p className='p-1' dangerouslySetInnerHTML={{ __html: eligibilityItem.exam.description }} />
                </div>
                <p className='py-2 bg-gray-50'>{eligibilityItem.eligibility}</p>
                <p className='p-1' dangerouslySetInnerHTML={{ __html: eligibilityItem.description }} />
                <p className='date '>Application Start Date: {eligibilityItem.application_start_date}</p>
                <p>Application End Date: {eligibilityItem.application_end_date}</p>
            </div>
        ));
    };

    const course_fee = () => {
        if (!Data || !collegeData.course_fee || !collegeData.course_fee[0]) {
            console.error('Data or course_fee is not available.');
            return <p>Course data is not available</p>;
        }
        const courseStreamId = collegeData.course_fee[0].course_stream;
        if (!courseStreamId) {
            console.error('Course stream ID is not available.');
            return null;
        }
        const stream = Data.find((stream) => stream.id === courseStreamId);
        if (!stream) {
            console.warn('No matching data found for stream ID:', courseStreamId);
            return null;
        }
        const fees = collegeData.course_fee[0];

        return (
            <div>
                <section className="table-data">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="table-responsive !p-2">
                                    <table className="table align-middle project-list-table table-nowrap table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">Academic Year</th>
                                                {/* <th scope="col">College</th> */}
                                                <th scope="col">Course</th>
                                                <th scope="col">Stream</th>
                                                <th scope="col">Yearly Fees</th>
                                                <th scope="col">Total Fees</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{fees.academic_year.year}</td>
                                                {/* <td>{collegeData.name}</td> */}
                                                <td>{collegeData.course_category?.map((course) => (<span key={course.id}>{course.name} {' '}<br /></span>)) || 'B tech'}</td>
                                                <td>
                                                    <span className="badge badge-soft-info">
                                                        {stream.name}
                                                    </span>
                                                </td>
                                                <td><span className="mb-0 badge badge-soft-danger">{fees.year_fees}</span></td>
                                                <td><span className="mb-0 badge badge-soft-success">{fees.total_fees}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    };

    if (!token) {
        navigate('/loginpage');
        return null;
    }

    if (Loading) {
        return (
            <div className='container'>
                <div className='row p-5 my-5'>
                    <LinearProgress />
                </div>
            </div>
        );
    }

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='sticky float-right py-3 top-16'>
                            {/* Add an onClick event to trigger the download */}
                            <button className='gap-2 text-center text-white md:px-3 md:py-2 btn-sm btn-info btn-file ' onClick={handleDownload} >Download PDF</button>
                        </div>
                    </div>
                </div>
            </div>
            <section ref={targetRef} className='pdfFile ' id='broucher'>
                <section className="banner">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="adver">
                                    <img src={banner} alt={collegeData.name && collegeData.name ? collegeData.name.slice(0, 10) : ''} title={collegeData.name} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="pdf">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-3 pic">
                                <img src={collegeData ? collegeData.logo : "images/banner.jpg"} alt={collegeData.name} />
                            </div>
                            <div className="col-lg-9 head">
                                <h1>{collegeData ? collegeData.name : ""}</h1>
                                <div className="category">
                                    <h2 className="cat"> { }</h2>
                                    <h2 className="add">
                                        {" "}
                                        {collegeData.current_address || ""}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="contentone">
                    <div className="container">
                        {collegeData.overview && (
                            <div className="row">
                                <div className="my-3 col-lg-12">
                                    <h2>Overview</h2>
                                    <p dangerouslySetInnerHTML={{ __html: collegeData.overview }} />
                                </div>
                            </div>
                        )}
                        {collegeData.exams_eligibility && (
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2>Exams & Eligibility</h2>
                                    <p>{renderEligibility()}</p>
                                </div>
                            </div>
                        )}
                        {collegeData.admission_process && (
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2>Admission Process</h2>
                                    <p dangerouslySetInnerHTML={{ __html: collegeData.admission_process }} className='!list-none !px-4' />
                                </div>
                            </div>
                        )}
                        {collegeData.course_fee && (
                            <div className="row">
                                <div className="my-3 col-lg-12">
                                    <h2>Course & Fee</h2>
                                    <p>{course_fee()}</p>
                                </div>
                            </div>
                        )}

                        {collegeData.specialization && (
                            <div className="row">
                                <div className=" col-lg-12">
                                    <h2>Specialization</h2>
                                    <div className='px-3 ' dangerouslySetInnerHTML={{ __html: collegeData.specialization }} />
                                </div>
                            </div>
                        )}

                        {collegeData.career_opportunity && (
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2>Career & Opportunity</h2>
                                    <div dangerouslySetInnerHTML={{ __html: collegeData.career_opportunity }} />
                                </div>
                            </div>
                        )}
                        {collegeData.faq && (
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className='my-2'>FAQ</h2>
                                    {collegeData.faq?.map((faqItem, index) => (
                                        <>
                                            <h4 className="text-lg font-semibold q" key={index}>  {faqItem.question} </h4>
                                            <p className='' dangerouslySetInnerHTML={{ __html: faqItem.answer }} />
                                        </>
                                    ))}

                                </div>
                            </div>
                        )}
                    </div>
                    &nbsp;&nbsp;
                    <section className="footer">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6 disc">
                                    <img src="images/coll.png" alt="" />
                                    <h4>Disclaimer</h4>
                                    <p>
                                        My college pedia is an independent platform created to provide general
                                        information and resources related to Your College or Universities.
                                        While we strive to ensure the accuracy and timeliness of the
                                        information presented, we cannot guarantee that all content is
                                        current, complete, or error-free. Users are encouraged to verify any
                                        critical information with the respective educational institution.
                                    </p>
                                </div>
                                <div className="col-lg-4 col-md-4 sbet">
                                    <p>
                                        <span>Phone:</span>7880110983
                                    </p>
                                    <p>
                                        <span>Whatsapp:</span>7880110983
                                    </p>
                                    <p>
                                        <span>Email:</span>mycollegepedia@gmail.com
                                    </p>
                                </div>
                                <div className="col-lg-2 col-md-2 icon ">
                                    <p className="instagram">
                                        <i className="fa-brands fa-instagram" />
                                    </p>
                                    <p className="envelope">
                                        <i className="fa-solid fa-envelope" />
                                    </p>
                                    <p className="facebookk">
                                        <i className="fa-brands fa-facebook" />
                                    </p>
                                </div>
                            </div>
                        </div>

                    </section>

                </section>
            </section>
        </>
    );
}

export default Broucher;