import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CollegeExam = () => {
  const [examData, setExamData] = useState([]);
  const [upcomingExams, setUpcomingExams] = useState([]);
  const [upcomingExamDetail, setUpcomingExamDetail] = useState(null);

  useEffect(() => {
    // Fetch Exam data using GET request
    const fetchExamData = async () => {
      try {
        const response = await axios.get('https://portal.mycollegepedia.com/exam/exam/slug');

        setExamData(response.data);
      } catch (error) {
        console.error('Error fetching Exam data:', error);
      }
    };

    // Fetch All Upcoming Exams using GET request
    const fetchAllUpcomingExams = async () => {
      try {
        const response = await axios.get('https://portal.mycollegepedia.com/exam/all-upcoming-exam');

        setUpcomingExams(response.data);
      } catch (error) {
        console.error('Error fetching All Upcoming Exams:', error);
      }
    };

    fetchExamData();
    fetchAllUpcomingExams();
  }, []);

  const handleGetUpcomingExamDetail = async (slug) => {
    try {
      const response = await axios.get(`https://portal.mycollegepedia.com/exam/upcoming-exam/${slug}`);

      setUpcomingExamDetail(response.data);
    } catch (error) {
      console.error('Error fetching Upcoming Exam detail:', error);
    }
  };

  return (
    <div>
      <h1>College Exam</h1>

      {/* Display Exam data */}
      <h2>Exam Data</h2>
      <pre>{JSON.stringify(examData, null, 2)}</pre>

      {/* Display All Upcoming Exams */}
      <h2>All Upcoming Exams</h2>
      <ul>
        {upcomingExams.map((exam, index) => (
          <li key={index}>
            {exam.name} - {exam.date}
            <button onClick={() => handleGetUpcomingExamDetail(exam.slug)}>View Details</button>
          </li>
        ))}
      </ul>

      {/* Display Upcoming Exam Detail */}
      <h2>Upcoming Exam Detail</h2>
      {upcomingExamDetail && (
        <div>
          <p>Name: {upcomingExamDetail.name}</p>
          <p>Date: {upcomingExamDetail.date}</p>
          {/* Add more details as needed */}
        </div>
      )}
    </div>
  );
};

export default CollegeExam;
