import React, {  useMemo, useState } from "react";
import axios from "axios";

import Swal from "sweetalert2";
import { Modal, Button, Form } from "react-bootstrap";
import { TiArrowBack } from "react-icons/ti";
const CompanyUpdate = ({ handlefunction, profiledata, data }) => {
    const token = useMemo(() => localStorage.getItem('token') || '', []);

    const [formData, setFormData] = useState({
        id: profiledata?.id || '',
        name: profiledata?.name || '',
        email: profiledata?.email || '',
        mobile: profiledata?.mobile || '',
        profile: profiledata?.profile || null,
        gender: profiledata?.gender || '',
        dob: profiledata?.dob || '',
        religion: profiledata?.religion || '',
        category: profiledata?.category || '',
        brand_name: profiledata?.brand_name || '',
        company_certificate: profiledata?.company_certificate || '',
        country: profiledata?.country || '',
        state: profiledata?.state || '',
        city: profiledata?.city || '',
        current_address: profiledata?.current_address || '',
        permanent_address: profiledata?.permanent_address || '',
        zipcode: profiledata?.zipcode || '',
      });
      

    const handlechange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            profile: file,
        });
    };

    const hanndlesubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataCopy = new FormData();
            Object.keys(formData).forEach((key) => {
                if (key !== 'profile' && key !=='company_certificate') {
                        formDataCopy.append(key, formData[key]); 
                }
            });

            if (formData.profile && typeof formData.profile !== 'string') {
                formDataCopy.append('profile', formData.profile);
            }

            const response = await axios.patch(
                'https://portal.mycollegepedia.com/account/counsellor-profile/',
                formDataCopy,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `JWT ${token}`,
                    },
                }
            );
            Swal.fire({
                icon:"success",
                position: "top-end",
                timer: 2000,
                title: 'Profile Updated Successfully',
            });
            handlefunction();
        } catch (error) {
            console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            });
        }
    };
    return (
        <div>
         
            <div className='justify-end row'>
                <button onClick={() => handlefunction()} title="back" className="btn-sm shadow-sm flex m-3 gap-1.5 md:float-right text-white items-center border bg-[#052963]">
                    <TiArrowBack />
                    back
                </button>
            </div>
            <div className="row">
            <Modal.Body >
                    <form className="common_dash_form p-3" onSubmit={hanndlesubmit} enctype="multipart/form-data">
                        <div className="row">
                            <div className="col-lg-6">
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Name</Form.Label>

                                    <Form.Control type="text" placeholder="Enter Name" name="name"
                                        value={formData.name} onChange={handlechange} />
                                </Form.Group>
                            </div>

                            <div className="col-lg-6">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Brand Name</Form.Label>
                                    <Form.Control type="Brand Name" placeholder="Enter  Brand Name" name="brand_name" value={formData.brand_name}
                                        onChange={handlechange} />
                                </Form.Group>
                            </div>
                            <div className="col-lg-6">
                                <Form.Group className="mb-3" controlId="no" >
                                    <Form.Label>Mobile No.</Form.Label>
                                    <Form.Control type="number" placeholder="Enter you contact number"
                                        value={formData.mobile} name="mobile" onChange={handlechange} />
                                </Form.Group>
                            </div>
                            <div className="col-lg-6">
                                <Form.Group className="mb-3" controlId="profile">
                                    <Form.Label>Profile</Form.Label>
                                    <Form.Control type="file" onChange={handleImageChange} name='profile' />
                                </Form.Group>
                            </div>
                            <div className="col-lg-6">
                                <Form.Label>Gender</Form.Label>
                                <Form.Select className="form-control" aria-label="Default select example" controlId="gender" name="gender" onChange={handlechange} value={formData.gender}>
                                    <option value="">Select Gender</option>
                                    <option value='MALE'>MALE</option>
                                    <option value='FEMALE'>FEMALE</option>
                                    <option value='OTHERS'>OTHERS</option>
                                </Form.Select>
                            </div>
                            <div className="col-lg-6">
                                <Form.Group className="mb-3" controlId="dob" >
                                    <Form.Label>Dob</Form.Label>
                                    <Form.Control type="date" placeholder="Enter Dob" onChange={handlechange} value={formData.dob} name="dob" />
                                </Form.Group>
                            </div>
                            <div className="col-lg-6">
                                <Form.Label>Religion</Form.Label>
                                <Form.Select
                                name="religion"
                                    className="form-control"
                                    aria-label="Default select example"
                                    controlId="rel"
                                    value={formData.religion}
                                    onChange={handlechange}
                                >
                                    <option>select-religion</option>
                                    {data.religion && data.religion.map((v) => (
                                        <option key={v.id} value={v.id}>{v.name}</option>
                                    ))}
                                </Form.Select>

                            </div>
                            <div className="col-lg-6">
                                <Form.Label>Category</Form.Label>
                                <Form.Select name="category" className="form-control" aria-label="Default select example" controlId="cat" onChange={handlechange} value={formData.category}>
                                    <option>Category</option>
                                    {data && data.castecategory.map((v) => (
                                        <option key={v.id} value={v.id}>{v.name}</option>
                                    ))}

                                </Form.Select>
                            </div>

                            <div className="col-lg-6">
                                <Form.Group className="mb-3" controlId="State">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select
                                        className="form-control"
                                        aria-label="State"
                                        controlId="state"
                                        name="state"
                                        value={formData.state}
                                        onChange={handlechange}
                                    >
                                        <option>Choose State</option>
                                        {data.State.map((state) => (
                                            <option key={state.id} value={state.id}>
                                                {state.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="col-lg-6">
                                <Form.Group className="mb-3" controlId="City">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control type="text" placeholder="Enter City" value={formData.city} name="city" onChange={handlechange} />
                                </Form.Group>
                            </div>
                            <div className="col-lg-6">
                                <Form.Group className="mb-3" controlId="Current_address">
                                    <Form.Label>Current_address</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Current_address" value={formData.current_address}
                                        name="current_address" onChange={handlechange} />
                                </Form.Group>
                            </div>
                            <div className="col-lg-6">
                                <Form.Group className="mb-3" controlId="Permanent_address">
                                    <Form.Label>Permanent_address</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Permanent_address" value={formData.permanent_address}
                                        name="permanent_address" onChange={handlechange} />
                                </Form.Group>
                            </div>
                            <div className="col-lg-6">
                                <Form.Group className="mb-3" controlId="Permanent_address">
                                    <Form.Label>zipcode</Form.Label>
                                    <Form.Control type="text" placeholder="Enter zipcode" value={formData.zipcode}
                                        name="zipcode" onChange={handlechange} />
                                </Form.Group>
                            </div>

                        </div>
                        <div className="d-flex justify-content-end px-2 gap-2">
                            <Button
                                className="button_of_submit"
                                type="submit"
                            >
                                Save
                            </Button>
                            <Button
                                className="button_of_close btn"
                                onClick={() => handlefunction()}
                            >
                                close
                            </Button>
                        </div>
                    </form>
                </Modal.Body>
            </div>
              
            </div>
    
    )
}
export default CompanyUpdate