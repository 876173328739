import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import StarRating from '../Counselordashboard/Subpages/StarRatingInput';
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from 'react-router-dom';

const FeedbackForm = ({ closeModal, token }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
    rating: 1,
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRatingChange = (value) => {
    setFormData({
      ...formData,
      rating: value,
    });
  };

  const validate = () => {
    const newErrors = {};
    const phonePattern = /^\+91\d{10}$/;

    if (!formData.phone.match(phonePattern)) {
      newErrors.phone = 'Mobile number must be in the format "+911234567890".';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }

    try {
      await axios.post('https://portal.mycollegepedia.com/site/feedback/', formData, {
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      });
      Swal.fire({
        icon: 'success',
        title: 'Feedback Submitted',
        toast: true,
        position: 'center',
        timer: 5000,
        timerProgressBar: true,
        text: 'Your feedback has been submitted successfully.',
      });
      closeModal();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        Swal.fire({
          icon: "error",
          title: "Oops..Try Again",
          text: "User Not Unauthorized!",
          toast: true,
          position: 'center',
          timer: 3000,
          timerProgressBar: true,
        });
        navigate('/login');
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Submission Error',
          text: 'There was an error submitting your feedback. Please try again.',
        });
      }
      console.error('Error submitting feedback:', error);
    }
  };

  return (
    <div className="feedback-form">
      <div className="feedback-header">
        <h2>Submit Your Feedback</h2>
        <button className='' onClick={closeModal}><RxCross2 /></button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="form-group col-md-6">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              className="form-control"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            {errors.phone && <div className="error-message">{errors.phone}</div>}
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="rating">Rating</label>
            <StarRating onChange={handleRatingChange} />
          </div>
          <div className="form-group col-12">
            <label htmlFor="message">Message</label>
            <textarea
              className="form-control"
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-12">
            <button type="submit" className="btn bg-[#052963] text-white hover:bg-[orange]">
              Submit
            </button>
          </div>
        </div>
      </form>
      <div className="feedback-footer">
        <p>Thank you for your feedback!</p>
      </div>
    </div>
  );
};

export default FeedbackForm;