import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FaEye, FaPen, FaPlus, FaTimes, FaTrash } from 'react-icons/fa';
import { Modal, Button } from "react-bootstrap";
import Apicontext from '../../Page/Contextapi';
import JoditEditor from 'jodit-react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { LinearProgress } from '@mui/material';
import CollelegeEligibilty from './CollelegeEligibilty';


const Eligibility = () => {
    const token = useMemo(() => localStorage.getItem('token') || '', []);
    const college = JSON.parse(localStorage.getItem('collegerecord'));
    const [Eligibility, setEligibility] = useState([]);
    const [exam, setExam] = useState([]);
    const [category, setCategory] = useState([]);
    const [subcategoryOptions, setSubcategoryOptions] = useState([]);
    const [selectsubcategory, setSelectedSubCategory] = useState('');
    const [stream, setStreamOptions] = useState([]);
    const [streamall, setStreamall] = useState([]);
    const [open, setClose] = useState(false);
    const handleopen = () => setClose(!open);
    const navigate = useNavigate();
    const [showModalUpdate, setShowModalUpdate] = useState(false);
    const handleModalCloseUpdate = () => setShowModalUpdate(false);
    const handleModalShowUpdate = () => setShowModalUpdate(true);

    const contextdata = useContext(Apicontext);
    console.log("elegibilty", Eligibility)
    const [formData, setFormData] = useState({
        id: '',
        category: '',
        course_subcategory: '',
        course_stream: '',
        college: college[0].id || '',
        exam: '',
        eligibility: '',
        description: '',
        application_start_date: null,
        application_end_date: null,
    });

    const handleUpdate = async (e) => {
        e.preventDefault();

        try {
            const id = formData.id;
            const response = await axios.patch(`https://portal.mycollegepedia.com/college/college-eligibility/${id}/`, formData, {
                headers,
            });
            // console.log(response);
            handleModalCloseUpdate();
            Swal.fire({
                icon: 'success',
                title: 'Success',
                position: 'top-end',
                timer: 3000,
                timerProgressBar: true,
                text: 'Eligibility updated successfully.',
            });
        } catch (error) {
            console.error('Error updating eligibility:', error);
        }
    };

    const handleSelectEligibility = (eligibility) => {
        setFormData({
            id: eligibility.id,
            category: eligibility.category || '',
            course_subcategory: eligibility.course_subcategory || '',
            course_stream: eligibility.course_stream || '',
            exam: eligibility.exam || '',
            eligibility: eligibility.eligibility || '',
            description: eligibility.description || '',
            application_start_date: eligibility.application_start_date || null,
            application_end_date: eligibility.application_end_date || null,
        });
        handleModalShowUpdate();
    };
    const headers = useMemo(() => ({
        Authorization: `JWT ${token}`,
    }), [token]);
    useEffect(() => {
        const data = contextdata.APIdata;
        setSubcategoryOptions(data.subcategory)
        setCategory(data.castecategory)
        setExam(data.exam)
        setStreamall(data.stream)
        const fetchSubcategories = async () => {
            if (selectsubcategory) {
                try {
                    const streamRes = await axios.get(`https://portal.mycollegepedia.com/general-setting/all_course_stream/?course_subcategory=${selectsubcategory}`, {
                        headers: {
                            Authorization: `JWT ${token}`,
                            'Content-Type': 'application/json',
                        },
                    });
                    setStreamOptions(streamRes.data || []);
                } catch (error) {
                    console.error('Error fetching subcategories:', error);
                }
            }
        };
        fetchSubcategories();
    }, [token, selectsubcategory, contextdata, showModalUpdate]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://portal.mycollegepedia.com/college/college-eligibility/', {
                    headers,
                });
                setEligibility(response.data);

            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem("token");
                    Swal.fire({
                        icon: "error",
                        title: "Please try again Login",
                        text: "There was  an error unauthorized.",
                    });
                    navigate("/login");
                }
                Swal.fire({
                    icon: 'error',
                    title: 'Please try again Login',
                    text: 'There was an error.'
                });
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [headers, showModalUpdate, open, navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        const selectedstream = subcategoryOptions.find((cat) => cat.id == value);
        if (name === 'course_subcategory') {
            setSelectedSubCategory(selectedstream ? selectedstream.course_name : '');
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://portal.mycollegepedia.com/college/college-eligibility/${id}/`, {
                headers,
            });
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                timer: 3000,
                timerProgressBar: true,
                text: 'Eligibility deleted successfully.',
            });
            navigate("/college-dashboard/eligibility/")

        } catch (error) {
            console.error('Error deleting eligibility:', error);
        }
    };
    if (!Eligibility) {
        return (
            <div className='container'>
                <div className='row'>
                    <p className="my-5"><LinearProgress />  </p>
                </div>
            </div>
        )
    }

    return (
        <div className='px-3 container-fluid common_dash'>
            <div className="row align-items-center top ">
                <div className="col-lg-6">
                    <h3>Eligibility</h3>
                </div>
                <div className="col-lg-6">
                    <Breadcrumb className="bread">
                        <Breadcrumb.Item ><NavLink to="/college-dashboard">Home</NavLink></Breadcrumb.Item>
                        <Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>

            <div>
                {
                    open ? <CollelegeEligibilty handleopen={handleopen} />
                        :
                        <div className='conatiner'>
                            <button onClick={handleopen} className="btn-sm elig-btn shadow-sm flex mt-5 gap-1.5 text-white items-center border bg-[#052963]" data-toggle="modal" href="#edit">
                                <FaPlus />
                                Add
                            </button>

                            <div className='card mt-5'>
                                <div className='table-responsive thin_scroll elig-table'>
                                    <table className="table">
                                        <thead>
                                            <tr className='bg-[whitesmoke]'>
                                                <th> Exam</th>
                                                {/* <th> Category</th> */}
                                                <th> Subcategory</th>
                                                <th> Stream</th>
                                                <th> Start-date</th>
                                                <th> End-date</th>
                                                <th> Action</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {Eligibility && Eligibility.map((eligibility) => (
                                                <tr key={eligibility.id}>
                                                    <td>
                                                        {exam && exam.map((value, i) => (
                                                            value.id === eligibility.exam ? (
                                                                <span key={i} className='lowercase width_define'>
                                                                    {value.title}
                                                                </span>
                                                            ) : null
                                                        ))}
                                                    </td>


                                                    {/* <td>
                                                        {category && category.map((value, i) => (
                                                            value.id === eligibility.category ? (
                                                                <span key={i} className='lowercase'>
                                                                    {value.name}
                                                                </span>
                                                            ) : null
                                                        ))}
                                                    </td> */}
                                                    <td>
                                                        {subcategoryOptions && subcategoryOptions.map((value, i) => (
                                                            value.id === eligibility.course_subcategory ? (
                                                                <span key={i} className='lowercase width_define'>
                                                                    {value.course_name}
                                                                </span>
                                                            ) : null
                                                        ))}
                                                    </td>
                                                    <td>
                                                        {streamall && streamall.map((value, i) => (
                                                            value.id === eligibility.course_stream ? (
                                                                <span key={i} className='lowercase'>
                                                                    {value.name}
                                                                </span>
                                                            ) : null
                                                        ))}
                                                    </td>

                                                    <td>
                                                        {eligibility.application_start_date ? eligibility.application_start_date : " --- "}
                                                    </td>

                                                    <td>{eligibility.application_end_date || " --- "}</td>
                                                    
                                                        

                                                    <td className='direction_of_btn d-flex gap-2'>
                                                        <button className='bg-warning text-white p-1 ' title='Update' onClick={() => handleSelectEligibility(eligibility)}>

                                                            <FaPen />
                                                        </button>
                                                        <button  className='bg-success text-white p-1 '>
                                                            <Link to={`/colleges/${college[0].slug}`} title={college[0].name}>
                                                                <FaEye />
                                                            </Link>
                                                        </button>
                                                        <button className='bg-danger text-white p-1 ' title='Delete' onClick={() => handleDelete(eligibility.id)}>
                                                        <FaTrash />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                }

            </div>
            <Modal show={showModalUpdate} onHide={handleModalCloseUpdate}>
                <div className='!justify-end float-right flex m-1.5'>
                    <Button variant="secondary" className='text-black max-w-fit hover:text-white' onClick={handleModalCloseUpdate}>
                        <FaTimes />
                    </Button>
                </div>
                <Modal.Header closeButton>
                    <Modal.Title>Update Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="p-2 bg-gray-50" onSubmit={handleUpdate} encType="multipart/form-data">
                        <div className="row form-row">
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor='courseCategory'>Course Category</label>
                                    <select
                                        className='form-control'
                                        id='Category'
                                        name='category'
                                        onChange={handleChange}
                                        value={formData.category}
                                    >
                                        <option value=''>Select Category</option>
                                        {category &&
                                            category.map((category) => (
                                                <option key={category.id} value={category.id}>
                                                    {category.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor='subcategory'>Course Subcategory</label>
                                    <select
                                        className='form-control'
                                        id='subcategory'
                                        name='course_subcategory'
                                        onChange={handleChange}
                                        value={formData.course_subcategory}
                                    >
                                        <option value=''>Select Subcategory</option>
                                        {subcategoryOptions &&
                                            subcategoryOptions.map((subcategory) => (
                                                <option key={subcategory.id} value={subcategory.id}>
                                                    {subcategory.course_name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className='form-group'>
                                    <label htmlFor='stream'>Course Stream</label>
                                    <select
                                        className='form-control'
                                        id='stream'
                                        name='course_stream'
                                        onChange={handleChange}
                                        value={formData.course_stream}
                                    >
                                        <option value=''>Select Stream</option>
                                        {stream &&
                                            stream.map((stream) => (
                                                <option key={stream.id} value={stream.id}>
                                                    {stream.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6">
                                <div className="form-group">
                                    <label>Exam</label>
                                    <select
                                        className='form-control'
                                        name='exam'
                                        onChange={handleChange}
                                        value={formData.exam}
                                    >
                                        <option value=''>Select exam</option>
                                        {exam &&
                                            exam.map((exam) => (
                                                <option key={exam.id} value={exam.id}>
                                                    {exam.exam.title}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6">
                                <div className="form-group">
                                    <label>Eligibility</label>
                                    <input
                                        type="text"
                                        name="eligibility"
                                        className="form-control"
                                        placeholder="Eligibility Criteria"
                                        value={formData.eligibility}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="col-12 col-sm-6">
                                <div className="form-group">
                                    <label>Start Date</label>
                                    <input
                                        type="date"
                                        name="application_start_date"
                                        className="form-control"
                                        value={formData.application_start_date}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-sm-6">
                                <div className="form-group">
                                    <label>End Date</label>
                                    <input
                                        type="date"
                                        name="application_end_date"
                                        className="form-control"
                                        value={formData.application_end_date}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Description</label>
                                    <JoditEditor

                                        value={formData.description}
                                        onChange={(value) => handleChange({ target: { name: 'description', value } })}
                                    />
                                </div>
                            </div>


                        </div>
                        <div className='flex item-center gap-3 '>
                            <button
                                type="submit"
                                className="text-[blue] btn btn-primary btn-block"
                            >
                                {'Update'}
                            </button>
                            <Button variant="secondary" className='text-black hover:text-white' onClick={handleModalCloseUpdate}>
                                Close
                            </Button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>
        </div >
    );
};
export default Eligibility