import React, { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";
import { LinearProgress } from "@mui/material";
import Apicontext from "../../Page/Contextapi";

const CollegeuserUpdate = ({ handlefunction }) => {
    const token = useMemo(() => localStorage.getItem('token') || '', []);
    const [Loading, setLoading] = useState(true);
    const [category, setCategory] = useState([]);
    const [religion, setReligion] = useState([]);
    const [country, setCountry] = useState([]);
    const [state, setState] = useState([]);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        profile: null,
        gender: '',
        dob: '',
        religion: '',
        category: '',
        department: '',
        designation: '',
        country: '',
        state: '',
        city: '',
        current_address: '',
        permanent_address: '',
        zipcode: '',
      });
      const contextdata = useContext(Apicontext);
      // console.log("user-profile", collegeData)
    
      const headers = useMemo(() => ({
        Authorization: `JWT ${token}`,
      }), [token]);
    
      const handlechange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
      const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
    
        if (imageFile) {
    
          setFormData((prevData) => ({
            ...prevData,
            profile: imageFile,
          }));
        }
      };
    
    
      useEffect(() => {
        const data = contextdata.APIdata;
        setReligion(data.religion)
        setCategory(data.castecategory)
        setCountry(data.Country)
        setState(data.State)
    
        const fetchData = async () => {
          try {
            const collegeResponse = await axios.get('https://portal.mycollegepedia.com/account/college-profile/', {
              headers,
            });
            setLoading(false)
            setFormData(collegeResponse.data);
    
          } catch (error) {
            if (error.response && error.response.status === 401) {
              localStorage.removeItem('token');
              Swal.fire({
                icon: 'error',
                title: 'Please try again Login',
                text: 'There was an error.'
              });
              navigate('/login');
            }
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, [headers, navigate, contextdata ,handlefunction]);
     
    
    

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
    
          const formDataToUpdate = new FormData();
          formDataToUpdate.append('name', formData.name);
          formDataToUpdate.append('email', formData.email);
          formDataToUpdate.append('mobile', formData.mobile);
          formDataToUpdate.append('gender', formData.gender);
          formDataToUpdate.append('dob', formData.dob);
          formDataToUpdate.append('religion', formData.religion);
          formDataToUpdate.append('category', formData.category);
          formDataToUpdate.append('department', formData.department);
          formDataToUpdate.append('designation', formData.designation);
          formDataToUpdate.append('state', formData.state);
          formDataToUpdate.append('country', formData.country);
          formDataToUpdate.append('city', formData.city);
          formDataToUpdate.append('current_address', formData.current_address);
          formDataToUpdate.append('permanent_address', formData.permanent_address);
          formDataToUpdate.append('zipcode', formData.zipcode);
    
          if (formData.profile && typeof formData.profile !== 'string') {
            formDataToUpdate.append('profile', formData.profile);
          }
    
          const response = await axios.patch(`https://portal.mycollegepedia.com/account/college-profile/`, formDataToUpdate, {
            headers,
          });
        //   console.log(response)
          Swal.fire({
            icon: 'success',
            title: 'Success',
            timer: 3000,
            position: 'top-end',
            text: 'Profile updated successfully.',
          });
          handlefunction();
        } catch (error) {
          if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
            Swal.fire({
              icon: 'error',
              title: 'Please try again Login',
              text: 'There was an error.'
            });
            navigate('/login');
          }
          console.error('Error updating profile:', error);
    
        }
      };
      if (Loading) {
        return <div>
          <div className="p-2 m-5 ">
            <LinearProgress />
          </div>
        </div>
      }
    return (
        <div className='conatiner p-2'>
            <div className=''>
                <form className="p-3 common_dash_form" onSubmit={handleUpdate} >
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className="card-body">
                                <h5 className="mb-3">Company Informaion</h5>
                                <div className="card border shadow">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label for="exampleFormControlInputcomname">Name</label>
                                                    <input type="text" className="form-control" id="exampleFormControlInputcomname"
                                                        placeholder="Enter your Name" onChange={handlechange} name="name" value={formData.name} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Image</label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        name="profile"
                                                        accept="image/*"
                                                        onChange={handleImageChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label for="email">Email</label>
                                                    <input type="email" className="form-control" id="eml" onChange={handlechange} name="email" value={formData.email} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label for="email">mobile</label>
                                                    <input type="number" className="form-control cm-number" id="eml" onChange={handlechange} name="mobile" value={formData.mobile} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label for="department">department</label>
                                                    <input type="text" className="form-control" id="eml" onChange={handlechange} name="department" value={formData.department} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label for="department">designation</label>
                                                    <input type="text" className="form-control" id="designation" onChange={handlechange} name="designation" value={formData.designation} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label>Date of Birth *</label>
                                                    <input
                                                        className="form-control datatable"
                                                        type="date"
                                                        name="dob"
                                                        value={formData.dob}
                                                        onChange={handlechange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label>Religion</label>
                                                    <select
                                                        className="form-control form-select"
                                                        name="religion"
                                                        value={formData.religion}
                                                        onChange={handlechange}
                                                    >
                                                        <option value='' >Select religion</option>
                                                        {religion &&
                                                            religion.map((item) => (
                                                                <option key={item.id} value={item.id}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label>category</label>
                                                    <select
                                                        className="form-control form-select"
                                                        name="category"
                                                        value={formData.category}
                                                        onChange={handlechange}
                                                    >
                                                        <option value='' >Select category</option>
                                                        {category &&
                                                            category.map((item) => (
                                                                <option key={item.id} value={item.id}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label>Gender *</label>
                                                    <select
                                                        name="gender"
                                                        className="form-control form-select"
                                                        value={formData.gender}
                                                        onChange={handlechange}
                                                    >
                                                        <option value="">Select Gender</option>
                                                        <option value='MALE'>MALE</option>
                                                        <option value='FEMALE'>FEMALE</option>
                                                        <option value='OTHERS'>OTHERS</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <h5 className=' mt-3 mb-3'>Address</h5>

                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlInputCountry">Country</label>
                                            <select
                                                className="form-select form-control"
                                                aria-label="Select country"
                                                name="country"
                                                value={formData.country}
                                                onChange={handlechange}
                                            >
                                                <option value="">---</option>
                                                {country && country.map((country) => (
                                                    <option key={country.id} value={country.id}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlInputState">State</label>
                                            <select
                                                className="form-select form-control"
                                                aria-label="Select state"
                                                name="state"
                                                value={formData.state}
                                                onChange={handlechange}
                                            >
                                                <option value="">---</option>
                                                {state && state.map((state) => (
                                                    <option key={state.id} value={state.id}>
                                                        {state.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className="form-group">
                                            <label for="exampleFormControlInputCity">City</label>
                                            <input type="text" className="form-control"
                                                placeholder="City" onChange={handlechange} name="city" value={formData.city} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className="form-group">
                                            <div className="form-group">
                                                <label for="exampleFormControlInputZC">Zip Code</label>
                                                <input type="number" className="form-control"
                                                    name="zipcode" onChange={handlechange} value={formData.zipcode} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label for="exampleFormControlInputAds">Address</label>
                                            <textarea className="form-control" id="exampleFormControlInputAds"
                                                rows="3" name="permanent_address" onChange={handlechange} value={formData.permanent_address}></textarea>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                    <Modal.Footer>
                        <Button className="button_of_close" onClick={() => handlefunction()}>
                            Close
                        </Button>
                        <Button className="button_of_submit" type="submit">
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </div>
        </div>
    )
}

export default CollegeuserUpdate