import React, { useState, useEffect, useMemo } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FaEye, FaPen, FaPlus,  FaTrash } from 'react-icons/fa';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import UpdateAwardsachivement from './UpdateAwardsachivement';
import { LinearProgress } from "@mui/material";


const Awards = () => {
    const token = useMemo(() => localStorage.getItem('token') || '', []);
    const college = JSON.parse(localStorage.getItem('collegerecord'));
    const [awards, setAwards] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [awardId, setAwardId] = useState(null);
   const [Loading, setLoading] = useState(true);

    const navigate = useNavigate();
    const handleFunction = (awid) => {
        setAwardId(awid);
        setShowModal(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://portal.mycollegepedia.com/college/awards-achivements/', {
                    headers: {
                        Authorization: `JWT ${token}`,
                    },
                });
                setAwards(response.data);
                setLoading(false)
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('token');
                }
                Swal.fire({
                    icon: 'error',
                    title: 'Please try again Login',
                    text: 'There was an error.'
                });
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [token,navigate]);

    const recordid = async (awardId) => {
        try {
            await axios.delete(`https://portal.mycollegepedia.com/college/awards-achivements/${awardId}/`, {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            });
            setAwards((prevawards) => prevawards.filter((faq) => faq.id !== awardId));
            Swal.fire({
                position:'top-end',
                timer:3000, 
                icon:'success'  ,                
                text: 'Deleted successfully!',
            });
           navigate("/college-dashboard/awards-achivements")

        } catch (error) {
            console.error('Error updating award:', error);
        }
    };
    if (Loading) {
        return (
            <>
                <p className="my-10 p-3">
                    {" "}
                    <LinearProgress />
                </p>
            </>
        );
    }
    return (
        <div>
            <div className='container-fluid common_dash px-3 my-3'>
                <div className="row align-items-center top">
                    <div className="col-lg-6">
                        <h3>Awards & Achievements</h3>
                    </div>
                    <div className="col-lg-6">
                        <Breadcrumb className="bread">
                            <Breadcrumb.Item><NavLink to="/college-dashboard">Home</NavLink></Breadcrumb.Item>
                            <Breadcrumb.Item active>Awards & Achievements</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>

                {/* Conditional rendering for the modal */}
                {showModal && awardId !== null ? (
                    <UpdateAwardsachivement handleFunction={() => setShowModal(false)} token={token} awardId={awardId} />
                ) : (

                <div>
                    <div className='md:justify-end row'>
                        <Link to={"/college-dashboard/add-awards-achivements"} className="btn-sm shadow-sm flex m-3 gap-1.5 md:float-right text-white items-center border bg-[#052963]">
                            <FaPlus />
                            Add
                        </Link>
                    </div>

                    <div className='card table-responsive thin_scroll elig_table  mt-2'>
                        <table className="table ">
                            <thead>
                                <tr className='bg-[whitesmoke]'>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Image</th>
                                    <th>Alt Text</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {awards && awards.map((award) => (
                                    <tr key={award.id}>
                                        <td>{award.title}</td>
                                        <td><div dangerouslySetInnerHTML={{ __html: award.description }} /></td>
                                        <td><img src={award.image} alt={award.alt} className='max-w-[120px] w-[100%]' /></td>
                                        <td>{award.alt}</td>
                                        <td className='direction_of_btn d-flex gap-2'>
                                            <button onClick={() => handleFunction(award.id)} title='update' className='bg-warning text-white p-1'>
                                                    <FaPen />
                                            </button>
                                            <button className='bg-success text-white p-1' >
                                                <Link title='view more' to={`/college/${college[0].slug}`}>
                                                    <FaEye />
                                                </Link>
                                            </button>
                                            <button onClick={() => recordid(award.id)} title='deleted' className='bg-danger text-white p-1'>
                                            
                                                    <FaTrash />
                                
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                )}
            </div>
        </div>
    );
};

export default Awards;
