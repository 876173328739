import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { FaPlus, FaTrash } from 'react-icons/fa';
import {  NavLink } from 'react-router-dom';

import Swal from 'sweetalert2';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Collegevideosadd from './Collegevideosadd';

const CollegeVideos = () => {
  const token = useMemo(() => localStorage.getItem('token') || '', []);
  const [videos, setVideos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const handleupdate = () => setShowModal(!showModal);
  
  const headers = useMemo(() => ({
    Authorization: `JWT ${token}`,

  }), [token]);
 


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://portal.mycollegepedia.com/college/intro-video/', {
          headers: {
            Authorization: `JWT ${token}`,
          },
        });

        setVideos(response.data);
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchData();
  }, [token]);

 


  const handleDeleteVideo = async (videoId) => {
    try {
      await axios.delete(`https://portal.mycollegepedia.com/college/intro-video/${videoId}/`, {
        headers,
      });
      Swal.fire({
        position: 'top-end',
        timer:2500,
        progressSteps:true,
        icon: 'success',
        text: 'video deleted successfully!',
      });
      setVideos(prevVideos => prevVideos.filter(video => video.id !== videoId));
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'There was an error Please try again.',
      });
      console.error('Error deleting video:', error);
    }
  };

  return (
    <div className="px-3 container-fluid common_dash">
      <div className="row align-items-center top   ">
        <div className="col-lg-6">

          <h3>College Videos</h3>
        </div>
        <div className="col-lg-6">
          <Breadcrumb className="bread">
            <Breadcrumb.Item ><NavLink to="/college-dashboard">Home</NavLink></Breadcrumb.Item>

            <Breadcrumb.Item active>College-Videos</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {/* <h1 className="mb-4 text-center">College-Videos</h1> */}
      {showModal ?
        <Collegevideosadd handleupdate={handleupdate} /> :

        <div className=''>
          <button
            className="btn-sm shadow-sm flex m-3 gap-1.5 float-right text-white items-center border bg-[#052963]"
            onClick={() => setShowModal(true)}
          >
            <FaPlus />
            Add
          </button>

          <div className="row">
            {videos && videos.map((video) => (
              <div key={video.id} className="mb-4 col-md-4">
                <div className="card">
                  <div className=" border rounded-lg">
                    <video controls width="100%" height="100px">
                      <source src={video.videofile} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div className="card-footer">
                    <h5 className="card-title">{video.title}</h5>
                    <div className="card-text line-clamp-6" dangerouslySetInnerHTML={{ __html: video.description }} />
                    <button
                      className="flex items-center float-right gap-2 mt-4 btn-sm btn-danger"
                      onClick={() => handleDeleteVideo(video.id)}
                    >
                      <FaTrash />
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      }
    </div>
  );
};
export default CollegeVideos;