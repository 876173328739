import React, { useState, useEffect, useContext } from 'react';
import engineertab from "../../assets/images/engineertab.png";
import { Link } from 'react-router-dom';
import Apicontext from '../../Page/Contextapi';
import { FaGlobe } from 'react-icons/fa';
import StarRating from './Startrating';

const TopSection = () => {
   
   const [Data, setData] = useState("");
   const [Colleges, setColleges] = useState([]);
   
   const contextdata = useContext(Apicontext);
   useEffect(() => {
      const APIdata = contextdata.APIdata;
      setData(APIdata);
      const sortedColleges = APIdata.college.sort((a, b) => {
         return new Date(b.created_at) - new Date(a.created_at); 
      });
      setColleges(sortedColleges);

   }, [contextdata]);

   return (
      <>
         <div className="container warpper">
            <input className="radio" id="one" name="group" type="radio" defaultChecked />
            <input className="radio" id="two" name="group" type="radio" />
            <input className="radio" id="three" name="group" type="radio" />
            <input className="radio" id="four" name="group" type="radio" />
            <div className="tabs">
               <label className="tab" id="one-tab" htmlFor="one">
                  {/* <img src={collegetab} alt="College tab" title='college-category' className='mx-auto ' /> */}
                  <p>College</p>
               </label>
               <label className="tab" id="two-tab" htmlFor="two">
                  {/* <img src={examtab} alt="Exam tab" title="exam" className='mx-auto' /> */}
                  <p>Exam</p>
               </label>
               <label className="tab" id="three-tab" htmlFor="three">
                  {/* <IoBookOutline className='mx-auto text-blue-900 w-[52px] h-[29px]' /> */}
                  <p>Course</p>
               </label>
               <label className="tab" id="four-tab" htmlFor="four">
                  {/* <IoLocationOutline className='mx-auto text-red-500 w-[52px] h-[26px]' /> */}
                  <p>Counsellor</p>
               </label>
               <span className="glider"></span>
            </div>
            <div className="flex-wrap panels ">
               <div className="panel" id="one-panel">
                  <div className="panel-title ">
                     <div className="grid grid-cols-2 gap-6 lg:grid-cols-4  ">
                        {Colleges && Colleges.slice(0, 12).map((college, index) => (
                           <div key={index} className="grid gap-2 px-2 py-3 overflow-hidden text-center cols web-tab-content rounded-[6px] md:lg:h-[150px] hover:bg-gray-100 hover:shadow-inner">
                              <Link to={`/college/${college.slug}`} title={college.name} aria-label={college.name}  className="tabs-links-click">
                              <div className=" d-flex rounded-full justify-content-around">
                                 
                                 <div className='pic'>
                             
                                 <img src={college.logo ? college.logo : engineertab} alt={college.name} title={college.name} className='text-base' loading='lazy' />
                             
                                </div>
                                <div className="second">
                                <p className='mode'>Private</p>
                                 <div className='rating'><StarRating rating={college.rating} /></div>
             
                                </div>
                              </div>
                              </Link>
                              <div className="web-tabs-para ">
                                 <p className="web-tabs-links">
                                    <Link to={`/college/${college.slug}`} title={college.name} aria-label={college.name}  className="tabs-links-click">
                                       {college.name}
                                    </Link>
                                 </p>
                              </div>
                           </div>
                        ))}
                     </div>
                  </div>
                  <div className='items-center my-5 text-center'>
                     <Link to="/all-colleges" aria-label='All college' title='view more college' className='rounded-md book-more-button px-3 py-1 bg-[#052963] text-white opacity-90' >All College</Link>
                  </div>
               </div>
               <div className="panel" id="two-panel">
                  <div className="panel-title ">
                     <div className="grid grid-cols-2 gap-6 lg:grid-cols-4 ">
                        {Data && Data.exam.slice(0, 12).map((exam, index) => (
                           <Link to={`/exam/${exam.slug}`} title={exam.title} aria-label='detial-page' className="tabs-links-click" key={index}>
                           <div  className="grid gap-2 px-1 py-2 overflow-hidden text-center cols web-tab-content rounded-[6px]">

                              <div className="mx-auto">
                                 
                                 <img src={exam.exam.image ? exam.exam.image : engineertab} title={exam.title || "view more"} alt={exam.title || "category logo"} loading='lazy' className='text-base' />
                              </div>
                              <div className="web-tabs-para ">
                                 <p className="web-tabs-links">
                                    <Link to={`/exam/${exam.slug}`} title={exam.title} aria-label='detial-page' className="tabs-links-click">
                                       {exam.exam.title}
                                    </Link>
                                 </p>
                              </div>
                           </div>
                           </Link>
                        ))}
                     </div>
                  </div>
                  <div className='items-center my-5 text-center'>
                   { Data && Data.exam.length >= 10 &&   <Link to="/all-exams" title='Exams' className='rounded-md book-more-button px-3 py-1  bg-[#052963] text-white opacity-90' >View All Exam</Link> }
                  </div>
               </div>
               <div className="panel" id="three-panel">
                  <div className="panel-title ">
                     <div className="grid grid-cols-2 gap-6 lg:grid-cols-4 ">
                        {Data && Data.category.slice(0, 12).map((category, index) => (
                           <Link to={`/category/${category.name}`} title={category.name || "view more"} className="tabs-links-click" key={index}>
                           <div  className="grid gap-2 px-1 py-2 overflow-hidden text-center cols web-tab-content rounded-[6px]">
                              <div className="mx-auto web-tabs-images w-14 h-14">
                                 <img src={category.image ? category.image : engineertab} alt={category.name} title={category.name} className='text-base' loading='lazy' />
                              </div>
                              <div className="web-tabs-para ">
                                 <p className="web-tabs-links">
                                    
                                       {category.name}
                                 
                                 </p>
                              </div>
                           </div>
                           </Link>
                        ))}
                     </div>
                  </div>
                  <div className='items-center my-5 text-center'>
                     <Link to="/all-colleges" title='colleges' className='rounded-md book-more-button px-3 py-1 bg-[#052963] text-white opacity-90' >View All College</Link>
                  </div>
               </div>
               <div className="panel" id="four-panel">
                  <div className="panel-title ">
                     <div className="grid grid-cols-2 gap-6 lg:grid-cols-4 ">
                        {Data && Data.State.slice(0, 12).map((State) => (
                           <div key={State.id} className="grid gap-2 px-1 py-2 overflow-hidden text-center cols web-tab-content rounded-[6px]">
                              <div className="mx-auto web-tabs-images">
                                 <FaGlobe className='w-12 h-12 text-[#052963] cal-icon'/>
                              </div>
                              <div className="web-tabs-para ">
                                 <p className="web-tabs-links">
                                    <Link to={`/state/${State.name}`} title={State.name || "view more"} className="tabs-links-click">
                                       {State.name}
                                    </Link>
                                 </p>
                              </div>
                           </div>
                        ))}
                     </div>
                  </div>
                  <div className='items-center my-5 text-center'>
                     <Link to="/all-exams"  title={'Exams'} className='rounded-md look-more-button px-3 py-1 bg-[#052963] text-white opacity-90' >View All Exams</Link>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};
export default TopSection;