import React from 'react';
import RichText from '../CollegeDashboard/Subpages/Richtext';

const CompanyOverview = ({ formData, handleInputChange, data, handleRichTextChange }) => {
    

    return (
        <div className='row'>
            <div className="form-group col-md-4">
                <label>Area of Operation</label>
                <div className="check_box_category">
                    {data && data.category.map((cate) => (
                        <div key={cate.id}>
                            <input
                                type="checkbox"
                                id={cate.id}
                                name="area_of_operation"
                                value={cate.id}
                                onChange={handleInputChange}
                            />
                            <label>{cate.name}</label>
                        </div>
                    ))}
                </div>
            </div>
            <div className="form-group col-md-8">
                <label>Overview</label>
                <RichText
                    className="form-control"
                    name="overview"
                    value={formData.overview}
                    onChange={handleRichTextChange}
                />
            </div>
        </div>
    );
};

export default CompanyOverview;