import axios from 'axios';
import React, { useMemo } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import smallbanner from '../../assets/images/predictor.jpg'
import { LinearProgress } from '@mui/material';
const CollegeNews = ({ college_id }) => {
    const token = useMemo(() => localStorage.getItem('token') || '', []);
    const [newsdata, setNewsData] = useState([]);
    const [Loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://portal.mycollegepedia.com/college/news-articles/${college_id}`);
                // console.log("response",response)
                const data = response.data;
                setNewsData(data);
                setLoading(false);
            } catch (error) {

                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [token,college_id]);

    if (Loading) {
        return (
          <div className='grid'>
            <div className='my-12 p-5'>
              <LinearProgress />
            </div>
    
          </div>
        )
      }

    return (
        <div className='gallery'>
            <div className='long_border'>
                <h3 className='text-center'>News & Aritcle</h3>
            </div>
            <div className='h_of_card'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='picc mt-2'>
                            <img src={smallbanner} alt={""} title={''} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='picc mt-2'>
                            <img src={smallbanner} alt={""} title={''} />
                        </div>
                    </div>

                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='picc mt-2'>
                            <img src={smallbanner} alt={""} title={''} />
                        </div>
                    </div>

                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='picc mt-2'>
                            <img src={smallbanner} alt={""} title={''} />
                        </div>
                    </div>

                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='picc mt-2'>
                            <img src={smallbanner} alt={""} title={''} />
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}


export default CollegeNews