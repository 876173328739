import React, { useContext, useEffect, useMemo, useState } from "react";
import { FaMapLocationDot } from "react-icons/fa6";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {  Button, } from "react-bootstrap";
import { LinearProgress } from "@mui/material";
import Apicontext from "../../Page/Contextapi";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { NavLink } from "react-router-dom";
import CompanyUpdate from "./CompanyUpdate";
const CompanyProfile = () => {
  const token = useMemo(() => localStorage.getItem('token') || '', []);
  const [counsellorData, setcounsellorData] = useState('');
  const [Loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const handlefunction = () => setShowModal(!showModal);

  const contextdata = useContext(Apicontext);

  const data = contextdata.APIdata;

  const headers = useMemo(() => ({
    Authorization: `JWT ${token}`,
  }), [token]);


  useEffect(() => {

    const fetchData = async () => {
      try {
        const collegeResponse = await axios.get('https://portal.mycollegepedia.com/account/counsellor-profile/', {
          headers,
        });
        setcounsellorData(collegeResponse.data);
        setLoading(false)

      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          Swal.fire({
            icon: 'error',
            title: 'Please try again Login',
            text: 'There was an error.'
          });
          navigate('/login');
        }
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [headers, showModal , contextdata]);



  if (Loading) {
    return <div className="my-10 px-5"><LinearProgress /></div>;
  }
  return (
    <>
      <div className="profile_of_student my-3 mb-5 px-3 common_dash ">
        <div className="container-fluid">

          <div className="row align-items-center top   ">
            <div className="col-lg-6">
              <h3>Profiler</h3>
            </div>
            <div className="col-lg-6">
              <Breadcrumb className="bread">
                <Breadcrumb.Item ><NavLink to="/college-dashboard">Home</NavLink></Breadcrumb.Item>
                <Breadcrumb.Item active>Profiler Name</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          {showModal && showModal ? (
            <CompanyUpdate handlefunction={handlefunction} profiledata={counsellorData} data={data} />
          ) : (
            <div>
              <div className="row">
                <div className="col-lg-12">
                  <Button className="float-right mb-3 px-3 py-1 bg-[#052963]" onClick={handlefunction}>
                    Edit
                  </Button>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md -12 col">

                  <div className=" card px-auto ">

                    <div className="row">
                      <div className="col-md-4 col">

                        <div className="student_image">
                          <img src={counsellorData.profile ? counsellorData.profile : " "} title={counsellorData.name} alt="student_image" />
                        </div>

                        <div id="name">
                          <h1>
                            Brand  Name : <span>{counsellorData.brand_name}</span>
                          </h1>
                        </div>
                        <div className="location ">
                          <span className="icon">
                            <FaMapLocationDot />
                          </span>
                          <span className="ltext">{counsellorData.designation}</span>
                        </div>

                      </div>
                      <div className="col-lg-8 col-md-12 col ">
                        <h3 className="hback">Personal Information</h3>
                        <div className="second">
                          {/* <Button className="float-right" variant="dark text-dark" onClick={handleModalShow}>
                        <FaEdit />
                      </Button> */}

                          <div className="mb-2 borderbt row">
                            <div className="col-lg-5 col-md-4">
                              <span id="id">Name : </span>
                            </div>
                            <div className="col-lg-7 col-md-8">
                              <span>{counsellorData.name}</span>
                            </div>
                          </div>
                          <div className="mb-2 borderbt row">
                            <div className="col-lg-5 col-md-4">

                              <span id="dob">D.O.B : </span></div>
                            <div className="col-lg-7 col-md-8">

                              <span>{counsellorData.dob} </span></div>
                          </div>
                          <div id="display" className="mb-2 borderbt row">
                            <div className="col-lg-5 col-md-4">

                              <span id="email">Email : </span></div>
                            <div className="col-lg-7 col-md-8">

                              <span>{counsellorData.email}</span></div>
                          </div>
                          <div className="mb-2 borderbt row">
                            <div className="col-lg-5 col-md-4">
                              <span id="gender">Gender : </span></div>
                            <div className="col-lg-7 col-md-8">

                              <span className="lowercase">{counsellorData.gender}</span></div>
                          </div>
                          <div className="mb-2 borderbt row">
                            <div className="col-lg-5 col-md-4">

                              <span id="id">Mobile : </span></div>
                            <div className="col-lg-7 col-md-8">

                              <span>{counsellorData.mobile}</span></div>
                          </div>
                          <div className="mb-2 borderbt row">
                            <div className="col-lg-5 col-md-4">

                              <span id="rel">  Religion :</span></div>
                            {data.religion.map((religion, i) =>
                              religion.id === counsellorData.religion ? (
                                <div className="col-lg-7 col-md-8">
                                  <span key={i}>
                                    <span key={religion.id} className="lowercase"> {religion.name}</span>
                                  </span></div>
                              ) : null
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 col-md-12 ">
                            <h3 className="hback">Address</h3>
                            <div className="second">
                              <div className="mb-2 borderbt row">
                                <div className="col-lg-5 col-md-4">

                                  <span id="rel">  Category :</span></div>
                                {data.castecategory.map((religion, i) =>
                                  religion.id === counsellorData.category ? (
                                    <div className="col-lg-7 col-md-8" key={religion.id}>

                                      <span key={i}>
                                        <span key={religion.id} className="lowercase"> {religion.name}</span>
                                      </span></div>
                                  ) : null
                                )}
                              </div>
                              <div className="mb-2 borderbt row">
                                <div className="col-lg-5 col-md-4">

                                  <span >  Address :</span></div>
                                <div className="col-lg-7 col-md-8">

                                  <span>{counsellorData.current_address}</span></div>
                              </div>
                              <div className="mb-2 borderbt row">
                                <div className="col-lg-5 col-md-4">
                                  <span id="Zip">zipcode : </span></div>
                                <div className="col-lg-7 col-md-8">

                                  <span>{counsellorData.zipcode}</span></div>
                              </div>
                              <div className="mb-2 borderbt row">
                                <div className="col-lg-5 col-md-4">

                                  <span id="city">City : </span> </div>
                                <div className="col-lg-7 col-md-8">

                                  <span>{counsellorData.city}</span></div>
                              </div>
                              <div className="mb-2 borderbt row">
                                <div className="col-lg-5 col-md-4">

                                  <span id="st">  State : </span></div>
                                {data.State.map((state, i) =>
                                  state.id === counsellorData.state ? (
                                    <div className="col-lg-7 col-md-8">

                                      <span key={i}>

                                        <span key={state.id} className="lowercase"> {state.name}</span>
                                      </span></div>
                                  ) : null
                                )}

                              </div>
                              <div className="mb-2 borderbt row">
                                <div className="col-lg-5 col-md-4">

                                  <span id="st"> Country : </span> </div>
                                <div className="col-lg-7 col-md-8">

                                  <span>india</span></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default CompanyProfile;