
import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { FaPlus } from 'react-icons/fa';
import { LinearProgress } from "@mui/material";

const FAQ = () => {
  const [faqs, setFAQs] = useState([]);
  const token = useMemo(() => localStorage.getItem('token') || '', []);
  const [Loading, setLoading] = useState(true);

  const headers = useMemo(() => ({
    Authorization: `JWT ${token}`,
  }), [token]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://portal.mycollegepedia.com/college/college-faq/',{headers});
        setFAQs(response.data);
        setLoading(false)
      } catch (error) {
        console.error('Error fetching FAQs:', error);
      }
    };
    fetchData();
  }, [headers]);

  const handleDeleteFAQ = async (faqId) => {
    try {
      await axios.delete(`https://portal.mycollegepedia.com/college/college-faq/${faqId}/`,{headers});
      setFAQs((prevFAQs) => prevFAQs.filter((faq) => faq.id !== faqId));
      Swal.fire({
        position:'top-end',
        icon: 'success',
        timer:3000,
        text: 'FAQ deleted successfully!',
      });
    } catch (error) {
      console.error('Error deleting FAQ:', error);
    }
  };
  if (Loading) {
    return (
        <>
            <p className="my-10 p-3">
                {" "}
                <LinearProgress />
            </p>
        </>
    );
}
  return (
    <div className='conatiner common_dash px-3 my-3'>
      <div className="row align-items-center top   ">
        <div className="col-lg-6">
          <h3>FAQs</h3>
        </div>
        <div className="col-lg-6">
          <Breadcrumb className="bread">
            <Breadcrumb.Item ><Link to="/college-dashboard">Home</Link></Breadcrumb.Item>
            <Breadcrumb.Item active>FAQ's</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <div className="">
        <div className='justify-end row'>
          <Link to="/college-dashboard/college-faqs/college-add-faqs" className="btn-sm shadow-sm flex m-3 gap-1.5 float-right text-white items-center border bg-[#052963]">
            <FaPlus />
            Add
          </Link>
        </div>

        <div className='p-2 border row'>
          <div className='col-12' id="accordion">
            {faqs.map((faq) => (
              <div className="card !mb-1" key={faq.id}>
                <div className="card-header" id={`faqHeading${faq.id}`}>
                  <h5 className="mb-0">
                    <div className='d-block'>
                      
                    <button
                      className="btn faqbtn"
                      data-toggle="collapse"
                      data-target={`#faqCollapse${faq.id}`}
                      aria-expanded="true"
                      aria-controls={`faqCollapse${faq.id}`}
                    >
                      <p className='px-1 faqquestion '> {faq.question}</p>
                    </button>
                    <button className="float-right ml-2 btn btn-danger btn-sm" onClick={() => handleDeleteFAQ(faq.id)}>Delete FAQ</button>
                    <Link to={`/college-dashboard/college-faqs/update-faqs/${faq.id}`} className="float-right ml-2 btn btn-primary btn-sm">Update FAQ</Link>
                    </div>
                  </h5>
                </div>

                <div
                  id={`faqCollapse${faq.id}`}
                  className="collapse"
                  aria-labelledby={`faqHeading${faq.id}`}
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p dangerouslySetInnerHTML={{ __html: faq.answer }} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
