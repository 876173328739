import React from 'react';

function Herosection() {

  return (
    <section className="static-section mb-5">
      <div className="container-fluid  static " >
        <div className="row static-head ">
          <div className="col-lg-6 static-col">
            <div className="static-text">
              <h6 className='my-5'>End-To-End Process<span> Simplified Admission</span></h6>
              <p>
                College Pedia makes your process of admission very simple. Our
                experienced team of professionals helps you in getting the best
                colleges in India for a secure future for your child.
              </p>
              <p>
                Our admission process is so simple, you just need to contact us and
                tell us about your field of study and relax.
              </p>
              <p>
                We assure you that we provide the best colleges to study in India
                specializing in Medicine, Engineering, Pharmacy, Law, and
                Management.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Herosection;
