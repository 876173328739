import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link,NavLink } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const StudentPending = () => {
    // const [showFilter, setShowFilter] = useState(false);
    const [Pendingleads, setPendingleads] = useState([]);
    const [loading, setLoading] = useState(true);
    const userToken = localStorage.getItem('token');

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = userToken;
                const headers = {
                    Authorization: `JWT ${token}`,
                };
                const response = await axios.get('https://portal.mycollegepedia.com/college/student-applied-colleges/', { headers });
                const data = response.data;
                const pendingData = data.filter(item => item.status === "Pending");
       
                setPendingleads(pendingData);
                setLoading(false)
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('token');
                }
                Swal.fire({
                    icon: 'error',
                    title: 'Unauthorized user',
                    text: 'Please try again'
                });
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();

    }, [userToken]);

    // const handleFilterToggle = () => {
    //     setShowFilter(!showFilter);
    // };

    // const handleFilterSubmit = (e) => {
    //     e.preventDefault();

    // };
    // const handleRemoveFilter = () => {
    // }

    return (
        <div>
            <div className="page-wrapper common_dash">
                <div className="px-3 container-fluid">
                <div className="row align-items-center top">
                        <div className="col-lg-6">
                            <h3>Pending</h3>
                        </div>
                        <div className="col-lg-6">
                            <Breadcrumb className="bread">
                            <Breadcrumb.Item ><NavLink to="/student-dashboard/home">Home</NavLink></Breadcrumb.Item>

                                <Breadcrumb.Item active>Pending</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                    {/* <div className="page-header">
                        <div className="row">
                            <div className="col">
                                <h3 className="page-title">Applied Colleges</h3>
                            </div>
                            <div className="float-right col-auto text-right">
                                <a
                                    className="btn btn-white filter-btn"
                                    href="#filter_search"
                                    id="filter_search"
                                    onClick={handleFilterToggle}
                                >
                                    <FaFilter className="text-2xl text-[gray] " />
                                </a>
                            </div>
                        </div>
                    </div>

                    {showFilter && (
                        <div className="card filter-card" id="filter_search">
                            <div className="pb-0 card-body">
                                <form onSubmit={handleFilterSubmit}>
                                    <div className="row filter-row">
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label>College Name</label>
                                                <input className="form-control" type="text" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label>From Date</label>
                                                <div className="cal-icon">
                                                    <input className="form-control datetimepicker" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label>To Date</label>
                                                <div className="cal-icon">
                                                    <input className="form-control datetimepicker" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <button
                                                    className="text-black border btn-lg btn-primary btn-block"
                                                    type="submit"
                                                >
                                                    Submit
                                                </button>
                                                <button
                                                    className="text-black border btn-lg btn-secondary btn-block"
                                                    type="button"
                                                    onClick={handleRemoveFilter}
                                                >
                                                    Remove Filter
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )} */}

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body tablemain">

                                    {loading ? (
                                        <p className="my-5"><LinearProgress />  </p>
                                    ) : (
                                        <div className="table-responsive thin_scroll">
                                            {Pendingleads.length === 0 ? (
                                                <table className="table">
                                                    <thead>
                                                        <tr className="bg-[whitesmoke]">
                                                            <th>Logo</th>
                                                            <th>College name</th>
                                                            <th>Course</th>
                                                            <th>Subcategory</th>
                                                            <th>Stream</th>
                                                            <th>Action</th>
                                                            {/* <th>Image</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-[white]">
                                                        <tr className='!m-8 text-lg text-red-700'>
                                                            <td colSpan="6">Not Applied to Colleges</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <table className="table mb-0  table-hover table-center">
                                                    <thead>
                                                        <tr className="bg-[whitesmoke]">
                                                            {/* <th></th> */}
                                                            {/* <th>University</th> */}
                                                            <th>College Name</th>
                                                            <th>Course</th>
                                                            <th>Course Category</th>
                                                            <th>Stream</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Pendingleads.map((record, index) => (
                                                            <tr key={index}>
                                                                {/* <td>{index + 1}</td> */}
                                                                {/* <td>
                                                                <h2 className="table-avatar">
                                                                    <a href={record.slug} className="mr-2 avatar avatar-sm">
                                                                        <img
                                                                            className="avatar-img rounded-circle"
                                                                            alt="collegeimage"
                                                                            src={record.image}
                                                                        />
                                                                    </a>
                                                                    <a href={record.universityLink}>{record.universityName}</a>
                                                                </h2>
                                                            </td> */}
                                                                <td className='width_define'>
                                                                    <Link to={record.college.slug} className="__cf_email__" >
                                                                        {record.college.name}
                                                                    </Link>
                                                                </td>
                                                                <td  className='width_define'>{record.course_category.name}</td>
                                                                <td  className='width_define'>{record.course_subcategory.course_name}</td>
                                                                <td  className='width_define'>{record.course_stream.name}</td>
                                                                <td  className='width_define' ><button className='text-white btn-sm btn-warning'>{record.status}</button></td>

                                                            </tr>
                                                        ))

                                                        }
                                                    </tbody>
                                                </table>
                                            )}
                                          </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StudentPending