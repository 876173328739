import React, { useEffect, useState, useContext } from 'react';
import CNF from "../../assets/images/clgnotfound.png";
import Apicontext from '../../Page/Contextapi';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import examlogo from "../../assets/images/predictor.jpg"
import { FcAlphabeticalSortingAz } from "react-icons/fc";
const Everyexam = () => {
    const [exams, setExams] = useState([]);
    const [card, setCard] = useState(16);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredExams, setFilteredExams] = useState([]);
    const [examsWithinWeek, setExamsWithinWeek] = useState([]);
    const [applicationWithinWeek, setAppliWithinWeek] = useState([]);
    const [result, setResult] = useState([]);
    const contextdata = useContext(Apicontext);
    const [loading, setLoading] = useState(true);
   
    const handleviewmore = () => {
        setCard(card + 4)
    }

    useEffect(() => {
        const responseData = contextdata.APIdata;
        const data = responseData.exam;
        setExams(data);

        const currentTime = new Date();
        const weekDaysLater = new Date(currentTime);
        weekDaysLater.setDate(currentTime.getDate() + 8);

        const examsWithin7 = data.filter(exam => {
            const examStartDate = new Date(exam.exam_start_date);
            return examStartDate > currentTime && examStartDate <= weekDaysLater;
        });
        setExamsWithinWeek(examsWithin7);
        const appWithin7 = data.filter(exam => {
            const appStartDate = new Date(exam.application_start_date);
            return appStartDate > currentTime && appStartDate <= weekDaysLater;
        });
        setAppliWithinWeek(appWithin7);
        const resultdate = data.filter(exam => {
            const result = new Date(exam.result);
            return result > currentTime && result <= weekDaysLater;
        });
        setResult(resultdate);

        setLoading(false);
    }, [contextdata]);

    useEffect(() => {
        const filtered = applyFilters(exams, searchQuery);
        setFilteredExams(filtered);
    }, [exams, searchQuery]);

    const applyFilters = (exams, searchQuery) => {
        let filteredExams = exams;

        if (searchQuery) {
            filteredExams = filterBySearchQuery(filteredExams, searchQuery);
        }
        if (searchQuery) {
            const titleFilteredExams = titleFilter(filteredExams, searchQuery);
            filteredExams = [...filteredExams, ...titleFilteredExams];
        }
        filteredExams = Array.from(new Set(filteredExams));
        return filteredExams;
    };

    const filterBySearchQuery = (exams, searchQuery) => {
        return exams.filter(exam =>
            exam.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    const titleFilter = (exams, searchQuery) => {
        return exams.filter(exam =>
            exam.exam.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    const handleSortChange = (event, type) => {
        const sortBy = event.target.value;

        if (sortBy === "") {
            setFilteredExams(exams); 
            return;
        }
        // console.log(filteredExams)
        let sortedData = [];
        switch (type) {
            case 'application':
                sortedData = sortExamsByDate(filteredExams, sortBy, 'application_start_date');
                break;
            case 'exam':
                sortedData = sortExamsByDate(filteredExams, sortBy, 'exam_start_date');
                break;
            case 'result':
                sortedData = sortExamsByDate(filteredExams, sortBy, 'result');
                break;
            case 'exam_mode':
                sortedData = sortExamsByMode(filteredExams, sortBy);
                break;
            default:
                break;
        }

        setFilteredExams(sortedData);
    };

    const sortExamsByDate = (data, sortBy, dateKey) => {
        const sortedData = [...data].sort((a, b) => {
            const dateA = new Date(a[dateKey]);
            const dateB = new Date(b[dateKey]);

            if (isNaN(dateA) || isNaN(dateB)) {
                return 0;
            }

            if (dateA < dateB) {
                return sortBy === 'asc' ? -1 : 1;
            } else if (dateA > dateB) {
                return sortBy === 'asc' ? 1 : -1;
            } else {
                return 0;
            }
        });

        return sortedData;
    };

    const sortExamsByMode = (data, sortBy) => {
        console.log("sorting mode ",data,sortBy)
        const sortedData = [...data].sort((a, b) => {
            if (sortBy == 'Online') {
               console.log("online")
                if (a.exam_mode === 'Online' && b.exam_mode !== 'Online') return -1;
                if (a.exam_mode !== 'Online' && b.exam_mode === 'Online') return 1;
                return 0; // Maintain order for same exam mode
            } else if (sortBy == 'Offline') {
                alert("OFonline")
                if (a.exam_mode === 'Offline' && b.exam_mode !== 'Offline') return -1;
                if (a.exam_mode !== 'Offline' && b.exam_mode === 'Offline') return 1;
                return 0; // Maintain order for same exam mode
            } else {
                return 0;
            }
        });
    
        return sortedData;
    };
    

    if (loading) {
        return (
          <div className="min-h-screen p-3 flex items-center justify-center">
            <div className="w-16 h-16 border-4 border-dashed rounded-full animate-spin border-[#052963] dark:border-[whitesmoke]"></div>
          </div>
        )
      }

    if (!filteredExams) {
        return (
            <div className='container'>
                <div className='mx-auto text-center col-lg-6 col-md-12'>
                    <div className='pt-10 text-2xl text-red-500'><p>No found Matching Exams...<span className='font-bold text-green-600'>Try Again</span></p></div>
                    <div className=''>
                        <img src={CNF} alt='errorimg' title='exam' className="mx-auto text-6xl text-red-500 " />
                    </div>
                </div>
            </div>
        )
    }
    const owlOptions = {
        autoplay: true,
        rewind: true,
        transition: 500,
        dots: false,
        responsiveClass: true,
        // autoheight: true, 
        autoplayTimeout: 5000,
        smartSpeed: 5000,
        nav: true,
        navText: ["<", ">"],
        responsive: {
            0: { items: 1 },
            600: { items: 2 },
            1024: { items: 3 },
            1366: { items: 2 },
        },
    };

    return (

        <section className='all_exams_page'>
            <Helmet>
                <title>All India Exams - Find and Prepare for Various Exams Across India</title>
                <meta name="description" content="Discover and prepare for various exams conducted across India. Find exam dates, application deadlines, and more." />
                <meta name="keywords" content="all india exams, indian exams, exam preparation, exam dates, application deadlines" />
            </Helmet>
            <div className='container'>
                <div className="row">
                    <div className="col-md-12">
                        {/* exams within a week */}
                        <div className="row">
                            <div className='col-md-12'>
                                <div className="main__card mb-5">
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <div className='long_border'>
                                                <h3>Exams within a week</h3>
                                            </div>
                                        </div>
                                    </div>
                                    {examsWithinWeek && examsWithinWeek.length > 0 ? (
                                        <OwlCarousel className="owl-theme" {...owlOptions}>
                                            {examsWithinWeek.map((ex) =>

                                                <div className="gap-5 mx-2" key={ex.id}>
                                                    <div className='card_exams my-3'>
                                                        <div className='top'>
                                                            <div className='pic'>
                                                                <img src={ex.exam.image || examlogo} alt={ex.exam.title} title={ex.exam.title} />
                                                            </div>
                                                            <div className='title'>
                                                                <p>{ex.title}</p>
                                                            </div>
                                                            <div className='mode'>
                                                                <p>{ex.exam_mode}</p>
                                                            </div>
                                                        </div>
                                                        <div className='middle'>
                                                            <h3 className='my-2'>Application Date</h3>
                                                            <p className='my-1'><span>Start Date:</span><span> {ex.application_start_date || '---'}</span></p>
                                                            <p className='my-1'><span>End Date:</span><span>{ex.application_end_date || '---'}</span></p>
                                                            <h3 className='my-2'>Exam Date</h3>
                                                            <p className='my-1'><span>Start Date:</span><span>{ex.exam_start_date || '---'}</span></p>
                                                            <p className='my-1'><span>End Date:</span><span>{ex.exam_end_date || '---'}</span></p>

                                                            <h4 className='my-2'><span>Result Date</span><span>{ex.result || "---"}</span></h4>
                                                        </div>
                                                        <div className='bottom float-right'>
                                                            <Link to={`/exam/${ex.slug}`} title={ex.title}><button className='btn view_button'>View More</button></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                        </OwlCarousel>
                                    ):(
                                        <div>No Exam In This Week </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-9'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className="main__card mb-5">
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <div className='long_border'>
                                                        <h3>All UpComing Exams</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* dropdowns */}
                                            <div className='row mt-3'>
                                                <div className='col-md-2'>
                                                    <div className='appli_dropd'>
                                                        <label>Application<FcAlphabeticalSortingAz /></label>
                                                        <select className='w-100' onChange={e => handleSortChange(e, 'application')}>
                                                            <option value="">All</option>
                                                            <option value="asc">Start-date</option>
                                                            <option value="desc">End-date</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='appli_dropd'>
                                                        <label>Exam<FcAlphabeticalSortingAz /></label>
                                                        <select className='w-100' onChange={e => handleSortChange(e, 'exam')}>
                                                            <option value="">All</option>
                                                            <option value="asc">Start-date</option>
                                                            <option value="desc">End-date</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='appli_dropd'>
                                                        <label>Result<FcAlphabeticalSortingAz /></label>
                                                        <select className='w-100' onChange={e => handleSortChange(e, 'result')}>
                                                            <option value="">All</option>
                                                            <option value="asc">Start-date</option>
                                                            <option value="desc">End-date</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='appli_dropd'>
                                                        <label>Exam Mode<FcAlphabeticalSortingAz /></label>
                                                        <select className='w-100' onChange={e => handleSortChange(e, 'exam_mode')}>
                                                            <option value="">All</option>
                                                            <option value="Online">Online</option>
                                                            <option value="Offline">Offline</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <div className='s_bar'>
                                                        <input type="text" placeholder="Search more exams" name="q" value={searchQuery} onChange={e => setSearchQuery(e.target.value)} />
                                                        <button type="submit"><i className="fas fa-search"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* all exam cards */}

                                            <div className='row'>
                                                {filteredExams.length === 0 ? (
                                                    <div className='col-md-6'>
                                                        <div className='mx-auto text-center '>
                                                            <div className='pt-5 text-2xl text-red-500'><p>No Exams<span className='font-bold text-green-600'>Try again</span></p></div>
                                                            <div className=''>
                                                                <img src={CNF} alt='errorimg' title='error-simage' className="mx-auto text-6xl text-red-500 " />
                                                            </div>
                                                        </div>
                                                    </div>

                                                ) : (
                                                    filteredExams.slice(0, card).map((Exams) => (
                                                        <div className='col-md-6' key={Exams.id}>
                                                            <div className='card_exams my-3'>
                                                                <div className='top'>
                                                                    <div className='pic'>
                                                                        <img src={Exams.exam.image || examlogo} alt={Exams.exam.title} title={Exams.exam.title} className='' loading='lazy' />
                                                                    </div>
                                                                    <div className='title'>
                                                                        <p>{Exams.title || "----"}</p>
                                                                    </div>
                                                                    <div className='mode'>
                                                                        <p className='btn'>{Exams.exam_mode}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='middle'>
                                                                    <h3 className='my-2'>Application Date</h3>
                                                                    <p className='my-1'><span>Start Date:</span><span> {Exams.application_start_date || '---'}</span></p>
                                                                    <p className='my-1'><span>End Date:</span><span>{Exams.application_end_date || '---'}</span></p>
                                                                    <h3 className='my-2'>Exam Date</h3>
                                                                    <p className='my-1'><span>Start Date:</span><span>{Exams.exam_start_date || '---'}</span></p>
                                                                    <p className='my-1'><span>End Date:</span><span>{Exams.exam_end_date || '---'}</span></p>

                                                                    <h4 className='my-2'><span>Result Date</span><span>{Exams.result || "---"}</span></h4>
                                                                </div>
                                                                <div className='bottom float-right'>
                                                                    <Link to={`/exam/${Exams.slug}`} title={Exams.title}><button className='btn view_button'>View More</button></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )))}

                                                {filteredExams && filteredExams.length > 10 ? <button className='btn view_button' onClick={handleviewmore} title='view-more-exam'>View More Exam</button> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* right side */}
                            <div className='col-md-3'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className="main__card mb-5">
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <div className='long_border_red'>
                                                        <h3>Alerts</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* application alerts */}
                                            {applicationWithinWeek.length > 0 && 
                                            <div className='h_of_card_alerts mt-3'>
                                                <div className='row mt-2'>
                                                    <div className='col-lg-12'>
                                                        <div className='long_border'>
                                                            <h3>Application Alerts</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                <marquee class="marq"
                                                   direction="Up">

                                                    {applicationWithinWeek && applicationWithinWeek.map((data) =>

                                                        <div className='alert_card' key={data.id}>
                                                            <div className='logo'>
                                                                <img src={data.exam.image || examlogo} alt={data.exam.title} title={data.exam.title} loading='lazy' />
                                                            </div>
                                                            <div className='content'>
                                                                <Link to={`/exam/${data.slug}`} title={data.title}>
                                                                    <h3>{data.title || "---"}</h3>
                                                                    <p><span>Application StartDate</span><span>{data.application_start_date || '---'}</span></p>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    )}

                                                </marquee>
                                            </div>
                                            }
                                            {/* exam alerts */}
                                            {examsWithinWeek &&  examsWithinWeek.length > 0 &&
                                                <div className='h_of_card_alerts mt-3'>
                                                    <div className='row mt-2'>
                                                        <div className='col-lg-12'>
                                                            <div className='long_border'>
                                                                <h3>Exams Alerts</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <marquee class="marq"
                                                        direction="Up">
                                                        {examsWithinWeek.length > 0  ? examsWithinWeek.map((data) =>

                                                            <div className='alert_card' key={data.id}>
                                                                <div className='logo'>
                                                                    <img src={data.exam.image || examlogo} alt={data.exam.title} title={data.exam.title} loading='lazy' />
                                                                </div>
                                                                <div className='content'>
                                                                    <Link to={`/exam/${data.slug}`} title={data.title}>
                                                                        <h3>{data.title || "---"}</h3>
                                                                        <p><span>Exam StartDate</span><span>{data.exam_start_date || '---'}</span></p>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div>No Exam In This Week</div>
                                                        )}

                                                    </marquee>
                                                </div>
                                            }
                                            {/* Result alerts */}
                                            {result && result.length > 0 &&
                                                <div className='h_of_card_alerts mt-3'>
                                                    <div className='row mt-2'>
                                                        <div className='col-lg-12'>
                                                            <div className='long_border'>
                                                                <h3>Result Alerts</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <marquee class="marq"
                                                        direction="Up" onMouseOver="this.stop()" onMouseOut="this.start()">
                                                        {result.length > 0 ? result.map((data) =>

                                                            <div className='alert_card' key={data.id}>
                                                                <div className='logo'>
                                                                    <img src={data.exam.image || examlogo} alt={data.exam.title} title={data.exam.title} loading='lazy' />
                                                                </div>
                                                                <div className='content'>
                                                                    <Link to={`/exam/${data.slug}`} title={data.title}>
                                                                        <h3>{data.title || "---"}</h3>
                                                                        <p><span>Result Date</span><span>{data.result || '---'}</span></p>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        ): (
                                                            <div>Result Not Declared Yet</div>
                                                        )}

                                                    </marquee>
                                                </div>
                                                
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Everyexam