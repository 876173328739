import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UpcomingExams() {
  // const [examsWithin24Hours, setExamsWithin24Hours] = useState([]);
  const [examsWithin10Days, setExamsWithin10Days] = useState([]);
  // const [application24Hours, setApplication24Hours] = useState([]); 
  const [application10Days, setApplication10Days] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchExams = async () => {
      try {
        const response = await axios.get('https://portal.mycollegepedia.com/exam/all-upcoming-exam/');
        const data = await response.data;
        const currentTime = new Date();
        
        const twentyFourHoursLater = new Date(currentTime);
        twentyFourHoursLater.setDate(currentTime.getDate() + 1);
        const tenDaysLater = new Date(currentTime);
        tenDaysLater.setDate(currentTime.getDate() + 7);  

        // const examsWithin24Hours = data.filter(exam => {
        //   const examStartDate = new Date(exam.exam_start_date);
        //   return examStartDate > currentTime && examStartDate <= twentyFourHoursLater;
        // });
        
        // setExamsWithin24Hours(examsWithin24Hours);

        // const application24Hours = data.filter(exam => {
        //   const applicationStartDate = new Date(exam.application_start_date);
        //   return applicationStartDate > currentTime && applicationStartDate <= twentyFourHoursLater;
        // })
        // setApplication24Hours(application24Hours)

        const examsWithin10Days = data.filter(exam => {
          const examStartDate = new Date(exam.exam_start_date);
          return examStartDate > currentTime && examStartDate <= tenDaysLater;
        });
        setExamsWithin10Days(examsWithin10Days);

        const application10Days = data.filter(exam => {
          const applicationStartDate = new Date(exam.application_start_date);
          return applicationStartDate > currentTime && applicationStartDate <= tenDaysLater;
        });
        setApplication10Days(application10Days);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching exams:', error);
      }
    };

    fetchExams();

  }, []);
if(!examsWithin10Days || !application10Days){
  return <div>Loading...</div>
}
  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <div className='exam_notification'>
      <div className='upcoming_exam_form_date'>
        <h2 className='divheading'>This week application date :-</h2>
        <ul className='application_list_exam'>
          {application10Days.map(exam => (
            <Link to={`/exam/${exam.slug}`} key={exam.id}>
              <li className='application_li'>
                <img src={exam.exam.image || ''} title={exam.title} alt={exam.title} />
                {exam.title}
                <p className='exam_date'>{exam.application_start_date} / {exam.application_end_date}</p>
              </li>
            </Link>
          ))}
        </ul>
      </div>
      <div className='upcoming_exam_form_date'>
        <h2 className='divheading'>This week exam date :- </h2>
        <ul className='upcoming_exam_date'>
          {examsWithin10Days.map(exam => (
            <Link to={`/exam/${exam.slug}`} key={exam.id}>
              <li className='application_li'>
                <img src={exam.exam.image || ''} title={exam.title} alt={exam.title} />
                {exam.title}
                <p className='exam_date'>{exam.exam_start_date || ''}  {exam.exam_end_date ? ( "/", exam.exam_end_date) : ''}</p>
              </li>
            </Link>
          ))}
        </ul>
      </div>
      <div className='upcoming_exam_form_date'>
        <h2 className='divheading'>Recent exam date :- </h2>
        <ul className='upcoming_exam_date'>
          {examsWithin10Days.map(exam => (
            <Link to={`/exam/${exam.slug}`} key={exam.id}>
              <li className='application_li'>
                <img src={exam.exam.image || ''} title={exam.title} alt={exam.title} />
                {exam.title}
                <p className='exam_date'>{exam.exam_start_date || ''}  {exam.exam_end_date ? ( "/", exam.exam_end_date) : ''}</p>
              </li>
            </Link>
          ))}
        </ul>
      </div>
      <ToastContainer />
    </div>
  );
}
export default UpcomingExams;