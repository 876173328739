import React, { useContext, useEffect, useState } from 'react'

import { createChatBotMessage } from 'react-chatbot-kit';
import Apicontext from '../../Page/Contextapi';
import Swal from 'sweetalert2';
import axios from 'axios';

const ActionProvider = ({ setState, children, customActions }) => {

  const [Collegelist, setCollegeList] = useState([]);
  const [courseCategory, setCourseCategory] = useState([]);
  const [Statelist, setStatelist] = useState([]);
  const [Examlist, setExamlist] = useState([]);
  const [FilterColleges, setFilterColleges] = useState([]);
  const [formData, setFormData] = useState({
    state: '',
    category: '',
    college_type: '',
  });

  const contextdata = useContext(Apicontext);
  useEffect(() => {
    const data = contextdata.APIdata;
    setCollegeList(data.college.slice(0, 10))
    setCourseCategory(data.category)
    setStatelist(data.State)
    setExamlist(data.exam.slice(0,12))
  }, [contextdata])

  const handleSubmit = async () => {
    try {
      const queryString = Object.keys(formData).map(key => key + '=' + encodeURIComponent(formData[key])).join('&');
      const res = await axios.get(`https://portal.mycollegepedia.com/college/all-college/?${queryString}`);
      const data = res.data;
      console.log("API Response: ", res);
      setFilterColleges(data)
      setState(prev => ({
        ...prev,
        CollegeData: data,
      }));
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Something went wrong!';
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: errorMessage,
      });
      console.error('Error fetching data:', error);
    }
  };

  // const updateUserProfile = async (profileData) => {
  //   try {
  //     const res = await axios.post('chatbot-user-api', profileData);
  //     setUserProfile(profileData);
  //     Swal.fire({
  //       icon: 'success',
  //       title: 'Profile Updated',
  //       text: 'Your profile has been updated successfully.',
  //     });
  //     const botMessage = createChatBotMessage(`Your profile has been updated!`, {
  //       withAvatar: true,
  //       delay: 500,
  //     });
  //     setState((prev) => ({ ...prev, messages: [...(prev.messages || []), botMessage] }));
  //   } catch (error) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Oops...',
  //       text: 'Something went wrong while updating your profile!',
  //     });
  //     console.error('Error updating profile:', error);
  //   }
  // };

  createChatBotMessage("Thanks, I'll see what I can find", {
    widget: "rentalCarResults",
    withAvatar: true,
    delay: 500
  })

  // College function start rohit patel


  const college_option = () => {
    const botMessage = createChatBotMessage(`which type of college are you looking for ?`, {
      withAvatar: true,
      delay: 500,
      widget: 'types_of_college',
    });

    setState((prev) => ({ ...prev, messages: [...prev.messages, botMessage] }));
  };


  const selectCollegeType = (type) => {
    if (customActions && customActions.setStateData) {
      customActions.setStateData(Statelist, setState);
      const botMessage = createChatBotMessage(`You selected ${type}. In which location are you looking for colleges?`, {
        withAvatar: true,
        delay: 500,
        widget:'location_college',
      });

      setFormData(prev => ({
        ...prev,
        college_type: type,
      }));
      setState((prev) => ({ ...prev, messages: [...(prev.messages || []), botMessage] }));
      console.log("selected type", type, formData)
    } else {
      console.error("setStateData function is not available in customActions.");
    }
  };

  const locationSelected = (selectedLocation) => {
    
    setFormData(prev => ({
      ...prev,
      state: selectedLocation,
    }));

    if (customActions && customActions.setCourseCategory) {
      customActions.setCourseCategory(courseCategory, setState);
      const botMessage = createChatBotMessage(`Which course are you interested in?`, {
        withAvatar: true,
        delay: 500,
        widget:'course_college',
      });

      setState((prev) => ({ ...prev, messages: [...(prev.messages || []), botMessage] }));
    } else {
      console.error("setCourseCategory function is not available in customActions.");
    }
  };
  
  const courseSelected = async (selectedCourse) => {
    await handleSubmit();
   
    customActions.setFilterColleges(FilterColleges, setState);
    setFormData(prev => ({
      ...prev,
      category: selectedCourse,
    }));
   
    const botMessage = createChatBotMessage("Here are some colleges:", {
      withAvatar: true,
      delay: 500,
      widget: 'colleges',
    });
    setState((prev) => ({ ...prev, messages: [...(prev.messages || []), botMessage] }));
  };


  const handleCollege = () => {
    console.log("collelist ls", Collegelist)
    customActions.setCollegeList(Collegelist, setState)
    const botMessage = createChatBotMessage("Here is all-college the list of colleges:", {
      withAvatar: true,
      widget: "all-colleges",
    });

    setState((prev) => ({ ...prev, messages: [...prev.messages, botMessage] }));
  };

  const CollegesList = () => {
    customActions.setCollegeList(Collegelist, setState)
    const botMessage = createChatBotMessage("Here is the list of colleges:", {
      withAvatar: true,
      widget: "all-colleges",
    });

    setState((prev) => ({ ...prev, messages: [...prev.messages, botMessage] }));
  };


  // exam function start rohit patel

  const exam_option =()=>{
   
    const botMessage = createChatBotMessage(`which type of exam are you looking for ?`, {
      withAvatar: true,
      delay: 500,
      widget: 'types_of_exam',
    });

    setState((prev) => ({ ...prev, messages: [...prev.messages, botMessage] }));

  }

  const examSelected = async (selectedExam) => {
    customActions.setExamData(Examlist, setState);

    setFormData(prev => ({
      ...prev,
      exam: selectedExam,
    }));
   
    const botMessage = createChatBotMessage("Here are some exams:", {
      withAvatar: true,
      delay: 500,
      widget: 'exams',
    });
    setState((prev) => ({ ...prev, messages: [...(prev.messages || []), botMessage] }));
  };

// exam section end of 
  // user profile update 
  const handleProfileUpdate = () => {
    const botMessage = createChatBotMessage(`Please update your profile information:`, {
      withAvatar: true,
      delay: 500,
      widget: 'userProfile',
    });
  
    setState((prev) => ({ ...prev, messages: [...prev.messages, botMessage] }));
  };


  const handleProfileUpdatePrompt = () => {
    const botMessage = createChatBotMessage('Would you like to update your profile?', {
      withAvatar: true,
      delay: 500,
      widget: 'profileUpdatePrompt', 
    });
  
    setState((prev) => ({ ...prev, messages: [...prev.messages, botMessage] }));
  };



  const handleNo = () => {
    const botMessage = createChatBotMessage(`No problem. How else can I assist you today?`);
    setState((prev) => ({ ...prev, messages: [...prev.messages, botMessage] }));
  };

  const handleHello = () => {
    const botMessage = createChatBotMessage('Hello sir/mam, this side vedvani! how can i help you', {
      withAvatar: true,
    });

    setState((prev) => ({ ...prev, messages: [...prev.messages, botMessage] }));
  };

  const unknown = () => {
    const botMessage = createChatBotMessage("I am not sure what you mean. Can you please clarify?", {
      withAvatar: true,
      widget: 'courseCategories',
      loading: 'loding',
      delay: 1200,
    });

    setState((prev) => ({ ...prev, messages: [...prev.messages, botMessage] }));
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
           
            college_option,
            selectCollegeType,
            locationSelected,
            courseSelected,
            handleCollege,
            CollegesList,
            exam_option,
            examSelected,

            handleNo,
            handleHello,
            unknown,
          },
          formData,
         
        });
      })}
    </div>
  );
};

export default ActionProvider;
