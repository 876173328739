import MagicBell, { FloatingNotificationInbox } from '@magicbell/magicbell-react';


const Notificationclg =()=> {
  return (
    <MagicBell
      apiKey={`https://portal.mycollegepedia.com/exam/all-upcoming-exam/`}
      userEmail='person@example.com'
      stores={[
        { id: 'comments', defaultQueryParams: { categories: ['comments'] } },
        { id: 'archive', defaultQueryParams: { archived: true } },
      ]}  
    >
      {(props) => (
        <FloatingNotificationInbox
          tabs={[
            { storeId: 'comments', label: 'Comments' },
            { storeId: 'archive', label: 'Archive' },
          ]}
          {...props}
        />
      )}
    </MagicBell>
  )
}
export default Notificationclg;