import React, { useState, useEffect, useMemo, useContext } from 'react';
import axios from 'axios';
import { FaPen, FaPlus, FaTrash } from 'react-icons/fa';
import Apicontext from '../../Page/Contextapi';

import { Link, NavLink } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { LinearProgress } from "@mui/material";
import UpdateCourseFee from './UpdateCourseFee';
import Swal from 'sweetalert2';

const CollegeCourseFee = () => {
    const [collegeData, setCollegeData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [Selectedcourse, setSelectedcourse] = useState(null);
    const [Loading, setLoading] = useState(true);
    const [selectsubcategory, setSelectedSubCategory] = useState('');
    const [stream, setStream] = useState([]);
    const token = useMemo(() => localStorage.getItem('token') || '', []);
    const collegeRecord = useMemo(() => JSON.parse(localStorage.getItem('collegerecord')) || [], []);
    const academic = useMemo(() => JSON.parse(localStorage.getItem('academic')) || [], []);
    const collegeId = collegeRecord.length > 0 ? collegeRecord[0].id : null;
    const contextdata = useContext(Apicontext);
    const Data = contextdata.APIdata
    const category = Data.subcategory;
    const [formData, setFormData] = useState({
        id: '',
        college: collegeId || '',
        academic_year: '',
        course_subcategory: '',
        course_stream: '',
        year_fees: '',
    });

    const headers = useMemo(() => ({
        Authorization: `JWT ${token}`,
    }), [token]);
    useEffect(() => {
        const fetchData = async () => {
            if (selectsubcategory) {
                try {
                    const streamRes = await axios.get(`https://portal.mycollegepedia.com/general-setting/all_course_stream/?course_subcategory=${selectsubcategory}`, {
                        headers: {
                            Authorization: `JWT ${token}`,
                            'Content-Type': 'application/json',
                        },
                    });
                    setStream(streamRes.data || []);
                } catch (error) {
                    console.error('Error fetching subcategories:', error);
                }
            }
            try {
                const response = await axios.get('https://portal.mycollegepedia.com/college/course-fees', { headers });
                setLoading(false);
                setCollegeData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [headers, selectsubcategory, showModal]);


    const handleEdit = (id) => {
        const selectedData = collegeData.find((data) => data.id === id);
        setFormData({
            ...formData,
            id: id,
            academic_year: selectedData.academic_year,
            course_subcategory: selectedData.course_subcategory,
            course_stream: selectedData.course_stream,
            year_fees: selectedData.year_fees,
        });
        selectedData.course_subcategory && setSelectedSubCategory(selectedData.course_subcategory.name)
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedcourse(null);
    };
    const handleDelete = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this course record!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`https://portal.mycollegepedia.com/college/course-fees/${id}`, { headers });
                    console.log(response)
                    setCollegeData(collegeData.filter((course) => course.id !== id));
                    Swal.fire(
                        'Deleted!',
                        'Your course record has been deleted.',
                        'success'
                    );
                } catch (error) {
                    console.error('Error deleting data:', error);
                    Swal.fire(
                        'Error!',
                        'Failed to delete course record. Please try again.',
                        'error'
                    );
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your course record is safe :)',
                    'error'
                );
            }
        });
    };


    if (Loading) {
        return (
            <p className="my-10 p-3">
                <LinearProgress />
            </p>
        );
    }


    return (
        <div className='container px-4 common_dash'>
            <div className="row align-items-center top">
                <div className="col-lg-6">
                    <h3>Course Fee</h3>
                </div>
                <div className="col-lg-6">
                    <Breadcrumb className="bread">
                        <Breadcrumb.Item><NavLink to="/college-dashboard">Home</NavLink></Breadcrumb.Item>
                        <Breadcrumb.Item active>Accepted</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            {showModal && showModal !== null ?
                <UpdateCourseFee handleCloseModal={handleCloseModal} formData={formData} category={category} stream={stream} token={token} Data={Data} />

                :
                <div>
                    <div className=''>
                        <div className='row justify-end '>
                            <Link to={'/college-dashboard/add-course-fee'} title='Add course fee' className="btn-sm shadow-sm flex my-2 gap-1.5 float-right text-white   items-center border bg-[#052963]" data-toggle="modal" >
                                <FaPlus /> Add
                            </Link>
                        </div>
                    </div>
                    <div className='row'>
                        <div className=" table-responsive thin_scroll">
                            <table className="table ">
                                <thead>
                                    <tr className="bg-[whitesmoke]">
                                        <th scope="col">Academic year</th>
                                        <th scope="col">Sub-category</th>
                                        <th scope="col">Stream</th>
                                        <th scope="col">Yearly fees</th>
                                        <th scope="col">Total fees</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className='tablemain bg-[white]'>
                                    {collegeData.map((data) => (
                                        <tr key={data.id} className="m-data">
                                            <td>{data.academic_year === academic[0].id ? academic[0].year : ''}</td>
                                            <td className='width_define'>{category.find((cat) => cat.id === data.course_subcategory)?.course_name}</td>
                                            <td>{Data.stream.find((cat) => cat.id === data.course_stream)?.name}</td>
                                            <td>{data.year_fees}</td>
                                            <td>{data.total_fees}</td>
                                            <td className='direction_of_btn d-flex gap-2'>

                                                <button className='bg-warning text-white p-2'onClick={() => handleEdit(data.id)} title='Update'><FaPen  /></button>
                                                <button  className='bg-danger text-white p-2' onClick={() => handleDelete(data.id)} title='Delete'><FaTrash /></button>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            }
        </div>
    );
};

export default CollegeCourseFee;
