import React, { useContext, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom';

import Apicontext from '../../Page/Contextapi';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FaTimes } from 'react-icons/fa';

const Predictorform = ({ handleform }) => {
  // const user = JSON.parse(localStorage.getItem('user'));
  const contextdata = useContext(Apicontext);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [examOptions, setExamOptions] = useState([]);
  const [courseCategoryOptions, setCourseCategoryOptions] = useState([]);


  const [formData, setFormData] = useState({
    college_type: '',
    country: null,
    course_category: '',
    exam: '',
    organization_type: '',
    state: '',

  });
  const navigate = useNavigate();
  console.log("category", courseCategoryOptions)
  useEffect(() => {
    const data = contextdata.APIdata;
    setCountryOptions(data.Country);
    setExamOptions(data.prev_exam);
    setCourseCategoryOptions(data.category);

  }, [contextdata])



  useEffect(() => {
    const fetchdata = async () => {
      const selectedCountry = countryOptions.find((option) => option.name === formData.country);
      if (formData.country && selectedCountry) {
        try {
          const response = await axios.get(`https://portal.mycollegepedia.com/main/state/?country_id=${selectedCountry.id}`);
          setStateOptions(response.data);
        } catch (error) {
          console.error('Error fetching states:', error);
        }
      }
    };
    fetchdata();
  }, [formData.country, countryOptions]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Serialize formData into a query string
      const queryString = Object.keys(formData).map(key => key + '=' + encodeURIComponent(formData[key])).join('&');

      const res = await axios.get(`https://portal.mycollegepedia.com/college/all-college/?${queryString}`);
      const collegeData = res.data
      console.log("predictor", res);

      navigate(`/college/predictor/`, { state: { collegeData } });

    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Something went wrong!';
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: errorMessage,
      });
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div>
      <div className="rounded-lg p-3 shadow-md predictor-div first-div">
        <div className='inner'>

          <form className="row" onSubmit={handleSubmit}>
            <div className='col-lg-12'>
              <button onClick={() => handleform()} className='float-end mb-3' title='close'><FaTimes /></button>
            </div>
            <div className="col-6">
              <select name="organization_type" value={formData.organization_type} onChange={handleChange} className="pl-2 mb-3 text-base text-gray-400 border border-gray-300 rounded-md form-control focus:text-gray-600 focus:border-gray-400">
                <option value="">Select type</option>
                <option value="university">University</option>
                <option value="college">College</option>
              </select>
              <select name="country" value={formData.country} onChange={handleChange} className="pl-2 mb-3 text-base text-gray-400 border border-gray-300 rounded-md form-control focus:text-gray-600 focus:border-gray-400">
                <option value="">Select Country</option>
                {countryOptions.map((country) => (
                  <option key={country.id} value={country.name}>{country.name}</option>
                ))}
              </select>
            </div>
            <div className="col-6">
              <select name="college_type" value={formData.college_type} onChange={handleChange} className="pl-2 mb-3 text-base text-gray-400 border border-gray-300 rounded-md form-control focus:text-gray-600 focus:border-gray-400">
                <option value="">Select college type</option>
                <option value="government">Government</option>
                <option value="private">Private</option>
              </select>
              <select name="state" value={formData.state} onChange={handleChange} className="pl-2 mb-3 text-base text-gray-400 border border-gray-300 rounded-md form-control focus:text-gray-600 focus:border-gray-400">
                <option value="">Select state</option>
                {stateOptions && stateOptions.map((state) => (
                  <option key={state.id} value={state.name}>{state.name}</option>
                ))}
              </select>
            </div>
            <div className="j col-6">
              <select name="course_category" value={formData.course_category} onChange={handleChange} className="pl-2 mb-3 text-base text-gray-400 border border-gray-300 rounded-md form-control focus:text-gray-600 focus:border-gray-400">
                <option value="">Select course category</option>
                {courseCategoryOptions.map((category) => (
                  <option key={category.id} value={category.name}>{category.name}</option>
                ))}
              </select>
            </div>
            <div className="col-6">
              <select name="exam" value={formData.exam} onChange={handleChange} className="pl-2 mb-3 text-base text-gray-400 border border-gray-300 rounded-md form-control focus:text-gray-600 focus:border-gray-400">
                <option value="">Select exam</option>
                {examOptions.map((exam) => (
                  <option key={exam.id} value={exam.title}>{exam.title}</option>
                ))}
              </select>
            </div>

            <button type="submit" className="px-4 mx-auto sub_btn">Submit</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Predictorform