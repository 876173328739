

import React, { useState } from 'react';
import image from '../../assets/images/collegeimg.jpg'
import { Link } from 'react-router-dom';
const EligibilityItem = ({ eligibilityItem, data }) => {
    const [Isshow, setIsShow] = useState(false);

    const cleanedDescription = eligibilityItem.description?.replace(/style="[^"]*"/g, '');
    const handleshowmore = () => {
        setIsShow(!Isshow)
    }
    
    return (
        <div className='course_fees' key={eligibilityItem.id}>
            <div className='row align-items-center  pb-0 '>
                <div className='col-md-12 justify-content-center d-flex'>
                    <div className='pic_of'>
                    <Link to={`/exam/${eligibilityItem?.exam?.slug}`} title={eligibilityItem.exam?.full_form}>
                        <img src={`${eligibilityItem.exam.image ? eligibilityItem.exam.image : image}`} title={eligibilityItem.exam.full_form || 'No title'} alt={eligibilityItem.exam.title || 'No alt-text'} className='w-20 h-20 rounded-full overflow-hidden my-2 singlelogo' />
                        </Link>
                    </div>
                </div>
            </div>

            <div className='row py-0'>
                <div className='col-md-12'>
                    <div className="table-responsive p-0">
                        <table className="table align-middle table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Perticulars</th>
                                    <th scope="col">Statistics</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">Exam Name</th>
                                    <td>
                                    <Link to={`/exam/${eligibilityItem?.exam?.slug}`} title={eligibilityItem?.exam.full_form} className="hover:underline-offset-1 cursor-pointer">
                                        {eligibilityItem.exam?.title || '----'}
                                        <span> ({eligibilityItem.exam?.full_form || '---'})</span>
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Course Category</th>
                                    <td>
                                        {data.category.find((item) => item.id === eligibilityItem.category)?.name || '---'}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Course Sub-category</th>
                                    <td>
                                        {data.subcategory.find((item) => item.id == eligibilityItem.course_subcategory)?.course_name || '---'}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Stream</th>
                                    <td>
                                        {data.stream.find((item) => item.id === eligibilityItem.course_stream)?.name || '---'}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Application Start Date</th>
                                    <td>{eligibilityItem.application_start_date || '---'}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Application End date</th>
                                    <td>{eligibilityItem.application_end_date || '---'}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Eligibility</th>
                                    <td>{eligibilityItem.eligibility || '---'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            {Isshow && (

                <div className='row py-0'>
                    <div className='col-md-12'>
                        <div className='desc'>
                            <p dangerouslySetInnerHTML={{ __html: cleanedDescription }} />
                        </div>
                    </div>
                </div>
            )}

            <div className='row view_grad'>
                <div className='col-md-12 text-center'>
                    <button className='btn_of_view' onClick={handleshowmore}>{Isshow ? "View less" : 'View More'}</button>
                </div>
            </div>
        </div>

    );
};
export default EligibilityItem;