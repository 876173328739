import React, { useState } from "react";
import { FaBars, FaUser, FaUserCircle, FaUserGraduate, FaCheck, FaTimes, FaStarHalfAlt,  FaNewspaper } from "react-icons/fa";
import { AiOutlineFileAdd } from "react-icons/ai";
import { IoMdNotifications } from "react-icons/io";
import collegepedia from "../../assets/images/Logo.png";
import { NavLink, useNavigate, Outlet, Link } from "react-router-dom";
import { FaUniversity } from "react-icons/fa";
import Nav from 'react-bootstrap/Nav';
import { IoLogOut } from "react-icons/io5";
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { MdOutlinePending, } from "react-icons/md";
import picimage from '../../assets/images/Bangalore.png';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import Collapsible from "react-collapsible";
import { TbWorldWww } from "react-icons/tb";
import Swal from "sweetalert2";
const Header = () => {
    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);
    // const [isopen1, setIsOpen1] = useState(true);

    // const toggleCollapsible1 = () => {
    //     setIsOpen1(!isopen1);
    // };

  const navigate = useNavigate();
  const logoutfun = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('path');
    localStorage.removeItem('userprofile');
    navigate('/login')
    
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
      Toast.fire({
        icon: "success",
        title: "Log-out successfully"
      });
};

    return (
        <>
         <ToastContainer />
            <div className="Newtopbar_dashbord">
                <Navbar expand="lg" className='Newtopbar'>
                    <Navbar id="basic-navbar-nav">
                        <div style={{ marginLeft: isOpen ? "250px" : "50px" }} className="bars outerbar">
                            <FaBars onClick={toggle} />
                        </div>
                        <div style={{ marginLeft: isOpen ? "10px" : "10px" }} className="bars innerbar">
                        <FaBars onClick={toggle} />
                    </div>
                        <Nav className="ms-auto">
                            <div className="first_dropdown">
                                <NavDropdown title={<span className="usericon"><IoMdNotifications />
                                </span>}>
                                    <NavDropdown.Item >
                                        <div className="comments">
                                            <div className="pic">
                                                <img src={picimage} alt="counsellor-icon " title="counsellor" />
                                                <div className="content-wrapper">
                                                    <p>comment saaaaaa sfgsfg sg dgg aaaa</p>
                                                </div>
                                            </div>
                                        </div>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item  className="card">
                                        <div className="comments">
                                            <div className="pic">
                                                <img src={picimage} alt="counsellor-icon " title="counsellor" />
                                                <div className="content-wrapper">
                                                    <p>comments aaaaaa sfgsfg sg dgg aaaa</p>
                                                </div>
                                            </div>
                                        </div>
                                    </NavDropdown.Item>
                                 
                                 
                                    <NavDropdown.Item >
                                        <button className="btn" >view more</button>
                                    </NavDropdown.Item>

                                </NavDropdown></div>


                            <div className="second_dropdown">
                                <NavDropdown title={<span className="usericon"><FaUser /></span>}>
                                    <NavDropdown.Item className="flex items-center gap-2" as={Link} to="/counsellor-dashboard/company-profile">
                                        Abhishek
                                    </NavDropdown.Item>
                                    <NavDropdown.Item className="flex items-center gap-2" as={Link} to="/counsellor-dashboard/company-profile">
                                        <FaUserCircle />   Profile
                                    </NavDropdown.Item>
                                    <NavDropdown.Item className="flex items-center gap-2" as={Link} to="/">
                                        <TbWorldWww />   Visit site
                                    </NavDropdown.Item>
                                    <NavDropdown.Item  onClick={logoutfun} className="flex items-center">
                                        <IoLogOut />  Log-out
                                    </NavDropdown.Item>
                                </NavDropdown></div>
                        </Nav> 
                    </Navbar>

                </Navbar>
            </div>
            <div className="containe">
                <div style={{ width: isOpen ? "260px" : "75px" }} className="sidebarbar">
                    <div className="top_section">
                        <Link to="/" className="logo">
                            <img src={collegepedia} alt='Mycollegepedia' title="counsellor-dashbaoed" />
                        </Link>
                        <div style={{ marginLeft: isOpen ? "10px" : "10px" }} className="bars innerbar">
                        <FaBars onClick={toggle} />
                    </div>
                    </div>

                    <NavLink to='/counsellor-dashboard/home' className="link mt-2" activeclassName="active">
                        <div className="icon"><FaUniversity /> </div>
                        <div className={` link_text ${isOpen ? "block" : "none"}`} > Dashboard </div>
                    </NavLink>


                    <NavLink to="/counsellor-dashboard/director" className="link mt-2" activeclassName="active">
                        <div className="icon"><FaUserGraduate /> </div>
                        <div className={` link_text ${isOpen ? "block" : "none"}`} > Directors </div>
                    </NavLink>
                    <NavLink to='/counsellor-dashboard/leads' className="link mt-2" activeclassName="active">
                        <div className="icon"><AiOutlineFileAdd /> </div>
                        <div className={`link_text ${isOpen ? "block" : "none"}`} > Applications </div>
                        <div className="arrow-icon-dash "> </div>
                    </NavLink>


                    <NavLink
                        to="/counsellor-dashboard/pending"
                        className="link mt-2"
                        activeClassName="active"
                    >
                        <div className="icon"><MdOutlinePending /></div>

                        <span className="link_text">Pending</span>
                    </NavLink>
                    <NavLink
                        to="/counsellor-dashboard/accepted"
                        className="link  mt-2"
                        activeClassName="activelink"
                    >
                        <div className="icon"> <FaCheck /></div>
                        <span className="link_text">Accepted</span>
                    </NavLink>
                    <NavLink
                        to="/counsellor-dashboard/rejected"
                        className="link mt-2"
                        activeClassName="activelink"
                    >

                        <div className="icon"> <FaTimes /></div>

                        <span className="link_text">Rejected</span>
                    </NavLink>
                    <NavLink to='/counsellor-dashboard/news-articles' className="link mt-2" activeclassName="active">
                        <div className="icon"><FaNewspaper className="" /> </div>
                        <div className={` link_text ${isOpen ? "block" : "none"}`} > News & Article </div>
                    </NavLink>
                    <NavLink to='/counsellor-dashboard/add-news-articles' className="link mt-2" activeclassName="active">
                        <div className="icon"><FaNewspaper /> </div>
                        <div className={` link_text ${isOpen ? "block" : "none"}`} > Add News&article </div>
                    </NavLink>
                    <NavLink to='/counsellor-dashboard/Testimonial' className="link mt-2" activeclassName="active">
                        <div className="icon"><FaStarHalfAlt /> </div>
                        <div className={` link_text ${isOpen ? "block" : "none"}`} > Testimonial </div>
                    </NavLink>


                </div>
                <main className={`ml-0 ${isOpen ? "md:!ml-[260px]" : "md:!ml-[75px]"}`}>
                    <Outlet />
                </main>
            </div>
        </>

    );
};

export default Header;
