import React, { useEffect, useMemo, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { MdDelete } from 'react-icons/md';
import { FaEdit, FaPlus } from 'react-icons/fa';
import axios from 'axios';
import Swal from 'sweetalert2';
import StarRating from '../Homepages/Startrating';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link, NavLink } from 'react-router-dom';
import TestimonialUpdate from './TestimonialUpdate';
const Testimonial = () => {
  const [show, setShow] = useState(true);
  const [testimonials, setTestimonials] = useState([]);
  const [selectedata, setSelectedata] = useState([]);
  const token = localStorage.getItem("token");
  const handlefunction =(data)=>{
    setSelectedata(data)
    setShow(!show)
  }
  const headers = useMemo(() => ({
    Authorization: `JWT ${token}`,
  }), [token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://portal.mycollegepedia.com/counsellor/counsellor-testimonial/', { headers });
        setTestimonials(response.data || []);
      } catch (error) {
        console.error("apierro", error);
      }
    };
    fetchData();
  }, [headers, show]);


  const handleDeleteTestimonial = async (testimonialId) => {
    try {
      const resDel = await axios.delete(`https://portal.mycollegepedia.com/counsellor/counsellor-testimonial/${testimonialId}/`, { headers });
      console.log('Testimonial deleted:', resDel);

      setTestimonials((prevTestimonials) => prevTestimonials.filter((testimonial) => testimonial.id !== testimonialId));
      if (resDel.status === 204) {
        Swal.fire({
          position:"top-end",
          icon: 'success',
          timer:2500,
          text: 'Testimonial deleted successfully!',
        });
      }
    } catch (error) {
      console.error('Error deleting testimonial:', error);
    }
  };
  return (
    <>
      <section className='tes common_dash common_dash_test my-3'>
        <div className='container-fluid'>
          <div className="row align-items-center top  mb-2 ">
            <div className="col-lg-6">

              <h3>Testimonials</h3>
            </div>
            <div className="col-lg-6">
              <Breadcrumb className="bread">
                <Breadcrumb.Item ><NavLink to="/counsellor-dashboard">Home</NavLink></Breadcrumb.Item>
                <Breadcrumb.Item active>Directors</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          {show ? (
          <div className='row'>
            {/* <div className='col-lg-12 mb-4'>
              <h2 className='text-center tes-head'> Testimonial </h2>
            </div> */}
            <div className='col-lg-12 mb-4'>
              <div className='tes-add float-right '>
                <Link to={"/counsellor-dashboard/add-testimonial"} className='px-1 d-flex text-white align-items-center'><FaPlus /> Add Testimonial</Link>
              </div>
            </div>

            {testimonials && testimonials.map((item, i) => (
              <div className='col-lg-6 mt-4 col-md-12' key={i}>
                <Card  className="text-center shadow tes-card">
                  <Card.Header>{item.name}</Card.Header>
                  <Card.Body>

                    <Card.Text className='tes-description'>
                      {item.description}
                    </Card.Text>
                    <Card.Text className='r-rating mt-3' > <StarRating rating={item.rating} /> </Card.Text>
                  </Card.Body>
                  <Card.Footer className=" d-flex justify-content-between">
                    <div className='tes-date'>
                      <div className='created d-flex'>
                        <p> Created Date : </p> <span> 12-12-2024</span>
                      </div>
                      <div className='updated d-flex'>
                        <p> Updated Date : </p> <span> 20-2-2024</span>
                      </div>
                    </div>
                    <div className=" tes-buttons">
                      <Button className="edit" title='update-data' onClick={() => handlefunction(item)}>
                        <FaEdit />
                      </Button>
                      <Button className="delete" onClick={() => handleDeleteTestimonial(item.id)}>
                        <MdDelete />
                      </Button>
                    </div>
                  </Card.Footer>
                </Card>
              </div>
            ))}
          </div>
          ) : (
            <TestimonialUpdate handlefunction={handlefunction} selectedata={selectedata} />
          )}
        </div>

      </section>
    </>
  );
};

export default Testimonial;