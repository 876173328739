import React, { useState } from 'react';
import contact_img from '../../assets/images/contact-us-image.jpg';
import { FaUser } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';
import { BiMessageAltDetail } from 'react-icons/bi';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input';
import { Helmet } from 'react-helmet';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        mobile: '',
        message: '',
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // console.log('Form Data:', formData);
            const res = await axios.post('https://portal.mycollegepedia.com/site/contact/', formData);
            console.log(res);
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
            });

            // Use the name field from the response data
            Toast.fire({
                icon: 'success',
                text: res.data.name,
                title: 'Submitted successfully. Thank you!',
            });

            navigate('/');
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Something went wrong!';
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: errorMessage,
            });
            console.error('Error submitting form:', error);
        }
    };
    return (
        <>
            <Helmet>
                <title>Contact MyCollegePedia - Find Colleges and Exams Across India</title>
                <meta name="description" content="Contact MyCollegePedia for inquiries related to colleges and exams across India. Explore a database of 20,000+ colleges and 3,000+ exams." />
                <meta name="keywords" content="contact, MyCollegePedia, colleges, exams, India, database, college, exam" />
            </Helmet>
            <section class="breadcrumb-section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <h1 class="ab-1">Contact Us</h1>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5 contact-us-section">
                <div className="container">
                    <div className="row">
                        <div className="p-0 ml-auto col-lg-7">
                            <div className="book-now wow bounceIn">
                                <div className="text-center section_heading">
                                    <div className="mb-1 small_heading">
                                        <span className="left_line" />
                                        <h6>Contact Us</h6>
                                        <span className="right_line" />
                                    </div>
                                </div>
                                <form className="row align-items-center" onSubmit={handleSubmit}>
                                    <div className="col-12 col-md-6">
                                        <div className="input-group custom-field">
                                            <div className="custom-field-icon">
                                                <FaUser />
                                            </div>
                                            <input
                                                type="text"
                                                className="custom-form-control"
                                                placeholder="Name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="input-group custom-field">
                                            <div className="custom-field-icon">
                                                <FiMail />
                                            </div>
                                            <input
                                                type="email"
                                                name="email"
                                                className="custom-form-control"
                                                placeholder="Email"
                                                value={formData.email}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="input-group custom-field">
                                            <div className="custom-field-icon">
                                                <BiMessageAltDetail />
                                            </div>
                                            <input
                                                type="text"
                                                name="subject"
                                                className="custom-form-control"
                                                placeholder="Subject"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <PhoneInput
                                        countryCallingCodeEditable={false}
                                        international
                                        defaultCountry="IN"
                                            placeholder="Enter phone number"
                                            value={formData.mobile}
                                            onChange={(value) =>
                                                setFormData({
                                                    ...formData,
                                                    mobile: value,
                                                })
                                            }
                                            className="form-control"
                                        />
                                        {/* Display formatted phone number */}
                                        {formData.mobile && (
                                            <span className="text-sm text-gray-500">
                                                National: {formatPhoneNumber(formData.mobile)}
                                            </span>
                                        )}
                                        {/* <div className="input-group custom-field">
                                        <div className="custom-field-icon">
                                            <FaPhone />
                                        </div>
                                        <input
                                            type="number"
                                            className="custom-form-control"
                                            placeholder="Mobile number"
                                            name="mobile"
                                            onChange={handleChange}
                                        />
                                    </div> */}
                                    </div>
                                    <div className="col-12">
                                        <div className="input-group custom-field">
                                            <textarea
                                                className="custom-form-control"
                                                rows={4}
                                                cols={100}
                                                name="message"
                                                placeholder="Type Your Message...."
                                                value={formData.message}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="text-center col-12 book-now-btn">
                                        <button type="submit" className="book-btn" disabled={formData.submitting}>
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="p-0 col-lg-5">
                            <div className="contact-img">
                                <img src={contact_img} className="img-fluid" alt="contact-us" />
                            </div>
                        </div>
                    </div>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
            </section>
        </>
    );
};

export default Contact;
