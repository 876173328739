import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {  NavLink } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';


const UpcomingExam = () => {
  const [examData, setExamData] = useState([]);
  const [upcomingExamDetail, setUpcomingExamDetail] = useState(null);
  useEffect(() => {
    const fetchAllUpcomingExams = async () => {
      try {
        const response = await axios.get('https://portal.mycollegepedia.com/exam/all-upcoming-exam');
        setExamData(response.data);
      } catch (error) {
        console.error('Error fetching All Upcoming Exams:', error);
      }
    };

    fetchAllUpcomingExams();
  }, []);

  const handleGetUpcomingExamDetail = async (slug) => {
    try {
      const response = await axios.get(`https://portal.mycollegepedia.com/exam/upcoming-exam/${slug}`);
      setUpcomingExamDetail(response.data);
    } catch (error) {
      console.error('Error fetching Upcoming Exam detail:', error);
    }
  };

  return (
    <div className="container px-3 upcoming_exams common_dash ">
      <div className="row align-items-center top">
        <div className="col-lg-6">

          <h3>Upcoming Exams</h3>


        </div>
        <div className="col-lg-6">
          <Breadcrumb className="bread">
            <Breadcrumb.Item ><NavLink to="/college-dashboard">Home</NavLink></Breadcrumb.Item>

            <Breadcrumb.Item active>Upcoming Exams</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <h3 className="mb-4 text-center">College Upcoming-Exam </h3>

      {/* Display All Upcoming Exams as Cards */}
      <div className="row">
        {examData.map((exam, index) => (
          <div key={index} className="mb-4 col-md-4">
            <div className="card p-2">
              <div className='max-w-[80px] ml-4  rounded-full upcoming'>
                <img src={`${exam.exam.image ? exam.exam.image : ""}`} className="card-img-top" alt={exam.exam.title} />
              </div>
              <div className="card-body">
                <h5 className="card-title">{exam.exam.title}</h5>
                <p className="card-text line-clamp-6"><p dangerouslySetInnerHTML={{ __html: exam.exam.description }} /> </p>
                <button

                  className="mt-4 btn-sm upcoming_btn"
                  onClick={() => handleGetUpcomingExamDetail(exam.exam.slug)}
                >
                  View Details
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Display Upcoming Exam Detail */}
      {upcomingExamDetail && (
        <div className="mt-4">
          <h2 className="text-center">Upcoming Exam Detail</h2>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">{upcomingExamDetail.title}</h5>
              <p className="card-text">{upcomingExamDetail.description}</p>
              {/* Add more details as needed */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpcomingExam;
