import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FaBackward } from 'react-icons/fa'
import RichText from './Subpages/Richtext';
import { FloatingLabel } from 'react-bootstrap';
import Apicontext from '../../Page/Contextapi';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { LinearProgress } from "@mui/material";
import Button from "react-bootstrap/Button";
import { TiArrowBack } from 'react-icons/ti';

const Collegeprofileupdate = ({ updateopen }) => {
    const token = useMemo(() => localStorage.getItem("token") || "", []);
    const [collegeData, setCollegeData] = useState(null);
    const [subcategory, setSubCategory] = useState([]);
    const contextData = useContext(Apicontext);
    const [Loading, setLoading] = useState(true);
    const [category, setCategory] = useState([]);

    const [formData, setFormData] = useState({
        city: "",
        current_address: "",
        permanent_address: "",
        zipcode: "",
        primary_mobile: "",
        email: "",
        website: null,
        whatsapp: null,
        facebook: null,
        instagram: null,
        linkedin: null,
        twitter: null,
        youtube: null,
        telegram: null,
        name: "",
        rank: '',
        logo: null,
        image: null,
        established_year: '',

        overview: "",
        affiliation: "",
        admission_process: "",
        career_opportunity: "",
        specialization: "",
        scholarship: "",
        placement: "",
        scope: "",
        country: '',
        state: '',
        course_subcategory: [],
        newSubcategory: '',
        course_category: '',
    });
    const handleRichTextChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleAddSubcategory = () => {
        const { newSubcategory, course_subcategory } = formData;

        if (newSubcategory.trim() !== '') {
            const newSubcategoryId = parseInt(newSubcategory, 10);
            if (!course_subcategory.includes(newSubcategoryId)) {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    course_subcategory: [...prevFormData.course_subcategory, newSubcategoryId],
                    newSubcategory: '',
                }));
            } else {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    course_subcategory: prevFormData.course_subcategory.filter((id) => id !== newSubcategoryId),
                    newSubcategory: '',
                }));
            }
        }
    };

    const handleRemoveSubcategory = (removedSubcategoryId) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            course_subcategory: prevFormData.course_subcategory.filter((id) => id !== removedSubcategoryId),
        }));
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            image: file,
        });
    };
    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            logo: file,
        });
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        // console.log("formda", formData)
        try {
            const formDataCopy = new FormData();
            Object.keys(formData).forEach((key) => {
                if (key !== 'image' && key !== 'logo' && key !== "facebook" && key !== 'instagram' && key !== 'website' && key !== 'telegram' && key !== 'linkedin' && key !== 'twitter' && key !== 'youtube' && key !== 'whatsapp') {
                    if (key === 'course_subcategory') {
                        formData.course_subcategory.forEach((subcategory) => {
                            formDataCopy.append('course_subcategory', subcategory);
                        });
                    } else if (typeof formData[key] !== 'string') {
                        formDataCopy.append(key, formData[key]);
                    }
                }
            });
            if (formData.image && typeof formData.image !== 'string') {
                formDataCopy.append('image', formData.image);
            }

            if (formData.logo && typeof formData.logo !== 'string') {
                formDataCopy.append('logo', formData.logo);
            }

            const slug = collegeData.slug;
            const response = await axios.patch(`https://portal.mycollegepedia.com/college/college-update/${slug}/`, formDataCopy, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `JWT ${token}`,
                },
            });
            setCollegeData(response.data);
            Swal.fire({
                position:"top-end",
                timer:2000,
                icon: 'success',
                text: 'Data updated successfully!',
            });
            updateopen()
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'There was an error. Please try again.',
            });
            console.error('Error updating college details:', error);
        }
    };

    const navigate = useNavigate();
    const headers = useMemo(
        () => ({
            Authorization: `JWT ${token}`,
        }),
        [token]
    );

    useEffect(() => {
        const data = contextData.APIdata;
        setCategory(data.category)
        setSubCategory(data.subcategory)
        const fetchData = async () => {
            try {
                const collegeResponse = await axios.get(
                    "https://portal.mycollegepedia.com/college/college-detail/",
                    { headers, }
                );

                setLoading(false);

                const collegeData = collegeResponse.data[0];
                setCollegeData(collegeResponse.data[0])
                setFormData({
                    name: collegeData.name || "",
                    rank: collegeData.rank || '',
                    logo: collegeData.logo || null,
                    image: collegeData.image || null,
                    established_year: collegeData.established_year || 1996,
                    city: collegeData.city || '',
                    current_address: collegeData.current_address || '',
                    permanent_address: collegeData.permanent_address || "",
                    zipcode: collegeData.zipcode || "",
                    primary_mobile: collegeData.primary_mobile || "",
                    email: collegeData.email || "",
                    website: collegeData.website || null,
                    whatsapp: collegeData.whatsapp || null,
                    facebook: collegeData.facebook || null,
                    instagram: collegeData.instagram || null,
                    linkedin: collegeData.linkedin || null,
                    twitter: collegeData.twitter || null,
                    youtube: collegeData.youtube || null,
                    telegram: collegeData.telegram || null,
                    overview: collegeData.overview || "",
                    affiliation: collegeData.affiliation || "",
                    admission_process: collegeData.admission_process || "",
                    career_opportunity: collegeData.career_opportunity || "",
                    scope: collegeData.scope || "",
                    scholarship: collegeData.scholarship || "",
                    specialization: collegeData.specialization || "",
                    placement: collegeData.placement || "",
                    country: collegeData.country || '',
                    state: collegeData.state || '',
                    course_subcategory: collegeData.course_subcategory || '',
                    course_category: collegeData.course_category || ''
                })
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem("token");
                    Swal.fire({
                        icon: "error",
                        title: "Please try again Login",
                        text: "There was  an error unauthorized.",
                    });
                    navigate("/login");
                }
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [headers, navigate, contextData]);
    if (Loading) {
        return (
            <p className="my-10">
                {" "}
                <LinearProgress />
            </p>
        );
    }


    // console.log("category",category)
    // console.log("collegedata",collegeData)
    // console.log("formdata",formData)
    return (
        <div>
            <div className="justify-end row">
                <button onClick={updateopen} title='back'  className="btn-sm shadow-sm flex justify-end  m-3 gap-1.5  text-white items-center border bg-[#052963]">
                    <TiArrowBack /> back
                </button>
            </div>
            <div className="container">
                <div className='row'>
                    <form className="common_dash_form p-3" onSubmit={handleUpdate} encType="multipart/form-data">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>College Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        placeholder="Enter college name"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Affilation/Affilated College </label>
                                    <input
                                        type="text"
                                        name="affiliation"
                                        className="form-control"
                                        placeholder="enter you affiliation"
                                        value={formData.affiliation}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Primary mobile number</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="primary_mobile"
                                        placeholder="mobile no."
                                        value={formData.primary_mobile}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>College image </label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        name="image"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>College logo</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        name="logo"
                                        accept="image/*"
                                        onChange={handleLogoChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Established year</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="established_year"
                                        placeholder="1990"
                                        value={formData.established_year}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <label>Course Category </label>
                                <select
                                    name="course_category"
                                    className="form-control form-select"
                                    value={parseInt(formData.course_category.id || formData.course_category[0].id)}
                                    onChange={handleChange}
                                >
                                    <option value=''>Select Course Category</option>
                                    {category &&
                                        category.map((category) => (
                                            <option key={category.id} value={parseInt(category.id)}>
                                                {category.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="col-md-12 py-2">
                                <label>Selected CourseSubcategories </label>
                                <div className="border-2  h-[90px] overflow-auto p-1">
                                    {formData.course_subcategory.map((selectedSubcategoryId, index) => {
                                        const selectedSubcategory = subcategory.find(category => category.id === selectedSubcategoryId);
                                        return (
                                            <div className="p-0.5 px-1" key={index}>
                                                {selectedSubcategory ? (
                                                    <>
                                                        {selectedSubcategory.course_name}
                                                        <span
                                                            style={{ cursor: 'pointer', marginLeft: '5px' }}
                                                            onClick={() => handleRemoveSubcategory(selectedSubcategoryId)}
                                                        >
                                                            &#10006;
                                                        </span>
                                                    </>
                                                ) : 'Unknown Subcategory'}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <label>Course Subcategory </label>
                                <div>
                                    <select
                                        name="newSubcategory"
                                        className="form-control form-select"
                                        value={formData.newSubcategory}
                                        onChange={handleChange}
                                    >
                                        <option value=''>Select or Add Subcategory</option>
                                        {subcategory.map((category) => (
                                            <option key={category.id} value={category.id}>
                                                {category.course_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-6 grid items-end">
                                <button type="button" className="border rounded-md bg-[whitesmoke] p-2 mx-3 " onClick={handleAddSubcategory}>Add Subcategory</button>

                            </div>

                            <div className="col-md-12">
                                <div className="social_links">
                                    <h1 className="my-2 font-semibold">Social Links</h1>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Facebook</label>
                                    <input
                                        type="url"
                                        className="form-control"
                                        name="facebook"
                                        placeholder="https://"
                                        value={formData.facebook}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Instagram</label>
                                    <input
                                        type="url"
                                        className="form-control"
                                        name="instagram"
                                        placeholder="https://"
                                        value={formData.instagram}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Twitter</label>
                                    <input
                                        type="url"
                                        className="form-control"
                                        name="twitter"
                                        placeholder="https://"
                                        value={formData.twitter}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>LinkedIn</label>
                                    <input
                                        type="url"
                                        className="form-control"
                                        name="linkedin"
                                        placeholder="https://"
                                        value={formData.linkedin}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Whatsapp</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="whatsapp"
                                        placeholder="9000990009"
                                        value={formData.whatsapp}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Youtube</label>
                                    <input
                                        type="url"
                                        className="form-control"
                                        name="youtube"
                                        placeholder="https://"
                                        value={formData.youtube}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Telegram</label>
                                    <input
                                        type="url"
                                        className="form-control"
                                        name="telegram"
                                        placeholder="https://"
                                        value={formData.telegram}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Website</label>
                                    <input
                                        type="url"
                                        className="form-control"
                                        name="website"
                                        placeholder="https://"
                                        value={formData.website}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="social_links">
                                    <h1 className="my-2 font-semibold">Adress details</h1>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>City</label>
                                    <input
                                        type="text"
                                        name="city"
                                        className="form-control"
                                        placeholder="city name"
                                        value={formData.city}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Current Address</label>
                                    <input
                                        type="text"
                                        name="current_address"
                                        className="form-control"
                                        placeholder="residency address"
                                        value={formData.current_address}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Zipcode</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="zipcode"
                                        placeholder="452001"
                                        value={formData.zipcode}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <label>Overview</label>
                                <RichText
                                    name="overview"
                                    placeholder={"enter your overview"}
                                    value={formData.overview}
                                    onChange={handleRichTextChange}
                                />
                            </div>
                            <div className="col-lg-12">
                                <label>Admission process</label>
                                <RichText
                                    name="admission_process"
                                    placeholder={"enter your Addmission"}
                                    value={formData.admission_process}
                                    onChange={handleRichTextChange}
                                />
                            </div>

                            <div className="col-lg-12">
                                <label>Specialization</label>
                                <FloatingLabel controlId="floatingTextarea">
                                    <RichText
                                        name="specialization"
                                        placeholder={"enter your Specialization"}
                                        value={formData.specialization}
                                        onChange={handleRichTextChange}
                                    />
                                </FloatingLabel>
                            </div>
                            <div className="col-lg-12">
                                <label>Placement</label>
                                <FloatingLabel controlId="floatingTextarea">
                                    <RichText
                                        name="placement"
                                        placeholder={"enter your placement"}
                                        value={formData.placement}
                                        onChange={handleRichTextChange}
                                    />
                                </FloatingLabel>
                            </div>

                            <div className="col-lg-12">
                                <label>Career Opportunity</label>
                                <FloatingLabel controlId="floatingTextarea">
                                    <RichText
                                        name="career_opportunity"
                                        placeholder={"enter your career_opportunity"}
                                        value={formData.career_opportunity}
                                        onChange={handleRichTextChange}
                                    />
                                </FloatingLabel>
                            </div>
                            <div className="col-lg-12">
                                <label>Scholarship</label>
                                <FloatingLabel controlId="floatingTextarea">
                                    <RichText
                                        name="scholarship"
                                        placeholder={"enter your Scholarship"}
                                        value={formData.scholarship}
                                        onChange={handleRichTextChange}
                                    />
                                </FloatingLabel>
                            </div>
                            <div className="col-lg-12">
                                <label>Scope</label>
                                <RichText
                                    name="scope"
                                    placeholder={"enter your scope"}
                                    value={formData.scope}
                                    onChange={handleRichTextChange}
                                />
                            </div>
                        </div>
                        <Button className="float-right mt-2 button_of_submit" type="submit">
                            Submit
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Collegeprofileupdate