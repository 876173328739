import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import smallbanner from '../../assets/images/predictor.jpg'
import { LinearProgress } from '@mui/material';
const CollegeGallery = ({ college_id }) => {
    // const token = useMemo(() => localStorage.getItem('token') || '', []);
    const [Gallery, setGallery] = useState([]);
    const [Loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://portal.mycollegepedia.com/college/college-gallery/${college_id}/`);
                console.log("response",response)
                const data = response.data;
                setGallery(data);
                setLoading(false);
            } catch (error) {

                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [college_id]);

    if (Loading) {
        return (
          <div className='grid'>
            <div className='my-12 p-5'>
              <LinearProgress />
            </div>
    
          </div>
        )
      }

    return (
        <div className='gallery'>
            <div className='long_border'>
                <h3 className='text-center'>Gallery</h3>
            </div>
            {Gallery && Gallery.length > 0 ? (
            <div className='h_of_card'>
                {Gallery && Gallery.map((data)=>
                <div className='row' key={data.id}>
                    <div className='col-lg-12'>
                        <div className='picc mt-2'>
                            <img src={data.image || smallbanner} alt={data.title} title={data.title} />
                        </div>
                    </div>
                </div>
                )}
            </div>
            ):(
                <p>No photos available</p>
            )}

        </div>
    )
}

export default CollegeGallery