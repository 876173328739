import React, { useState, useEffect, useContext, useMemo } from 'react'
import EducationForm from './EducationForm';
import Swal from 'sweetalert2';
import axios from 'axios';
import Apicontext from '../../Page/Contextapi';
import { useNavigate, NavLink } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import { MdBackHand } from "react-icons/md";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
const Stepform = () => {
  const token = useMemo(() => localStorage.getItem('token') || '', []);
  const contextdata = useContext(Apicontext)
  const user = useMemo(() => JSON.parse(localStorage.getItem('userprofile')) || {} , [token,]);
  const [activeStep, setActiveStep] = useState(1);
  const [data, setData] = useState([])
  const [Loading, setLoading] = useState(true);
  const [BasicInfo, setBasicinfo] = useState({
    id: user.id,
    name: user.name || '',
    mobile: user.mobile || '',
    email: user.email || '',
    profile: user.profile || '',
    gender: user.gender || '',
    dob: user.dob || '',
    religion: user.religion || '',
    category: user.category || '',
    course_interest: user.course_interest || '',
    country: user.country || '',
    state: user.state || '',
    city: user.city || '',
    current_address: user.current_address || '',
    permanent_address: user.permanent_address || '',
    zipcode: user.zipcode || '',
  });
  const [country_id, setCountry_id] = useState(null);
  const [statesList, setStatesList] = useState([]);
  console.log("user", user)
  const navigate = useNavigate();
  const headers = useMemo(() => ({
    Authorization: `JWT ${token}`,
  }), [token]);
  useEffect(() => {
    const APIdata = contextdata.APIdata;
    setData(APIdata)
    if(user || user.length > 0){
      setLoading(false)
    }
  }, [contextdata,]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://portal.mycollegepedia.com/main/state/?country_id=${country_id}`
        );
        const states = response.data;
        setStatesList(states);
      } catch (error) {
        console.error('Error fetching states:', error);
      }
    };
    if (country_id) {
      fetchData();
    }
  }, [country_id]);

  const religion = data.religion
  const Category = data.category;
  const countryList = data.Country;
  const castecategory = data.castecategory

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "profile" && files && files.length > 0) {
      const selectedFile = files[0];
      setBasicinfo({
        ...BasicInfo,
        profile: selectedFile,
      });
    } else if (name === 'country') {
      setCountry_id(value);
      setBasicinfo({
        ...BasicInfo,
        [name]: value,
        state: '',
      });
    } else {
      setBasicinfo({ ...BasicInfo, [name]: value });
    }
  };
  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const handleSaveAndContinue = () => {
    const nextStep = activeStep + 1;
    handleStepChange(nextStep);
  };
  const handleBack = () => {
    const prevStep = activeStep - 1;
    handleStepChange(prevStep);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleStepChange(1)
    const formDataForSubmit = new FormData();
    formDataForSubmit.append('id', BasicInfo.id);
    formDataForSubmit.append('name', BasicInfo.name);
    formDataForSubmit.append('email', BasicInfo.email);
    formDataForSubmit.append('mobile', BasicInfo.mobile);

    formDataForSubmit.append('gender', BasicInfo.gender);
    formDataForSubmit.append('dob', BasicInfo.dob);
    formDataForSubmit.append('religion', BasicInfo.religion);
    formDataForSubmit.append('category', BasicInfo.category);
    formDataForSubmit.append('course_interest', BasicInfo.course_interest);
    formDataForSubmit.append('country', BasicInfo.country);
    formDataForSubmit.append('state', BasicInfo.state);
    formDataForSubmit.append('city', BasicInfo.city);
    formDataForSubmit.append('current_address', BasicInfo.current_address);
    formDataForSubmit.append('permanent_address', BasicInfo.permanent_address);
    formDataForSubmit.append('zipcode', BasicInfo.zipcode);
    if (BasicInfo.profile && typeof BasicInfo.profile !== "string") {
      formDataForSubmit.append("prfoile", BasicInfo.profile);
  }

    try {
      const studentProfileApiResponse = await axios.patch(
        'https://portal.mycollegepedia.com/account/student-profile/',
        formDataForSubmit,
        { headers }
      );
      const data = studentProfileApiResponse.data;
      // localStorage.setItem('profileData', JSON.stringify(data));
      console.log("apires", studentProfileApiResponse);

      if (studentProfileApiResponse.status === 200) {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        });

        Toast.fire({
          icon: 'success',
          text: 'Data submitted successfully!',
          title: data.name
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: data.message,
          text: 'There was an error in form submission   Please try again.'
        });
      }
    } catch (error) {
      console.error('Error submitting data:', error);
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        Swal.fire({
          icon: 'error',
          title: error.response.message,
          text: 'Unauthorized  Please try again.'
        });
        navigate('/login')
      }
    }

  };

  if (Loading) {
    return <div>
      <div className="my-10 px-3">
        <LinearProgress />
      </div>
    </div>;
  }

  const renderTabs = () => {
    const stepLabels = [
      'Basic Information',
      'Acadmic Details',
      'Address',
      'Finish',
    ];

    return stepLabels.map((label, index) => (
      <li key={index + 1} role="presentation" className={activeStep === index + 1 ? 'active' : 'disabled'}>
        <a
          href={`#step${index + 1}`}
          data-toggle="tab"
          aria-controls={`step${index + 1}`}
          role="tab"
          aria-expanded={activeStep === index + 1 ? 'true' : 'false'}
          onClick={() => handleStepChange(index + 1)}
        >
          <span className={`round-tab ${activeStep === index + 1 ? 'active' : ''}`}>{index + 1}</span> <i>{label}</i>
        </a>
      </li>
    ));

  };
 
  console.log("this function useser user data",user)
  return (
    <div>
      <section className="signup-step-container common_dash">
        <div className="container">

          <div className="row align-items-center top ">
            <div className="col-lg-6">

              <h3>Welcome {user.name || ''}<MdBackHand className="text-warning anim" /> !</h3>
            </div>
            <div className="col-lg-6">
              <Breadcrumb className="bread">
                <Breadcrumb.Item ><NavLink to="/student-dashboard/home" className='text-[#052963]'>Home</NavLink></Breadcrumb.Item>

                <Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="items-center row d-flex justify-content-center">
            <div className="col-md-12">
              <div className="wizard">
                {/* <div className="main-logo">
                  <img src={Profiledata.profile} className="logo" alt="collegepedia" />
                </div> */}
                <div className="pt-3 wizard-inner">

                  <div className="connecting-line" />
                  <ul className="nav nav-tabs" role="tablist">
                    {renderTabs()}
                  </ul>
                </div>
                <form encType='multipart/form-data' className="login-box ">
                  <div className="tab-content" id="main_form">
                    <div className={`tab-pane ${activeStep === 1 ? 'active' : ''}`} role="tabpanel" id="step1">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group" >
                            <label> Name *</label>
                            <input
                              className="form-control"
                              type="text"
                              name="name"
                              value={BasicInfo.name}
                              onChange={handleChange}
                              placeholder="Full Name"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Mobile Number *</label>
                            <input
                              className="form-control cm-number"
                              type="number"
                              name="mobile"
                              value={BasicInfo.mobile}
                              onChange={handleChange}
                              placeholder="+918956230000"
                            />
                          </div>

                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Email Address *</label>
                            <input
                              className="form-control"
                              type="email"
                              name="email"
                              value={BasicInfo.email}
                              onChange={handleChange}
                              placeholder="abc@gmail.com"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group ">
                            <label htmlFor="profile" className="form-label">Upload Image:</label>
                            <input
                              type="file"
                              onChange={(e) => handleChange(e)}
                              name="profile"
                              id="profile"
                              accept="image/*"
                              className="form-control-file"
                            />

                          </div>
                        </div>
                        <div className="col-md-4">

                          <div className="form-group">
                            <label>Gender *</label>
                            <select
                              name="gender"
                              className="form-control form-select"
                              value={BasicInfo.gender}
                              onChange={handleChange}
                            >
                              <option value="">Select Gender</option>
                              <option value='MALE'>MALE</option>
                              <option value='FEMALE'>FEMALE</option>
                              <option value='OTHERS'>OTHERS</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Date of Birth *</label>
                            <input
                              className="form-control datatable"
                              type="date"
                              name="dob"
                              value={BasicInfo.dob}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                      </div>
                      <div className='row'>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Religion</label>
                            <select
                              className="form-control form-select"
                              name="religion"
                              value={BasicInfo.religion}
                              onChange={handleChange}
                            >
                              <option value='' >Select religion</option>
                              {religion &&
                                religion.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                            </select>

                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Caste-Category</label>
                            <select
                              className="form-control form-select"
                              name="category"
                              value={BasicInfo.category}
                              onChange={handleChange}
                            >
                              <option value='' >Select caste category</option>
                              {castecategory &&
                                castecategory.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}

                             
                            </select>

                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Course of interst</label>
                            <select
                              className="form-control form-select"
                              name="course_interest"
                              value={BasicInfo.course_interest}
                              onChange={handleChange}
                            >
                              <option value=''>Select Course of interst</option>
                              {Category &&
                                Category.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}

                            </select>

                          </div>
                        </div>
                      </div>

                      <ul className="list-inline pull-right">
                        <li>
                          <button
                            type="button"
                            onClick={handleSaveAndContinue}
                            className="text-white default-btn next-step"
                          >
                            Save & next Continue
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className={`tab-pane ${activeStep === 2 ? 'active' : ''}`} role="tabpanel" id="step2">

                      <EducationForm handleSaveAndContinue={handleSaveAndContinue} handleBack={handleBack} activeStep={activeStep} />

                    </div>
                    <div className={`tab-pane ${activeStep === 3 ? 'active' : ''}`} role="tabpanel" id="step3">
                      {/* <h4 className="text-center">Address</h4> */}
                      <div className="row">

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>City / Town *</label>
                            <input
                              className="form-control"
                              type="text"
                              name="city"
                              value={BasicInfo.city}
                              onChange={handleChange}
                              placeholder="indore"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Country *</label>
                            <select
                              className="form-control form-select"
                              name="country"
                              onChange={handleChange}

                              value={BasicInfo.country}
                            >
                              <option value="">Select Country</option>
                              {countryList &&
                                countryList.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>State *</label>
                            <select
                              className="form-control form-select"
                              name="state"
                              onChange={handleChange}
                              value={BasicInfo.state}
                            >
                              <option value="">Select State</option>
                              {statesList &&
                                statesList.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Zip code *</label>
                            <input
                              className="form-control cm-number"
                              type="number"
                              onChange={handleChange}
                              name="zipcode"
                              value={BasicInfo.zipcode}
                              placeholder="450026"
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Current Address  *</label>
                            <textarea
                              className="form-control"
                              name="current_address"
                              onChange={handleChange}
                              placeholder="current address"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Permanent Address 2 *</label>
                            <textarea
                              className="form-control"
                              type="text"
                              onChange={handleChange}
                              name="permanent_address"
                              placeholder="permanent address"
                            />
                          </div>
                        </div>

                      </div>


                      <ul className="list-inline pull-right">
                        {activeStep !== 1 && (
                          <li>
                            <button
                              type="button"
                              className="default-btn prev-step"
                              onClick={handleBack}
                            >
                              Back
                            </button>
                          </li>
                        )}
                        {activeStep !== 4 ? (
                          <li>
                            <button
                              type="button"
                              className="text-white default-btn next-step"
                              onClick={handleSaveAndContinue}
                            >
                              Save & Next Continue
                            </button>
                          </li>
                        ) : (
                          <li>
                            <button
                              type="button"
                              className="text-white default-btn next-step"
                              onClick={handleSubmit}
                            >
                              Submit & return
                            </button>
                          </li>
                        )}
                      </ul>
                    </div>

                    <div className={`tab-pane ${activeStep === 4 ? 'active' : ''}`} id="step4">
                      <p className='px-8 py-2 text-justify'>
                        Thank you for taking the time to provide us with your details. Your input is invaluable to us, and it will contribute to making MyCollegePedia a comprehensive and user-friendly platform for college students like yourself.
                        We appreciate your willingness to share information about your college experience. Your insights will not only help us improve our services but also benefit other students who rely on MyCollegePedia for valuable guidance.
                        If you have any further questions or suggestions, feel free to reach out to us. We're here to support you on your academic journey.
                        Best regards,
                        <i>The MyCollegePedia Team </i>
                      </p>
                      <ul className="list-inline pull-right">
                        {activeStep !== 1 && (
                          <li>
                            <button
                              type="button"
                              className="default-btn prev-step"
                              onClick={handleBack}
                            >
                              Back
                            </button>
                          </li>
                        )}
                        {activeStep !== 4 ? (
                          <li>
                            <button
                              type="button"
                              className="text-white default-btn next-step"
                              onClick={handleSaveAndContinue}
                            >
                              Save & Next Continue
                            </button>
                          </li>
                        ) : (
                          <li>
                            <button
                              type="button"
                              className="text-white default-btn next-step"
                              onClick={handleSubmit}
                            >
                              Finish & return
                            </button>
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="clearfix" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div >
      </section >

    </div >
  )
}

export default Stepform