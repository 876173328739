import React, { useMemo, useEffect, useState } from 'react';
import axios from 'axios';

const CollegeVideo = ({ college_id,token }) => {
   
    const [videoData, setVideoData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://portal.mycollegepedia.com/college/college-video/${college_id}/`);
                const data = response.data;
                setVideoData(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [college_id, token]);

    if (loading) {
        return <p className='p-2'>Loading...</p>;
    }

    // Function to extract YouTube video ID from URL
console.log("video ", videoData)

    return (
        <div className='gallery'>
            <div className='long_border'>
                <h3 className='text-center'>Collegs Video </h3>
            </div>
            <div className='h_of_card'>
                {videoData && videoData.length > 0 ? (
                    <div className='row'>
                        {videoData.map((data) => (
                            <div className='col-lg-12' key={data.id}>
                                <div className='picc mt-2'>
                                    {/* Construct the embed URL using the extracted video ID */}
                                    <video autoPlay loop muted className="img-fluid ">
                                        <source src={data.videourl} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>No video available</p>
                )}
            </div>
        </div>
    );
};

export default CollegeVideo;
