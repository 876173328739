import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import "../../assets/css/Brochure.css"
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Apicontext from '../../Page/Contextapi';
import { FaDownload, FaMousePointer, FaWhatsapp, FaInstagram, FaFacebook, FaLinkedin, FaYoutube, FaTelegram, FaArrowRight } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import image from '../../assets/images/collegeimg.jpg'
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2';
import LazyLoad from 'react-lazyload';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Modal from 'react-modal';
import Applyform from "./Applyform";
import CollegeFQs from './CollegeFQs';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { NavLink } from 'react-router-dom';
import { MdOutlineBadge } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { CiCalendarDate, CiStar } from "react-icons/ci";
import StarRating from '../Homepages/Startrating';
import Predictorform from '../Collegepredictor/Predictorform';
import { Suspense } from 'react';
import collegepredictor from '../../assets/images/predictor_banner.jpg'
import EligibilityItem from './EligibilityItem';
import CollegeGallery from './CollegeGallery';
import CollegeVideo from './CollegeVideo';
import CollegeNews from './CollegeNews';
import feedbackbanner from '../../assets/images/Feedback.jpg'
import exambanner from '../../assets/images/Exams.jpg'
import FeedbackForm from '../Feedbackform/Feedback';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1100,
  },
};

const Collegesingle = () => {
  const [collegeData, setCollegeData] = useState({});
  const token = useMemo(() => localStorage.getItem('token') || '', []);
  const user = JSON.parse(localStorage.getItem('user'));
  const [Data, setData] = useState([]);
  const [Allapidata, setAllapidata] = useState([]);
  // const [State, setState] = useState(null);
  const [Loading, setLoading] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsFeedback, setIsFeedback] = useState(false);
  const [predicform, setPredicFrom] = useState(false);
  const [Topviewcollege, setTopviewcollege] = useState(null);
  const [populerBlog, setPopulerBlog] = useState(null);
  const [blogdata, setBlogdata] = useState([]);
  const [colleges, setColleges] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const owlOptions = {
    autoplay: true,
    rewind: true,
    transition: 500,
    dots: false,
    responsiveClass: true,
    // autoheight: true, 
    autoplayTimeout: 13000,
    smartSpeed: 5000,
    nav: true,
    navText: ["<", ">"],
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1024: { items: 2 },
      1366: { items: 2 },
    },
  };

  let subtitle;
  const { slug } = useParams();
  const contextdata = useContext(Apicontext);


  const handleFormToggle = () => {
    setPredicFrom(!predicform);
  };
  const handlefeedback = () => {
    setIsFeedback(!modalIsFeedback);
  };
  useEffect(() => {
    if (blogdata.length > 0) {
      const sortedBlogs = [...blogdata].sort((a, b) => b.views - a.views);
      const mostViewedBlogs = sortedBlogs.slice(0, 8);
      setPopulerBlog(mostViewedBlogs);
    }
    if (colleges.length > 0) {
      const sortedcollege = [...colleges].sort((a, b) => b.views - a.views);
      const mostViewedclg = sortedcollege.slice(0, 8);
      setTopviewcollege(mostViewedclg);
    }
  }, [blogdata, collegeData]);



  const openModal = (course) => {
    if (user && user.is_student && token) {
      setIsOpen(true);
      setSelectedCourse(course);
    } else {
      console.log("function run");
      Swal.fire({
        icon: "error",
        title: "Oops..Try Again",
        text: "User Not Unauthorized!",
        toast: true,
        position: 'top-end',
        timer: 3000,
        timerProgressBar: true,
      });
      console.error("this user not unauthorized or user not logged in");
    }
  };

  function afterOpenModal() {
    subtitle.style.color = 'black';
  }
  function closeModal() {
    setIsOpen(false);
  }


  useEffect(() => {
    const data = contextdata.APIdata;
    setData(data.stream)
    setAllapidata(data)
    // setState(data.State)
    setBlogdata(data.blog)
    setColleges(data.college)

    const fetchCollegeData = async () => {
      try {
        const response = await axios.get(`https://portal.mycollegepedia.com/college/college/${slug}`);
        setCollegeData(response.data);
        setLoading(false)
      } catch (error) {
        console.error('Error fetching college data:', error);
      }
    };
    fetchCollegeData();
  }, [slug, contextdata]);


  const renderEligibility = () => {
    return collegeData.eligibility?.map((eligibilityItem) => (
      <EligibilityItem eligibilityItem={eligibilityItem} data={Allapidata} />
    ));
  };



  console.log("single college", collegeData)

  const renderCourseFee = () => {
    if (!collegeData.course_fee || collegeData.course_fee.length === 0) {
      console.error('Data or course_fee is not available.');
      return <p>Course data is not available</p>;
    }

    return (
      <section className="table-data">
        <div className="container">
          <div className="row p-0">
            <div className="col-lg-12 p-0">
              <div className="table-responsive p-0 thin_scroll">
                <table className="table align-middle table-striped">
                  <thead>
                    <tr>
                      <th scope="tableheadingcolor">Academic Year</th>
                      <th scope="tableheadingcolor">Course Category</th>
                      <th scope="tableheadingcolor">Sub Caegory</th>
                      <th scope="tableheadingcolor">Stream</th>
                      <th scope="tableheadingcolor">Yearly Fees</th>
                      <th scope="tableheadingcolor">Total Fees</th>
                      <th scope="tableheadingcolor">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {collegeData.course_fee.map((fees) => {
                      const courseStreamId = fees.course_stream;
                      const stream = Data.find((stream) => stream.id === courseStreamId) || {};
                      const courseSubCategoryId = fees.course_subcategory;
                      const sub_category = Allapidata.subcategory.find((course) => course.id === courseSubCategoryId) || {};

                      return (
                        <tr key={fees.id}>
                          <td>{fees.academic_year.year}</td>
                          <td>
                            {collegeData.course_category?.map((course) => (
                              <p key={course.id}>{course.name || '---'}</p>
                            )) || 'B tech'}
                          </td>

                          <td>
                            <span className="badge badge-soft-info">
                              {sub_category.course_name || "---"}
                            </span>
                          </td>
                          <td>
                            <span className="badge badge-soft-info">
                              {stream.name || "---"}
                            </span>
                          </td>
                          <td><span className="mb-0 badge badge-soft-danger">{fees.year_fees || '--/--/--'}</span></td>
                          <td><span className="mb-0 badge badge-soft-success">{fees.total_fees || '--'}</span></td>
                          <td><button className='button_of_submit btn' onClick={() => openModal(fees)}>Apply</button></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const renderFAQ = () => {
    return collegeData.faq?.map((faqItem, index) => (
      <div key={index}>
        <CollegeFQs key={faqItem.id} faqItem={faqItem} isFirst={index === 0} />
      </div>
    ));
  };
  const Alldetails = () => {
    return (
      <section className="contentone">
        <div className="container">
          {collegeData.overview && (
            <div className="row">
              <div className="col-lg-12">
                {/* <h4 className='font-bold overview'>Overview</h4> */}
                <p className='font_f' dangerouslySetInnerHTML={{ __html: collegeData.overview }} />
              </div>
              {/* {Affiliation && <div className="my-3 col-lg-12">
                <h3 className='single_page_heading'>Affiliation</h3>
                <p>{Affiliation}</p>
              </div>
              } */}
            </div>
          )}

          <div className="table-responsive">
            <h3 className='single_page_heading'>{collegeData.name || "-"}</h3>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col tableheadingcolor">Particulars</th>
                  <th scope="col tableheadingcolor">Statistics </th>
                </tr>
              </thead>
              <tbody>
                {collegeData.affiliation &&
                  <tr>
                    <td>affiliation </td>
                    <td>{collegeData.affiliation || '-'}</td>
                  </tr>
                }

                {collegeData.organization_type?.name &&
                  <tr>
                    <td>organization type </td>
                    <td>{collegeData.organization_type?.name ? collegeData.organization_type?.name : "-"}</td>
                  </tr>
                }

                {collegeData.college_type?.name &&
                  <tr>

                    <td>college type </td>
                    <td>{collegeData.college_type?.name || '-'}</td>
                  </tr>
                }
                {collegeData.rating &&
                  <tr>

                    <td>rating </td>
                    <td> <StarRating rating={collegeData.rating} /></td>
                  </tr>
                }
                {collegeData.established_year &&
                  <tr>

                    <td>established year </td>
                    <td>{collegeData.established_year}</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>

          {collegeData.exams_eligibility && (
            <div className="row">
              <div className="col-lg-12">
                <h3 className='single_page_heading'>Exams & Eligibility</h3>
                <p>{renderEligibility()}</p>
              </div>
            </div>
          )}
          {collegeData.admission_process && (
            <div className="row">
              <div className="  col-lg-12">
                <h3 className='single_page_heading'>Admission Process</h3>
                <p className='!text-black list_st' dangerouslySetInnerHTML={{ __html: Admission_process }} />
              </div>
            </div>
          )}
          {renderCourseFee && (
            <div className="row">
              <div className=" col-lg-12">
                <h3 className='single_page_heading'>Course & Fee</h3>
                <p className='spec_padding'>{renderCourseFee()}</p>
              </div>
            </div>
          )}

          {collegeData.placement && (
            <div className="row">
              <div className="col-lg-12 ">
                <h3 className='single_page_heading'>Placements</h3>
                <div className='p-0' dangerouslySetInnerHTML={{ __html: collegeData.placement }} />
              </div>
            </div>
          )}
          {collegeData.specialization && (
            <div className="row">
              <div className="col-lg-12 ">
                <h3 className='single_page_heading'>Specialization</h3>
                <div className='list_st p-0' dangerouslySetInnerHTML={{ __html: specialization }} />
              </div>
            </div>
          )}

          {collegeData.career_opportunity && (
            <div className="row">
              <div className="col-lg-12">
                <h3 className='single_page_heading'>Career & Opportunity</h3>
                <div className='p-0' dangerouslySetInnerHTML={{ __html: career_opportunity }} />
              </div>
            </div>
          )}
          {collegeData.scholarship && (
            <div className="row">
              <div className="  col-lg-12 ">
                <h3 className='single_page_heading'>Specialization</h3>
                <div className='mx-2' dangerouslySetInnerHTML={{ __html: collegeData.scholarship }} />
              </div>
            </div>
          )}

          {collegeData.faq && (
            <div className="row">
              <div className="col-lg-12 px-0">
                <h3 className='single_page_heading ml-4'>FAQs</h3>
                <p>{renderFAQ()}</p>
              </div>
            </div>
          )}
        </div>
        &nbsp;&nbsp;
      </section>

    );
  }


  const Overview = collegeData.overview;
  const collegeoverview = Overview && Overview.replace(/style="[^"]*"/g, '');
  const Addmession = collegeData.admission_process;
  const Admission_process = Addmession && Addmession.replace(/style="[^"]*"/g, '');
  const specializations = collegeData.specialization;
  const specialization = specializations && specializations.replace(/style="[^"]*"/g, '');
  const Placementdata = collegeData.placement;
  const Placement_data = Placementdata && Placementdata.replace(/style="[^"]*"/g, '');
  const Scholarship = collegeData.scholarship;
  const careeropportunity = collegeData.career_opportunity;

  const career_opportunity = careeropportunity && careeropportunity.replace(/style="[^"]*"/g, '');
  const Scopedata = collegeData.scope;
  const tabs = [
    Alldetails && { id: 'All Details', label: 'Information', content: Alldetails() || 'Overview content' },
    Overview && { id: 'Overview', label: 'Overview', content: <div className='tab_contents'>  <div className='py-3 ' dangerouslySetInnerHTML={{ __html: collegeoverview }} /> </div> || 'Overview content' },
    renderEligibility && { id: 'Eligibility', label: 'Exams & Eligibility', content: renderEligibility() },
    Admission_process && { id: 'Admission', label: 'Admission Process', content: <div className='tab_contents'> <div dangerouslySetInnerHTML={{ __html: Admission_process }} /> </div> || 'Admission Process content' },
    renderCourseFee && { id: 'Courses', label: 'Course & Fee', content: renderCourseFee() || 'Course content' },
    specialization && { id: 'Specialization', label: 'Specialization', content: <div className='tab_contents'>  <div dangerouslySetInnerHTML={{ __html: specialization }} /> </div> || 'Specialization content' },
    career_opportunity && { id: 'Opportunity', label: 'Career & Opportunity', content: <div dangerouslySetInnerHTML={{ __html: collegeData.career_opportunity }} /> || 'Opportunity content' },
    Scopedata && { id: 'scope', label: 'Scope', content: <div className='tab_contents'>  <div dangerouslySetInnerHTML={{ __html: Scopedata }} /></div> || 'scope content' },
    Placement_data && { id: 'Placement', label: 'Placement', content: <div className='tab_contents'>  <div className='' dangerouslySetInnerHTML={{ __html: Placement_data }} /></div> || 'palcement content' },
    Scholarship && { id: 'Scholarship', label: 'Scholarship', content: <div className='tab_contents'>  <div dangerouslySetInnerHTML={{ __html: Scholarship }} /> </div> || 'scholarship content' },
    renderFAQ && { id: 'FAQ', label: 'FAQ', content: renderFAQ() || 'FAQ content' },
  ];

  // const handlePrevClick = () => {
  //   setCurrentTabIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  // };

  // const handleNextClick = () => {
  //   setCurrentTabIndex((prevIndex) => Math.min(prevIndex + 1, tabs.length - 1));
  // };

  if (Loading) {
    return (
      <div className="min-h-screen p-3 flex items-center justify-center">
        <div className="w-16 h-16 border-4 border-dashed rounded-full animate-spin border-[#052963] dark:border-[whitesmoke]"></div>
      </div>
    )
  }

  return (
    <div className='single_college_section'>
      <Helmet>
        <title>{collegeData ? collegeData.meta_title : "MyCOllegePedia - College"}</title>
        <meta name="description" content={collegeData.meta_description ? collegeData.meta_description : "company in Indore, delivering innovative solutions for businesses"} />
        <meta name="keywords" content={collegeData.meta_keyword ? collegeData.meta_keyword : " "} />
      </Helmet>
      <div className="container-fluid" id="college-content-section">

        <div className='row'>
          <div className='px-0 col-lg-12'>
            <div className="img-section ">
              <div className='titlecollege'>
                <div className='single_college_bread'>
                  <div className="row align-items-center">
                    <div className="col-lg-12">
                      <Breadcrumb className="bread">
                        <Breadcrumb.Item >
                          <NavLink to="/" title='Home-page'>Home</NavLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item >
                          <NavLink to="/all-colleges" title='All-colleges'>Colleges</NavLink>
                        </Breadcrumb.Item>
                        {/* <Breadcrumb.Item className='lowercase'>{State.map((data) => data.id === collegeData.state && <Link to={`/state/${data.name}`} title={data.name}> {data?.name} </Link>) || null}</Breadcrumb.Item> */}
                        <Breadcrumb.Item active>{collegeData.name || null}</Breadcrumb.Item>
                      </Breadcrumb>
                    </div>
                  </div>
                </div>
                <div className='college_main_details'>
                  <div className='logo_pic'>
                    <img src={`${collegeData.logo ? collegeData.logo : ''}`} alt={collegeData.name} title={collegeData.name} loading='lazy' className='hidden singlelogo md:block' />
                  </div>
                  <div className='college_name'>
                    <div className='c_name'>
                      <h1 className='name'>{collegeData.name}</h1>
                    </div>
                    <div className='other_details'>
                      <p className='d-flex align-items-center gap-1'><IoLocationOutline />{collegeData.city}{collegeData.current_address}</p></div>
                    <div className='other_details'>
                      <p className='d-flex align-items-center gap-1'><MdOutlineBadge /> AICTE</p>
                      <p className='d-flex align-items-center gap-1'><CiCalendarDate /> {collegeData.established_year || ""}</p>
                      <p className='d-flex align-items-center gap-1'><CiStar className='text-yellow-500' />{collegeData.course_category?.map((college) => (
                        <span key={college.id}> {college.name} {' '}</span>
                      ))}
                      </p>
                    </div>
                    <div className='other_details'>
                      <div className='social_d mt-3'>
                        <div className='row align-items-center'>
                          <div className='col-md-9'>
                            <div className='personal_d_college '>
                              <p className='conte'><span>Primary:</span><span>{collegeData.primary_mobile || '+917880110983'}</span></p>

                              <p className='conte'><span>Email:</span><span>{collegeData.email || "mycollegepedia@gmail.com"} </span></p>
                            </div>
                          </div>
                          <div className='col-md-3'>
                            <div className='social_college'>
                              {collegeData.whatsapp &&
                                <a href={collegeData.whatsapp || 'https://wa.link/enygey'} target="_blank" className="foot-icon-click" rel="noopener noreferrer">
                                  <span><FaWhatsapp size={18} /></span>
                                </a>
                              }
                              {collegeData.instagram &&
                                <a href={collegeData.instagram || ''} target="_blank" className="foot-icon-click" rel="noopener noreferrer">
                                  <span> <FaInstagram /></span>
                                </a>
                              }
                              {collegeData.facebook &&
                                <a href={collegeData.facebook || ''} target="_blank" className="foot-icon-click" rel="noopener noreferrer">
                                  <span><FaFacebook /></span>
                                </a>
                              }
                              {collegeData.linkedin &&
                                <a href={collegeData.linkedin || ''} target="_blank" className="foot-icon-click" rel="noopener noreferrer">
                                  <span><FaLinkedin /></span>
                                </a>
                              }
                              {collegeData.twitter &&
                                <a href={collegeData.twitter || ''} target="_blank" className="foot-icon-click" rel="noopener noreferrer">
                                  <span><FaXTwitter /></span>
                                </a>
                              }
                              {collegeData.youtube &&
                                <a href={collegeData.youtube || ''} target="_blank" className="foot-icon-click" rel="noopener noreferrer">
                                  <span>
                                    <FaYoutube /></span>
                                </a>
                              }
                              {collegeData.telegram &&
                                <a href={collegeData.telegram || ''} target="_blank" className="foot-icon-click" rel="noopener noreferrer">
                                  <span> <FaTelegram /></span>
                                </a>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='college_ratings'>
                    <div className="mba1">
                      <h6 className="title">(#{collegeData.rank || '11'})</h6>
                      <p>{collegeData.views || '51'} Reviews</p>
                      <p className='d-flex align-items-center text-warning'><StarRating rating={collegeData.rating} /></p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="div-img">
                <img src={`${collegeData.image ? collegeData.image : image}`} alt={collegeData.name} title={collegeData.name} className='main_h_fluid_content' />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h4 ref={(_subtitle) => (subtitle = _subtitle)}> </h4>
        <Applyform closeModal={closeModal} collegeData={collegeData} selectedCourse={selectedCourse} />
      </Modal>

      <Modal
        isOpen={modalIsFeedback}
        onRequestClose={handlefeedback}
        style={customStyles}
        contentLabel="Feedback Modal"
      >

        <FeedbackForm closeModal={handlefeedback} token={token} />
      </Modal>

      <div className='container'>
        <div className="row ">
          <div className=" col-md-9">
            <Tabs >
              <div className="tab-container" >

                <TabList>
                  {tabs && tabs.map((tab) => (
                    tab && tab.label && tab.content ? (
                      <Tab key={tab.id}>{tab.label}</Tab>
                    ) : null
                  ))}
                </TabList>

              </div>

              {/* predictor form */}
              <div className='row'>
                <div className='col-md-12'>
                  <div className='single_col_pform'>
                    {predicform && <Predictorform handleform={handleFormToggle} />}
                  </div>
                </div>
              </div>
              {tabs && tabs.map((tab) => (
                tab && tab.label && tab.content ? (
                  <TabPanel key={tab.id}>
                    {typeof tab.content === 'string' ? (
                      <div>
                        <div className='tab_contents'>{tab.content} </div>
                      </div>

                    ) : (
                      <div>
                        <div className='tab_contents'>{tab.content} </div>
                        <div className='tab_contents'>
                          {tab.id === 'Overview' && (
                            <div className="table-responsive ">
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th scope="col tableheadingcolor">Particulars</th>
                                    <th scope="col tableheadingcolor">Statistics </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {collegeData.affiliation &&
                                    <tr>
                                      <td>affiliation </td>
                                      <td>{collegeData.affiliation || '----'}</td>
                                    </tr>
                                  }
                                  {collegeData.email &&
                                    <tr>
                                      <td>email </td>
                                      <td>{collegeData.email || '---'}</td>
                                    </tr>
                                  }

                                  {collegeData.organization_type?.name &&
                                    <tr>
                                      <td>organization type </td>
                                      <td>{collegeData.organization_type?.name ? collegeData.organization_type?.name : "-"}</td>
                                    </tr>
                                  }

                                  {collegeData.college_type?.name &&
                                    <tr>

                                      <td>college type </td>
                                      <td>{collegeData.college_type?.name || '-'}</td>
                                    </tr>
                                  }
                                  {collegeData.rating &&
                                    <tr>

                                      <td>rating </td>
                                      <td>{collegeData.rating}</td>
                                    </tr>
                                  }
                                  {collegeData.established_year &&
                                    <tr>

                                      <td>established year </td>
                                      <td>{collegeData.established_year}</td>
                                    </tr>
                                  }
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </TabPanel>
                ) : null
              ))}
            </Tabs>
            {/* owl random college */}
            <div className='owl_slider_rec'>
              <div className='main__card'>
                <div className='row'>
                  <div className='col-lg-12 p'>
                    <div className='long_border'>
                      <h3>Recommended Colleges</h3>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    {colleges && colleges.length > 0 && (
                      <OwlCarousel className="owl-theme " {...owlOptions}>
                        {colleges.map((college) => (
                          <div className="gap-5 mx-2" key={college.id}>


                            <div className="card_college">
                              <img className="card-img-top mba-img" src={college.image} alt={college.name} title={college.name} />
                              <div className="card-body">
                                <div className="main d-flex align-item-center">
                                  <div className="logocard">
                                    <Suspense key={college.id + "_suspense"}>
                                      <LazyLoad height={200} offset={100} once key={college.id + "_lazyload"}>
                                        <img src={college.logo} title={college.name} alt={college.name} loading='lazy' key={college.id + "_image"} />
                                      </LazyLoad>
                                    </Suspense>
                                  </div>
                                </div>
                                <div className="d-flex head">
                                  <div className="mba">
                                    <h6 className="title"> {college.course_category[0].name}</h6>

                                  </div>
                                  <div className="mba1">
                                    <h6 className="title d-flex text-warning"><StarRating rating={collegeData.rating} /></h6>

                                  </div>

                                  <small className='small-c'> {college.organization_type.name}</small>
                                </div>
                                <div>
                                  <div className="card-pera" key={college.id + "_cardpera"}>
                                    <h4>{college.name}</h4>
                                  </div>
                                  <small className="pt-1 adress">
                                    <span > {college.current_address}</span>
                                  </small>
                                  <small className="pt-1 lowercase adress">


                                  </small>
                                  <div className="link">
                                    <Link to={`/college/${college.slug}`} title={college.name} className="py-2">View More.. <FaArrowRight /></Link>

                                  </div>
                                </div>
                                <div className="link">
                                  <Link to={`/college/${college.slug}`} title={college.name} className="py-2">View More.. <FaArrowRight /></Link>

                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </OwlCarousel>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-3 '>

            <div className='row' >
              <div className='col-md-12'>
                <Stack spacing={2} className='btnbroucher'>
                  <Link to={`/broucher/${slug}`}>
                    <Button className='btn' variant="outlined"   >
                      Download brochure <FaDownload className='' />
                    </Button>
                  </Link>
                </Stack>
              </div>
              <div className='col-md-12'>
                <Stack spacing={2} className='btnapply'>
                  <Button className='btn' variant="outlined" onClick={openModal}>
                    Apply <FaMousePointer className='' />
                  </Button>
                </Stack>
              </div>

            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='small_banner' onClick={handleFormToggle}>
                  <img src={collegepredictor} alt="predictor" title={'MyCollegePedia | Predicted Colleges '} />
                </div>
              </div>
              <div className='col-md-12'>
                <div className='small_banner'>
                  <button onClick={handlefeedback} title='Feedback'>
                    <img src={feedbackbanner} alt={"feedback"} title={'MycollegePedia | Feedback '} />
                  </button>
                </div>
              </div>
              <div className='col-md-12'>
                <div className='small_banner'>
                  <Link to={'/all-exams'} title='exam view'> <img src={exambanner} alt={"exam"} title={'Upcoming Exams'} /></Link>
                </div>
              </div>
            </div>
            {/* photos */}
            <div className='row'>
              <div className='col-md-12'>
                <CollegeGallery token={token} college_id={collegeData.id} />
              </div>
            </div>
            {/* videos */}
            <div className='row'>
              <div className='col-md-12'>
                <CollegeVideo token={token} college_id={collegeData.id} />
              </div>
            </div>
            {/* news and alerts */}
            <div className='row'>
              <div className='col-md-12'>
                <CollegeNews college_id={collegeData.id} />
              </div>
            </div>
            {/* popular college */}
            <div className='row'>
              <div className='col-md-12'>
                <div className='gallery'>
                  <div className='long_border'>
                    <h3 className='text-center'>Popular college</h3>
                  </div>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='main_side_card h_of_card'>
                        {Topviewcollege && Topviewcollege.map((data) =>
                          <div className='popular_col' key={data.id}>
                            <div className='pic'>
                              <LazyLoad height={200} offset={100} once key={data.id + "_lazyload"}>
                                <img src={data.logo} title={data.name} alt={data.name} loading='lazy' />
                              </LazyLoad>
                            </div>
                            <div className='content'>
                              <p className='title'>{data.name}</p>
                              <p className='rating'><StarRating rating={data.rating} /> </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            {/* popular college */}
            <div className='for_sticky'>
              <div className='row '>
                <div className='col-md-12'>
                  <div className='gallery'>
                    <div className='long_border'>
                      <h3 className='text-center'>Random college</h3>
                    </div>

                    <div className='row'>
                      <div className='col-lg-12'>
                        <div className='main_side_card h_of_card'>
                          {populerBlog && populerBlog.map((data) =>
                            <div className='popular_col' key={data.id}>
                              <div className='pic'>
                                <img
                                  src={data.image || "https://templates.hibootstrap.com/sanu/default/assets/images/events/events-1.jpg"}
                                  className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                  alt={data.title}
                                  title={data.title}
                                />
                              </div>
                              <div className='content'>
                                <p className='title'>{data.title}</p>
                                <p className='published_on'><span>Published On:</span><span>{new Date(data.published_date).toLocaleDateString('en-GB')}</span></p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};
export default Collegesingle;