import React, { useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

import { TiArrowBack } from 'react-icons/ti';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Apicontext from '../../../Page/Contextapi';
import RichText from '../../CollegeDashboard/Subpages/Richtext';

const AddNewsandAleretCounslr = () => {
    const token = useMemo(() => localStorage.getItem('token') || '', []);
    const college = JSON.parse(localStorage.getItem('collegerecord'));
    const user = JSON.parse(localStorage.getItem('user'));
    const [category, setCategory] = useState([]);
    const [formData, setFormData] = useState({
        counsellor: college[0].id || '',
        user: user.id || '',
        title: '',
        post: '',
        image: '',
        status: '',
        course_category: '',
    });
    const contextdata = useContext(Apicontext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const categories = await contextdata.APIdata.category;
            setCategory(categories)
        }
        fetchData()
    }, [navigate, contextdata]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleRichTextChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            image: imageFile,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("formdata", formData)
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('counsellor', formData.counsellor)
            formDataToSend.append('user', formData.user)
            formDataToSend.append('title', formData.title);
            formDataToSend.append('status', formData.status);
            formDataToSend.append('post', formData.post);
            formDataToSend.append('course_category', formData.course_category);
            formDataToSend.append('image', formData.image);

            const response = await axios.post('https://portal.mycollegepedia.com/counsellor/news/', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `JWT ${token}`,
                },
            });
            console.log("add news", response)
            if (response.status === 201) {
                Swal.fire({
                    position: 'top-end',
                    timer: 2500,
                    icon: "success",
                    text: 'Data submitted successfully!',
                });
                navigate('/counsellor-dashboard/news-articles')
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'There was an error in form submission. Please try again.',
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'There was an error. Please try again.',
            });
        }
    };

    return (
        <div className='container common_dash my-3 '>
            <div className="row align-items-center top">
                <div className="col-lg-6">
                    <h3>News & Articles</h3>
                </div>
                <div className="col-lg-6">
                    <Breadcrumb className="bread">
                        <Breadcrumb.Item><NavLink to="/college-dashboard">Home</NavLink></Breadcrumb.Item>
                        {/* <Breadcrumb.Item><NavLink to="/college-dashboard/awards-achivements">News & Articles</NavLink></Breadcrumb.Item> */}
                        <Breadcrumb.Item active>Add-News & Articles </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className='md:justify-end row'>
                <Link to={"/counsellor-dashboard/news-article"} className="btn-sm shadow-sm flex m-3 gap-1.5 md:float-right text-white items-center border bg-[#052963]">
                    <TiArrowBack />
                    back
                </Link>
            </div>
            <form onSubmit={handleSubmit} encType="multipart/form-data" className='common_dash_form p-3'>
                <div className="row">
                    <div className='col-lg-6 col-md-12'>
                    <div className="form-group">
                        <label>Title</label>
                        <input
                            type="text"
                            name="title"
                            className="form-control"
                            placeholder="Title"
                            required
                            value={formData.title}
                            onChange={handleChange}
                        />
                    </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                    <div className="form-group">
                        <label>Image</label>
                        <input
                            type="file"
                            name="image"
                            className="form-control"
                            accept="image/*"
                            onChange={handleImageChange}
                        />
                        {formData.image && (
                            <div className="mb-3">
                                <img src={formData.image} alt="ImagePreview" className="img-preview" />
                            </div>
                        )}
                    </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                    <div className="form-group ">
                        <label>Course Category</label>
                        <select
                            name="course_category"
                            className="form-control"
                            value={formData.course_category}
                            onChange={handleChange}
                        >
                            <option value="">Select Category</option>
                            {category.map((category) => (
                                <option key={category.id} value={category.id}>
                                    {category.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                    <div>
                        <div className="form-group">
                            <label>Status </label>
                            <select
                                name="status"
                                className="form-control"
                                value={formData.status}
                                onChange={handleChange}
                            >
                                <option value="PUBLIC">Public</option>
                                <option value="DRAFT">Draft</option>
                            </select>
                        </div>
                    </div>
                    </div>
                    <div className='col-lg-12 col-md-12'>
                    <div className="form-group">
                        <label>post</label>
                        <RichText
                            name="post"
                            placeholder="post"
                            value={formData.post}
                            onChange={handleRichTextChange}
                        />
                    </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                    <div className='d-flex justify-content-end align-bottom'>
                        <button type="submit" className="btn button_of_submit">Add news</button>
                    </div>
                    </div>
             </div>
            </form>
        </div>
    );
};


export default AddNewsandAleretCounslr