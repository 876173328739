import React from 'react'
import NotificationExam from './NotificationExam'

const Notificationmsg = () => {
  return (
    <div>
        <NotificationExam />

    </div>
  )
}

export default Notificationmsg