import { useEffect, useState, useMemo } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { LinearProgress } from "@mui/material";
import { FaPen, FaTimes, } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const Leds = () => {
    const token = useMemo(() => localStorage.getItem('token') || '', []);
    const [collegeData, setCollegeData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [selectedLead, setSelectedLead] = useState(null);
    const [newStatus, setNewStatus] = useState('');
    const navigate = useNavigate();

    const handleModalClose = () => {
        setShowModal(false);
        setSelectedLead(null);
    };


    const handleModalShow = (lead) => {
        setSelectedLead(lead);
        setShowModal(true);
    };

    const handleChange = (e) => {
        setNewStatus(e.target.value);
    };

    const handleStatusColor = (status) => {
        switch (status) {
            case 'Pending':
                return 'btn-warning';
            case 'Accepted':
                return 'btn-primary';
            case 'Rejected':
                return 'btn-danger';
            default:
                return '';
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.patch(`https://portal.mycollegepedia.com/college/college-leads/${selectedLead.id}/`, {
                status: newStatus,
            }, {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            });

            const updatedLead = response.data;
            setCollegeData((prevData) => prevData.map((lead) => (lead.id === updatedLead.id ? updatedLead : lead)));
            Swal.fire({
                position:'top-end',
                icon: 'success',
                timer: 3000,
                text: 'Status Update successfully!',
            });
            handleModalClose();
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://portal.mycollegepedia.com/college/college-leads/', {
                    headers: {
                        Authorization: `JWT ${token}`,
                    },
                });
                const data = response.data;
                setCollegeData(data);
                setLoading(false);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('token');
                    Swal.fire({
                        icon: 'error',
                        title: 'Please try again Login',
                        text: 'There was an error.'
                    });
                    navigate('/login');
                }

                console.error('Error college leads fetching data:', error);
            }
        };

        fetchData();
    }, [token, navigate, showModal]);

    return (
        <div className="common_dash px-3">
            <div className="row align-items-center top   ">
                <div className="col-lg-6">

                    <h3>Leads</h3>
                </div>
                <div className="col-lg-6">
                    <Breadcrumb className="bread">
                        <Breadcrumb.Item ><NavLink to="/college-dashboard">Home</NavLink></Breadcrumb.Item>

                        <Breadcrumb.Item active>Leads</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className="card ">
                <div className='bg-white'>
                    {loading ? (
                        <p className="my-5"> <LinearProgress /></p>
                    ) : (
                        <div className="table-responsive thin_scroll">
                            {collegeData.length === 0 ? (
                                <table className="table">
                                    <thead>
                                        <tr className="bg-[whitesmoke]">
                                            <th>Profile</th>
                                            <th>Student name</th>
                                            <th>Course</th>
                                            <th>Subcategory</th>
                                            <th>Stream</th>
                                            <th>Action</th>
                                            {/* <th>Image</th> */}
                                        </tr>
                                    </thead>
                                    <tbody className="bg-[white]">
                                        <p className="text-center my-4 text-red-600 ">! No records </p>
                                    </tbody>
                                </table>
                            ) : (
                                <table className="table">
                                    <thead>
                                        <tr className="bg-[whitesmoke]">
                                            <th>Profile</th>
                                            <th>Student name</th>
                                            <th>Course</th>
                                            <th>Subcategory</th>
                                            <th>Stream</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-[white]">
                                        {collegeData.map((clg, i) => (
                                            <tr key={i}>
                                                <th><img src={`${clg.student.profile}`} alt={clg.student.name} title={clg.student.name} /></th>
                                                <th className="student_clr"><Link aria-label={clg.student.name} to={`student-profile/${clg.student.id}`}>{clg.student.name}</Link></th>
                                                <th>{clg.course_category.name}</th>
                                                <th className="width_define">{clg.course_subcategory.course_name}</th>
                                                <th className="width_define">{clg.course_stream.name}</th>

                                                <th><button className={`text-white btn-sm ${handleStatusColor(clg.status)} ms-2`} >{clg.status} </button></th>

                                                <th className=""><button className="px-3 py-2 text-white bg-success btn-sm" onClick={() => handleModalShow(clg)} title="Action"><FaPen /></button> </th>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>  
                    )}
                </div></div>
            <Modal show={showModal} onHide={handleModalClose}>
                <div className='!justify-end float-right flex m-1.5'>
                    <button variant="secondary" className='text-black max-w-fit hover:text-white' onClick={handleModalClose}>
                        <FaTimes />
                    </button>
                </div>
                <Modal.Header closeButton>
                    <h5 className="modal-title">Leads Update</h5>
                </Modal.Header>
                <div className="modal-body">
                    <form className="p-2 bg-gray-50" onSubmit={handleSubmit} encType="multipart/form-data">
                        <div className="row form-row">
                            <div className="col-12 col-sm-6">
                                <div className="py-3 form-group">
                                    <label>Enquiry Leads</label>
                                    <select
                                        name="Status"
                                        className="form-control form-select"
                                        value={newStatus}
                                        onChange={handleChange}
                                    >
                                        <option value=''>Select  Status</option>
                                        <option value="Pending">Pending</option>
                                        <option value="Accepted">Accepted</option>
                                        <option value="Rejected">Rejected</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="text-[blue] btn btn-primary btn-block"
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </Modal>
        </div>
    )
}
export default Leds