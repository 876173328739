import React, { useState, useEffect, useMemo, useContext } from 'react';
import axios from 'axios';
import Apicontext from '../../Page/Contextapi';
import Swal from 'sweetalert2';
import { TiArrowBack } from 'react-icons/ti';
import RichText from './Subpages/Richtext';
import { LinearProgress } from "@mui/material";

const BlogUpdate = ({ handlefunction, blogid }) => {
  const token = useMemo(() => localStorage.getItem('token') || '', []);
  const user = useMemo(() => localStorage.getItem('user') || '', []);
  const [Loading, setLoading] = useState(true);
  const authorprofile = JSON.parse(user);
  const [category, setCategory] = useState([]);
  const [Formdata, setFormdata] = useState({
    id: null,
    category: '',
    title: '',
    post: '',
    image: '',
    status: 'PUBLIC', // Default status
    author: authorprofile.id,
  });

  const contextdata = useContext(Apicontext);

  const handleUpdateImageChange = (e) => {
    const imageFile = e.target.files[0];
    // Display the time when the image was last modified
    const lastModified = new Date(imageFile.lastModified).toLocaleString();
    setFormdata((prevData) => ({
      ...prevData,
      image: imageFile,
      lastModified: lastModified,
    }));
  };

  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setFormdata((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdateBlog = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append('category', Formdata.category);
      formData.append('title', Formdata.title);
      formData.append('post', Formdata.post);
      formData.append('status', Formdata.status); 
      if (Formdata.image && typeof Formdata.image !== 'string') {
        formData.append('image', Formdata.image);
    }
      const response = await axios.patch(
        `https://portal.mycollegepedia.com/blog/blogs/${blogid}/`,
        formData,
        {
          headers: {
            Authorization: `JWT ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
        });
        Toast.fire({
          icon: "success",
          title: "Updated successfully"
        });
      }

      // Update the state with the updated blog entry
      setFormdata({
        id: null,
        category: '',
        title: '',
        post: '',
        image: '',
        status: 'PUBLIC', // Reset status to default
        author: authorprofile.id,
      });

      handlefunction();

    } catch (error) {
      console.error('Error updating blog entry:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = contextdata.APIdata;
      setCategory(data.category);
      try {
        const response = await axios.get(`https://portal.mycollegepedia.com/blog/blogs/${blogid}`, {
          headers: {
            Authorization: `JWT ${token}`,
          },
        });
        setLoading(false);
        setFormdata(response.data);
      } catch (error) {
        console.error('Error fetching blog entry:', error);
      }
    };

    fetchData();
  }, [token, contextdata,blogid]);

  if (Loading) {
    return (
      <>
        <p className="my-10 p-3">
          <LinearProgress />
        </p>
      </>
    );
  }

  return (
    <div className='container p-3'>
      <div className='justify-end row'>
        <button onClick={() => handlefunction()} className="btn-sm shadow-sm flex m-3 gap-1.5 float-right text-white items-center border bg-[#052963]">
          <TiArrowBack />
          Back
        </button>
      </div>
      <div className='p-3 common_dash_form'>
        <form onSubmit={handleUpdateBlog} encType="multipart/form-data">
          <div className="row form-row">
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label>Category:</label>
                <select
                  name="category"
                  className="form-control"
                  value={Formdata.category}
                  onChange={handleUpdateChange}
                >
                  <option value="">Select Category</option>
                  {category.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-12 col-sm-6 d-flex align-items-center">
           
              <div className="form-group ">
                <label>Image :</label>
            
                <input type="file" name="image"   className="form-file"
                  onChange={handleUpdateImageChange} class="form-control" accept="image/*"/>
              </div>
                   {/* Display last modified time */}
                   {Formdata.image && (
                <div className='w-[60px] mx-3'>
                  <img src={Formdata.image || ''} title={Formdata.title} alt={Formdata.title} />
                </div>
                )}
            </div>
            
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label>Title:</label>
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  placeholder="Blog Title"
                  maxLength={60}
                  value={Formdata.title}
                  onChange={handleUpdateChange}
                />
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label>Status:</label>
                <select
                  name="status"
                  className="form-control"
                  value={Formdata.status}
                  onChange={handleUpdateChange}
                >
                  <option value="PUBLIC">Public</option>
                  <option value="DRAFT">Draft</option>
                </select>
              </div>
            </div>
            <div className="col-12 ">
              <div className="form-group">
                <label>Post:</label>
                <RichText
                  name="post"
                  placeholder={"Post"}
                  value={Formdata.post}
                  onChange={(value) => handleUpdateChange('post', value)}
                />
              </div>
            </div>
           
          </div>
          <div className='d-flex justify-content-end'>
          <button type='submit' title='Save data' className='btn  button_of_submit'>Save</button>

          </div>
        </form>
      </div>
    </div>
  );
};

export default BlogUpdate;
