

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AvatarF from "../../assets/images/AvatarF1.gif"
import AvatarM from "../../assets/images/Avatarboy.gif"
import StarRating from './Startrating';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { RiDoubleQuotesR, RiDoubleQuotesL } from "react-icons/ri";
function Testimonials() {
   const [Data, setData] = useState([]);
   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios(`https://portal.mycollegepedia.com/site/testimonial/`);
            const data = response.data;
            setData(data);
         } catch (error) {
            console.error('Error fetching data:', error);
         }
      };
      fetchData();
   }, []);

   const testimonial = {
      autoplay: true,
      rewind: true,
      transition: 400,
      margin: 20,
      dots: true,
      responsiveClass: true,
      autoheight: "true",
      autoplayTimeout: 6000,
      smartSpeed: 3500,
      nav: false,

      responsive: {
         0: { items: 1 },
         600: { items: 2 },
         1024: { items: 3 },
         1366: { items: 3 },
      },
   };
   return (
      <>
         <div className="container my-2 md:!my-6 student-test-top">
            <div className="web-for-test">
               <p className="web-colleges-hadding">Student<span className="web-colleges-recomnded"> Testimonials</span></p>
            </div>
         </div>
         <section className='testimonial_sec'>
            <div className="container">
               <section className="text-center ">
                  {Data && Data.length > 0 && (
                     <OwlCarousel className="owl-theme" {...testimonial}>
                        {Data.map((data, i) => (
                           <div key={i} className='grid testi_card '>
                              <div className="flex mx-auto mb-3 justify-center max-w-[160px] w-full h-[160px] tetsi-pic">
                                 <img
                                    src={`${data.user && data.user.profile ? data.user.profile : data.user.gender === "MALE" ? AvatarM : AvatarF}`}
                                    alt={`Testimonial by ${data.user && data.user.name}`}
                                    width="100"
                                    title={data.user.name}
                                    height="100"
                                    loading="lazy"
                                    className="shadow-sm rounded-full dark:shadow-black/20"
                                 />
                                 <span className='icon_bg_rotate'></span>
                              </div>
                              <h4>{data.user.name}</h4>
                              <div className="mb-1">
                                 <div className='description'>
                                    <RiDoubleQuotesL />
                                    <p className='line-clamp-4'>{data.description}</p>
                                    <RiDoubleQuotesR />
                                 </div>
                              </div>

                              <ul className="flex justify-center mb-0">
                                 <h6><StarRating rating={data.rating} /></h6>
                              </ul>
                           </div>
                        ))}
                     </OwlCarousel>
                  )}
               </section>
            </div>
         </section>


      </>
   )
}

export default Testimonials;