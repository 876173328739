import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import mycollegepedialogo from "../../assets/images/Logo.png";
import Swal from 'sweetalert2';
const VerifyOtp = () => {
  const location = useLocation();
  const { number } = location.state || '';
  const [otp, setOtp] = useState({
    mobile: number || '',
    otp: '' ,
  });
  console.log(otp)

  
  const navigate = useNavigate();
  const [resendCountdown, setResendCountdown] = useState(0);
  const [resendClickCount, setResendClickCount] = useState(0);
  useEffect(() => {
    let countdownInterval;

    if (resendCountdown > 0 && resendClickCount < 3) {
      countdownInterval = setInterval(() => {
        setResendCountdown((prevCount) => prevCount - 1);
      }, 1000);
    } else if (resendCountdown === 0 && resendClickCount > 0) {
      clearInterval(countdownInterval);
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [resendCountdown, resendClickCount]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setOtp((prevOtp) => ({
      ...prevOtp,
      [name]: value,
    }));
  };
  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    try {
      console.log(otp);
      const response = await axios.post('https://portal.mycollegepedia.com/account/verify-otp/', otp);
      console.log(response);
      if (response.status === 200) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'success',
            position:'top-end',
            text: response.data.message,
            title: 'Successfully'
        })
        navigate(`/login`);
    } else {
        Swal.fire({
            icon: 'error',
            title: 'server error try again',
            text: response.data.message
        });

    }
    } catch (error) {
      console.error('OTP Verification Error:', error);
    }
  };

  const handleResendOTP = async (e) => {
    e.preventDefault();
    if (resendClickCount < 3) {
      setResendCountdown(45); 
      setResendClickCount((prevCount) => prevCount + 1); 
      
      console.warn(number);
      try {
        await axios.post('https://portal.mycollegepedia.com/account/resend-otp/', {mobile: number});
      } catch (error) {
        console.error('Resend OTP Error:', error);
      }
    } else {
      Swal.fire({
        title: "You've reached the maximum resend attempts!",
        text: "Please wait before trying again!",
        icon: "warning"
      });
      
    }
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="login-page">
          <div className="container login-body">
            <div className="loginbox">
              <div className="login-right-wrap">
                <div className="account-header">
                  <div className="flex justify-center mb-4 text-center account-logo">
                    <Link to="/" title='home page '>
                      <img src={mycollegepedialogo} title='authentication otp' alt='clgLogo' className='w-full brightness-110' />
                    </Link>
                  </div>
                </div>
                <div className="login-header">
                  <h3>
                    Verify <span>OTP</span>
                  </h3>
                </div>
                <form onSubmit={handleVerifyOTP}>
                  <div className="form-group">
                    <label
                      htmlFor="otp"
                      className="absolute left-0 flex items-start justify-center h-full px-2 control-label top-2 "
                    >
                      Otp
                    </label>
                    <input
                      type="text"
                      name="otp"
                      placeholder="Enter your OTP ..."

                      id="outlined-basic" label="Outlined" variant="outlined"
                      className={` form-control h-[37px] rounded-[4px]  border border-[#222222] text-[#222222] text-[1em]  !focus:text-[.8rem] focus:text-[#909090]`}

                      onChange={handleChange}
                      error="Please enter your OTP"
                    />
                    <p className="text-[#676767] text-[12px] mt-[5px]">
                      Please enter your OTP
                    </p>
                  </div>

                  <div className="text-center">
                    <button
                      className="btn btn-primary btn-block text-[#052963] hover:text-white account-btn"
                      type="submit"
                    >
                      VerifyOtp
                    </button>
                  </div>
                </form>

                <div className="text-center dont-have">
                  Resend OTP{" "}
                  <button onClick={handleResendOTP} className="border btn">
                    {resendClickCount < 3
                      ? resendCountdown === 0
                        ? "Resend" 
                        : `Wait ${resendCountdown}s`
                      : "After 3hr ResendOTP"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOtp
