import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
const Privacy = () => {
    const [collegeData, setCollegeData] = useState({});
    useEffect(() => {
        const fetchCollegeData = async () => {
            try {
                const response = await axios.get(`https://portal.mycollegepedia.com/site/privacy/`);
                setCollegeData(response.data);
            } catch (error) {
                console.error('Error fetching college data:', error);
            }
        };
        fetchCollegeData();
    }, []);
    // console.log(collegeData);
    return (
        <>
            <Helmet>
                <title>{collegeData && collegeData.meta_title ? collegeData.meta_title : 'MyCollegePedia: India s Top College Platform'}</title>
                <meta name="description" content={collegeData && collegeData.meta_description ? collegeData.meta_description : ""} />
                <meta property="og:title" content={collegeData && collegeData ? collegeData.title : "MyCollegePedia"} />
                <meta name="keywords" content={collegeData && collegeData.meta_keyword ? collegeData.meta_keyword : "collegepedia,collegeduniya, colleges, exams, courses ,All india colleges, MyCollegePedia"} />
            </Helmet>
            <section className="breadcrumb-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="ab-1 p-1">{collegeData.title}</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">privacy-policy</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container pt-10">
                <div className="p-3 row">
                    <div dangerouslySetInnerHTML={{ __html: collegeData.description }} />
                </div>
            </div>
        </>
    );
};

export default Privacy;
