import React from 'react';
import config from './Config.js';
import MessageParser from './MessageParser.js';
import ActionProvider from './ActionProvider.js';
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import { FaTimes } from 'react-icons/fa';
 

const ChatBot = ({ isOpen, handleclose }) => {

  const customActions = {
    setCollegeList: (colleges, setState) => { 
      setState((prevState) => ({ ...prevState, collegeList: colleges }));
    },
    setStateData: (Statelist, setState) => { 
      setState((prevState) => ({ ...prevState, StateData: Statelist }));
    },
    setCourseCategory: (courselist, setState) => { 
      setState((prevState) => ({ ...prevState, courseData: courselist }));
    },
    setFilterColleges: (filterClg, setState) => { 
      setState((prevState) => ({ ...prevState, FilterColleges: filterClg }));
    },
    setExamData: (exams, setState) => { 
      setState((prevState) => ({ ...prevState, exam: exams }));
    },
   
  };
  // const handleSaveMessages = (messages) => {
  //   setChatState((prevState) => ({ ...prevState, messages }));
  // };
  return (
    <div className={`chatbot-container ${isOpen ? 'open' : ''}`}>
      <div className='d-flex justify-content-center bg-[whitesmoke] '>

        <Chatbot
          config={config}
          messageParser={MessageParser}
          actionProvider={(props) => <ActionProvider {...props} customActions={customActions} />}
          disableScrollToBottom="false"
          // saveMessages={handleSaveMessages}
          
        />
        <div className="close-icon btn chatbot_cross_btn" onClick={handleclose}>
          <FaTimes />
        </div>
      </div>
    </div>
  );
};

export default ChatBot;
