import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import RichText from './Subpages/Richtext';
import { TiArrowBack } from 'react-icons/ti';

const UpdateAwardsachivement = ({ handleFunction, token, awardId }) => {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        image: '',
        alt: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://portal.mycollegepedia.com/college/awards-achivements/${awardId}/`, {
                    headers: {
                        Authorization: `JWT ${token}`,
                    },
                });
                console.log(response)
                const { title, description, image, alt } = response.data;
                setFormData({ title, description, image, alt });
            } catch (error) {
                console.error('Error fetching award:', error);
            }
        };
        fetchData();
    }, [awardId, token]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleRichTextChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('title', formData.title);
            formDataToSend.append('alt', formData.alt);
            formDataToSend.append('description', formData.description);
            if (formData.image && typeof formData.image !== 'string') {
                formDataToSend.append('image', formData.image);
            }

            const response = await axios.patch(`https://portal.mycollegepedia.com/college/awards-achivements/${awardId}/`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `JWT ${token}`,
                },
            });
            handleFunction();
            if (response.status === 200) {
                Swal.fire({
                    position: 'top-end',
                    timer: 2500,
                    progressStepsDistance: true,
                    text: 'Data updated successfully!',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'There was an error in updating the data. Please try again.',
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'There was an error. Please try again.',
            });
        }
    };

    return (
        <div>
            <div className='justify-content-end  row'>
                <button title='no-update' onClick={() => handleFunction()} className="btn-sm shadow-sm flex m-3 gap-1.5 text-white align-items-center bg-[#052963]">
                    <TiArrowBack />
                    Back
                </button>

            </div>

            <form onSubmit={handleSubmit} className='p-3 common_dash_form' encType="multipart/form-data">
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className="form-group">
                            <label>Title</label>
                            <input
                                type="text"
                                name="title"
                                className="form-control"
                                placeholder="Title"
                                required
                                value={formData.title}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className="form-group">
                            <label>Image</label>
                            <input
                                type="file"
                                name="image"
                                className="form-control"
                                accept="image/*"
                                onChange={handleChange}
                            />
                            {formData.image && (
                                <div className="my-3 edit_awards">
                                    <img src={formData.image} alt="ImagePreview" className="img-preview" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-12'>
                        <div className="form-group">
                            <label>Alt-Text</label>
                            <input
                                type="text"
                                className="form-control"
                                name="alt"
                                required
                                placeholder="Alt-text"
                                value={formData.alt}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className="form-group">
                            <label>Description</label>
                            <RichText
                                name="description"
                                placeholder="Description"
                                value={formData.description}
                                onChange={handleRichTextChange}
                            />
                        </div>
                    </div>
                </div>



                <div className='d-flex justify-content-end'>
                    <button type="submit" className="btn button_of_submit">Update</button>

                </div>
            </form>
        </div>
    );
};

export default UpdateAwardsachivement;