import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "./assets/css/newresponsive.css"
import Appprovider from './Page/Appprovider';
// import Dashboardfooter from './Component/dashboardfooter/Dashboardfooter';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <Appprovider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Appprovider>

);

