import React from "react";

import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Swal from "sweetalert2";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { NavLink } from "react-router-dom";
import { useMemo } from "react";

const Directoradd = () => {
    const token = useMemo(() => localStorage.getItem("token") || "", []);
    const CounsellorId = useMemo(() => JSON.parse(localStorage.getItem("counsellor_id") || "", []));
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        primary_mobile: "",
        secondary_mobile: "",
        website: "",
        whatsapp: "",
        facebook: "",
        instagram: "",
        linkedin: "",
        twitter: "",
        youtube: "",
        telegram: "",
        profile: null,
        id_proof: null,
        counsellor: CounsellorId,
        organization_type: "",
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const navigate = useNavigate();
    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            profile: imageFile,
        }));
    };
    const handleIdproofChange = (e) => {
        const id_proofs = e.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            id_proof: id_proofs,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        try {
            const formDataToSend = new FormData();
            formDataToSend.append("counsellor", formData.counsellor || "");
            formDataToSend.append("name", formData.name || "");
            formDataToSend.append("email", formData.email || "");
            formDataToSend.append("primary_mobile", formData.primary_mobile || "");
            formDataToSend.append("secondary_mobile", formData.secondary_mobile || "");
            formDataToSend.append("website", formData.website || "");
            formDataToSend.append("whatsapp", formData.whatsapp || "");
            formDataToSend.append("facebook", formData.facebook || "");
            formDataToSend.append("instagram", formData.instagram || "");
            formDataToSend.append("linkedin", formData.linkedin || "");
            formDataToSend.append("twitter", formData.twitter || "");
            formDataToSend.append("youtube", formData.youtube || "");
            formDataToSend.append("telegram", formData.telegram || "");
            formDataToSend.append(
                "organization_type",
                formData.organization_type || ""
            );
            if (formData.id_proof && typeof formData.id_proof !== "string") {
                formDataToSend.append("id_proof", formData.id_proof);
            }
            if (formData.profile && typeof formData.profile !== "string") {
                formDataToSend.append("profile", formData.profile);
            }

            const response = await axios.post(
                "https://portal.mycollegepedia.com/counsellor/director-detail/",
                formDataToSend,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `JWT ${token}`,
                    },
                }
            );
            if (response.status === 201) {
                Swal.fire({
                    position: 'top-end',
                    timer: 2500,
                    icon: "success",
                    text: "Data submitted successfully!",
                });
            } else {
                if (response.status === 401) {
                    localStorage.removeItem("token");
                }
                Swal.fire({
                    icon: "error",
                    title: "There was an error in form submission. Please try again.",
                });
            }
            navigate("/counsellor-dashboard/director");
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "There was an error Please try again.",
            });
            navigate("/counsellor-dashboard/director");
            console.error("Error adding award:", error);
        }
    };
    return (
        <div>
            <div className="container-fluid common_dash">
            <div className="row align-items-center top">
                        <div className="col-lg-6">

                            <h3>Directors</h3>


                        </div>
                        <div className="col-lg-6">
                            <Breadcrumb className="bread">
                            <Breadcrumb.Item ><NavLink to="/counsellor-dashboard">Home</NavLink></Breadcrumb.Item>

                                <Breadcrumb.Item active>Add-directors</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                <div className="row">
                    <Modal.Body>
                        <form
                            className="p-3 common_dash_form"
                            onSubmit={handleSubmit}
                            encType="multipart/form-data"
                        >
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="name"
                                            placeholder="Jhon"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            className="form-control lowercase"
                                            name="email"
                                            placeholder="email@email.com"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Primary no.</label>
                                        <input
                                            type="tel"
                                            className="form-control cm-number"
                                            name="primary_mobile"
                                            placeholder="+9124578259"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Secondary no.</label>
                                        <input
                                            type="tel"
                                            className="form-control"
                                            name="secondary_mobile"
                                            placeholder="+9124578259"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Profile Image</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            name="image"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                        />
                                        {/* {formData.profile && <span > {formData.profile}</span>} */}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Valid ID</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            name="id_proof"
                                            accept="image/*"
                                            onChange={handleIdproofChange}
                                        />
                                        {/* {formData.profile && <span > {formData.profile}</span>} */}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Instagram</label>
                                        <input
                                            type="url"
                                            className="form-control"
                                            name="instagram"
                                            placeholder="https://"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Twitter</label>
                                        <input
                                            type="url"
                                            className="form-control"
                                            name="twitter"
                                            placeholder="https://"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>LinkedIn</label>
                                        <input
                                            type="url"
                                            className="form-control"
                                            name="linkedin"
                                            placeholder="https://"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Whatsapp</label>
                                        <input
                                            type="url"
                                            className="form-control"
                                            name="whatsapp"
                                            placeholder="1123456789"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Youtube</label>
                                        <input
                                            type="url"
                                            className="form-control"
                                            name="youtube"
                                            placeholder="https://"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Telegram</label>
                                        <input
                                            type="url"
                                            className="form-control"
                                            name="telegram"
                                            placeholder="https://"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Website</label>
                                        <input
                                            type="url"
                                            className="form-control"
                                            name="website"
                                            placeholder="https://"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Facebook</label>
                                        <input
                                            type="url"
                                            className="form-control"
                                            name="facebook"
                                            placeholder="https://"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <label>Organization Type</label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        onChange={handleChange}
                                        name="organization_type"
                                        className="form-control">
                                        <option>Organization Type</option>
                                        <option value="1">Public</option>
                                        <option value="2">Private</option>
                                    </Form.Select>
                                </div>
                            </div>
                            <Modal.Footer>

                                <Button className="save_director" type="submit">
                                    Save
                                </Button>
                            </Modal.Footer>
                        </form>
                    </Modal.Body>
                </div>
            </div>
        </div>
    )
}

export default Directoradd