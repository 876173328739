import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FaEye, FaPen, FaPlus, FaTrash } from 'react-icons/fa';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { LinearProgress } from "@mui/material";
import UpdateNewsArticles from './UpdateNewsArticles';
import Apicontext from '../../../Page/Contextapi';


const NewsandAlert = () => {
    const token = useMemo(() => localStorage.getItem('token') || '', []);
  
    const college = JSON.parse(localStorage.getItem('collegerecord'));
    const [news, setNews] = useState([]);
    const [category, setCategory] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [Slug, setSlug] = useState(null);
    const [Loading, setLoading] = useState(true);
    const contextdata = useContext(Apicontext);

    const navigate = useNavigate();
    const handleFunction = (newsslug) => {
        setSlug(newsslug);
        setShowModal(true);
    };

    console.log("News", news)
    useEffect(() => {
        const fetchData = async () => {
            const categories = await contextdata.APIdata.category;
            setCategory(categories)
            try {
                const response = await axios.get(`https://portal.mycollegepedia.com/college/news/`, {
                    headers: {
                        Authorization: `JWT ${token}`,
                    },
                });
                
                setNews(response.data);
                setLoading(false)
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('token');
                }
                Swal.fire({
                    icon: 'error',
                    title: 'Please try again Login',
                    text: 'There was an error.'
                });
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [navigate,contextdata,showModal]);

    const recordid = async (Slug) => {
        try {
            await axios.delete(`https://portal.mycollegepedia.com/college/news/${Slug}/`, {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            });
            setNews((prevawards) => prevawards.filter((data) => data.id !== Slug));
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.onmouseenter = Swal.stopTimer;
                  toast.onmouseleave = Swal.resumeTimer;
                }
              });
              Toast.fire({
                icon: "success",
                title: "deleted successfully"
              });
            //    navigate("/college-dashboard/news-articles")

        } catch (error) {
            console.error('Error updating news&article:', error);
        }
    };
    if (Loading) {
        return (
            
                <p className="my-10 p-5">
                    {" "}
                    <LinearProgress />
                </p>
          
        );
    }
    return (
        <div className='m-1 '>
            <div className='container-fluid common_dash px-3'>
                <div className="row align-items-center top">
                    <div className="col-lg-6">
                        <h3>News & Articles</h3>
                    </div>
                    <div className="col-lg-6">
                        <Breadcrumb className="bread">
                            <Breadcrumb.Item><NavLink to="/college-dashboard">Home</NavLink></Breadcrumb.Item>
                            <Breadcrumb.Item active>News & Articles</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>

                {/* Conditional rendering for the modal */}
                {showModal && Slug !== null ? (
                    <UpdateNewsArticles handleFunction={() => setShowModal(false)} category={category} token={token} Slug={Slug}  />
                ) : (

                    <div>
                        <div className='md:justify-end row'>
                            <Link to={"/college-dashboard/add-news-articles"} className="btn-sm  shadow-sm flex m-3 gap-1.5 md:float-right text-white items-center border bg-[#052963]">
                                <FaPlus />
                                Add
                            </Link>
                        </div>

                        <div className='mt-2 table-responsive thin_scroll elig-table'>
                        <table className="table">
                            <thead>
                                <tr className='bg-[whitesmoke]'>
                                    <th>Image</th>
                                    <th>Title</th>
                                    <th>Course category</th>
                                    <th>post</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody  className='text-center'>
                                {news && news.length > 0 ? (

                                news.map((newsdata) => (
                                    <tr key={newsdata.id} className='bg-[white]'>
                                        <td><img src={newsdata.image} alt={newsdata.title} title={newsdata.title} className='max-w-[120px] w-[100%]' /></td>
                                        <td>{newsdata.title}</td>
                                        <td>{category && category.find(ct => ct.id === newsdata.course_category)?.name || ''}</td>
                                        <td><div dangerouslySetInnerHTML={{ __html: newsdata.post }} /></td>
                                        <td className=''>{newsdata.status}</td>
                                        <td className='d-flex gap-2 align-items-center'>
                                            <button onClick={() => handleFunction(newsdata.slug)} title='update' className="bg-warning text-white p-1">
                                                    <FaPen />
                                            </button>
                                            <button className="bg-success text-white  p-1">
                                                <Link title='view more' to={`/college/${college[0].slug || '' }`}>
                                                    <FaEye />
                                                </Link>
                                            </button>
                                            <button onClick={() => recordid(newsdata.slug)} title='deleted' className="bg-danger text-white  p-1">
                                                    <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))) : (
                                    <tr className='text-end pt-2'> no data !</tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NewsandAlert;