import React, { useState } from 'react';
import { CiShare2 } from 'react-icons/ci';
import { FacebookShareButton, TwitterShareButton,WhatsappShareButton, LinkedinShareButton,EmailShareButton,PinterestShareButton, InstapaperShareButton,TelegramShareButton,InstapaperIcon,PinterestIcon,FacebookIcon, TelegramIcon,MailruIcon,TwitterIcon, WhatsappIcon,LinkedinIcon } from 'react-share';


const ShareButtons = ({ url, title }) => {
  const [showShareButtons, setShowShareButtons] = useState(false);

  const handleToggle = () => {
    setShowShareButtons(!showShareButtons);
  };

  return (
    <div className="share-buttons-container">
      <CiShare2 onClick={handleToggle} />
      {showShareButtons && (
        <div className="share-buttons-list">
          <FacebookShareButton url={url} quote={title}>
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <TwitterShareButton url={url} title={title}>
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
          <LinkedinShareButton url={url} title={title} summary={title}>
            <LinkedinIcon size={32} round={true} />
          </LinkedinShareButton>
          <WhatsappShareButton url={url} title={title}>
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
          <InstapaperShareButton url={url} title={title}>
            <InstapaperIcon size={32} round={true} />
          </InstapaperShareButton>
          <TelegramShareButton url={url} title={title}>
            <TelegramIcon size={32} round={true} />
          </TelegramShareButton>
          <EmailShareButton url={url} title={title}>
            <MailruIcon size={32} round={true} />
          </EmailShareButton>
          <PinterestShareButton url={url} title={title}>
            <PinterestIcon size={32} round={true} />
          </PinterestShareButton>
        </div>
      )}
    </div>
  );
};

export default ShareButtons;
