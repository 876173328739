import React, { useState, useEffect, useMemo, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Apicontext from '../../Page/Contextapi';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import RichText from './Subpages/Richtext';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { TiArrowBack } from 'react-icons/ti';
import { NavLink } from 'react-router-dom';


const CollegeAddblog = () => {
    const token = useMemo(() => localStorage.getItem('token') || '', []);
    const user = useMemo(() => localStorage.getItem('user') || '', []);
    const authorprofile = JSON.parse(user)
    const [category, setCategory] = useState([]);
    const [newBlogEntry, setNewBlogEntry] = useState({
        id: null,
        category: '',
        title: '',
        post: '',
        image: '',
        status: 'PUBLIC',
        author: authorprofile.id,
    });
   
    const navigate = useNavigate();
    const contextdata = useContext(Apicontext);
    const handleRichTextChange = (name, value) => {
        setNewBlogEntry({
            ...newBlogEntry,
            [name]: value,
        });
    };
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setNewBlogEntry({
            ...newBlogEntry,
            image: file,
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewBlogEntry({
            ...newBlogEntry,
            [name]: value,
        });
    };

    const handleAddBlogEntry = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData();
            formData.append('category', newBlogEntry.category);
            formData.append('title', newBlogEntry.title);
            formData.append('post', newBlogEntry.post);
            formData.append('image', newBlogEntry.image);
            formData.append('status', newBlogEntry.status);
            formData.append('author', newBlogEntry.author);

            const response = await axios.post('https://portal.mycollegepedia.com/blog/blogs/', formData, {
                headers: {
                    Authorization: `JWT ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log(response)
            if (response.status === 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Add Blog',
                    text: 'successfully.',
                });
            }
            setNewBlogEntry({
                category: '',
                title: '',
                post: '',
                image: '',
                status: 'PUBLIC',
            });

            navigate("/college-dashboard/blog")
        } catch (error) {
            console.error('Error adding blog entry:', error);
        }
    };
    useEffect(() => {
        const data = contextdata.APIdata;
        setCategory(data.category);

    }, [contextdata]);

    return (
        <div className='container  common_dash px-3'>
            <div className="row align-items-center top">
                <div className="col-lg-6">

                    <h3>College-blogs</h3>
                </div>
                <div className="col-lg-6">
                    <Breadcrumb className="bread">
                        <Breadcrumb.Item ><NavLink to="/college-dashboard">Home</NavLink></Breadcrumb.Item>
                        <Breadcrumb.Item active>Accepted</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className='justify-end row'>
                <Link to={'/college-dashboard/blog'} className="btn-sm shadow-sm flex m-3 gap-1.5 float-right text-white items-center border bg-[#052963]">
                    <TiArrowBack />
                    Back
                </Link>
            </div>
            <div className='shadow p-2 '>
                <form onSubmit={handleAddBlogEntry} className='p-3 common_dash_form' encType="multipart/form-data">
                    <div className="row form-row">
                        <div className="col-12 col-sm-6">
                            <div className="form-group">
                                <label>Category:</label>
                                <select
                                    name="category"
                                    className="form-control"
                                    value={newBlogEntry.category}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Category</option>
                                    {category && category.map((category) => (
                                        <option key={category.id} value={category.id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </select>

                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="form-group">
                                <label>Image :</label>
                                <input type="file" onChange={handleImageChange} name="image" class="form-control" accept="image/*"></input>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label>Title:</label>
                                <input
                                    type="text"
                                    name="title"
                                    className="form-control"
                                    placeholder="Blog Title"
                                    maxLength={60}
                                    value={newBlogEntry.title}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-12 ">
                            <div className="form-group">
                                <label>Post:</label>
                                <RichText
                                    name="post"
                                    placeholder={"post"}
                                    value={newBlogEntry.post}
                                    onChange={handleRichTextChange}
                                />
                            </div>
                        </div>

                    </div>
                    <div className='d-flex justify-content-end'>
                        <button type='submit' title='save data' className='btn button_of_submit'>Save </button>

                    </div>
                </form>
            </div>
        </div>
    )
}

export default CollegeAddblog