import React, { useState } from "react";
import { FaBars, FaAward, FaStar, FaDatabase, FaCheck, FaTimes, FaQuestion, FaUser, FaUserCircle, FaBullhorn, FaBook, } from "react-icons/fa";
import { IoMdNotifications } from "react-icons/io";
import collegepedia from "../../assets/images/Logo.png";
import { NavLink, useNavigate, Outlet, Link } from "react-router-dom";
// import Collapsible from "react-collapsible";
import { FaUniversity, FaVideo } from "react-icons/fa";
import "./collegedahboard.css"
import { MdOutlinePending, MdPending } from "react-icons/md";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { IoLogOut } from "react-icons/io5";
import Swal from "sweetalert2";
import { TbWorldWww } from "react-icons/tb";
import picimage from '../../assets/images/Bangalore.png';
import { FaNewspaper } from "react-icons/fa6";

const Header = () => {
    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);
    const [isopen1, setIsOpen1] = useState(false);

    const toggleCollapsible1 = () => {
        setIsOpen1(!isopen1);
    };
    const navigate = useNavigate();
    const logoutfun = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('path');
        localStorage.removeItem('userprofile');
        navigate('/login')

        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
            }
        });
        Toast.fire({
            icon: "success",
            title: "Log-out successfully"
        });
    };

    return (
        <>
            <div className="Newtopbar_dashbord">
                <Navbar expand="lg" className='Newtopbar'>
                    <Navbar id="basic-navbar-nav">
                        <div style={{ marginLeft: isOpen ? "250px" : "50px" }} className="bars outerbar">
                            <FaBars onClick={toggle} />
                        </div>
                        <Nav className="ms-auto">
                            <div className="first_dropdown">
                                <NavDropdown title={<span className="usericon"><IoMdNotifications />
                                </span>}>
                                    <NavDropdown.Item >
                                        <div className="comments">
                                            <div className="pic">
                                                <img src={picimage} alt="stu-image" title="dashbaord" />
                                                <div className="content-wrapper">
                                                    <p>comment saaaaaa sfgsfg sg dgg aaaa</p>
                                                </div>
                                            </div>
                                        </div>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item className="card">
                                        <div className="comments">
                                            <div className="pic">
                                                <img src={picimage} alt="stu-image" />
                                                <div className="content-wrapper">
                                                    <p>comments aaaaaa sfgsfg sg dgg aaaa</p>
                                                </div>
                                            </div>
                                        </div>
                                    </NavDropdown.Item>


                                    <NavDropdown.Item >
                                        <button className="btn" >view more</button>
                                    </NavDropdown.Item>

                                </NavDropdown></div>


                            <div className="second_dropdown">
                                <NavDropdown title={<span className="usericon"><FaUser /></span>}>
                                    <NavDropdown.Item className="flex items-center gap-2" as={Link} to="/college-dashboard/user-Profile">
                                        Abhishek
                                    </NavDropdown.Item>
                                    <NavDropdown.Item className="flex items-center gap-2" as={Link} to="/college-dashboard/user-Profile">
                                        <FaUserCircle />   Profile
                                    </NavDropdown.Item>
                                    <NavDropdown.Item className="flex items-center gap-2" as={Link} to="/">
                                        <TbWorldWww />   Visit site
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={logoutfun} className="flex items-center">
                                        <IoLogOut />  Log-out
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </div>
                        </Nav>
                    </Navbar>

                </Navbar>
            </div>
            <div className="containe pb-3">
                <div style={{ width: isOpen ? "270px" : "75px" }} className="sidebarbar pb-5">
                    <div className="top_section">
                        <Link to="/" className="logo" title="Home page">
                            <img src={collegepedia} alt='Mycollegepeedia' title="MycollegePedia logo" />
                        </Link>
                        <div style={{ marginLeft: isOpen ? "10px" : "10px" }} className="bars innerbar">
                            <FaBars onClick={toggle} />
                        </div>
                    </div>

                    <NavLink to='/college-dashboard/home' className="mt-2 link" activeclassName="active">
                        <div className="icon"><FaUniversity /> </div>
                        <div className={` link_text ${isOpen ? "block" : "none"}`} >  Dashboard </div>
                    </NavLink>


                    {/* <h2 className="headings text-muted">
                    <div className="icon">  <MdSettingsSuggest />  </div>
                    <div  className={` link_text ${isOpen ? "block" : "none"}`} >  <strong> Genral Settings</strong>  </div>
                </h2> */}
                    <NavLink to='/college-dashboard/awards-achivements' className="mt-2 link" activeclassName="active">
                        <div className="icon">    <FaAward />  </div>
                        <div className={` link_text ${isOpen ? "block" : "none"}`} >  Awards &Achivements
                        </div>
                    </NavLink>
                    {/* <NavLink to='/college-dashboard/college-videos' className="mt-2 link" activeclassName="active">
                        <div className="icon">   <FaVideo /> </div>
                        <div className={` link_text ${isOpen ? "block" : "none"}`} id="basic-nav-dropdown"  > College Video </div>
                    </NavLink> */}
                    <NavLink to='/college-dashboard/news-articles' className="mt-2 link" activeclassName="active">
                        <div className="icon">   <FaNewspaper /> </div>
                        <div className={` link_text ${isOpen ? "block" : "none"}`} id="basic-nav-dropdown"  > News & Articles </div>
                    </NavLink>
                    <NavLink to='/college-dashboard/eligibility' className="mt-2 link" activeclassName="active">
                        <div className="icon">   <FaStar /> </div>
                        <div className={` link_text ${isOpen ? "block" : "none"}`} id="basic-nav-dropdown"  > Eligibility </div>
                    </NavLink>
                    <NavLink to='/college-dashboard/enquiry' onClick={toggleCollapsible1} className="mt-2 link" activeclassName="active">
                        <div className="icon">   <FaDatabase /> </div>
                        <div className={` link_text ${isOpen ? "block" : "none"}`} id="basic-nav-dropdown"  > Enquiry Leads
                        </div>
                        {/* <div className="arrow-icon-dash "> {isopen1 ? <IoIosArrowUp /> : <IoIosArrowDown />}  </div> */}
                    </NavLink>
                    {/* <Collapsible trigger="" open={isopen1} onTriggerOpening={toggleCollapsible1} onTriggerClosing={toggleCollapsible1}>
                        <NavLink
                            to="/college-dashboard/pending"
                            className="flex items-center gap-1 py-1 pl-5 text-gray-600 hover:text-[#052963]"
                            activeClassName="activelink"
                        >
                            <MdOutlinePending className="mr-2 text-yellow-500 " />
                            Pending
                        </NavLink>
                        <NavLink
                            to="/college-dashboard/accepted"
                            className="flex items-center gap-1 py-1 pl-5 text-gray-600 hover:text-[#052963]"
                            activeClassName="activelink"
                        >
                            <FaCheck className="mr-2 text-[green]" />
                            Accepected
                        </NavLink>
                        <NavLink
                            to="/college-dashboard/rejected"
                            className="flex items-center gap-1 py-1 pl-5 text-gray-600 hover:text-[#052963]"
                            activeClassName="activelink"
                        >
                            <FaTimes className="mr-2 text-[red]" />
                            Rejected
                        </NavLink>
                    </Collapsible> */}

                    <NavLink
                        to="/college-dashboard/pending"
                        className="link mt-2"
                        activeClassName="active"
                    >
                        <div className="icon"><MdOutlinePending /></div>

                        <span className="link_text">Pending</span>
                    </NavLink>
                    <NavLink
                        to="/college-dashboard/accepted"
                        className="link  mt-2"
                        activeClassName="activelink"
                    >
                        <div className="icon"> <FaCheck /></div>
                        <span className="link_text">Accepted</span>
                    </NavLink>
                    <NavLink
                        to="/college-dashboard/rejected"
                        className="link mt-2"
                        activeClassName="activelink"
                    >
                        <div className="icon"> <FaTimes /></div>
                        <span className="link_text">Rejected</span>
                    </NavLink>
                    <NavLink to="/college-dashboard/college-course" className="mt-2 link" activeclassName="active">
                        <div className="icon">   <FaBook /> </div>
                        <div className={` link_text ${isOpen ? "block" : "none"}`} id="basic-nav-dropdown"  > Course-fee </div>
                    </NavLink>
                    <NavLink to="/college-dashboard/college-faqs" className="mt-2 link" activeclassName="active">
                        <div className="icon">   <FaQuestion /> </div>
                        <div className={` link_text ${isOpen ? "block" : "none"}`} id="basic-nav-dropdown"  > College FAQ </div>
                    </NavLink>


                    {/* <NavLink to="/college-dashboard/exams" className="mt-2 link" activeclassName="active">
                    <div className="icon">   <FaPenAlt /> </div>
                    <div  className={` link_text ${isOpen ? "block" : "none"}`} id="basic-nav-dropdown"  > Exam </div>
                </NavLink> */}
                    <NavLink to="/college-dashboard/upcoming-exams" className="mt-2 link" activeclassName="active">
                        <div className="icon">   <MdPending /> </div>
                        <div className={` link_text ${isOpen ? "block" : "none"}`} id="basic-nav-dropdown"  > Upcoming Exam </div>
                    </NavLink>
                    {/* <div className="flex mt-2  gap-[15px] py-[10px] px-[13px] text-[18px]" >
                    <div className="icon">  <FaBlogger />  </div>
                    <div  className={` link_text ${isOpen ? "block" : "none"}`} >  <strong> Blog </strong>  </div>
                </div> */}
                    <NavLink to="/college-dashboard/blog" className="mt-2 link" activeclassName="active">
                        <div className="icon">   <FaBullhorn /> </div>
                        <div className={` link_text ${isOpen ? "block" : "none"}`} id="basic-nav-dropdown"  > Blog </div>
                    </NavLink>
                </div>

                <main className={`ml-0 ${isOpen ? "md:!ml-[270px]" : "md:!ml-[75px]"}`} >

                    <Outlet />

                </main>

            </div>

        </>

    );
};

export default Header;
