import React, { useState, useEffect } from 'react';

const Loading = () => {
    const [loadingProgress, setLoadingProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setLoadingProgress(prev => {
                if (prev >= 99) {
                    clearInterval(interval);
                    return 99;
                }
                return prev + 11;
            });
        }, 500);
    }, []);

    return (
        <div className='loading_page'>
            <div className="container">
                <div className="book-section">
                    <div className="page"></div>
                    <div className="page backPage"></div>
                    <div className="page pageFlip"></div>
                    <div className="page pageFlip"></div>
                    <div className="page pageFlip"></div>
                </div>
                <div className="progress-container">
                    <div className="progress-bar" style={{ width: `${loadingProgress}%` }}></div>
                    <div className="progress-text">{loadingProgress}%</div>
                </div>
            </div>
        </div>
    );
};

export default Loading;