import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import mycollegepedialogo from '../../assets/images/Logo.png';
import axios from 'axios';
import Swal from 'sweetalert2';
import { AiFillEye } from 'react-icons/ai';
import { FaEyeSlash } from 'react-icons/fa';

function Loginpage() {
  const [viewPassword, setViewPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const navigate = useNavigate();
  const passwordViewHandler = () => {
    setViewPassword(!viewPassword);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleforget = async () => {
    try {
      const { value: Femail } = await Swal.fire({
        title: "Enter your email register",
        input: "email",
        inputLabel: "Email",
        inputPlaceholder: "Enter your email address."
      });
      // if (Femail) {
      //   Swal.fire(`Entered email: ${Femail}`);
      // }
      const emailid = Femail
    
      const response = await axios.post('https://portal.mycollegepedia.com/account/password_reset/', { email: emailid })
      // console.log("after",response) 
      Swal.fire({
        icon: "success",
        text: "Link send successfully ",
      });
    }
    catch (error) {
      if(error.response.status===404){
      Swal.fire({
        icon: 'error',
        title: 'Try again ',
        text: "User Not Found",
      });
    }
      console.log("error", error)
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://portal.mycollegepedia.com/account/login/', formData);
      const data = response.data;
      console.log("login data",data)
      if (response.status === 200) {
        localStorage.setItem('token', data.access);
        localStorage.setItem('refreshtoken', data.refresh);
        if (!data.user.is_active) {
          navigate('/Signup');
        }
        if (data.user) {
          localStorage.setItem('user', JSON.stringify(data.user));
          if (data.user.is_student) {
            localStorage.setItem('path', JSON.stringify('/student-dashboard/home'));
            navigate('/student-dashboard/home');
          } else if (data.user.is_college) {
            localStorage.setItem('path', JSON.stringify('/college-dashboard/home'));
            navigate('/college-dashboard/home');
          } else if (data.user.is_counsellor) {
            localStorage.setItem('path', JSON.stringify('/counsellor-dashboard/home'));
            navigate('/counsellor-dashboard/home');
          }

          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: 'success',
            title: 'Logged in successfully',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'try again.',
            text: data.success,
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Try again...',
          text: "Server did't respond",
        });
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        Swal.fire({
          icon: 'error',
          title: 'Try again...',
          text: error.response.data.detail,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Try again',
          text: "Server did't respond",
        });
        console.error('API error', error);
      }
    };
  }

  return (
    <div className="main-wrapper">
      <div className="login-page">
        <div className="container login-body">
          <div className="loginbox">
            <div className="login-right-wrap">
              <div className="account-header">
                <div className="flex justify-center mb-4 text-center account-logo">
                  <Link to="/">
                    <img src={mycollegepedialogo} title='login-page' alt='clgLogo' className='w-full brightness-110' />
                  </Link>
                </div>
              </div>
              <div className="login-header">
                <h3>
                  Login to <span>MyCollegePedia</span>
                </h3>
                {/* <p className="text-muted">Access to our dashboard</p> */}
              </div>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label
                    htmlFor="email"
                    className="px-2 control-label top-2"
                  >
                  Email Address
                  </label>
                  <input
                    type="text"
                    name="email"
                    placeholder="Enter your email address."
                    autoComplete="username"
                    id="outlined-basic" label="Outlined" variant="outlined"
                    className={` form-control h-[37px] rounded-[4px]  border border-[#222222] text-[#222222] text-[1em]  !focus:text-[.8rem] focus:text-[#909090]`}
                    onChange={handleChange}
                    error="Please enter your email"
                  />
                  {/* <p className="text-[#676767] text-[12px] mt-[5px]">
                    Please enter your email
                  </p> */}
                </div>
                <div className="relative mb-4 form-group">
                  <label className="control-label">Password</label>
                  <input
                    type={viewPassword ? 'text' : 'password'}
                    name="password"
                    placeholder="Enter your password."
                    id="password"
                    required
                    autoComplete="new-password"
                    className={` form-control h-[37px] rounded-[4px]  border border-[#222222] pr-2 text-[#22222] text-[1em]  `}
                    onChange={handleChange}
                  />
                  <div className="absolute transform -translate-y-1/2 cursor-pointer right-4 top-[72%]">
                    {viewPassword ? (
                      <AiFillEye
                        onClick={passwordViewHandler}
                        size="20px"
                        className="text-[#707070]"
                      />
                    ) : (
                      <FaEyeSlash
                        onClick={passwordViewHandler}
                        size="20px"
                        color="#909090"
                      />
                    )}
                  </div>
                </div>
                <div className="text-center">
                  <button
                    className="btn btn-primary  !text-[#052963] hover:!text-white account-btn"
                    type="submit"
                  >
                    Login
                  </button>
                </div>
              </form>
              <div className="mt-4 text-end forgotpass">
                <button onClick={handleforget} className='hover:text-black hover:text-semibold'>Forgot Password?</button>
              </div>
              <div className="login-or">
                <span className="or-line" />
                <span className="span-or">or</span>
              </div>
              <div className="social-login">
                {/* <span>Login with</span>
                <a href="#" className="facebook">
                  <i className="fab fa-facebook-f" />
                </a>
                <a href="#" className="google">
                  <i className="fab fa-google" />
                </a> */}
              </div>
              <div className="text-center dont-have">
                Don’t have an account? <Link to="/signup" className=' text-semibold'>Register</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loginpage