import React, { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { LinearProgress } from "@mui/material";
import Apicontext from "../../Page/Contextapi";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { NavLink } from "react-router-dom";
const StudentProfile = () => {
    const token = useMemo(() => localStorage.getItem('token') || '', []);
    const [Profiledata, setProfiledata] = useState('');
    const [category, setCategory] = useState('');
    const [religion, setReligon] = useState('');
    const [state, setState] = useState('');
    const [Country, setCountry] = useState('');
    const [Loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const Id = useParams();
    console.log("student-profile", Profiledata)

    const headers = useMemo(() => ({
        Authorization: `JWT ${token}`,
    }), [token]);
    const contextdata = useContext(Apicontext);

    useEffect(() => {
        const data = contextdata.APIdata;
        setCategory(data.castecategory)
        setCountry(data.Country)
        setState(data.State)
        setReligon(data.religion)

        const fetchData = async () => {
            const id = Id.id;
            try {
                const Response = await axios.get(`https://portal.mycollegepedia.com/account/student/${id}/`, {
                    headers,
                });
                setProfiledata(Response.data);
                setLoading(false)

            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('token');
                    Swal.fire({
                        icon: 'error',
                        title: 'Please try again Login',
                        text: 'There was an error.'
                    });
                    navigate('/login');
                }
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [headers, navigate, contextdata]);

    if (Loading) {
        return <div>
            <div className="my-10 px-3">
                <LinearProgress />
            </div>
        </div>;
    }
    return (

        <div className="profile_of_student px-3 common_dash mb-5 ">

            <div className="container">
                <div className="row align-items-center top   ">
                    <div className="col-lg-6">

                        <h3>Student Profile</h3>


                    </div>
                    <div className="col-lg-6">
                        <Breadcrumb className="bread">
                            <Breadcrumb.Item ><NavLink to="/college-dashboard">Home</NavLink></Breadcrumb.Item>
                            <Breadcrumb.Item active>Profile</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col">
                        <div className=" card px-auto hit">

                            <div className="row align-items-start">
                                <div className="col-lg-5 col-md-12">

                                    <div className="student_image">
                                        <img src={Profiledata.profile ? Profiledata.profile : " "} alt="student_image" title={Profiledata.name} />
                                    </div>
                                    <div className="name">
                                        <h3 className="clip_stu">
                                            Name : <span>{Profiledata.name}</span>
                                        </h3>
                                    </div>
                                   
                               
                                    <div className=" stu_gender mt-2">
                                        <span id="gender">  Course of Interest :  </span>
                                        <span className="lowercase"> {Profiledata.gender}</span>
                                    </div>

                                </div>

                                <div className="col-lg-7 col-md-12 ">

                                    <ul class="nav nav-pills nav_of_stu">
                                        <li class="nav-item">
                                            <a class="nav-link active" data-toggle="pill" href="#overview" role="tab" aria-controls="pills-overview" aria-selected="true">Overview</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" data-toggle="pill" href="#academic" role="tab" aria-controls="pills-academic" aria-selected="false">Education</a>
                                        </li>




                                    </ul>


                                    <div class="tab-content mt-3">
                                        <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h3 className="hback">Personal Details</h3>

                                                    <div className="second ">
                                                        <div id="display" className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="email">Name : </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span>{Profiledata.name}</span>
                                                            </div>
                                                        </div>
                                                        <div id="display" className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="email">Email : </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span>{Profiledata.email}</span>
                                                            </div>
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="rel">Mobile : </span></div>
                                                            <div className="col-lg-7 col-md-8">

                                                                <span>{Profiledata.mobile}</span></div>
                                                        </div>
                                                        <div className="mb-2 borderbt row">

                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="gender">Gender : </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">

                                                                <span className="lowercase">{Profiledata.gender}</span>
                                                            </div>  </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="gender">Category : </span></div>

                                                            {category && category.map((item, i) =>
                                                                item.id === Profiledata.category ? (
                                                                    <div className="col-lg-7 col-md-8">

                                                                        <span key={i}>
                                                                            <span key={item.id} className="lowercase"> {item.name ? item.name : 'obc'}</span>
                                                                        </span></div>
                                                                ) : null
                                                            )}

                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="dob">D.O.B : </span> </div>
                                                            <div className="col-lg-7 col-md-8">

                                                                <span>{Profiledata.dob} </span></div>
                                                        </div>


                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="id">religion : </span></div>
                                                            {religion && religion.map((item, i) =>
                                                                item.id === Profiledata.religion ? (
                                                                    <div className="col-lg-7 col-md-8">

                                                                        <span key={i}>
                                                                            <span key={item.id}> {item.name ? item.name : 'Hindu'}</span>
                                                                        </span></div>
                                                                ) : null
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h3 className="hback">Address</h3>

                                                    <div className="second">
                                                    <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-8">
                                                                <span id="rel">Country : </span>
                                                            </div>
                                                            {Country && Country.map((india, i) =>
                                                                india.id === Profiledata.country ? (
                                                                    <div className="col-lg-7 col-md-8">

                                                                        <span key={i}>
                                                                            <span key={india.id} className="lowercase"> {india.name ? india.name : 'India'}</span>
                                                                        </span></div>
                                                                ) : null
                                                            )}
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-8">
                                                                <span id="rel">State : </span>
                                                            </div>
                                                            {state && state.map((india, i) =>
                                                                india.id === Profiledata.state ? (
                                                                    <div className="col-lg-7 col-md-8">

                                                                        <span key={i}>
                                                                            <span key={india.id} className="lowercase"> {india.name ? india.name : 'Mp'}</span>
                                                                        </span></div>
                                                                ) : null
                                                            )}
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-8">
                                                                <span id="rel">city : </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span>{Profiledata.city}</span></div>
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-8">
                                                                <span id="rel">Current Address : </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">

                                                                <span>{Profiledata.current_address}</span></div>
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-8">
                                                                <span id="rel">Permament Address : </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">

                                                                <span>{Profiledata.permanent_address}</span>
                                                            </div>
                                                        </div>
                                                     
                                                     
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-8">
                                                                <span id="rel">zipcode : </span>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8">

                                                                <span>{Profiledata.zipcode}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="academic" role="tabpanel" aria-labelledby="profile-tab">
                                            <div className="row">

                                                <div className="col-lg-12">
                                                    <h3 className="hback">Acedemic Details</h3>
                                                    <div className="second ">
                                                    <div id="display" className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="email">University/College/School: </span></div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span>{Profiledata.educations[0].institute}</span></div>
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="dob">Institute : </span> </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span>{Profiledata.educations[0].institute_name} </span></div>
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4 ">
                                                                <span className="">
                                                                    Degree :</span> </div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span className="ltext">{Profiledata.educations[0].degree}</span>
                                                            </div>
                                                        </div>
                                                     
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="gender">Course Stream : </span></div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span>{Profiledata.educations[0].field_of_study}</span></div>
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="id">Grade : </span></div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span>{Profiledata.educations[0].score}</span></div>
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="id">Score : </span></div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span>{Profiledata.educations[0].score}</span></div>
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="id">Field of Study : </span></div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span>{Profiledata.educations[0].end_date}</span></div>
                                                        </div>
                                                        <div className="mb-2 borderbt row">
                                                            <div className="col-lg-5 col-md-4">
                                                                <span id="id">Passing Year : </span></div>
                                                            <div className="col-lg-7 col-md-8">
                                                                <span>{Profiledata.educations[0].end_date}</span></div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div >

    );
};

export default StudentProfile;