import React, { useState, useEffect, useMemo, useContext } from 'react';
import axios from 'axios';
import { FaEye, FaPen, FaPlus, FaTrash } from 'react-icons/fa';
import { Link, NavLink } from 'react-router-dom';
import Apicontext from '../../Page/Contextapi';
import { LinearProgress } from "@mui/material";
import Swal from 'sweetalert2';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import BlogUpdate from './BlogUpdate';

const Blog = () => {
  const token = useMemo(() => localStorage.getItem('token') || '', []);
  const [blog, setBlog] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [selectedBlogId, setSelectedBlogId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const contextdata = useContext(Apicontext);
  const headers = useMemo(() => ({
    Authorization: `JWT ${token}`,
  }), [token]);

  useEffect(() => {
    const data = contextdata.APIdata;
    // setCategory(data.category);

    const fetchData = async () => {
      try {
        const response = await axios.get('https://portal.mycollegepedia.com/blog/blogs/', {
          headers,
        });
        // console.log(response)
        setLoading(false)
        setBlog(response.data);
      } catch (error) {
        console.error('Error fetching blog entries:', error);
      }
    };

    fetchData();
  }, [headers, contextdata, showModal]);

  const handleDeleteBlogEntry = async (blogId) => {
    try {
      await axios.delete(`https://portal.mycollegepedia.com/blog/blogs/${blogId}/`, {
        headers,
      });

      setBlog(prevBlogEntries => prevBlogEntries.filter(blogEntry => blogEntry.id !== blogId));
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
      Toast.fire({
        icon: "success",
        title: "deleted successfully"
      });
    } catch (error) {
      console.error('Error deleting blog entry:', error);
    }
  };
  const handleEditBlog = (blogId) => {
    setSelectedBlogId(blogId);
    setShowModal(true);
  };
  if (Loading) {
    return (
        <>
            <p className="my-10 p-3">
                {" "}
                <LinearProgress />
            </p>
        </>
    );
}
  return (
    <div className=" container common_dash px-3 my-3">
      <div className="row align-items-center top">
        <div className="col-lg-6">

          <h3>College-blogs</h3>
        </div>
        <div className="col-lg-6">
          <Breadcrumb className="bread">
            <Breadcrumb.Item ><NavLink to="/college-dashboard">Home</NavLink></Breadcrumb.Item>
            <Breadcrumb.Item active>Accepted</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      {showModal && selectedBlogId !== null ?
        <BlogUpdate handlefunction={() => setShowModal(false)} blogid={selectedBlogId} />
        :
        <div>
          <div className='row justify-end p-0 m-0'>
            <Link to={"/college-dashboard/add-blog"}
              className="btn-sm shadow-sm flex gap-1.5 my-3 text-white items-center border bg-[#052963]"
            >
              <FaPlus />
              Add
            </Link>
          </div>

          <div className="row">
            {blog && blog.map((item, index) => (
              <div key={index} className="col-md-4">
                <div className="card p-3">
                  <div className='image_of_blog'>
                    <img src={item.image} className="card-img-top" alt={item.title} title={item.title} />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">{item.title}</h5>
                    <p className="card-text line-clamp-6" dangerouslySetInnerHTML={{ __html: item.post }}></p>
                  </div>
                  <div className='flex justify-end gap-4 my-2'>
                    <Link
                      className=" btn-sm text-white bg-success p-2"
                      to={`/blog/${item.slug}`} aria-label={item.title}
                    >
                      <FaEye />
                    </Link>
                    <button
                  className="btn-sm text-white bg-warning p-2"
                  onClick={() => handleEditBlog(item.slug)}
                >
                  <FaPen />
                  
                </button>
                    <button
                      className=" btn-sm text-white bg-danger p-2"
                      onClick={() => handleDeleteBlogEntry(item.slug)}
                    >
                      <FaTrash />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      }
    </div>
  );
};

export default Blog;