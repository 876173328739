import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import axios from 'axios';
import CNF from "../../assets/images/clgnotfound.png";
import { LinearProgress } from '@mui/material';
import StarRating from '../Homepages/Startrating';
const CollegeCategory = () => {
    const { courseCategory } = useParams();
    const [collegeData, setCollegeData] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {

                const response = await axios(`https://portal.mycollegepedia.com/college/all-college/?course_category=${courseCategory}`);
                const data = response.data;
                setCollegeData(data);
                setLoading(false)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [courseCategory]);

    const applyFilters = (collegeData, searchQuery) => {
        if (!searchQuery) {
            return collegeData;
        }
        return collegeData.filter((college) =>
            college.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    const filteredCourse = applyFilters(collegeData || [], searchQuery);

    if (loading) {
        return (
            <div className='my-10 '>
                <LinearProgress />
            </div>
        );
    }
    if (!collegeData || Object.keys(collegeData).length === 0) {
        return (
            <>
                <section className="breadcrumb-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h3 className="ab-1">Category College</h3>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page"><Link to="/Allcollege">All College</Link></li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='mx-auto text-center col-lg-6 col-md-12'>
                    <div className='pt-10 text-2xl text-red-500'>
                        <p>No found for college that state...<span className='font-bold text-green-600'>Try Again</span></p>
                    </div>
                    <div className=''>
                        <img src={CNF} title='not found' alt='errorimg' className="mx-auto text-6xl text-red-500 " />
                    </div>
                </div>
            </>
        );
    }
    return (
        <div>
            <section className="breadcrumb-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="ab-1">College Category </h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page"><Link to="all-colleges">{courseCategory}</Link></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container mt-3 colleges-main-section">
                <div className=' my-4 w-full md:w-[60%] top-16 sticky z-10 rounded-md bg-slate-300 shadow-gray-800 shadow-lg mx-auto '>
                    <div className="justify-center space-y-3 ">
                        <input
                            type="text"
                            placeholder="Search by college..."
                            defaultValue={"Serch by College"}
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            className="w-full p-2 px-2 py-3 text-black border-2 border-gray-400 rounded-md"
                        />
                    </div>
                </div>
                <div className="row">
                    {!filteredCourse.length > 0 ?

                        <div className='mx-auto text-center col-lg-6 col-md-12'>
                            <div className='pt-10 text-2xl text-red-500'>
                                <p>No found for college {courseCategory}.<span className='font-bold text-green-600'>Try Again</span></p>
                            </div>
                            <div className=''>
                                <img src={CNF} title='error' alt='errorimg' className="mx-auto text-6xl text-red-500 " />
                            </div>
                        </div> :

                        filteredCourse && filteredCourse.map((college) => (
                            <div className="col-md-4" key={college.id}>
                                <div className="card_college" >
                                    <img className="card-img-top mba-img" src={college.image}
                                        alt="Collegeimage" title={college.name} />
                                    <div className="card-body">
                                        <div className="main d-flex align-item-center">
                                            <div className="logocard">
                                                <img className="" src={college.logo} alt={college.name} title={college.name} />
                                            </div>

                                        </div>
                                        <div className="d-flex head">
                                            <div className="mba">
                                                <h6 className="title"> {college.course_category[0].name}</h6>
                                                {/* <p>25.00 Lacks Total Fees</p> */}
                                            </div>
                                            {/* <div className="mba1">
                                                <h6 className="title">(#{college.rank}/100)</h6>
                                                <p>51 Reviews</p>
                                            </div> */}
                                            <div className="mba1">
                                                <h6 className="title d-flex text-warning"><StarRating rating={college.rating} /></h6>
                                                {/* <p>51 Reviews</p> */}
                                            </div>
                                            <small className='small-c'> {college.organization_type.name}</small>
                                        </div>
                                        <div className="card-pera">
                                            <h4>{college.name}</h4>
                                        </div>
                                        <div>
                                            <small className="pt-1 adress">
                                                <span > {college.current_address}</span>
                                            </small>
                                            <small className="pt-1 lowercase adress">
                                                {/* 
                                       {CollegeData.State.map((state) =>
                                          state.id === college.state ? (
                                             <>
                                                {college.city}
                                                <span key={state.id}> {state.name}</span>
                                                <span className="web-loction-name"> India</span>
                                             </>
                                          ) : null
                                       )} */}

                                            </small>
                                        </div>
                                        <div className="link">
                                            <Link to={`/college/${college.slug}`} title='more-view' className="py-2">View All Course and Fees <FaArrowRight /></Link>
                                            {/* <Link to={`/broucher/college.brochure`} className="py-2">Download Broucher<FaArrowRight /></Link> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                        ))
                    }
                </div>

            </div>
        </div>
    );
};

export default CollegeCategory;
